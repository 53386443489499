import React from 'react'
import { bool, func, object } from 'prop-types'
import CustomerOpeningHours from 'views/Customer/CustomerOpeningHours'

import { CustomerInfoSheet } from 'components/CustomerCard'
import { Modal } from 'components/Modal'

const StoreInfoModal = ({ open, onOpenChange, customer }) => {
  return (
    <Modal title="Store info" open={open} onOpenChange={onOpenChange} onHandeClose={() => onOpenChange(false)}>
      <div className="space-y-6">
        <CustomerInfoSheet customer={customer} />
        <div className="space-y-2">
          <h1>Opening hours</h1>
          <CustomerOpeningHours customerId={+customer.id} customerHours={customer.openingHours} />
        </div>
      </div>
    </Modal>
  )
}

StoreInfoModal.propTypes = {
  open: bool,
  onOpenChange: func,
  customer: object
}

export default StoreInfoModal
