import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import needsAuth from 'layouts/authWrappers/needsAuth'
import { capitalize, startCase } from 'lodash'

import LangContext from 'context/LangContext'

import { fetchGoFundExceptionForApproval, updateGoFundExceptions } from 'store/goFundExceptions/endpoints'

import Button from 'components/button/Button'
import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import GlobalHeader from 'components/Header'
import Input from 'components/Input'

import { pricify } from 'utils/helpers'

import { goBlue } from 'styles/colors'

const ExceptionApprovalLayout = () => {
  const { exceptionId } = useParams()
  const user = useSelector((state) => state.auth.user)
  const { formatMessage } = useContext(LangContext)

  const [approvalReason, setApprovalReason] = useState()
  const [goFundException, setGoFundException] = useState()
  const [error, setError] = useState(null)
  const [userHasAccess, setUserHasAccess] = useState(true)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [exceptionWasAlreadyHandled, setExceptionWasAlreadyHandled] = useState(false)

  useEffect(() => {
    let mounted = true

    setLoading(true)
    fetchGoFundExceptionForApproval(exceptionId)
      .then(({ data }) => {
        if (mounted) {
          if (data.goFundException) setGoFundException(data.goFundException)
          if (data.error) setError(data.error)
        }
      })
      .catch((err) => {
        if (mounted) {
          setError(err.message)
        }
      })
      .finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [])

  const updateException = async (status) => {
    let mounted = true
    setError()

    if (status === 'rejected' && (!approvalReason || approvalReason === '')) {
      setError(formatMessage({ messageId: 'goFunds.exception.requestedReason' }))
      return
    }

    setLoading(true)
    try {
      await updateGoFundExceptions({
        id: goFundException.id,
        status,
        approvalReason
      })
      setSuccess(true)
    } catch (err) {
      if (mounted) {
        setError(err.message)
      }
    } finally {
      if (mounted) {
        setLoading(false)
      }
    }

    return () => {
      mounted = false
    }
  }

  useEffect(() => {
    if (!goFundException || !goFundException.requester) return
    const isTeamLead = goFundException.requester.teamLeadId === user.id
    const isRda = goFundException.requester.rda && goFundException.requester.rda.id === user.id
    if (!isTeamLead && !isRda) {
      setUserHasAccess(false)
      setError(formatMessage({ messageId: 'goFunds.exception.notRequesterManagerError' }))
    }

    if (goFundException.status !== 'awaitingManagerApproval') {
      setExceptionWasAlreadyHandled(true)
    }
  }, [goFundException, user])

  const payeeContactRole = useMemo(() => {
    if (
      goFundException &&
      goFundException.payeeId &&
      goFundException.customer &&
      goFundException.customer.storeContacts
    ) {
      const { payeeId, customer } = goFundException
      const contact = customer.storeContacts.find((c) => c.id === payeeId)
      if (contact && contact.role) {
        switch (contact.role) {
          case 'B2B Store Owner':
            return 'Owner'
          case 'B2B Primary Contact':
            return 'Manager'
          case 'B2B Clerk':
            return 'Clerk'
        }
      }
    }
  }, [goFundException])

  return (
    <Formik initialValues={goFundException}>
      <Form>
        <GlobalHeader color={goBlue} title="Extra Funds Exception Approval" />
        <Container>
          <Fieldset>
            {success ? (
              <FieldsetItem>
                <GlobalAlert warning>Your exception has been successfully submitted</GlobalAlert>
              </FieldsetItem>
            ) : (
              goFundException && (
                <>
                  <FieldsetItem>
                    <h2>
                      {capitalize(goFundException.goFund.englishName)} / {capitalize(goFundException.goFund.frenchName)}
                    </h2>
                  </FieldsetItem>
                  <FieldsetItem>
                    <b>Requested amount :</b> {pricify(goFundException.requestedAmount)}
                  </FieldsetItem>
                  <FieldsetItem>
                    <b>Requested by :</b> {capitalize(goFundException.requester.firstName)}{' '}
                    {capitalize(goFundException.requester.lastName)}
                  </FieldsetItem>
                  <FieldsetItem>
                    <b>Payee :</b> {capitalize(goFundException.payee.firstname)}{' '}
                    {capitalize(goFundException.payee.lastname)}{' '}
                    {payeeContactRole && `${capitalize(goFundException.payee.role)}`}
                  </FieldsetItem>
                  <FieldsetItem>
                    <b>Reason :</b> {goFundException.details}
                  </FieldsetItem>
                  <FieldsetItem>
                    <b>Status :</b> {startCase(goFundException.status)}
                  </FieldsetItem>
                  <FieldsetItem>
                    <Input
                      disabled={!userHasAccess || loading || exceptionWasAlreadyHandled}
                      textarea
                      label="Approval / Rejection reason"
                      input={{
                        onChange: ({ target }) => setApprovalReason(target.value),
                        value: approvalReason
                      }}
                      placeholder={
                        exceptionWasAlreadyHandled
                          ? goFundException.approvalReason || 'No reason provided'
                          : 'Please add a reason for the approval or rejection...'
                      }
                    />
                  </FieldsetItem>
                  <FieldsetItem half>
                    <Button
                      color={'#FA403C'}
                      full
                      onClick={() => updateException('rejected')}
                      disabled={!userHasAccess || loading || exceptionWasAlreadyHandled}
                    >
                      Reject
                    </Button>
                  </FieldsetItem>
                  <FieldsetItem half>
                    <Button
                      color={'#15CD96'}
                      full
                      onClick={() => updateException('awaitingAdminApproval')}
                      disabled={!userHasAccess || loading || exceptionWasAlreadyHandled}
                    >
                      Approve
                    </Button>
                  </FieldsetItem>
                </>
              )
            )}
            {error && (
              <FieldsetItem>
                <GlobalAlert>{error}</GlobalAlert>
              </FieldsetItem>
            )}
          </Fieldset>
        </Container>
      </Form>
    </Formik>
  )
}

export const ManagerExceptionApprovalLayout = needsAuth(ExceptionApprovalLayout)
