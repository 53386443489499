import React from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from 'components/Sidebar'

const MainLayout = () => {
  return (
    <div className="flex h-screen bg-slate-100 text-sm">
      <div className="max-md:hidden">
        <Sidebar />
      </div>
      <Outlet />
    </div>
  )
}

export default MainLayout
