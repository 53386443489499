// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { func, node, number, object, string } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { fetchPlanningHealthCheck } from 'store/HealthCheck/actions'
import { planningHealthCheckData } from 'store/HealthCheck/selectors'
import { getCurrentProductType } from 'store/sector/selectors'

import IconButton from 'components/button/IconButton'
import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import VariationIndicator from 'components/VariationIndicator'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const Header = tw.span`text-lg font-semibold text-slate-500`

const DataL = ({ header, children, variation, additional }) => (
  <div className="flex w-full flex-col items-center space-y-3">
    <Header>{header}</Header>
    <div className="flex flex-col items-center space-y-2">
      <span className="text-3xl font-medium text-slate-900">{children}</span>
      <div className="flex items-center space-x-2">
        <VariationIndicator variation={variation} />
        <span className="text-2xs font-medium text-slate-500">
          {variation > 0 ? '+' : variation < 0 ? '-' : ''}
          {Math.abs(variation)} {additional}
        </span>
      </div>
    </div>
  </div>
)

DataL.propTypes = {
  header: string,
  additional: string,
  children: node,
  variation: number
}

const DataSm = ({ header, children }) => (
  <div className="flex flex-col items-center space-y-2">
    <Header>{header}</Header>
    {children}
  </div>
)

DataSm.propTypes = {
  header: string,
  children: node
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const VerticalLine = tw.div`w-px h-20 bg-slate-200`

const TopSection = tw.div`space-y-5`

const BottomSection = tw.div`flex w-full space-x-4  items-center`

const Title = tw.span`inline-flex items-center h-9 text-xl font-semibold text-slate-900`

const HealthCheckCard = ({ fetchPlanningHealthCheck, span, amplifyAction, oosInvAmplify }) => {
  const { currentSector, selectedLevel } = useContext(SectorContext)
  const isMounted = useRef()

  const [error, setError] = useState()

  const healthCheckData = useSelector((state) => planningHealthCheckData(state))

  const activeProductType = useSelector((state) => getCurrentProductType(state))

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_HEALTH_CHECK, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: activeProductType
  })

  useEffect(() => {
    isMounted.current = true
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchPlanningHealthCheck(
        { id: currentSector[selectedLevel].id, sectorLevel: selectedLevel, activeProductType },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [currentSector, selectedLevel, activeProductType])

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  return (
    <Card title="Health check" span={span} amplifyAction={amplifyAction} amplifySize={2}>
      <TopSection>
        <Eyebrow />
        <div className="space-y-4">
          <DataL header="Distro" variation={healthCheckData.distro.variation} additional="vs PW">
            {healthCheckData.distro.percent}%
          </DataL>
          <div className="flex items-center">
            <div className="w-full">
              <DataSm header="Stores">
                <div className="flex space-x-1 font-medium text-slate-900">
                  <span className="font-medium text-slate-900">{healthCheckData.stores}%</span>
                </div>
              </DataSm>
            </div>
            <VerticalLine />
            <div className="w-full">
              <DataSm header="SKUs">
                <span className="flex font-medium text-slate-900">{healthCheckData.skus}%</span>
              </DataSm>
            </div>
          </div>
        </div>
        <Eyebrow />
      </TopSection>
      <div className="flex items-center justify-between">
        <Title>Inv. capture & OOS</Title>
        <div className="space-x-3">
          <IconButton secondary icon="three-stars" onClick={oosInvAmplify}>
            Amplify
          </IconButton>
        </div>
      </div>
      <BottomSection>
        <div className="w-full">
          <DataL header="OOS" variation={healthCheckData.oos.variation} additional="vs PW">
            {healthCheckData.oos.percent}%
          </DataL>
        </div>
        <VerticalLine />
        <div className="w-full">
          <DataL header="Inv capture" variation={healthCheckData.inventoryCaptures.variation} additional="vs PW">
            {healthCheckData.inventoryCaptures.percent}%
          </DataL>
        </div>
      </BottomSection>
    </Card>
  )
}

HealthCheckCard.propTypes = {
  fetchPlanningHealthCheck: func,
  span: object,
  amplifyAction: func,
  oosInvAmplify: func
}

const mapActionCreators = {
  fetchPlanningHealthCheck
}

export default connect(null, mapActionCreators)(HealthCheckCard)
