import React, { createContext, useState } from 'react'
import { node } from 'prop-types'

const SellinContext = createContext()
export default SellinContext
/**
 * HOW TO USE:
 *
 * In a component where you want to use actions in the header, import addAction from this context
 * Call the method with an object containing the name of an action as well as it implementation
 *
 * addAction({
 *  doSomething: () => alert("Hello World!")
 * })
 *
 * In the HeaderContent.js file, go to the section you want to bind an action to.
 * headerActions is a method that gets passed the context actions. You can extract your action from there.
 *
 * orders: {
 *      headerActions: ({ doSomething }) => [
 *          <Button onClick={doSomething} />
 *      ]
 * }
 */
export const SellinProvider = ({ children }) => {
  const [vapeCategory, setVapeCategory] = useState('all')

  const value = {
    vapeCategory,
    setVapeCategory
  }

  return <SellinContext.Provider value={value}>{children}</SellinContext.Provider>
}

SellinProvider.propTypes = {
  children: node.isRequired
}
