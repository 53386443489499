export function storeFootprint(state, { payload: { id, ...data } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        storeFootprint: {
          ...data
        }
      }
    }
  })
}

export function brandCompletion(state, { payload: { id, brandCompletion, category } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        [`brandCompletion-${category}`]: brandCompletion
      }
    }
  })
}

export function filters(state, { payload: { id, filters } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        filters
      }
    }
  })
}

export function geography(state, { payload: { id, geography, category, filters, data } }) {
  const { channel = 'all', ownershipType = 'all', banner = 'all', headoffice = 'all' } = filters
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        geographies: {
          ...state[id]?.priceCaptureCompliance?.geographies,
          [`geography-${geography}-${category}-${channel}-${ownershipType}-${banner}-${headoffice}`]: data
        }
      }
    }
  })
}

export function strategyCompliance(state, { payload: { id, strategyCompliance, category } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        [`strategy-compliance-${category}`]: strategyCompliance
      }
    }
  })
}

export function edlpCompliance(state, { payload: { id, edlpCompliance } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        edlpCompliance
      }
    }
  })
}

export function priceComplianceGeographyFilters(state, { payload: { id, filters: priceComplianceGeographyFilters } }) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        priceComplianceGeographyFilters
      }
    }
  })
}

export function priceComplianceGeography(
  state,
  {
    payload: {
      id,
      geography,
      category,
      data,
      filters: {
        channel = 'all',
        headoffice = 'all',
        banner = 'all',
        packType = 'all',
        packCount = 'all',
        strategyType = 'all',
        ownershipType = 'all'
      }
    }
  }
) {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      priceCaptureCompliance: {
        ...state[id]?.priceCaptureCompliance,
        priceComplianceGeographies: {
          ...state[id]?.priceCaptureCompliance?.priceComplianceGeographies,
          [`geography-${geography}-${category}-${channel}-${headoffice}-${banner}-${packType}-${packCount}-${strategyType}-${ownershipType}`]:
            data
        }
      }
    }
  })
}
