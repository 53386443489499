import React from 'react'
import { number } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'

const NoVariation = tw.div``

const IconWrapper = styled.div(({ variation }) => [variation > 0 ? tw`text-green-500` : tw`text-red-500 rotate-180`])

const VariationIndicator = ({ variation }) => {
  return variation ? (
    <IconWrapper variation={variation}>
      <Icon caret icon="caret" />
    </IconWrapper>
  ) : (
    <NoVariation />
  )
}

VariationIndicator.propTypes = {
  variation: number.isRequired
}

export default VariationIndicator
