import React from 'react'
import { bool, func, object } from 'prop-types'

import ActionSheet from 'components/ActionSheet'
import Container from 'components/Container'
import GlobalAlert from 'components/GlobalAlert'
import Section from 'components/Section'

const EndOngoingCallWarning = ({ visible, close, ongoingCall }) => {
  return (
    <ActionSheet
      title="In-Call Warning"
      action={
        <button type="button" onClick={() => close()}>
          Close
        </button>
      }
      visible={visible}
      onOverlayClick={() => close()}
    >
      <Container noPadding>
        <Section>
          <GlobalAlert>
            You are too already in a call with {ongoingCall.customer.name}. You must end it before starting a call with
            another customer.
          </GlobalAlert>
        </Section>
      </Container>
    </ActionSheet>
  )
}

EndOngoingCallWarning.propTypes = {
  close: func.isRequired,
  ongoingCall: object,
  visible: bool
}

export default EndOngoingCallWarning
