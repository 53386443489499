import React from 'react'
import snakeCase from 'lodash/snakeCase'
import { array, bool, object } from 'prop-types'
import tw, { styled } from 'twin.macro'

const TD = tw.td`px-3 py-3 first:pl-0 last:pr-0 group-last:pb-0 text-sm font-medium text-slate-900`

const ValueWrapper = styled.div(({ alignCenter }) => [tw`flex items-center`, alignCenter && tw` justify-center`])

const TableRow = ({ rowData, alignCenter, columns }) => {
  const rowHeaderCol = rowData[columns[0].field]
  return (
    <tr className="group">
      {columns.map(({ field }, idx) => {
        const value = rowData[field]
        const key = `${field}_${snakeCase(rowHeaderCol)}_${idx}`
        return (
          <TD key={key}>
            <ValueWrapper alignCenter={alignCenter}>{value}</ValueWrapper>
          </TD>
        )
      })}
    </tr>
  )
}

TableRow.propTypes = {
  rowData: object,
  alignCenter: bool,
  columns: array.isRequired
}

const Table = styled.table(({ fillContainer, fullWidth }) => [
  tw`relative table-auto border-separate border-spacing-0`,
  fillContainer && tw`h-full w-full`,
  fullWidth && !fillContainer && tw`w-full`
])

const TableHeader = styled.th(({ alignCenter }) => [
  tw`sticky bg-white z-10 top-0 pb-3 px-3 first:pl-0 last:pr-0 text-lg font-medium text-slate-500 border-b border-slate-300`,
  alignCenter ? tw`text-center` : tw`text-left`
])

const DataTable = ({ columns, rows, alignCenter, fillContainer, fullWidth }) => {
  return (
    <div className="flow-root h-full">
      <Table fillContainer={fillContainer} fullWidth={fullWidth}>
        <thead>
          <tr>
            {columns.map((column) => (
              <TableHeader key={column.field} scope="col" alignCenter={alignCenter}>
                {column.headerName}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <TableRow key={i} rowData={row} alignCenter={alignCenter} columns={columns} />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

DataTable.propTypes = {
  columns: array,
  rows: array,
  alignCenter: bool,
  fillContainer: bool,
  fullWidth: bool
}

export default DataTable
