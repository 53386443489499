import queryString from 'query-string'

import { api } from 'store/api'

const getRoot = (sectorType, sectorId) => `/price-capture-compliance/${sectorType}/${sectorId}`
export const getStoreFootprint = (sectorType, sectorId) => api.get(`${getRoot(sectorType, sectorId)}/store-footprint`)

export const getBrandCompletion = (sectorType, sectorId, category) =>
  api.get(`${getRoot(sectorType, sectorId)}/brand-completion?${queryString.stringify({ category })}`)

export const getFilters = (sectorType, sectorId) => api.get(`${getRoot(sectorType, sectorId)}/filters`)

/**
 * @typedef Filters
 * @property {string} channel
 * @property {string} ownershipType
 * @property {string} banner
 * @property {string} headoffice
 */
/**
 * @param {string} sectorType
 * @param {string} sectorId
 * @param {string} geography
 * @param {Filters} filters
 */
export const getGeography = (sectorType, sectorId, geography, category, filters) =>
  api.get(`${getRoot(sectorType, sectorId)}/geography?${queryString.stringify({ ...filters, geography, category })}`)

export const getStrategyCompliance = (sectorType, sectorId, category) =>
  api.get(`${getRoot(sectorType, sectorId)}/strategy-compliance?${queryString.stringify({ category })}`)

export const getEdlpCompliance = (sectorType, sectorId) => api.get(`${getRoot(sectorType, sectorId)}/edlp-compliance`)

export const getPriceComplianceGeographyFilters = (sectorType, sectorId) =>
  api.get(`${getRoot(sectorType, sectorId)}/price-compliance-geography-filters`)

/**
 * @typedef PriceComplianceGeographyFilters
 * @property {string} channel
 * @property {string} ownershipType
 * @property {string} banner
 * @property {string} headoffice
 */
/**
 * @param {string} sectorType
 * @param {string} sectorId
 * @param {string} geography
 * @param {string} category
 * @param {PriceComplianceGeographyFilters} filters
 */
export const getPriceComplianceGeography = (sectorType, sectorId, geography, category, filters) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/price-compliance-geography?${queryString.stringify({
      ...filters,
      geography,
      category
    })}`
  )
