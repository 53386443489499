import { addEntities, mergeEntities, setEntities } from 'store/actions'

// import { addCycleCampaigns } from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => ({
    ...state,
    ...action.payload.cycleCampaign
  }),
  [setEntities]: (state, action) => {
    if (!action.payload.cycleCampaign) return state
    return Object.entries(action.payload.cycleCampaign).reduce((mergedCampaigns, [id, campaign]) => {
      return {
        ...mergedCampaigns,
        [id]: {
          ...(state[id] || {}),
          ...campaign
        }
      }
    }, {})
  },
  [mergeEntities]: (state, action) => {
    if (!action.payload.cycleCampaign) return state
    return Object.entries(action.payload.cycleCampaign).reduce((mergedCampaigns, [id, campaign]) => {
      return {
        ...mergedCampaigns,
        [id]: {
          ...(state[id] || {}),
          ...campaign
        }
      }
    }, state)
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
