import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'
import omit from 'lodash/omit'
import { func, number, object } from 'prop-types'
import styled from 'styled-components'

import { updateCustomerHours } from 'store/customers/actions'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Dropdown from 'components/Dropdown'

import { DEFAULT_OPENING_HOURS, TIME_OPTIONS } from 'utils/constants'

import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

const Table = styled.table`
  width: 100%;
  margin-bottom: ${spacing.medium};

  td,
  th {
    padding: ${spacing.small};
    font-size: ${tinyFontSize};
    position: relative;

    @media print {
      padding: ${spacing.tiny};
      font-size: ${spacing.small};
    }
  }

  th {
    text-align: left;
    -webkit-print-color-adjust: exact;
  }
`

const RenderHeaders = () => {
  return (
    <thead>
      <tr>
        <th>Day</th>
        <th>Opens</th>
        <th>Closes</th>
      </tr>
    </thead>
  )
}

const DisplayOpeningHours = ({ openingHours, startEditing }) => {
  return (
    <Fragment>
      <Table>
        <RenderHeaders />
        <tbody>
          {openingHours &&
            Object.entries(openingHours).map(([key, value]) => {
              const { open, close } = value
              return (
                <tr key={`opening-hour-row-${key}`}>
                  <td>{capitalize(key)}</td>
                  <td>{TIME_OPTIONS.find((o) => o.value === open).label}</td>
                  <td>{TIME_OPTIONS.find((o) => o.value === close).label}</td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      <Button onClick={startEditing} secondary full>
        Edit
      </Button>
    </Fragment>
  )
}

DisplayOpeningHours.propTypes = {
  openingHours: object,
  startEditing: func
}

const EditOpeningHours = ({ openingHours, setOpeningHours, saveHours, cancelEditing }) => {
  return (
    <Fragment>
      <Table>
        <RenderHeaders />
        <tbody>
          {openingHours &&
            Object.entries(openingHours).map(([key, value]) => {
              const { open, close } = value
              return (
                <tr key={`opening-hour-edit-row-${key}`}>
                  <td>{capitalize(key)}</td>
                  <td>
                    <Dropdown
                      options={TIME_OPTIONS}
                      onChange={({ target }) =>
                        setOpeningHours({ ...openingHours, [key]: { ...value, open: target.value } })
                      }
                      value={open}
                    />
                  </td>
                  <td>
                    <Dropdown
                      options={TIME_OPTIONS}
                      onChange={({ target }) =>
                        setOpeningHours({ ...openingHours, [key]: { ...value, close: target.value } })
                      }
                      value={close}
                    />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      <ButtonGroup full>
        <Button onClick={saveHours} primary full>
          Save
        </Button>
        <Button onClick={cancelEditing} secondary full>
          Cancel
        </Button>
      </ButtonGroup>
    </Fragment>
  )
}

EditOpeningHours.propTypes = {
  openingHours: object,
  setOpeningHours: func,
  saveHours: func,
  cancelEditing: func
}

const CustomerOpeningHours = ({ customerHours, customerId, updateCustomerHours }) => {
  const hours = customerHours
    ? omit(customerHours, ['id', 'customerId', 'createdAt', 'updatedAt'])
    : { ...DEFAULT_OPENING_HOURS }

  const [isEditing, setIsEditing] = useState(false)
  const [backupHours, setBackupHours] = useState({})
  const [openingHours, setOpeningHours] = useState(hours)

  const today = new Date()
  const { open, close } = openingHours[Object.keys(DEFAULT_OPENING_HOURS)[today.getDay()]]
  const currentTime = `${today.getHours()}:${today.getMinutes()}`.padStart(5, '0')

  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }

  const startEditing = () => {
    setBackupHours(openingHours)
    toggleEditing()
  }

  const saveHours = () => {
    updateCustomerHours(customerId, openingHours)
    toggleEditing()
  }

  const cancelEditing = () => {
    setOpeningHours(backupHours)
    toggleEditing()
  }

  return (
    <Fragment>
      <BasicAccordion title={`${currentTime > open && currentTime < close ? 'Open' : 'Closed'} : ${open} - ${close}`}>
        {isEditing ? (
          <EditOpeningHours
            cancelEditing={cancelEditing}
            saveHours={saveHours}
            openingHours={openingHours}
            setOpeningHours={setOpeningHours}
          />
        ) : (
          <DisplayOpeningHours openingHours={openingHours} startEditing={startEditing} />
        )}
      </BasicAccordion>
    </Fragment>
  )
}

CustomerOpeningHours.propTypes = {
  updateCustomerHours: func.isRequired,
  customerId: number,
  customerHours: object
}

const mapActionCreators = {
  updateCustomerHours
}

export default connect(null, mapActionCreators)(CustomerOpeningHours)
