import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { stripUnit } from 'polished'
import { bool, func, object, string } from 'prop-types'
import GMapsLogo from 'static/images/directions/gmaps.png'
import WazeLogo from 'static/images/directions/waze.png'
import styled from 'styled-components'

import ScheduleContext from 'context/ScheduleContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Section from 'components/Section'

import { getAddressString } from 'utils/helpers'

import { borderColor, greyDark } from 'styles/colors'
import { borderRadius } from 'styles/global'
import * as spacing from 'styles/spacing'
import { fontSemibold, H1, H3, smallFontSize } from 'styles/typography'

// Test for staging

const inPersonLocationErrors = {
  denied: 'You must enable location tracking in NOD settings and on your device.',
  outsideRadius: 'You are too far to start an in-person visit. Move closer to the store.'
}

const inPersonLocationWarnings = {
  unavailable:
    'The acquisition of the geolocation failed because at least one internal source of position returned an internal error',
  timeout: 'It took too long to get your location.'
}

const Directions = styled.div`
  text-align: left;
`

const Actions = styled.div`
  display: flex;
  width: 100%;
`

const Action = styled(Button)`
  flex: 1;

  & + & {
    margin-left: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }
`

const Brand = styled.img`
  width: 24px;
  margin-right: ${spacing.small};
  border: 1px solid ${borderColor};
  border-radius: ${borderRadius};
`

const Title = styled(H1)`
  margin-bottom: ${spacing.tiny};

  small {
    display: inline-block;
    margin-left: ${spacing.small};
    vertical-align: text-bottom;
  }
`

const Address = styled.small`
  display: flex;
  align-items: center;
  gap: ${spacing.tiny};
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.medium};

  > div {
    flex: 1;
  }
`

const MetaLabel = styled.span`
  display: block;
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  color: ${greyDark};
`

const StartCall = ({
  startCall,
  startCallScreenVisible,
  setStartCallScreenVisible,
  call,
  inPersonAllowed,
  inPersonCallLocationError,
  editCall,
  inOngoingCall,
  loadingLocation,
  employee
}) => {
  const { isDuringOffTime } = useContext(ScheduleContext)

  const [showOotWarning, setShowOotWarning] = useState(false)
  const [intendedType, setIntendedType] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [call])

  useEffect(() => {
    setShowOotWarning(false)
  }, [startCallScreenVisible])

  const callCustomerAssignedToEmployee = useMemo(() => {
    if (!call?.customer?.primaryTerritory) return true // this is for safety to avoid disabling a call starting just because the primary territory is missing.
    return (
      call?.customer?.primaryTerritory &&
      employee.territories &&
      employee.territories.includes(call?.customer?.primaryTerritory?.id)
    )
  }, [call?.customer?.primaryTerritory, employee.territories])

  const canStartCall = callCustomerAssignedToEmployee && !inOngoingCall

  const customerAddress = getAddressString(call?.customer.address, ['line1', 'city'])
  const handleWazeClick = () => {
    window.open(`https://waze.com/ul?q=${encodeURIComponent(customerAddress)}&navigate=yes`)
  }

  const handleGoogleMapsClick = () => {
    if (!customerAddress) return
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(customerAddress)}`)
  }

  const handleStartClick = (type) => {
    if (!canStartCall) return
    if (isDuringOffTime(moment())) {
      setShowOotWarning(true)
      setIntendedType(type)
    } else {
      startCall(type)
      setLoading(true)
    }
  }

  const handleEditCall = () => {
    setStartCallScreenVisible()
    editCall(call)
  }

  const locationError = inPersonCallLocationError && inPersonLocationErrors[inPersonCallLocationError]
  const locationWarning = inPersonCallLocationError && inPersonLocationWarnings[inPersonCallLocationError]
  // const unknownLocationWarning =
  //   !locationError &&
  //   !locationWarning &&
  //   inPersonCallLocationError &&
  //   `UNKNOWN ERROR: ${inPersonCallLocationError} - Please report this to support.`

  return (
    <ActionSheet
      title="Start call"
      action={
        <ButtonGroup>
          {callCustomerAssignedToEmployee && (
            <button type="button" onClick={handleEditCall}>
              Edit
            </button>
          )}
          <button type="button" onClick={() => setStartCallScreenVisible()}>
            Close
          </button>
        </ButtonGroup>
      }
      visible={startCallScreenVisible}
      onOverlayClick={() => setStartCallScreenVisible()}
    >
      <Container noPadding>
        <Section
          as={Link}
          to={{
            pathname: `/customers/${call?.customer?.id}/calls`,
            state: { prevRoute: { pathname: '/schedule' } }
          }}
          onClick={() => setStartCallScreenVisible()}
          disabled={!callCustomerAssignedToEmployee}
        >
          <Title>
            <span>{call?.customer?.name}</span>
            <small>{call?.customer?.id}</small>
          </Title>
          <Address>
            <Icon icon="location" small accent />
            <span>{customerAddress}</span>
          </Address>
        </Section>
        {call?.scheduledStart && (
          <Section>
            <Meta>
              <div>
                <MetaLabel>Start time</MetaLabel>
                <div>{moment(call?.scheduledStart).format('h:mm a')}</div>
              </div>
              {call?.scheduledEnd && (
                <div>
                  <MetaLabel>End time</MetaLabel>
                  <div>{moment(call?.scheduledEnd).format('h:mm a')}</div>
                </div>
              )}
            </Meta>
          </Section>
        )}
        <Section>
          <Fieldset>
            {inOngoingCall && (
              <FieldsetItem>
                <GlobalAlert>You are too currently in a call. You must end it before starting a new one.</GlobalAlert>
              </FieldsetItem>
            )}
            {!callCustomerAssignedToEmployee && (
              <FieldsetItem>
                <GlobalAlert warning>You are no longer assigned to this customer.</GlobalAlert>
              </FieldsetItem>
            )}
            {canStartCall && (
              <>
                <FieldsetItem>
                  <Button
                    full
                    primary
                    onClick={() => handleStartClick('in-person')}
                    disabled={loadingLocation || locationError}
                    isLoading={loading}
                  >
                    Start visit
                  </Button>
                </FieldsetItem>

                <FieldsetItem>
                  <Button full secondary onClick={() => handleStartClick('telemarketing')} isLoading={loading}>
                    Start telemarketing call
                  </Button>
                </FieldsetItem>
                {locationWarning && (
                  <FieldsetItem>
                    <GlobalAlert>{locationWarning}</GlobalAlert>
                  </FieldsetItem>
                )}
                {(loadingLocation || locationError) && (
                  <FieldsetItem>
                    {loadingLocation ? (
                      <GlobalAlert warning>Verifying your location...</GlobalAlert>
                    ) : (
                      locationError && <GlobalAlert>{locationError}</GlobalAlert>
                    )}
                  </FieldsetItem>
                )}
              </>
            )}

            {showOotWarning && (
              <FieldsetItem>
                <GlobalAlert warning>
                  <p>You are about to start a call during an OOT. Are you sure?</p>
                  <ButtonGroup>
                    <Button secondary small onClick={() => startCall(intendedType)}>
                      Yes
                    </Button>
                    <Button secondary small onClick={() => setShowOotWarning(false)}>
                      No
                    </Button>
                  </ButtonGroup>
                </GlobalAlert>
              </FieldsetItem>
            )}

            {callCustomerAssignedToEmployee && customerAddress && (
              <FieldsetItem>
                <Directions>
                  <H3>Get directions</H3>
                  <Actions>
                    <Action secondary onClick={handleWazeClick}>
                      <Brand src={WazeLogo} />
                      Waze
                    </Action>
                    <Action secondary onClick={handleGoogleMapsClick}>
                      <Brand src={GMapsLogo} />
                      Google Maps
                    </Action>
                  </Actions>
                </Directions>
              </FieldsetItem>
            )}
          </Fieldset>
        </Section>
      </Container>
    </ActionSheet>
  )
}

StartCall.propTypes = {
  startCallScreenVisible: bool,
  setStartCallScreenVisible: func.isRequired,
  call: object,
  inPersonAllowed: bool,
  inPersonCallLocationError: string,
  startCall: func,
  editCall: func,
  inOngoingCall: bool,
  loadingLocation: bool,
  employee: object
}

export default StartCall
