import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import { bool, func, number, object } from 'prop-types'

import ScheduleContext from 'context/ScheduleContext'

import { deleteCustomerCall, upsertCustomerCall } from 'store/customerCalls/actions'
import * as customerSelector from 'store/customers/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import BasicCreateCallForm from 'components/schedule/BasicCreateCallForm'
import ConfirmeDeleteCallSheet from 'components/schedule/ConfirmeDeleteCallSheet'

import { HOUR_OPTIONS, MINUTE_OPTIONS } from 'utils/constants'

const CreateCallSheet = ({
  defaultCustomerId,
  createCallScreenVisible,
  setCreateCallScreenVisible,
  callToEdit,
  createdFromMessageId,
  upsertCustomerCall,
  deleteCustomerCall,
  canEdit
}) => {
  const erpOptions = useSelector((state) => customerSelector.erpOptions(state))
  const employeeTimezone = useSelector(
    (state) => state.auth.user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const defaultValues = callToEdit
    ? {
        startDate: callToEdit.scheduledStart,
        startHour: HOUR_OPTIONS.find((opt) => opt.value === `${moment(callToEdit?.scheduledStart).hour()}`),
        startMinute: MINUTE_OPTIONS.find((opt) => opt.value === `${moment(callToEdit?.scheduledStart).minute()}`),
        endHour: HOUR_OPTIONS.find((opt) => opt.value === `${moment(callToEdit?.scheduledEnd).hour()}`),
        endMinute: MINUTE_OPTIONS.find((opt) => opt.value === `${moment(callToEdit?.scheduledEnd).minute()}`),
        customer: callToEdit.customerId ? erpOptions.find(({ id }) => id === callToEdit.customerId) : null,
        type: callToEdit.type
      }
    : {}

  const { isDuringOffTime, isDuringCall } = useContext(ScheduleContext)

  const [startDate, setStartDate] = useState(defaultValues?.startDate)
  const [startHour, setStartHour] = useState(defaultValues?.startHour)
  const [startMinute, setStartMinute] = useState(defaultValues?.startMinute)
  const [endHour, setEndHour] = useState(defaultValues?.endHour)
  const [endMinute, setEndMinute] = useState(defaultValues?.endMinute)
  const [customer, setCustomer] = useState(defaultValues?.customer)
  const [type, setType] = useState(defaultValues?.type || 'in-person')
  const [loading, setLoading] = useState(false)
  const [globalError, setGlobalError] = useState(false)
  const [timeError, setTimeError] = useState()

  const [confirmDeleteCall, setConfirmDeleteCall] = useState(false)

  useEffect(() => {
    if (!isEmpty(callToEdit)) {
      if (callToEdit.scheduledStart) {
        setStartDate(callToEdit.scheduledStart)
        setStartHour(`${moment(callToEdit?.scheduledStart).hour()}`.padStart(2, '0'))
        setStartMinute(`${moment(callToEdit?.scheduledStart).minute()}`.padStart(2, '0'))
      }
      if (callToEdit.scheduledEnd) {
        setEndHour(`${moment(callToEdit?.scheduledEnd).hour()}`.padStart(2, '0'))
        setEndMinute(`${moment(callToEdit?.scheduledEnd).minute()}`.padStart(2, '0'))
      }
      if (callToEdit.type) {
        setType(callToEdit.type)
      }
      if (callToEdit.customerId) {
        setCustomer(erpOptions.find(({ id }) => id === callToEdit.customerId) || null)
      }
    } else {
      setStartDate(moment())
      setStartHour(String(moment().add(1, 'hour').hour()).padStart(2, '0'))
      setStartMinute('00')
      setEndHour(String(moment().add(2, 'hours').hour()).padStart(2, '0'))
      setEndMinute('00')
      setCustomer(null)
      setType('in-person')
    }
    if (defaultCustomerId) {
      setCustomer(erpOptions.find(({ id }) => id === defaultCustomerId))
    }
  }, [callToEdit, defaultCustomerId, erpOptions])

  useEffect(() => {
    if (moment({ hour: endHour, minute: endMinute }) < moment({ hour: startHour, minute: startMinute })) {
      const newEnd = moment({ hour: startHour, minute: startMinute }).add(1, 'minute')
      setEndHour(String(newEnd.hours()).padStart(2, '0'))
      setEndMinute(String(newEnd.minutes()).padStart(2, '0'))
    }
    setTimeError()
  }, [startHour, startMinute, endHour, endMinute])

  const startDatetime = useMemo(
    () =>
      startDate &&
      startHour &&
      moment(startDate).set({ hour: startHour, minute: startMinute || 0, second: 0, millisecond: 0 }),
    [startDate, startHour, startMinute]
  )
  const endDatetime = useMemo(
    () =>
      startDate &&
      endHour &&
      moment(startDate).set({ hour: endHour, minute: endMinute || 0, second: 0, millisecond: 0 }),
    [startDate, endHour, endMinute]
  )

  const timeWarning = useMemo(() => {
    if (isDuringCall(startDatetime, endDatetime, callToEdit)) return 'A call is already scheduled during this time'
    if (isDuringOffTime(startDatetime, endDatetime)) return 'This call will be scheduled during OOT'
  }, [startDatetime, endDatetime, callToEdit, isDuringCall, isDuringOffTime])

  const hasError = useMemo(() => {
    return !customer || timeError
  }, [timeError, customer])

  const hasOrders = useMemo(() => {
    return callToEdit?.orders?.length || false
  }, [callToEdit])

  const resetFormValues = () => {
    setStartDate(defaultValues?.startDate)
    setStartHour(defaultValues?.startHour)
    setStartMinute(defaultValues?.startMinute)
    setEndHour(defaultValues?.endHour)
    setEndMinute(defaultValues?.endMinute)
    setCustomer(defaultValues?.customer)
    setType(defaultValues?.type || 'in-person')

    setGlobalError(null)
  }

  const createAppointment = async () => {
    try {
      setLoading(true)
      setTimeError()

      if (endDatetime && startDatetime && endDatetime.isSameOrBefore(startDatetime)) {
        setTimeError('Start time must be before end time.')
        return
      }

      await upsertCustomerCall({
        ...(callToEdit ? { id: callToEdit.id } : null),
        customerId: customer.id,
        scheduledStart: moment.tz(startDatetime, employeeTimezone),
        scheduledEnd: moment.tz(endDatetime, employeeTimezone),
        scheduledType: type,
        loggedBy: 'APP',
        updatedAt: moment().toISOString(),
        ...(createdFromMessageId ? { createdFromMessageId } : {})
      })

      resetFormValues()

      setCreateCallScreenVisible(null)
    } catch (e) {
      console.log(e)
      setGlobalError(
        `An error occurred when trying to schedule your call, please try again
        Error details : ${JSON.stringify(e.message)}`
      )
    } finally {
      setLoading(false)
    }
  }

  const deleteAppointment = async () => {
    setLoading(true)
    deleteCustomerCall(callToEdit)
      .then(() => {
        setLoading(false)
        setCreateCallScreenVisible()
        setConfirmDeleteCall(false)
      })
      .catch((err) => {
        setLoading(false)
        setGlobalError(
          `An error occurred when trying to delete your call, please try again!
          Error details : ${JSON.stringify(err.message)}`
        )
        setConfirmDeleteCall(false)
      })
  }

  return (
    <>
      <ActionSheet
        title={callToEdit && callToEdit.id ? 'Call details' : 'Schedule call'}
        action={
          <button type="button" onClick={() => setCreateCallScreenVisible()}>
            Cancel
          </button>
        }
        visible={createCallScreenVisible}
      >
        <div>
          <BasicCreateCallForm
            startDate={startDate}
            setStartDate={setStartDate}
            startHour={startHour}
            setStartHour={setStartHour}
            startMinute={startMinute}
            setStartMinute={setStartMinute}
            endHour={endHour}
            setEndHour={setEndHour}
            endMinute={endMinute}
            setEndMinute={setEndMinute}
            timeError={timeError}
            customer={customer}
            setCustomer={setCustomer}
            type={type}
            setType={setType}
            allowPastTime={false}
            canEdit={canEdit}
            wasGenerated={Boolean(callToEdit?.generatedCallId)}
          />
          {canEdit && (
            <Fieldset>
              <FieldsetItem>
                <Button full primary onClick={createAppointment} disabled={loading || hasError} isLoading={loading}>
                  {callToEdit ? 'Save' : 'Create'}
                </Button>
              </FieldsetItem>
              {(callToEdit?.id || callToEdit?.callId) && !hasOrders && !callToEdit?.generatedCallId && (
                <FieldsetItem>
                  <Button
                    full
                    secondary
                    onClick={() => setConfirmDeleteCall(true)}
                    disabled={loading}
                    isLoading={loading}
                  >
                    Delete call
                  </Button>
                </FieldsetItem>
              )}
            </Fieldset>
          )}

          {globalError && <GlobalAlert>{globalError}</GlobalAlert>}
          {timeWarning && (
            <FieldsetItem>
              <GlobalAlert warning>{timeWarning}</GlobalAlert>
            </FieldsetItem>
          )}
        </div>
      </ActionSheet>
      <ConfirmeDeleteCallSheet
        confirmDeleteCall={confirmDeleteCall}
        deleteAppointment={deleteAppointment}
        setConfirmDeleteCall={setConfirmDeleteCall}
        isLoading={loading}
      />
    </>
  )
}

CreateCallSheet.propTypes = {
  createCallScreenVisible: bool.isRequired,
  setCreateCallScreenVisible: func.isRequired,
  upsertCustomerCall: func.isRequired,
  deleteCustomerCall: func.isRequired,
  callToEdit: object,
  defaultCustomerId: number,
  createdFromMessageId: number,
  canEdit: bool
}

export default connect(null, { upsertCustomerCall, deleteCustomerCall })(CreateCallSheet)
