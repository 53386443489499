import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { func, number, object, string } from 'prop-types'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import tw from 'twin.macro'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchEdlpCompliance } from 'store/priceCaptureCompliance/actions'
import { getEdlpCompliance } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const PackSize = ({ size }) => {
  return (
    <div className="flex flex-col items-center font-medium text-slate-900">
      <span className="text-base">{size}</span>
    </div>
  )
}

PackSize.propTypes = {
  size: string.isRequired
}

const CompletedAmount = tw.span`text-sm font-medium text-slate-900`

const BarContainer = tw.div`relative grow h-5 w-full overflow-clip rounded bg-brand-50`

const TotalAmount = tw.span`basis-16 text-sm font-medium text-slate-500`

const Line = ({ completed, total }) => {
  const completedPercent = total === 0 ? 0 : (completed / total) * 100
  const percent = completedPercent.toFixed(2)
  const chartData = [{ name: 'data', value: percent }]

  const notEdlp = total - completed
  return (
    <div className="w-full space-y-1">
      <div className="flex items-center gap-4">
        <CompletedAmount>{completedPercent}%</CompletedAmount>
        <BarContainer>
          <div className="absolute h-full w-full">
            <ResponsiveContainer width={'100%'} height={'100%'}>
              <BarChart
                barCategoryGap="0%"
                layout="vertical"
                data={chartData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <XAxis type="number" hide domain={['dataMin', 100]} />
                <YAxis type="category" hide domain={['dataMin', 'dataMax']} />
                <Bar dataKey="value" stackId="a" fill="#4FAEE6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </BarContainer>
        <TotalAmount>of {total}</TotalAmount>
      </div>
      {Boolean(notEdlp) && <span className="block text-2xs text-red-500">Not EDLP {notEdlp}</span>}
    </div>
  )
}

Line.propTypes = {
  completed: number.isRequired,
  total: number.isRequired
}

const COLS = [
  {
    field: 'type',
    headerName: ''
  },
  {
    field: 'single',
    headerName: 'Pack size 20'
  },
  {
    field: 'twoPack',
    headerName: 'Pack size 25'
  }
]

const EdlpCompliance = ({ span, fetchEdlpCompliance }) => {
  const { sectorType, sectorId } = useParams()
  const [error, setError] = useState()

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.EDLP_COMPLIANCE, {
    sectorType,
    sectorId
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const edlpCompliance = useSelector((state) => getEdlpCompliance(state))
  const rows = useMemo(() => {
    const defaultEdlp = { totalStores: 0, compliantStores: 0 }
    const edlp120 = edlpCompliance['1']?.['20'] || defaultEdlp
    const edlp220 = edlpCompliance['2']?.['20'] || defaultEdlp
    const edlp125 = edlpCompliance['1']?.['25'] || defaultEdlp
    const edlp225 = edlpCompliance['2']?.['25'] || defaultEdlp
    return [
      {
        type: <PackSize size="Single" />,
        single: <Line completed={edlp120.compliantStores} total={edlp120.totalStores} />,
        twoPack: <Line completed={edlp125.compliantStores} total={edlp125.totalStores} />
      },
      {
        type: <PackSize size="2 Pack" />,
        single: <Line completed={edlp220.compliantStores} total={edlp220.totalStores} />,
        twoPack: <Line completed={edlp225.compliantStores} total={edlp225.totalStores} />
      }
    ]
  }, [edlpCompliance, isLoading])

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchEdlpCompliance({ dataKey, sectorType, sectorId })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId])

  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (isEmpty(edlpCompliance)) return <EmptyState title="Nothing to display" />

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }
  return (
    <Card title="EDLP Performance" span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

EdlpCompliance.propTypes = {
  span: object,
  fetchEdlpCompliance: func
}

export default connect(null, {
  fetchEdlpCompliance
})(EdlpCompliance)
