import React from 'react'
import { useParams } from 'react-router-dom'
import Comments from 'views/CustomerMessages'
import InboxMessages from 'views/inbox/InboxMessages'

import { SECTOR_LEVELS } from 'utils/constants'

const CommentActionSwitcher = () => {
  const { sectorType } = useParams()

  return (
    <>
      {sectorType === SECTOR_LEVELS.TERRITORY && <InboxMessages />}
      {sectorType === SECTOR_LEVELS.CUSTOMER && <Comments />}
    </>
  )
}

export default CommentActionSwitcher
