import React from 'react'
import { useNavigate } from 'react-router-dom'
import { shape, string } from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'

const Tile = styled.div((props) => [
  tw`rounded-md p-8 flex flex-col bg-white shadow-md shadow-slate-900/5 w-full h-fit hover:cursor-pointer gap-4`,
  `order: ${props.order}`
])
const TileHref = Tile.withComponent('a')

const ImgPlaceholder = tw.div`bg-gray-200 w-full h-[200px]`
const Title = tw.h1`font-bold text-lg`
const ActionTile = ({ content }) => {
  const navigate = useNavigate()
  const Container = content.href ? TileHref : Tile

  const containerProps = {
    href: content.href,
    onClick: content.href ? undefined : () => navigate(content.to)
  }

  return (
    <Container target="_blank" order={content.order} {...containerProps}>
      <ImgPlaceholder />
      <Title>{content.title}</Title>
      <div className="flex h-4 items-center">{content.description}</div>
    </Container>
  )
}

ActionTile.propTypes = {
  content: shape({
    title: string.isRequired,
    description: string.isRequired,
    to: string,
    href: string
  }).isRequired
}

export default ActionTile
