import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { capitalize, toLower } from 'lodash'
import { bool, func } from 'prop-types'
import styled from 'styled-components'

import ActionHeaderContext from 'context/ActionHeaderContext'
import LangContext from 'context/LangContext'

import { currentCustomer } from 'store/customers/selectors'
import { submitGoFundProgramPayment } from 'store/goFundPrograms/actions'
import { programFromUrl } from 'store/goFundPrograms/selectors'
import { budgetFromUrl, goFundPaymentInitialValues } from 'store/goFunds/selectors'

import Button from 'components/button/Button'
import Container from 'components/Container'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import FileInput from 'components/FileInput'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import ProgramCard from 'components/ProgramCard'

import { GO_FUNDS_PAYMENTS_REASONS, INDEPENDENT_STORE_LEVEL_ACTIVATION, SECTOR_LEVELS } from 'utils/constants'
import { required } from 'utils/validators'

import { goBlue, white } from 'styles/colors'

import Card from './Card'

const SecondaryButton = styled(Button)`
  color: ${white};
  background-color: ${goBlue};
`

const GoProgramPayment = ({ isMobileViewOnly, submitGoFundProgramPayment }) => {
  const { addAction } = useContext(ActionHeaderContext)
  const { formatMessage } = useContext(LangContext)
  const formRef = useRef()

  const navigate = useNavigate()
  const { sectorId: customerId, programId, sectorType } = useParams()
  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  const [error, setError] = useState()
  const [emailBody, setEmailBody] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const employeeId = useSelector((state) => state.auth.user.id)
  const program = useSelector((state) => programFromUrl(state, { programId }))
  const goFund = useSelector((state) => budgetFromUrl(state, { programId }))
  const customer = useSelector((state) => currentCustomer(state, { customerId }))
  const initialValues = useSelector((state) => goFundPaymentInitialValues(state, { customerId, programId }))
  const newImages = useSelector((state) => state.auth.newImages)

  const { remainingBudget } = program.programCardInfos

  useEffect(() => {
    addAction({
      onCreate: () => formRef.current.handleSubmit(),
      getLabel: () => 'Save',
      disabled: isSubmitting || isMobileViewOnly
    })
  }, [formRef])

  const validatePayment = useCallback((val) => {
    const newPayment = +val

    if (!newPayment) return 'Please select a pay amount'
    if (newPayment <= 0) return 'Pay amount must be higher than 0'
    if (newPayment > remainingBudget) return `Pay amount must be lower or equal than ${remainingBudget}`
  }, [])

  const payeeOptions = useMemo(() => {
    if (!customer || !customer.storeContacts) return []

    const sortedActiveContacts = customer.storeContacts
      .filter((contact) => toLower(contact.status) === 'active')
      .sort((first, second) => first.firstname.localeCompare(second.firstname))
    return sortedActiveContacts.map((contact) => ({
      label: `${capitalize(contact.firstname)} ${capitalize(contact.lastname)} - ${capitalize(contact.role)} - ${
        contact.email
      }`,
      value: contact.id
    }))
  })

  const submitPayment = async (values) => {
    setError()
    setEmailBody()

    const { ctnsSold, ctnsToReward, payeeId, payAmount: paymentAmount, paymentReason } = values
    const { id, customerId, goFundId } = program

    const cartonQty = ctnsSold || ctnsToReward

    setIsSubmitting(true)
    return submitGoFundProgramPayment({
      payment: {
        go_fund_id: goFundId,
        customer_id: customerId,
        payment_amount: paymentAmount,
        payee_id: payeeId,
        carton_qty: cartonQty,
        requested_by: employeeId,
        programId: id,
        paymentReason
      },
      activity: goFund.activity,
      newImages: newImages || []
    })
      .then(() => navigate(-1))
      .catch(({ response }) => {
        if (response.status === 424) {
          setError(
            customer.language === 'EN'
              ? 'An issue has occurred. Your payment has failed.'
              : 'Un problème est survenu. Votre paiement a échoué.'
          )
          if (response?.data?.details?.emailBody) {
            setEmailBody(response?.data?.details?.emailBody)
          }
        } else {
          setError(response.data.message)
        }
      })
      .finally(() => setIsSubmitting(false))
  }

  const newPayeeSubject = useMemo(() => {
    if (customer?.language)
      return customer.language === 'FR'
        ? `Demande de création d’un nouvel employé Extra Hub`
        : 'New Extra Hub user request'
  }, [customer])

  const newPayeeEmailBody = useMemo(() => {
    if (customer?.language)
      return customer.language === 'FR'
        ? `S'il vous plaît, ajouter ce bénéficiaire aux contacts du magasin. \nMagasin: ${customer.name} \nTitre du contact: \nPrénom du contact: \nNom du contact: \nCourriel du contact: \nLangage du contact: \nRôle du contact: \n`
        : `Please add this payee to the store contacts. \nStore: ${customer.name} \nContact title: \nContact first name: \nContact last name: \nContact email: \nContact language: \nContact role: \n`
  }, [customer])

  const emailHref = useMemo(() => {
    if (customer?.primaryContact?.language)
      return `mailto:${customer.primaryContact.email}?subject=${newPayeeSubject}&body=${encodeURIComponent(
        newPayeeEmailBody
      )}`
  }, [customer])

  if (program.finalCost || program.finalCost === 0) {
    return <Navigate to={`/customers/${customerId}/investments/programs`} replace={true} />
  }

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={submitPayment}>
      {({ values }) => {
        const toRemove = +values.payAmount || 0

        const fundsAfterPayment = remainingBudget - toRemove
        return (
          <Form>
            <Container>
              <ProgramCard
                program={program}
                isCompleted={program.status === 'completed'}
                canRequestException={program.programCardInfos.canRequestException}
                canPayNow={program.programCardInfos.canPayGoFund}
              />
              <Card>
                <Fieldset padded>
                  {program.activity === INDEPENDENT_STORE_LEVEL_ACTIVATION && (
                    <FieldsetItem>
                      <Field
                        component={Dropdown}
                        options={GO_FUNDS_PAYMENTS_REASONS}
                        name="paymentReason"
                        label="Reason"
                        menuPlacement="top"
                        placeholder={'Select one...'}
                        validate={required}
                        disabled={isSubmitting}
                      />
                    </FieldsetItem>
                  )}

                  <FieldsetItem>
                    <Field
                      component={Dropdown}
                      options={payeeOptions}
                      name="payeeId"
                      label="Payee"
                      menuPlacement="top"
                      placeholder={payeeOptions.length ? 'Select one...' : 'No payee available.'}
                      renderWithoutOptions
                      validate={required}
                      disabled={isSubmitting}
                    />
                  </FieldsetItem>

                  <FieldsetItem>
                    <SecondaryButton disabled={isSubmitting}>
                      <a href={emailHref} target="_blank" rel="noreferrer">
                        Request new contact
                      </a>
                    </SecondaryButton>
                  </FieldsetItem>
                  <FieldsetItem half>
                    <Field
                      component={Input}
                      name="payAmount"
                      label="Pay amount"
                      placeholder="$0"
                      normalize={(val) => val.replace(/\D/g, '')}
                      validate={validatePayment}
                      disabled={isSubmitting}
                      required
                    />
                  </FieldsetItem>
                  <FieldsetItem half>
                    <Input label="Remaining budget" placeholder={`$${fundsAfterPayment}`} disabled />
                  </FieldsetItem>

                  {program.programCardInfos.isCartonBasedActivity && (
                    <FieldsetItem>
                      <FileInput
                        name="images"
                        existingImages={[]}
                        formatMessage={formatMessage}
                        loading={isSubmitting}
                      />
                    </FieldsetItem>
                  )}

                  {error && (
                    <FieldsetItem>
                      <GlobalAlert>
                        <div>{error}</div>
                        {emailBody && (
                          <p>
                            &apos;Please contact &apos;
                            <a
                              href={`mailto:narrosupport@bat.com?subject=Mulesoft payment error&body=${encodeURIComponent(
                                emailBody
                              )}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              narrosupport@bat.com
                            </a>
                            .
                          </p>
                        )}
                      </GlobalAlert>
                    </FieldsetItem>
                  )}
                </Fieldset>
              </Card>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

GoProgramPayment.propTypes = {
  submitGoFundProgramPayment: func,
  isMobileViewOnly: bool
}

const mapActionCreators = {
  submitGoFundProgramPayment
}

export default connect(null, mapActionCreators)(GoProgramPayment)
