import React from 'react'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Hint from 'components/Hint'
import Label from 'components/Label'

import { getInputFieldProps, getMeta } from 'utils/helpers'

const Wrapper = styled.div((props) => [props.minW ? tw`min-w-min` : tw`min-w-max grow basis-0`])

const Container = styled.div((props) => [!props.minW && tw`w-full`])

const Select = styled.select((props) => [
  tw`w-full font-medium text-sm h-9 rounded-md align-bottom text-slate-900 border-0 outline-0 transition focus-visible:bg-white focus-visible:shadow-md focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.error && tw`ring-2 ring-red-500`,
  props.secondary ? tw`bg-slate-200` : tw`bg-slate-500/5`
])

const ErrorMessage = tw.small`text-red-500 mt-2 text-2xs`

export const Switcher = tw.div`px-4 py-0 mb-4 sm:p-0`

const Dropdown = ({
  options,
  placeholder,
  label,
  secondary,
  tertiary,
  placeholderIsValid,
  disabled,
  hint,
  renderWithoutOptions,
  optionLabel = 'label',
  optionValue = 'value',
  error,
  small,
  noIcon,
  minW,
  ...rest
}) => {
  if ((!options || !options.length) && !renderWithoutOptions) return null
  const inputFieldProps = getInputFieldProps(rest)
  const meta = getMeta(rest) || {}
  return (
    <Wrapper minW={minW}>
      <Container translate="no" secondary={secondary} tertiary={tertiary} {...rest}>
        {label && (
          <Label>
            {label}
            {hint && <Hint hint={hint} />}
          </Label>
        )}
        <Select
          noIcon={noIcon}
          secondary={secondary}
          tertiary={tertiary}
          error={(meta.touched && meta.error) || error}
          disabled={disabled || !options?.length}
          small={small}
          {...inputFieldProps}
        >
          {placeholder && (
            <option value="" disabled={!placeholderIsValid}>
              {placeholder}
            </option>
          )}
          {options?.map(({ disabled = false, ...opt }, i) => {
            const label = opt[optionLabel]
            const value = opt[optionValue]
            return (
              <option key={i} value={value} disabled={disabled}>
                {label}
              </option>
            )
          })}
        </Select>
        {meta.error && meta.touched && !meta.active && <ErrorMessage>{meta.error}</ErrorMessage>}
      </Container>
    </Wrapper>
  )
}

Dropdown.propTypes = {
  options: array.isRequired,
  onChange: func,
  label: string,
  placeholder: string,
  value: oneOfType([string, number]),
  secondary: bool,
  tertiary: bool,
  input: object,
  meta: object,
  placeholderIsValid: bool,
  disabled: bool,
  hint: string,
  renderWithoutOptions: bool,
  optionLabel: string,
  optionValue: string,
  error: bool,
  small: bool,
  noIcon: bool,
  minW: bool
}

export default Dropdown
