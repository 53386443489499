import React from 'react'
import { stripUnit } from 'polished'
import { bool, func, node, object, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import Label from 'components/Label'

import { borderColor, secondaryColor, white } from 'styles/colors'
import { animationCurve, animationTime, boxShadow } from 'styles/global'

export const toggleDimensions = {
  width: '50px',
  height: '30px'
}

const Container = styled.label`
  display: inline-block;
`

const Input = styled.input`
  display: none;
`

const Indicator = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${toggleDimensions.width};
  height: ${toggleDimensions.height};
  background-color: ${borderColor};
  border-radius: ${stripUnit(toggleDimensions.height) / 2 + 'px'};
  cursor: pointer;
  transition: background-color ${animationTime} ${animationCurve};

  ${Input}:checked + & {
    background-color: ${secondaryColor};
  }

  &:before {
    content: '✓';
    text-align: center;
    font-weight: bold;
    color: ${white};
    position: absolute;
    top: 3px;
    left: 3px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${white};
    transition: transform ${animationTime} ${animationCurve}, color ${animationTime} ${animationCurve};
    ${boxShadow};

    ${Input}:checked + & {
      transform: translateX(20px);
      color: ${(props) => (props.isDisabled ? borderColor : secondaryColor)};
    }
  }
`

const FieldWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`

const Toggle = ({ checked, onClick }) => (
  <Container checked={checked} onClick={onClick}>
    <Input disabled type="checkbox" id="toggle" checked={checked} />
    <Indicator />
  </Container>
)

Toggle.propTypes = {
  checked: bool,
  onClick: func.isRequired
}

const getInputFieldProps = ({ input, field, name, onChange, onBlur, value }) => {
  return input || field || { name, onChange, onBlur, value } // redux-form provides input, formik provides field
}

export function ToggleField({ label, isDisabled, defaultValue, form, ...props }) {
  const inputFieldProps = getInputFieldProps(props)

  const handleClick = () => {
    if (isDisabled) return
    if (props.field) {
      // Formik
      form.setFieldValue(inputFieldProps.name, !inputFieldProps.value)
    } else {
      // Redux-Forms
      inputFieldProps.onChange(!inputFieldProps.value)
    }
  }

  const ToggleLabel = typeof label === 'string' ? <Label>{label}</Label> : label
  return (
    <FieldWrap>
      {ToggleLabel}
      <Container checked={inputFieldProps.value} onClick={handleClick}>
        <Input disabled type="checkbox" id="toggle" checked={inputFieldProps.value} {...inputFieldProps} />
        <Indicator isDisabled={isDisabled} />
      </Container>
    </FieldWrap>
  )
}
ToggleField.propTypes = {
  label: oneOfType([node, string]),
  isDisabled: bool,
  defaultValue: bool,
  form: object,
  field: object,
  input: object
}
export default Toggle
