import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

const getCurrentInventory = createSelector(dataSourceFromSectorLevel, (sectorData) => sectorData?.inventory || {})
export const getCurrentTrending = createSelector(
  getCurrentInventory,
  (inventory) => inventory?.trending || { oos: [], inventory: [] }
)

export const getCurrentBrands = createSelector(getCurrentInventory, (inventory) => inventory?.brands || {})

export const getCurrentMaterials = createSelector(getCurrentInventory, (inventory) => inventory?.materials || {})

export const getCurrentGeography = createSelector(getCurrentInventory, (inventory) => inventory?.geographies || {})

export const getCurrentFilters = createSelector(getCurrentInventory, (inventory) => inventory?.filters || {})
