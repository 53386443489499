import React from 'react'
import { bool, func, node, number, object, string } from 'prop-types'
import tw, { css, styled } from 'twin.macro'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'

const Amplify = ({ size, amplifyAction }) => {
  if (size <= 2)
    return (
      <IconButton secondary icon="three-stars" onClick={amplifyAction}>
        Amplify
      </IconButton>
    )
  else
    return (
      <Button secondary icon="three-stars" onClick={amplifyAction}>
        Amplify
      </Button>
    )
}

Amplify.propTypes = {
  size: number,
  amplifyAction: func
}

export const Controls = styled.div((props) => [tw`grid gap-4`, props.twocols && tw`grid-cols-2`])

export const DataLabel = tw.span`block text-2xs font-medium text-slate-500`

export const DataXL = tw.span`block text-xl font-medium text-slate-900`

export const Data2XL = tw.span`block text-3xl font-medium text-slate-900`

const ResponsiveContainer = styled.div`
  display: grid;
  gap: 16px;

  ${({ hideOnSmallScreens }) => css`
    @media (max-width: 1200px) {
      display: ${hideOnSmallScreens ? 'none' : 'grid'};
    }
  `}

  ${({ span }) => css`
    grid-column: span ${span.xs} / span ${span.xs};
  `}

  ${({ span }) => css`
    @media (min-width: 600px) {
      grid-column: span ${span.sm} / span ${span.sm};
    }
  `}

  ${({ span }) => css`
    @media (min-width: 900px) {
      grid-column: span ${span.md} / span ${span.md};
    }
  `}

  ${({ span }) => css`
    @media (min-width: 1200px) {
      grid-column: span ${span.lg} / span ${span.lg};
    }
  `}

  ${({ span }) => css`
    @media (min-width: 1600px) {
      grid-column: span ${span.xl} / span ${span.xl};
    }
  `}
`

const Container = tw.div`flex h-full flex-wrap flex-col bg-white p-6 gap-6 rounded-md shadow-md shadow-brand-900/10`

const Title = tw.span`inline-flex items-center h-9 text-xl font-semibold text-slate-900`

const Content = tw.div`grow space-y-4`

const ActionsContainer = tw.div`w-full max-w-6xl justify-end flex-wrap flex gap-3`

const Card = ({
  title,
  customTitle,
  actions,
  headerActions,
  amplifyAction,
  children,
  span,
  amplifySize,
  hideOnSmallScreens
}) => {
  return (
    <ResponsiveContainer span={span} hideOnSmallScreens={hideOnSmallScreens}>
      <Container>
        <div className="flex items-center justify-between">
          {customTitle ? <div>{customTitle}</div> : <Title>{title}</Title>}
          <div className="space-x-3">
            {headerActions && (
              <ActionsContainer>
                {headerActions.map((action, i) => (
                  <div className="min-w-min" key={i}>
                    {action}
                  </div>
                ))}
              </ActionsContainer>
            )}
            {amplifyAction && <Amplify size={amplifySize} amplifyAction={amplifyAction} />}
          </div>
        </div>
        <Content>{children}</Content>
        {actions && (
          <ActionsContainer>
            {actions.map((action, i) => (
              <div className="w-full" key={i}>
                {action}
              </div>
            ))}
          </ActionsContainer>
        )}
      </Container>
    </ResponsiveContainer>
  )
}

Card.propTypes = {
  children: node.isRequired,
  actions: node,
  headerActions: node,
  span: object,
  title: string,
  customTitle: object,
  amplifyAction: func,
  amplifySize: number,
  hideOnSmallScreens: bool
}

export default Card
