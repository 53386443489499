import { createSelector } from 'reselect'

import { allCustomers } from 'store/customers/selectors'
import { allDistricts } from 'store/districts/selectors'
import { allNations } from 'store/nations/selectors'
import { allRegions } from 'store/regions/selectors'
import { allTerritories } from 'store/territories/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

export const getSelectedSectorLevel = (state, props) => state.sector?.selectedLevel || props?.selectedLevel
export const getCurrentSector = (state, props) => state.sector?.currentSector || props?.currentSector || {}
export const getCurrentProductType = (state, props) => state.sector?.productType || 'fmc'

export const getSelectedSector = createSelector(
  getSelectedSectorLevel,
  getCurrentSector,
  (selectedLevel, currentSector) => {
    return currentSector?.[selectedLevel]
  }
)

function createSectorSelector(sectorLevel) {
  return createSelector(getCurrentSector, (currentSector) => currentSector?.[sectorLevel])
}
export const getNationSector = createSectorSelector(SECTOR_LEVELS.NATIONAL)
export const getRegionSector = createSectorSelector(SECTOR_LEVELS.REGION)
export const getDistrictSector = createSectorSelector(SECTOR_LEVELS.DISTRICT)
export const getTerritorySector = createSectorSelector(SECTOR_LEVELS.TERRITORY)
export const getCustomerSector = createSectorSelector(SECTOR_LEVELS.CUSTOMER)

function createCurrentIdSelector(getSector) {
  return createSelector(getSector, (sector) => sector?.id)
}
export const getCurrentNationId = createCurrentIdSelector(getNationSector)
export const getCurrentRegionId = createCurrentIdSelector(getRegionSector)
export const getCurrentDistrictId = createCurrentIdSelector(getDistrictSector)
export const getCurrentTerritoryId = createCurrentIdSelector(getTerritorySector)
export const getCurrentCustomerId = createCurrentIdSelector(getCustomerSector)

function createCurrentStateSelector(getAll, getId) {
  return createSelector(getAll, getId, (all, id) => all[id])
}
export const getCurrentNation = createCurrentStateSelector(allNations, getCurrentNationId)
export const getCurrentRegion = createCurrentStateSelector(allRegions, getCurrentRegionId)
export const getCurrentDistrict = createCurrentStateSelector(allDistricts, getCurrentDistrictId)
export const getCurrentTerritory = createCurrentStateSelector(allTerritories, getCurrentTerritoryId)
export const getCurrentCustomer = createCurrentStateSelector(allCustomers, getCurrentCustomerId)

export const dataSourceFromSectorLevel = createSelector(
  getSelectedSectorLevel,
  getCurrentNation,
  getCurrentRegion,
  getCurrentDistrict,
  getCurrentTerritory,
  getCurrentCustomer,
  (sectorLevel, currentNation, currentRegion, currentDistrict, currentTerritory, currentCustomer) => {
    if (!sectorLevel) return {}
    switch (sectorLevel) {
      case SECTOR_LEVELS.CUSTOMER:
        return currentCustomer
      case SECTOR_LEVELS.TERRITORY:
        return currentTerritory
      case SECTOR_LEVELS.DISTRICT:
        return currentDistrict
      case SECTOR_LEVELS.REGION:
        return currentRegion
      case SECTOR_LEVELS.NATIONAL:
        return currentNation

      default:
        return {}
    }
  }
)
