import React, { Component } from 'react'
import { SingleDatePicker } from 'react-dates'
import omit from 'lodash/omit'
import moment from 'moment'
import { nanoid } from 'nanoid'
import { func, instanceOf, object, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import ErrorMessage from 'components/ErrorMessage'
import Label from 'components/Label'

import { getInputFieldProps, getMeta } from 'utils/helpers'

import { borderColor, red } from 'styles/colors'
import { borderRadius } from 'styles/global'

import { Container } from './styles.js'

import 'react-dates/initialize'

const Wrap = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  box-shadow: inset 0 0 0 1px ${borderColor};
  ${(props) =>
    props.error &&
    `
    input { box-shadow: inset 0 0 0 1px ${red}, 0 0 0 1px ${red} !important; }
  `};
`

export class DatePicker extends Component {
  static propTypes = {
    input: object,
    meta: object,
    label: string,
    onChange: func,
    onBlur: func,
    value: oneOfType([string, instanceOf(Date), instanceOf(moment)]),
    openDirection: string,
    placeholder: string
  }

  state = {
    focused: false,
    id: `id-${nanoid(10)}`
  }

  onDateChange = (dateInput) => {
    const { onChange } = getInputFieldProps(this.props)
    const date = moment(dateInput).local()
    const validatedDate = date.isValid() ? date.startOf('day').format() : null
    onChange(validatedDate)
    if (validatedDate) {
      document.querySelector(`input[id='${this.state.id}']`).blur()
    }
  }

  onFocusChange = ({ focused }) => {
    const { onBlur, value } = getInputFieldProps(this.props)
    this.setState({ focused })
    if (onBlur) onBlur()
    if (!focused) {
      const passedDate = moment(value)
      const date = passedDate.isValid() ? passedDate.format('MM/DD/YYYY') : ''
      document.querySelector(`input[id='${this.state.id}']`).value = date
    }
  }

  render() {
    const { focused, id } = this.state
    const { openDirection, label, placeholder, ...props } = this.props
    const meta = getMeta(this.props)
    const { value } = getInputFieldProps(props)
    const passedDate = moment(value)
    const date = passedDate.isValid() ? passedDate : null

    return (
      <Container>
        {label && <Label>{label}</Label>}
        <Wrap error={meta.error && meta.touched && !meta.active && !focused}>
          <SingleDatePicker
            {...omit(props, 'onChange', 'value', 'name', 'meta', 'field', 'form', 'input')}
            date={date}
            placeholder={placeholder || 'MM/DD/YYYY'}
            isDayHighlighted={(d) => (date ? d.startOf('date') === date.startOf('date') : false)}
            onDateChange={this.onDateChange}
            onFocusChange={this.onFocusChange}
            focused={focused}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            openDirection={openDirection || 'down'}
            id={id}
          />
        </Wrap>
        {meta.error && meta.touched && !meta.active && !focused && <ErrorMessage>{meta.error}</ErrorMessage>}
      </Container>
    )
  }
}

export default DatePicker
