import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import * as orderSelector from 'store/orders/selectors'

import Container from 'components/Container'
import TableRow from 'components/tableRow/TableRow'
import TableRowGroup from 'components/tableRow/TableRowGroup'

const perfTypeDescriptors = {
  F1: "Today's Orders",
  F2: "Today's Targets & Gap,\nTotals for LW, L4, P4",
  F3: 'All other KPIs'
}

const AwaitingInformation = () => (
  <TableRow>
    <strong>
      <small>Awaiting update information...</small>
    </strong>
  </TableRow>
)

const renderLastSync = (lastSyncs) => {
  if (!lastSyncs) return <AwaitingInformation />
  return Object.entries(lastSyncs).map(([type, date]) => (
    <TableRow key={type}>
      <strong>
        <small style={{ whiteSpace: 'pre-line' }}>{perfTypeDescriptors[type]}</small>
      </strong>
      <small>{moment(date).fromNow()}</small>
    </TableRow>
  ))
}

const LastSync = () => {
  const lastUserFetch = useSelector(({ auth }) => auth.lastUserFetch)
  const lastSyncs = useSelector(({ auth }) => auth.user.lastSyncs || {})
  const lastOrderSync = useSelector((state) => orderSelector.lastOrderSync(state))

  return (
    <Fragment>
      <Container>
        <TableRowGroup>
          <TableRow>
            <strong>
              <span>User Fetched</span>
            </strong>
            <span>{moment(lastUserFetch).fromNow()}</span>
          </TableRow>
          <TableRow>
            <strong>
              <span>SAQ Updated</span>
            </strong>
            <span>{lastOrderSync ? lastOrderSync.fromNow() : '-'}</span>
          </TableRow>
        </TableRowGroup>

        <TableRowGroup heading="Territory Performance">{renderLastSync(lastSyncs.territory)}</TableRowGroup>
        <TableRowGroup heading="Customer Performance">{renderLastSync(lastSyncs.customer)}</TableRowGroup>
      </Container>
    </Fragment>
  )
}

export default LastSync
