import omit from 'lodash/omit'
import { createSelector } from 'reselect'

const allCampaigns = (state) => omit(state.cycleCampaigns, '_persist')

const campaignIdFromProps = (state, props) => props.campaignId

export const activeCycleCampaigns = createSelector(allCampaigns, (campaigns) => {
  return Object.values(campaigns).filter((c) => c.isVisible)
})

export const activeCycleCampaignOptions = createSelector(activeCycleCampaigns, (campaigns) => {
  return campaigns.map((c) => ({
    label: c.name,
    value: c.id
  }))
})

export const campaignDetails = createSelector(allCampaigns, campaignIdFromProps, (campaigns, campaignId) => {
  return Object.values(campaigns).find((c) => c.id === +campaignId)
})
