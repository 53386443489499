import { addEntities } from 'store/actions'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToDistrict,
  addAmplifyExtraHubEngagementValuesToDistrict,
  addAmplifyExtraHubFiltersValuesToDistrict,
  addAmplifyExtraHubGeographyValuesToDistrict,
  addAmplifyExtraHubTrendingValuesToDistrict,
  addAmplifyInventoryOosBrandsValuesToDistrict,
  addAmplifyInventoryOosFiltersValuesToDistrict,
  addAmplifyInventoryOosGeographyValuesToDistrict,
  addAmplifyInventoryOosMaterialValuesToDistrict,
  addAmplifyInventoryOosTrendingValuesToDistrict,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToDistrict,
  addAmplifyPriceCaptureComplianceFiltersValuesToDistrict,
  addAmplifyPriceCaptureComplianceFootprintValuesToDistrict,
  addAmplifyPriceCaptureComplianceGeographyValuesToDistrict,
  addAmplifyPriceComplianceEdlpComplianceValuesToDistrict,
  addAmplifyPriceComplianceGeographyComplianceValuesToDistrict,
  addAmplifyPriceComplianceGeographyFiltersValuesToDistrict,
  addAmplifyPriceComplianceStrategyComplianceValuesToDistrict,
  addAmplifySelloutGeographyValuesToDistrict,
  addAmplifySelloutValuesToDistrict,
  addDistroBrandDataToDistrict,
  addDistroGeographyDataToDistrict,
  addDistroMaterialDataToDistrict,
  addDistroTrendedDataToDistrict,
  addPlanningEngagementValuesToDistrict,
  addPlanningHealthCheckValuesToDistrict,
  addPlanningPriceCheckValuesToDistrict,
  addPlanningSellinValuesToDistrict,
  addPlanningSelloutValuesToDistrict,
  addSellinGeographyToDistrict,
  addSellinOrderCompletionToDistrict,
  addSellinRunRateToDistrict
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.districts) return state

    return Object.entries(payload.districts).reduce((acc, [id, district]) => {
      return Object.assign(acc, {
        [id]: district
      })
    }, Object.assign({}, state))
  },
  [addSellinRunRateToDistrict]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addSellinGeographyToDistrict]: (
    state,
    { payload: { sectorId: id, currentProductType, geography, period, range, vapeCategory, offset, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${range}-${offset}`]: result
      }
    })
  },
  [addSellinOrderCompletionToDistrict]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addDistroTrendedDataToDistrict]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}`]: result
      }
    })
  },
  [addDistroBrandDataToDistrict]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}`]: result
      }
    })
  },
  [addDistroGeographyDataToDistrict]: (state, { payload: { sectorId: id, geography, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${geography}`]: result
      }
    })
  },
  [addDistroMaterialDataToDistrict]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}`]: result
      }
    })
  },
  [addPlanningSellinValuesToDistrict]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToDistrict]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToDistrict]: (state, { payload: { id, activeProductType, channel, dataType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`amplify-sellout-${activeProductType}-${channel}-${dataType}`]: result
      }
    })
  },
  [addAmplifySelloutGeographyValuesToDistrict]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToDistrict]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${activeProductType}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToDistrict]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToDistrict]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },
  [addAmplifyInventoryOosTrendingValuesToDistrict]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToDistrict]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToDistrict]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToDistrict]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToDistrict]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToDistrict]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToDistrict]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToDistrict]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToDistrict]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToDistrict]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToDistrict]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToDistrict]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToDistrict]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToDistrict]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToDistrict]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToDistrict]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToDistrict]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToDistrict]: priceCaptureCompliance.priceComplianceGeographyFilters
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
