import React from 'react'
import { string } from 'prop-types'
import styled, { keyframes } from 'styled-components'
import tw from 'twin.macro'

import Icon from 'components/Icon'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled(Icon)`
  animation: ${rotate} 0.75s linear infinite;
`
export default Spinner

export const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`
export const WrappedSpinner = ({ icon }) => (
  <SpinnerContainer>
    <Spinner icon={icon} />
  </SpinnerContainer>
)
WrappedSpinner.propTypes = {
  icon: string.isRequired
}
