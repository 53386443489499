import React from 'react'
import { mix, stripUnit } from 'polished'
import { bool, node, string } from 'prop-types'
import styled, { css } from 'styled-components'

import { borderColor, offWhite, white } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'

const Container = styled.section`
  position: relative;
  background-color: ${white};
  padding-top: ${spacing.medium};
  padding-bottom: ${spacing.medium};
  margin-left: -${spacing.medium};
  margin-right: -${spacing.medium};

  ${media.breakpoint`
    padding-left: ${spacing.medium};
    padding-right: ${spacing.medium};
  `};

  ${(props) =>
    props.muted &&
    css`
      background-color: ${mix(0.5, offWhite, white)};
    `};

  &:first-child {
    margin-top: -${spacing.medium}; /* Negative margin to offset parent accordion padding */
  }

  &:last-child {
    margin-bottom: -${spacing.medium}; /* Negative margin to offset parent accordion padding */
  }

  & + & {
    border-top: 1px solid ${borderColor};
  }
`

const Header = styled.header`
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  padding-left: ${spacing.medium};

  ${media.breakpoint`
    padding-left: 0;
    margin-bottom: ${spacing.medium};
  `};
`

const Title = styled(H3)`
  margin-bottom: ${stripUnit(spacing.tiny) * 0.5 + 'px'};
  white-space: pre-line;
`

const Content = styled.main`
  position: static;

  ${(props) =>
    props.paddedMobile &&
    css`
      padding-left: ${spacing.medium};
      padding-right: ${spacing.medium};

      ${media.breakpoint`
        padding-left: 0;
        padding-right: 0;
      `};
    `};
`

const PayoutSection = ({ title, children, muted, paddedMobile }) => (
  <Container muted={muted}>
    {title && (
      <Header>
        <Title>{title}</Title>
      </Header>
    )}
    <Content paddedMobile={paddedMobile}>{children}</Content>
  </Container>
)

PayoutSection.propTypes = {
  title: string,
  children: node.isRequired,
  muted: bool,
  paddedMobile: bool
}

export default PayoutSection
