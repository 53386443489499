import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import { requestLogin } from 'store/auth/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { isEmail, minPasswordLength, required } from 'utils/validators'

const errorMessages = {
  registrationRequired: (
    <GlobalAlert>
      You must <Link to="/auth/signup">sign up</Link> to access this site.
    </GlobalAlert>
  ),
  invalidEmployeeDetails: <GlobalAlert>Please check your email and password and try again.</GlobalAlert>,
  permissionDenied: <GlobalAlert>You do not have the necessary permissions to access this site.</GlobalAlert>,
  offlineError: <GlobalAlert>You cannot login without an internet connection.</GlobalAlert>,
  sessionExpired: <GlobalAlert>Your session has expired. Please log in again.</GlobalAlert>,
  authRequired: <GlobalAlert>You must log in again.</GlobalAlert>,
  generic: <GlobalAlert>An error has occured. Please try again later.</GlobalAlert>
}

function Login({ requestLogin }) {
  const [error, setError] = useState()

  const validateEmail = (value) => {
    return required(value) || isEmail(value)
  }

  const validatePassword = (value) => {
    return required(value) || minPasswordLength(value)
  }

  const handleEmailChange = (value, setFieldValue) => {
    // Somehow the .trim() is not working. Need to figure out why...
    const email = value.trim().toLowerCase()
    setFieldValue('email', email)
  }

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setError()
    try {
      await requestLogin(values)
    } catch ({ response }) {
      const loginError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[loginError] || errorMessages.generic)
    }
  }

  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={handleFormSubmit}>
      {({ isSubmitting, errors, setFieldValue }) => {
        return (
          <Form>
            <Fieldset>
              <FieldsetItem>
                <h3>Log in</h3>
                <p>
                  Don&apos;t have an account? <Link to="/auth/signup">Sign up</Link>
                </p>
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  type="email"
                  component={Input}
                  icon="mail"
                  placeholder="Email address"
                  name="email"
                  onChange={(event) => handleEmailChange(event.target.value, setFieldValue)}
                  validate={validateEmail}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  icon="lock"
                  placeholder="Password"
                  type="password"
                  name={'password'}
                  validate={validatePassword}
                />
              </FieldsetItem>

              {error && <FieldsetItem>{error}</FieldsetItem>}

              <FieldsetItem>
                <Button type="submit" disabled={Object.keys(errors).length > 0} primary full isLoading={isSubmitting}>
                  Log in
                </Button>
              </FieldsetItem>

              <FieldsetItem>
                <Link to="/auth/forgot-password">
                  <small>Forgot your password?</small>
                </Link>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

const mapActionCreators = {
  requestLogin
}

Login.propTypes = {
  requestLogin: func
}

export default connect(null, mapActionCreators)(Login)
