import { api } from 'store/api'

export const submitTerritoryPerfReorder = ({ territoryId, sequence }) =>
  api.put(`/employees-territories/${territoryId}`, sequence)

export const fetchTerritoryPerformance = (territoryId) => api.get(`/territories/${territoryId}/performance`)

export const fetchTerritoryCustomerList = (territoryId) => api.get(`/territories/${territoryId}/customer-list`)

export const fetchTerritoryIntel = (territoryId) => api.get(`/territories/${territoryId}/intel`)

export const fetchTerritory = (territoryId) => api.get(`/territories/${territoryId}`)

export const fetchTerritoryGoFunds = (territoryId) => api.get(`/territories/${territoryId}/go-funds`)

export const fetchTerritorySellInPrograms = (territoryId) => api.get(`/territories/${territoryId}/sell-in-programs`)
