import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { submitNewPassword } from 'store/auth/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { match, minPasswordLength, required } from 'utils/validators'

const errorMessages = {
  invalidToken: (
    <GlobalAlert>
      Make sure you have copied the link correctly or <Link to="/auth/forgot-password">request a new token</Link>.
    </GlobalAlert>
  ),
  tokenExpired: (
    <GlobalAlert>
      Your token has expired. You can request a new one <Link to="/auth/forgot-password">here</Link>.
    </GlobalAlert>
  ),
  offlineError: <GlobalAlert>You cannot reset your password without an internet connection.</GlobalAlert>,
  generic: <GlobalAlert>An error has occured. Please try again later.</GlobalAlert>
}

function ResetPassword({ submitNewPassword }) {
  const navigate = useNavigate()
  const { resetToken } = useParams()
  const { formatMessage } = useContext(LangContext)

  const [error, setError] = useState()

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setError()

    try {
      await submitNewPassword({ resetToken, password: values.password }, navigate)
    } catch ({ response }) {
      const resetError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[resetError] || errorMessages.generic)
    } finally {
      setSubmitting(false)
    }
  }

  const validatePassword = (value) => {
    return required(value) || minPasswordLength(value)
  }

  const validateConfirmPassword = (values) => {
    const { password, confirmPassword } = values
    return required(confirmPassword) || match(confirmPassword, { password })
  }

  return (
    <Formik initialValues={{ password: '', confirmPassword: '' }} onSubmit={handleFormSubmit}>
      {({ isSubmitting, errors, values }) => {
        return (
          <Form>
            <h3>{formatMessage({ messageId: 'auth.chooseNewPassword' })}</h3>
            <p>{formatMessage({ messageId: 'auth.newPasswordCondition' })}</p>
            <Fieldset>
              <FieldsetItem>
                <Field
                  component={Input}
                  icon="lock"
                  placeholder="New password"
                  name="password"
                  type="password"
                  validate={validatePassword}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  icon="lock"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type="password"
                  validate={validateConfirmPassword(values)}
                />
              </FieldsetItem>

              {error && <FieldsetItem>{error}</FieldsetItem>}

              <FieldsetItem>
                <Button
                  primary
                  full
                  type="submit"
                  disabled={Object.keys(errors).length > 0 || values.password !== values.confirmPassword}
                  isLoading={isSubmitting}
                >
                  {formatMessage({ messageId: 'send' })}
                </Button>
              </FieldsetItem>

              <FieldsetItem>
                <Link to="/auth/login">
                  <small>{formatMessage({ messageId: 'auth.returnToLogin' })}</small>
                </Link>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

ResetPassword.propTypes = {
  submitNewPassword: func.isRequired
}

const mapActionCreators = {
  submitNewPassword
}

export default connect(null, mapActionCreators)(ResetPassword)
