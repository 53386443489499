import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { transparentize } from 'polished'
import { func, object, string } from 'prop-types'
import { Pie, PieChart } from 'recharts'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchPlanningSellin } from 'store/Sellin/actions'
import { planningSellinData } from 'store/Sellin/selectors'

import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import SegmentControl from 'components/SegmentControl'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import { CURRENT_COMPARISONS_OPTIONS, DATAKEY_TYPES, DISABLE_TOGO_PERIOD, PERIOD_FILTERS } from 'utils/constants'
import { createDataKey, getErrorMessage, parseNumberString } from 'utils/helpers'

function getCircularData(completionPercentage) {
  const completed = completionPercentage
  const toDo = 1 - completed

  return [
    {
      name: 'completionPercentage',
      value: Math.min(completed, 100),
      fill: '#E08330'
    },
    {
      name: 'gapPercentage',
      value: Math.max(toDo, 0),
      fill: transparentize(0.8, '#E08330')
    }
  ]
}

const placeholderCircularData = [
  {
    name: 'completionPercentage',
    value: 0,
    fill: '#E08330'
  },
  {
    name: 'gapPercentage',
    value: 100,
    fill: transparentize(0.8, '#E08330')
  }
]

const Controls = tw.div`grid grid-cols-2 gap-3`

const DataLabel = tw.span`block text-2xs font-medium text-slate-500 text-center`

const ChartWrapper = tw.div`relative flex grow flex-col items-center justify-center h-[200px]`

const DataXL = tw.span`block text-xl font-medium text-slate-900 text-center min-w-fit`

const Data2XL = tw.span`block text-3xl font-medium text-slate-900 text-center`

const Spacer = tw.div`shrink-0 h-9 w-full max-md:hidden`

const SellinCard = ({ fetchPlanningSellin, span, amplifyAction, vapeCategory = 'all' }) => {
  const { formatMessage } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)

  const [periodFilter, setPeriodFilter] = useState(PERIOD_FILTERS.TODAY)
  const [currentComparison, setCurrentComparison] = useState(CURRENT_COMPARISONS_OPTIONS[0].value)
  const [error, setError] = useState()

  const customerSellinData = useSelector((state) =>
    planningSellinData(state, {
      period: periodFilter,
      activeProductType: currentProductType,
      currentComparison,
      vapeCategory
    })
  )

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_SELL_IN, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType,
    vapeCategory
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const periodFilterLabel = useMemo(() => {
    if (periodFilter) return formatMessage({ messageId: `period.${periodFilter}` })
  }, [periodFilter])

  const translatedPeriods = Object.values(PERIOD_FILTERS).map((p) => ({
    value: p,
    label: formatMessage({ messageId: `period.${p}` })
  }))

  useEffect(() => {
    let mounted = true
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchPlanningSellin(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          vapeCategory
        },
        dataKey
      ).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }

    return () => {
      mounted = false
    }
  }, [currentSector, selectedLevel, currentProductType, vapeCategory])

  function handleChangePeriod(e) {
    setPeriodFilter(e.target.value)
    if (DISABLE_TOGO_PERIOD.includes(e.target.value)) {
      setCurrentComparison('to_date')
    }
  }

  const renderContent = () => {
    const { actuals, gap, completionPercentage } = customerSellinData
    const circularData = getCircularData(completionPercentage)

    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title="An error occured" subtitle={error} />

    return (
      <div className="flex h-full flex-col justify-between">
        <Controls>
          <Dropdown onChange={(e) => handleChangePeriod(e)} value={periodFilter} options={translatedPeriods} />
          <SegmentControl
            small
            onChange={(e) => setCurrentComparison(e.target.value)}
            value={currentComparison}
            options={CURRENT_COMPARISONS_OPTIONS}
            disabled={DISABLE_TOGO_PERIOD.includes(periodFilter)}
          />
        </Controls>
        <div className="flex pt-8">
          <div className="my-auto w-16 min-w-fit space-y-5 max-md:hidden">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.actuals' })}</DataLabel>
              <DataXL>
                <Tooltip isNumber hint={actuals}>
                  {actuals ? parseNumberString(actuals) : '-'}
                </Tooltip>
              </DataXL>
            </div>
          </div>
          <ChartWrapper>
            <div className="absolute">
              <PieChart width={200} height={200} margin={0}>
                <Pie
                  data={circularData[0] === 0 ? placeholderCircularData : circularData}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value"
                  innerRadius="90%"
                  outerRadius="100%"
                  fill={['#E08330', transparentize(0.8, '#E08330')]}
                  isAnimationActive={false}
                />
              </PieChart>
            </div>
            <DataLabel>{periodFilterLabel}</DataLabel>
            <Data2XL>{completionPercentage ? parseNumberString(completionPercentage * 100) : '-'}%</Data2XL>
            <DataLabel>{formatMessage({ messageId: 'sellIn.ofTarget' })}</DataLabel>
          </ChartWrapper>
          <div className="my-auto w-16 min-w-fit space-y-5  max-md:hidden">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.gap' })}</DataLabel>
              <DataXL>
                <Tooltip isNumber hint={gap}>
                  {gap && gap > 0 ? '+' : ''} {gap ? parseNumberString(gap) : '-'}
                </Tooltip>
              </DataXL>
            </div>
          </div>
        </div>
        <Spacer />
        <div className="flex pt-8 md:hidden">
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.actuals' })}</DataLabel>
              <DataXL>{actuals?.toLocaleString()}</DataXL>
            </div>
          </div>
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.gap' })}</DataLabel>
              <DataXL>
                <div>{`${gap && gap >= 0 ? '+' : '-'}${gap?.toLocaleString() || '-'}`}</div>
              </DataXL>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card title="Orders vs SAT" span={span} amplifyAction={amplifyAction}>
      <div className="flex h-full flex-col justify-between">{renderContent()}</div>
    </Card>
  )
}

SellinCard.propTypes = {
  fetchPlanningSellin: func,
  span: object,
  amplifyAction: func,
  vapeCategory: string
}

const mapActionCreators = {
  fetchPlanningSellin
}

export default connect(null, mapActionCreators)(SellinCard)
