import queryString from 'query-string'

import { api } from 'store/api'

export const fetchPlanningSellout = ({ id, sectorLevel, activeProductType, manufacturer, channel }) =>
  api.get(
    `/sellout-actuals?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType,
        manufacturer,
        channel
      },
      { skipNull: true }
    )}`
  )

export const fetchAmplifySellout = ({ id, sectorLevel, activeProductType, channel, dataType }) => {
  // console.log({ id, sectorLevel, activeProductType, channel })
  return api.get(
    `/sellout-actuals/amplify?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType,
        channel,
        dataType
      },
      { skipNull: true }
    )}`
  )
}

export const fetchAmplifySelloutGeographyData = ({
  id,
  sectorLevel,
  activeProductType,
  channel,
  geography,
  dataType,
  sortColumn,
  sortDirection,
  offset,
  limit
}) => {
  // console.log({ id, sectorLevel, activeProductType, channel })
  return api.get(
    `/sellout-actuals/amplify/geography?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType,
        channel,
        geography,
        dataType,
        sortColumn,
        sortDirection,
        offset,
        limit
      },
      { skipNull: true }
    )}`
  )
}

export const fetchAmplifySelloutSkuData = ({
  id,
  sectorLevel,
  activeProductType,
  channel,
  dataType,
  sortColumn,
  sortDirection,
  offset,
  limit
}) => {
  // console.log({ id, sectorLevel, activeProductType, channel })
  return api.get(
    `/sellout-actuals/amplify/sku?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType,
        channel,
        dataType,
        sortColumn,
        sortDirection,
        offset,
        limit
      },
      { skipNull: true }
    )}`
  )
}
