import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { bool, func, object, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import DatePicker from 'components/DatePicker'
import Dropdown from 'components/Dropdown'
import ErpSuggest from 'components/ErpSuggest'
import FieldError from 'components/ErrorMessage'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Label from 'components/Label'
import SegmentControl from 'components/SegmentControl'

import { HOUR_OPTIONS, MINUTE_OPTIONS } from 'utils/constants'

import * as spacing from 'styles/spacing'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Divider = styled.span`
  padding: 0 ${spacing.tiny};
`

const FloatingFieldError = styled(FieldError)`
  padding: ${spacing.small};
  padding-top: 0;
  margin-top: 0;
`

const BasicCreateCallForm = ({
  startDate,
  setStartDate,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  timeError,
  customer,
  setCustomer,
  type,
  setType,
  allowPastTime,
  canEdit,
  wasGenerated
}) => {
  const [startHourOptions, setStartHourOptions] = useState(HOUR_OPTIONS)
  const [startMinuteOptions, setStartMinutesOptions] = useState(MINUTE_OPTIONS)
  const [endHourOptions, setEndHourOptions] = useState(HOUR_OPTIONS)
  const [endMinuteOptions, setEndMinuteOptions] = useState(MINUTE_OPTIONS)

  const employee = useSelector((state) => state.auth.user)

  useEffect(() => {
    const hourOptions = getStartHourOptions()
    setStartHourOptions(hourOptions)
    if (moment(startDate).isSame(moment(), 'day') && !allowPastTime && hourOptions[0].value > startHour) {
      setStartHour(hourOptions[0].value)
    }
  }, [startDate])

  useEffect(() => {
    const newStartMinuteOptions = getMinuteOptions()
    setStartMinutesOptions(newStartMinuteOptions)

    if (newStartMinuteOptions[0].value > startMinute) {
      setStartMinute(newStartMinuteOptions[0].value)
    }
    const newEndHourOptions = getEndHourOptions()
    setEndHourOptions(newEndHourOptions)
    if (newEndHourOptions[0].value > endHour) {
      setEndHour(getEndHourOptions()[0].value)
    }
  }, [startDate, startHour])

  useEffect(() => {
    setEndMinuteOptions(getEndMinuteOptions())
  }, [startHour, startMinute, endHour])

  function getStartHourOptions() {
    const now = moment()
    if (allowPastTime) {
      if (now.isSame(startDate, 'day')) {
        return HOUR_OPTIONS.filter((opt) => opt.value <= now.hour())
      }
      return HOUR_OPTIONS
    }

    return HOUR_OPTIONS.filter((opt) => (now.isSame(startDate, 'day') ? opt.value >= now.hour() : true))
  }

  function getMinuteOptions() {
    const currentTime = moment()
    if (creatingPastEarlierInTheSameDay(currentTime)) {
      return MINUTE_OPTIONS.filter((opt) =>
        currentTime.isSame(startDate, 'day') ? opt.value <= currentTime.minute() : true
      )
    }

    return MINUTE_OPTIONS.filter((opt) => (isSameDaySameHour(currentTime) ? opt.value >= currentTime.minute() : true))
  }

  function getEndHourOptions() {
    const allHourOptions = startHourOptions
    const baseHour = startHour || allHourOptions[0].value

    return HOUR_OPTIONS.filter((opt) => opt.value >= baseHour)
  }

  function getEndMinuteOptions() {
    const baseStartHour = startHour || startHourOptions[0]
    const baseEndHour = endHour || endHourOptions[0]
    const allMinuteOptions = baseStartHour === baseEndHour ? startMinuteOptions : MINUTE_OPTIONS
    const baseStartMinute = startMinute || allMinuteOptions[0].value

    return allMinuteOptions.filter((opt) => (baseStartHour === baseEndHour ? opt.value >= baseStartMinute : true))
  }

  const creatingPastEarlierInTheSameDay = (currentTime) => {
    return allowPastTime && moment().isSame(startDate, 'day') && Number(startHour) === currentTime.hour()
  }

  const isSameDaySameHour = (currenTime) => {
    return currenTime.isSame(startDate, 'day') && (!startHour || currenTime.hour() === Number(startHour))
  }

  const adjustedCycleEndDate = useMemo(() => {
    if (employee?.currentCycle?.endTime)
      return employee?.currentCycle &&
        employee?.currentCycle?.endTime &&
        moment(employee.currentCycle.endTime).diff(moment(), 'days') < 7
        ? moment(employee.currentCycle.endTime).add(7, 'days')
        : moment(employee.currentCycle.endTime)
  }, [employee.currentCycle])

  const dateIsOutsideOfRange = (day) => {
    const isOutOfCycle =
      wasGenerated &&
      employee.currentCycle &&
      employee.currentCycle.endTime &&
      moment(day).isAfter(adjustedCycleEndDate)
    const pastTimeAllowed = allowPastTime ? day.isAfter(moment(), 'day') : day.isBefore(moment(), 'day')
    return pastTimeAllowed || isOutOfCycle
  }

  return (
    <Fieldset>
      <FieldsetItem>
        <DatePicker
          label="Select a date"
          value={startDate}
          onChange={(val) => setStartDate(val)}
          isOutsideRange={dateIsOutsideOfRange}
          disabled={!canEdit}
        />
      </FieldsetItem>
      <FieldsetItem half>
        <Label>Start time</Label>
        <Row>
          <Dropdown
            noIcon
            options={startHourOptions}
            value={startHour || ''}
            onChange={({ target }) => {
              setStartHour(target.value)
            }}
            disabled={!canEdit}
          />
          <Divider>:</Divider>
          <Dropdown
            noIcon
            options={startMinuteOptions}
            value={startMinute || ''}
            onChange={({ target }) => {
              setStartMinute(target.value)
            }}
            disabled={!canEdit}
          />
        </Row>
      </FieldsetItem>
      <FieldsetItem half>
        <Label>End time</Label>
        <Row>
          <Dropdown
            noIcon
            name="End time"
            options={endHourOptions}
            value={endHour || ''}
            onChange={({ target }) => {
              setEndHour(target.value)
            }}
            disabled={!canEdit}
          />
          <Divider>:</Divider>
          <Dropdown
            noIcon
            options={endMinuteOptions}
            value={endMinute || ''}
            onChange={({ target }) => {
              setEndMinute(target.value)
            }}
            disabled={!canEdit}
          />
        </Row>
      </FieldsetItem>
      {timeError && <FloatingFieldError>{timeError}</FloatingFieldError>}
      <FieldsetItem>
        <Label>Store</Label>
        <ErpSuggest
          input={{
            onChange: (val) => setCustomer(val),
            value: customer
          }}
          label="Store"
          isMulti={false}
          notTerritoryRestricted
          isDisabled={wasGenerated || !canEdit}
        />
      </FieldsetItem>
      <FieldsetItem>
        <SegmentControl
          label="Type"
          onChange={(e) => setType(e.target.value)}
          value={type || ''}
          options={[
            {
              label: 'Phone',
              value: 'telemarketing'
            },
            {
              label: 'In-person',
              value: 'in-person'
            }
          ]}
          disabled={!canEdit}
        />
      </FieldsetItem>
    </Fieldset>
  )
}

BasicCreateCallForm.propTypes = {
  startDate: oneOfType([object, string]),
  setStartDate: func,
  startHour: string,
  setStartHour: func,
  startMinute: string,
  setStartMinute: func,
  endHour: string,
  setEndHour: func,
  endMinute: string,
  setEndMinute: func,
  timeError: string,
  customer: object,
  setCustomer: func,
  type: string,
  setType: func,
  allowPastTime: bool,
  canEdit: bool,
  wasGenerated: bool
}

export default BasicCreateCallForm
