import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { bool, func, node, oneOf, oneOfType, string } from 'prop-types'

import IconButton from 'components/button/IconButton'

const overlayVariants = {
  initial: {
    opacity: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  },
  enter: {
    opacity: 1,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  }
}

const contentVariants = {
  initial: {
    opacity: 0,
    y: '5%',
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  }
}

export const Modal = ({ title, subtitle, open, onHandleClose, onOpenChange, children, size }) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild forceMount>
              <motion.div
                className="fixed inset-0 z-[200] overflow-y-auto bg-black/60"
                key="overlay"
                variants={overlayVariants}
                initial="initial"
                animate="enter"
                exit="initial"
              >
                <div className="flex min-h-full items-center justify-center px-5 text-center">
                  <Dialog.Content forceMount asChild>
                    <motion.div
                      className={`relative my-8 w-full ${
                        size === 'large' ? 'max-w-3xl' : 'max-w-lg'
                      } overflow-hidden rounded-xl bg-white p-6 text-left shadow-xl focus:outline-none sm:p-8`}
                      key="content"
                      variants={contentVariants}
                      initial="initial"
                      animate="enter"
                      exit="initial"
                    >
                      <div className="space-y-6 sm:space-y-8">
                        {title || subtitle ? (
                          <div>
                            {title ? (
                              <Dialog.Title asChild>
                                <h3 className="text-xl font-semibold leading-6 text-slate-900">{title}</h3>
                              </Dialog.Title>
                            ) : null}
                            {subtitle ? (
                              <Dialog.Description asChild>
                                <p className="mt-3">{subtitle}</p>
                              </Dialog.Description>
                            ) : null}
                          </div>
                        ) : null}

                        <div>{children}</div>
                      </div>
                      <div className="absolute right-5 top-5">
                        <Dialog.Close asChild>
                          <IconButton ghost icon="close" onClick={onHandleClose} aria-label="Close modal"></IconButton>
                        </Dialog.Close>
                      </div>
                    </motion.div>
                  </Dialog.Content>
                </div>
              </motion.div>
            </Dialog.Overlay>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

Modal.propTypes = {
  title: oneOfType([node, string]),
  subtitle: string,
  open: bool,
  onHandleClose: func,
  onOpenChange: func,
  children: node,
  size: oneOf(['large', undefined])
}
