import React, { useEffect, useState } from 'react'
import omit from 'lodash/omit'
import { nanoid } from 'nanoid'
import { rgba } from 'polished'
import { array, arrayOf, bool, func, object } from 'prop-types'
import styled from 'styled-components'

import Input from 'components/Input'
import Select from 'components/Select'
import BodyCell from 'components/table/cells/body/BodyCell'
import FooterCell from 'components/table/cells/footer/FooterCell'
import HeaderCell from 'components/table/cells/header/HeaderCell'

import { borderColor, greyDark, offWhite, white } from 'styles/colors'

export const StyledTable = styled.table`
  width: 100%;

  td,
  th {
    border: 1px solid ${borderColor};
    padding: 8px;
    font-size: 12px;
    position: relative;
    background-color: ${white};
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  th {
    color: ${greyDark};
    background-color: ${rgba(offWhite, 0.5)};
  }
`

const formatColumnConfig = (config) => {
  if (typeof config === 'string') {
    return { label: config }
  } else if (config && typeof config === 'object') {
    return config
  }
}

const sortFn =
  (key, direction = 'asc') =>
  (a, b) => {
    if (direction === 'neutral') return 0
    let la, lb
    if (direction === 'desc') {
      la = a
      lb = b
    } else {
      la = b
      lb = a
    }
    // nullish coalescing (??) operator would be quite cool here ... Would be nice if we could use it
    const aUndefinedOrNull = !la[key] && la[key] !== 0
    const bUndefinedOrNull = !lb[key] && lb[key] !== 0

    if (aUndefinedOrNull && bUndefinedOrNull) return 0
    if (aUndefinedOrNull && !bUndefinedOrNull) return 1
    if (!aUndefinedOrNull && bUndefinedOrNull) return -1
    if (la[key] > lb[key]) return 1
    if (lb[key] > la[key]) return -1
    return 0
  }

const Table = ({ data = [], columns = [], useFilter }) => {
  const [cols, setCols] = useState([])
  const [records, setRecords] = useState(data)
  const [showFilter, setShowFilter] = useState(false)
  const [filters, setFilters] = useState([])
  const [selectedFilter, setSelectedFilter] = useState()
  const [searchValue, setSearchValue] = useState()
  const [sortConfig, setSortConfig] = useState({ direction: 'neutral' })
  const [hasFooter, setHasFooter] = useState(false)

  // used to force rerender of certain components
  const [tableKey, setTableKey] = useState(nanoid())

  useEffect(() => {
    setTableKey(nanoid())
  }, [columns, data])

  useEffect(() => {
    const clmns = [...columns]
    clmns.sort(sortFn('order'))
    const newClmns = clmns.map((col) => omit(col, 'order'))
    setCols(newClmns)
  }, [columns])

  useEffect(() => {
    const filterClmns = cols.filter((clmn) => clmn.canFilter)
    if (filterClmns.length && useFilter) {
      setShowFilter(true)
      setFilters(filterClmns.map(({ label, name }) => ({ label, value: name })))
    }
    setHasFooter(cols.find((col) => Object.prototype.hasOwnProperty.call(col, 'footer')))
  }, [cols, useFilter])

  // resets the table when the data changes
  useEffect(() => {
    if (!cols || !data) return
    setRecords(data)
  }, [data])

  // Filters and Sorts the data
  useEffect(() => {
    let list = [...data]
    selectedFilter && searchValue && (list = applyFilter(list))
    sortConfig && (list = applySort(list))
    setRecords(list)
  }, [selectedFilter, searchValue, sortConfig])

  const applyFilter = (list = []) => {
    if (!selectedFilter || !searchValue) return list
    return list.filter((item) => item[selectedFilter].toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
  }

  const applySort = (list = []) => {
    if (!sortConfig) return list
    const cpy = [...list]
    cpy.sort(sortFn(sortConfig.name, sortConfig.direction))
    return cpy
  }

  return (
    <>
      {showFilter && (
        <TextFilter
          onInputChange={(e) => {
            setSearchValue(e.currentTarget.value)
          }}
          onFilterChange={(option) => {
            setSelectedFilter(option.value)
          }}
          filterOptions={filters}
        />
      )}
      <StyledTable>
        <thead>
          <tr key="header">
            {cols?.map((col, colInd) => {
              const headerCellConfig = Object.assign(
                {
                  type: 'textcell'
                },
                formatColumnConfig(col.header),
                {
                  column: col,
                  key: `${tableKey}${colInd}`
                }
              )

              // add default sort function if the headercell is a sortcell and no onSort handler has been provided
              if (headerCellConfig.type === 'sortcell') {
                if (!headerCellConfig.onSort)
                  headerCellConfig.onSort = (direction) => setSortConfig({ direction, name: col.name })
                headerCellConfig.direction = col.name === sortConfig.name ? sortConfig.direction : 'neutral'
              }

              return <HeaderCell key={colInd} {...headerCellConfig} />
            })}
          </tr>
        </thead>
        <tbody>
          {records?.map((record, rowInd) => (
            <tr key={rowInd}>
              {cols?.map((col, colIndex) => (
                <BodyCell
                  key={colIndex}
                  {...Object.assign(
                    {
                      type: 'textcell'
                    },
                    formatColumnConfig(col.body),
                    {
                      column: col,
                      record,
                      key: `${col.name}_${rowInd}`
                    }
                  )}
                />
              ))}
            </tr>
          ))}
          {hasFooter && (
            <tr key="footer" className="footer">
              {cols.map((col, colIndex) => {
                return (
                  <FooterCell
                    key={colIndex}
                    {...Object.assign(
                      {
                        type: 'textcell'
                      },
                      formatColumnConfig(col.footer),
                      {
                        column: col,
                        records: records || [],
                        key: `${col.name}_footer`
                      }
                    )}
                  />
                )
              })}
            </tr>
          )}
        </tbody>
      </StyledTable>
    </>
  )
}

Table.propTypes = {
  data: array.isRequired,
  columns: array.isRequired,
  useFilter: bool
}

// Should probably be it's own component
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const StyledInput = styled(Input)`
  height: 38px;
  flex: 0 1 60%;
`

const StyledSelect = styled(Select)`
  flex: 0 1 40%;
`

const TextFilter = ({ filterOptions = [], onFilterChange, onInputChange }) => {
  return (
    <FilterContainer>
      <StyledInput icon="search" onChange={onInputChange} />
      <StyledSelect placeholder="Filter by..." options={filterOptions} onChange={onFilterChange} />
    </FilterContainer>
  )
}
TextFilter.propTypes = {
  filterOptions: arrayOf(object).isRequired,
  onFilterChange: func.isRequired,
  onInputChange: func.isRequired
}

export default Table
