import React, { useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { rgba } from 'polished'
import { func } from 'prop-types'
import styled, { css } from 'styled-components'

import { submitPricingCapture } from 'store/pricings/actions'
import * as pricingSelectors from 'store/pricings/selectors'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import Input from 'components/Input'
import SegmentControl from 'components/SegmentControl'

import { formatUpc } from 'utils/helpers'

import { black, borderColor, greyDark, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, boxShadow, cover, square } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H2 } from 'styles/typography'

const Container = styled.div`
  ${cover('fixed')};
  background-color: ${rgba(black, 0.65)};
  z-index: 500;
`

const Table = styled.div`
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
`

const Cell = styled.div`
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: ${spacing.large};
  padding-bottom: ${spacing.xxLarge};
`

const Inner = styled.div`
  position: relative;
  padding: ${spacing.large};
  background-color: ${white};
  border-radius: ${borderRadius};
  margin: 0 auto;
  ${boxShadow};

  ${(props) =>
    props.capturesRemaining >= 1 &&
    css`
      &:after {
        content: '';
        position: absolute;
        background-color: ${white};
        left: 6px;
        right: 6px;
        bottom: -6px;
        height: 12px;
        border-radius: ${borderRadius};
        z-index: -1;
        ${boxShadow};
      }
    `}

  ${(props) =>
    props.capturesRemaining >= 2 &&
    css`
      &:before {
        content: '';
        position: absolute;
        background-color: ${white};
        left: 12px;
        right: 12px;
        bottom: -12px;
        height: 12px;
        border-radius: ${borderRadius};
        z-index: -2;
        ${boxShadow};
      }
    `}

  ${media.breakpoint`
    max-width: 380px;
  `};
`

const CodeWrap = styled.div`
  height: 100px;
  margin-bottom: ${spacing.medium};
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

const Code = styled.img`
  height: 100%;
`

const Name = styled(H2)`
  margin-bottom: ${spacing.medium};
  text-align: center;
`

const NavLinks = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${spacing.large} 0;
`
const Skip = styled(Link)`
  display: block;
  text-align: center;
  color: ${rgba(white, 0.6)};
  transition: color ${animationTime} ${animationCurve};

  &:hover {
    color: ${white};
  }
`

const Close = styled(Link)`
  position: absolute;
  bottom: 100%;
  right: 0;
  ${square('48px')};
  border-radius: 50%;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(black, 0.3)};
  margin-bottom: ${spacing.medium};
  transition: background-color ${animationTime} ${animationCurve};

  ${media.breakpoint`
    position: fixed;
    top: ${spacing.large};
    right: ${spacing.large};
    margin: 0;
  `};

  &:hover {
    background-color: ${rgba(black, 0.5)};
  }
`

const Details = styled.div`
  display: flex;
  padding: ${spacing.large} 0;
  border-top: 1px solid ${borderColor};
`

const DetailsColumn = styled.div`
  width: 50%;

  &:first-child {
    padding-right: ${spacing.small};
  }

  &:last-child {
    padding-left: ${spacing.small};
  }
`

const Meta = styled.small`
  display: block;
  margin-top: ${spacing.medium};

  strong {
    color: ${greyDark};
  }
`

const normalizePrice = (val) => val.replace(/[^\d|.]/g, '')
const normalizeVolume = (val) => val.replace(/[^\d|.|-]/g, '')

const PricingCard = ({ submitPricingCapture }) => {
  const [price, setPrice] = useState('')
  const [volume, setVolume] = useState('')
  const [outOfDistribution, setOutOfDistribution] = useState(false)
  const [validUpc, setValidUpc] = useState(true)

  const { sectorId: customerId, upc } = useParams()
  const navigate = useNavigate()

  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const pricing = useSelector((state) => pricingSelectors.pricingFromUrl(state, { customerId, upc }))
  const nextCapture = useSelector((state) => pricingSelectors.nextCaptureUpc(state, { customerId, upc }))
  const previousCapture = useSelector((state) => pricingSelectors.previousCaptureUpc(state, { customerId, upc }))
  const capturesRemaining = useSelector((state) => pricingSelectors.capturesRemaining(state, { customerId, upc }))

  const isValidSubmit = Boolean(+price || outOfDistribution)
  const isDuplicateSubmit = useMemo(() => {
    const isSamePrice = (!price && !pricing?.price) || +price === +pricing?.price
    const isSameDistribution = Boolean(outOfDistribution) === Boolean(pricing?.outOfDistribution)
    const isSameVolume = (!volume && !pricing?.volume) || +volume === +pricing?.volume
    const lastSubmitTooRecent = pricing?.updatedAt && moment().diff(pricing?.updatedAt, 'hours') < 6
    return isSamePrice && isSameDistribution && isSameVolume && lastSubmitTooRecent
  }, [price, outOfDistribution, volume, pricing])
  const nextCaptureSlug = nextCapture ? `/${nextCapture}` : '/thank-you'

  const submitCapture = () => {
    if (!isValidSubmit || isMobileViewOnly) return
    if (!isDuplicateSubmit) {
      const body = {
        customerId: +customerId,
        upc: pricing.upc,
        price: +price || null,
        volume: +volume || null,
        outOfDistribution,
        competitorUpc: pricing.competitorUpc
      }

      submitPricingCapture(body)
    }

    navigate('..' + nextCaptureSlug)
  }

  const renderBarcode = (newUpc) => {
    const { format, upcString } = formatUpc(newUpc)
    JsBarcode('#barcode', upcString, {
      format,
      valid: setValidUpc
    })
  }

  useEffect(() => {
    if (pricing) {
      try {
        renderBarcode(pricing.upc)
      } catch (e) {
        console.error(e)
      } finally {
        setOutOfDistribution(false)
        setPrice(pricing?.price || '')
        setVolume('')
      }
    }
  }, [pricing?.upc])

  useEffect(() => {
    if (outOfDistribution) {
      setPrice('')
    }
  }, [outOfDistribution])

  const pricingName = useMemo(() => {
    return [pricing.shortBrand, pricing.packSize, pricing.packCount].filter(Boolean).join(' ')
  }, [pricing])

  if (!pricing) return <Navigate to=".." />

  return (
    <Container>
      <Table>
        <Cell>
          <Inner capturesRemaining={capturesRemaining}>
            <Close to="..">
              <Icon icon="close" />
            </Close>
            <CodeWrap>{validUpc && <Code id="barcode" />}</CodeWrap>
            <Name className="notranslate" translate="no">
              {pricingName}
            </Name>
            <SegmentControl
              onChange={(e) => setOutOfDistribution(!outOfDistribution)}
              value={String(outOfDistribution)}
              options={[
                { label: 'In distribution', value: 'false' },
                { label: 'Out of distribution', value: 'true' }
              ]}
            />
            <Details>
              <DetailsColumn>
                <Input
                  dollar
                  disabled={outOfDistribution}
                  small
                  label="Price before tax"
                  input={{
                    value: price,
                    onChange: ({ target }) => setPrice(normalizePrice(target.value))
                  }}
                />
                <Meta>
                  Ceiling: <strong>{pricing.priceCeiling}</strong>
                </Meta>
                {pricing.retailerCapture && (
                  <Meta>
                    Ret. PC: <strong>{pricing.retailerCapture?.price || ''}</strong>
                  </Meta>
                )}
              </DetailsColumn>
              <DetailsColumn>
                <Input
                  label="Volume (optional)"
                  small
                  input={{
                    value: volume,
                    onChange: ({ target }) => setVolume(normalizeVolume(target.value))
                  }}
                />
                <Meta>
                  Comp: <strong>{pricing.competitivePrice}</strong>
                </Meta>
                {pricing.retailerCapture && (
                  <Meta>
                    Ret. Comp: <strong>{pricing.retailerCapture?.competitivePrice || ''}</strong>
                  </Meta>
                )}
              </DetailsColumn>
            </Details>
            <Button onClick={submitCapture} disabled={!isValidSubmit || isMobileViewOnly} primary full>
              Submit
            </Button>
            <NavLinks>
              <Skip to={`../${previousCapture}`}>Previous</Skip>
              <Skip to={`..${nextCaptureSlug}`}>Next</Skip>
            </NavLinks>
          </Inner>
        </Cell>
      </Table>
    </Container>
  )
}

PricingCard.propTypes = {
  submitPricingCapture: func.isRequired
}

export default connect(null, { submitPricingCapture })(PricingCard)

export const ThankYouCard = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Table>
        <Cell>
          <Inner>
            <Close to={'..'}>
              <Icon icon="close" />
            </Close>
            <Name>Thank you for submitting all prices.</Name>
            <Button onClick={() => navigate('..')} primary full>
              Done
            </Button>
          </Inner>
        </Cell>
      </Table>
    </Container>
  )
}
