import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS } from 'store/actions'

import { validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchStoreFootprint = createAction(
  'Fetch Price Capture Compliance Store Footprint Data',
  ({ dataKey, sectorType, sectorId }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data } = await api.getStoreFootprint(sectorType, sectorId)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].priceCaptureCompliance?.storeFootprint
        dispatch(
          action({
            id: sectorId,
            ...data
          })
        )
      })
)

export const fetchBrandCompletion = createAction(
  'Fetch Price Capture Compliance Brand Completion Data',
  ({ dataKey, sectorType, sectorId, category }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { brandCompletion }
        } = await api.getBrandCompletion(sectorType, sectorId, category)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].priceCaptureCompliance?.brandCompletion

        dispatch(
          action({
            id: sectorId,
            brandCompletion,
            category
          })
        )
      })
)

export const fetchFilters = createAction(
  'Fetch Price Capture Compliance Filters Data',
  ({ dataKey, sectorType, sectorId }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data: filters } = await api.getFilters(sectorType, sectorId)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].priceCaptureCompliance?.filters
        dispatch(
          action({
            id: sectorId,
            filters
          })
        )
      })
)

export const fetchGeography = createAction(
  'Fetch Price Capture Compliance Geography Data',
  ({ dataKey, sectorType, sectorId, geography, channel, ownershipType, banner, headoffice, category }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { geographies }
        } = await api.getGeography(sectorType, sectorId, geography, category, {
          channel,
          ownershipType,
          banner,
          headoffice
        })
        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.geography
        dispatch(
          action({
            id: sectorId,
            geography,
            category,
            filters: {
              channel,
              ownershipType,
              banner,
              headoffice
            },
            data: geographies
          })
        )
      })
)

export const fetchStrategyCompliance = createAction(
  'Fetch Price Compliance Strategy Compliance Data',
  ({ dataKey, sectorType, sectorId, category }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { strategyCompliance }
        } = await api.getStrategyCompliance(sectorType, sectorId, category)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.strategyCompliance
        dispatch(
          action({
            id: sectorId,
            strategyCompliance,
            category
          })
        )
      })
)

export const fetchEdlpCompliance = createAction(
  'Fetch Price Compliance Edlp Compliance Data',
  ({ dataKey, sectorType, sectorId }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { edlpCompliance }
        } = await api.getEdlpCompliance(sectorType, sectorId)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.edlpCompliance
        dispatch(
          action({
            id: sectorId,
            edlpCompliance
          })
        )
      })
)

export const fetchPriceComplianceGeographyFilters = createAction(
  'Fetch Price Compliance Geography Filters Data',
  ({ dataKey, sectorType, sectorId }) =>
    (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data: filters } = await api.getPriceComplianceGeographyFilters(sectorType, sectorId)

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.priceComplianceGeographyFilters
        dispatch(
          action({
            id: sectorId,
            filters
          })
        )
      })
)

export const fetchPriceComplianceGeographyCompliance = createAction(
  'Fetch Price Compliance Geography Compliance Data',
  ({
      dataKey,
      sectorType,
      sectorId,
      geography,
      category,
      // Below are filters
      channel,
      headoffice,
      banner,
      packType,
      packCount,
      strategyType,
      ownershipType
    }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { geographies }
        } = await api.getPriceComplianceGeography(sectorType, sectorId, geography, category, {
          // tier,
          channel,
          headoffice,
          banner,
          packType,
          packCount,
          strategyType,
          ownershipType
        })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType]?.priceCaptureCompliance?.priceComplianceGeography
        dispatch(
          action({
            id: sectorId,
            geography,
            category,
            data: geographies,
            filters: {
              // tier,
              channel,
              headoffice,
              banner,
              packType,
              packCount,
              strategyType,
              ownershipType
            }
          })
        )
      })
)
