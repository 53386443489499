import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Outlet, useParams } from 'react-router-dom'
import moment from 'moment'
import { object, string } from 'prop-types'

// import { customerFromUrl } from 'store/customers/selectors'
import * as pricingSelectors from 'store/pricings/selectors'

import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
// import GlobalAlert from 'components/GlobalAlert'
import PriceCaptureTable, { PriceCaptureTableRow } from 'components/PriceCaptureTable'
import Status from 'components/Status'

import { tinyFontSize } from 'styles/typography'

function getCaptureDate({ status, updatedAt, price, outOfDistribution }) {
  if (!price && !outOfDistribution) return ''
  if (status === 'expired') return 'OUTDATED'
  return moment(updatedAt).parseZone().format('MM/DD/YY')
}

const PriceCaptures = () => {
  // const [generatingPrintout, setGeneratingPrintout] = useState(false)
  const { sectorId: customerId } = useParams()

  const pricings = useSelector((state) => pricingSelectors.customerPricing(state, { customerId }))
  const nextCapture = useSelector((state) => pricingSelectors.nextCaptureUpc(state, { customerId }))
  // const customer = useSelector((state) => customerFromUrl(state, { customerId }))

  // useEffect(() => {
  //   let mounted = true

  //   async function generatePrintout() {
  //     if (!mounted) return

  //     if (generatingPrintout) {
  //       const { printItcoPrices } = await import('utils/createPricePrintoutPdf')
  //       printItcoPrices(customer.id, pricings, setGeneratingPrintout)
  //     }
  //   }

  //   generatePrintout()

  //   return () => {
  //     mounted = false
  //   }
  // }, [generatingPrintout, customer.id, pricings])

  if (!pricings.length) return <EmptyState title="No price requests" subtitle="We have no pricing data." />

  return (
    <Container padded>
      {/* {customer.pricingNotification && (
        <>
          <GlobalAlert>{customer.pricingNotification}</GlobalAlert>
          <br />
        </>
      )} */}

      <ButtonGroup>
        <Button to={nextCapture} primary>
          Update all prices
        </Button>
      </ButtonGroup>

      <PriceCaptureTable captureLink="nextCapture">
        {pricings.map((pricing, i) => {
          const pricingName = [pricing.shortBrand, pricing.packSize, pricing.packCount].filter(Boolean).join(' ')
          return (
            <PriceCaptureTableRow key={i}>
              <td className="notranslate" translate="no">
                <Link to={pricing.upc}>{pricingName}</Link>
                {/* {pricing.priceCeiling && <Subscript label={'Ceil:'} value={pricing.priceCeiling} />} */}
              </td>
              <td>
                <BeforeTax status={pricing.status} entity={pricing} />
              </td>
            </PriceCaptureTableRow>
          )
        })}
      </PriceCaptureTable>
      <Outlet />
    </Container>
  )
}

export default PriceCaptures

const Subscript = ({ label, value }) => (
  <div style={{ fontSize: tinyFontSize }}>
    <b>{label}</b> {value}
  </div>
)

Subscript.propTypes = {
  label: string,
  value: string
}

const BeforeTax = ({ entity }) => (
  <>
    <Status status={entity?.status || 'noStatus'} label={entity?.outOfDistribution ? '-' : entity?.price || ''} />
    {entity?.price && <Subscript label={'On:'} value={getCaptureDate(entity || {})} />}
  </>
)
BeforeTax.propTypes = {
  status: string.isRequired,
  entity: object.isRequired
}
