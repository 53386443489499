import queryString from 'query-string'

import { api } from 'store/api'

export const getCustomer = (customerId) => api.get(`/customers/${customerId}`)
export const updateCustomerHours = ({ customerId, hours }) => api.post(`/customers/${customerId}/hours`, { hours })
export const getCustomerPrimaryTerritory = (customerId) => api.get(`/customers/${customerId}/primary-territory`)

export const submitCustomerScopeUpdate = ({ customerId, id, programExists }) =>
  api.put(`/customers/${customerId}`, { id, programExists })

export const submitProjectResponse = (values) => api.post(`/customer-projects`, values)

export const getCustomerSuggestions = ({ match }) =>
  api.get(`/customers/suggest?${queryString.stringify({ match, fullCustomer: true }, { arrayFormat: 'comma' })}`)

export const createCustomerMessage = (details) => api.post('/messages', details)
export const updateCustomerMessage = (details) => api.put(`/messages/${details.id}`, details)
export const fetchMyMessages = () => api.get('/messages')

export const fetchCustomerGoFunds = (customerId) => api.get(`/customers/${customerId}/go-funds`)

export const getCustomerSellInPrograms = (customerId) => api.get(`/sell-in-programs/customer/${customerId}`)
