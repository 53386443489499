import React, { useContext } from 'react'
import { arrayOf, shape, string } from 'prop-types'

import MediaContext from 'context/MediaContext'

import Button from 'components/button/Button'

import MobileOverlay from './MobileOverlay'

const ViewHeader = ({ parentHeader, header, filters }) => {
  const { isWideLayout } = useContext(MediaContext)
  return (
    <div className="col-span-12 flex items-center max-md:mx-2 max-md:mt-16 md:justify-between md:py-2">
      <div className="flex w-full">
        <h1 className="text-left text-2xl font-semibold text-slate-900">
          <span className="opacity-60">{parentHeader && `${parentHeader} /`}</span> {header}
        </h1>
      </div>
      {filters && (
        <>
          {isWideLayout ? (
            <div className="flex flex-wrap justify-end gap-3 max-md:hidden">
              {filters.map((filter, i) => (
                <div key={i}>{filter}</div>
              ))}
            </div>
          ) : (
            <div className="flex flex-wrap justify-end md:hidden">
              <MobileOverlay
                header="Filters"
                trigger={
                  <Button icon="page-info" secondary>
                    Filters
                  </Button>
                }
              >
                {filters.map((filter, i) => (
                  <div key={`mobile-filter-${i}`}>{filter}</div>
                ))}
              </MobileOverlay>
            </div>
          )}
        </>
      )}
    </div>
  )
}

ViewHeader.propTypes = {
  parentHeader: string,
  header: string.isRequired,
  filters: arrayOf(shape({}))
}

export default ViewHeader
