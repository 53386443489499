import React, { useEffect } from 'react'
import { rgba } from 'polished'
import { bool, func, node } from 'prop-types'
import styled from 'styled-components'

import { black, borderColor, secondaryColor, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, containerMaxWidth, cover } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'

const Container = styled.div`
  ${cover('fixed')};
  background-color: ${rgba(black, 0.65)};
  z-index: 500;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};
`

const Panel = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: ${borderRadius} ${borderRadius} 0 0;
  background-color: ${white};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transform: ${(props) => (props.visible ? 'none' : 'translateY(100%)')};
  transition: opacity ${animationTime} ${animationCurve}, transform ${animationTime} ${animationCurve};
`

const Header = styled.header`
  border-bottom: 1px solid ${borderColor};
`

const HeaderInner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${spacing.medium};

  ${media.breakpoint`
    max-width: ${containerMaxWidth};
  `};
`

const Action = styled.div`
  color: ${secondaryColor};
`

const Title = styled(H3)`
  margin-bottom: 0;
`

const Inner = styled.div`
  margin: 0 auto;
  padding: ${spacing.medium};

  ${media.breakpoint`
    max-width: ${containerMaxWidth};
  `};
`

const Section = styled.div`
  & + & {
    border-top: 1px solid ${borderColor};
  }
`

const Scroll = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
`

const ActionSheet = ({ visible, action, title, children, alwaysRenderChildren, onOverlayClick = () => {} }) => {
  const handlePanelClick = (e) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (visible) {
      document.body.classList.add('lock')
    } else {
      document.body.classList.remove('lock')
    }
  }, [visible])

  return (
    <Container visible={visible} onClick={onOverlayClick}>
      <Panel visible={visible} onClick={handlePanelClick}>
        {title && (
          <Header>
            <HeaderInner>
              <Title>{title}</Title>
              <Action>{action}</Action>
            </HeaderInner>
          </Header>
        )}
        <Scroll>
          {(visible || alwaysRenderChildren) &&
            React.Children.map(
              children,
              (child, i) =>
                child && (
                  <Section key={i}>
                    <Inner>{child}</Inner>
                  </Section>
                )
            )}
        </Scroll>
      </Panel>
    </Container>
  )
}
ActionSheet.propTypes = {
  visible: bool,
  children: node,
  action: node,
  title: node,
  alwaysRenderChildren: bool,
  onOverlayClick: func
}

export default ActionSheet
