import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { func } from 'prop-types'
import styled from 'styled-components'

import ActionHeaderContext from 'context/ActionHeaderContext'
import SectorContext from 'context/SectorContext'

import { getCustomer } from 'store/customers/actions'
import { currentCustomer } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchSellInProgram } from 'store/sellInPrograms/actions'
import { sendSellInRetailerEmail } from 'store/sellInPrograms/endpoints'
import * as sellInSelector from 'store/sellInPrograms/selectors'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Button from 'components/button/Button'
import ConfirmActionSheet from 'components/ConfirmActionSheet'
import Container from 'components/Container'
import LoadingCard from 'components/LoadingCard'
import Pill from 'components/Pill'
import SectionDivider from 'components/SectionDivider'
import BrandTable from 'components/sellInPrograms/BrandTable'
import ExceptionTable from 'components/sellInPrograms/ExceptionTable'
import SellInEndedAlert, { isSellInEnded } from 'components/sellInPrograms/SellInEndedAlert'
import StatsDash from 'components/StatsDash'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

import { borderColor, sellInStatusColors, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

import SellInCustomerDetails from './SellInCustomerDetails'
import SellInCustomerForm from './SellInCustomerForm'
import SellInExceptionModal from './SellInExceptionModal'

const Card = styled.div`
  padding: ${spacing.medium};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  margin-bottom: ${spacing.medium};
  background-color: ${white};

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const SellInCustomerView = ({ connectedGetCustomer, connectedFetchSellInProgram }) => {
  const { programId: sellInProgramId, sectorId: customerId, sectorType } = useParams()
  const { addAction } = useContext(ActionHeaderContext)
  const { currentSector } = useContext(SectorContext)
  const territoryId = currentSector[SECTOR_LEVELS.TERRITORY].id

  const [loading, setLoading] = useState(true)
  const [exceptionType, setExceptionType] = useState('')
  const [emailSending, setEmailSending] = useState(false)
  const [showConfirmEmailSend, setShowConfirmEmailSend] = useState(false)
  const [selectedException, setSelectedException] = useState()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    connectedFetchSellInProgram(sellInProgramId, territoryId, null).then(() => {
      if (isMounted.current) {
        setLoading(false)
      }
    })
  }, [sectorType, customerId])

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      connectedGetCustomer(customerId, customerDataKey)
    }
  }, [])

  useEffect(() => {
    setShowModal(Boolean(selectedException))
  }, [selectedException])

  useEffect(() => {
    connectedGetCustomer(customerId, customerDataKey).then(() => {
      if (isMounted.current) {
        setLoading(false)
      }
    })
  }, [sectorType, customerId])

  const customerDataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })
  const customerIsFetching = useSelector((state) => isDataKeyLoading(state, { dataKey: customerDataKey }))
  const isLoading = customerIsFetching || loading

  const customer = useSelector((state) => currentCustomer(state, { customerId }))
  const isVolumeProgram = useSelector((state) =>
    sellInSelector.isVolumeSellInProgram(state, { sellInProgramId, territoryId })
  )
  const { sellInTarget, sellInProgram } = useSelector((state) =>
    sellInSelector.sellInProgramTarget(state, { sellInProgramId, customerId, territoryId })
  )

  const isEnded = isSellInEnded(sellInProgram?.endDate)
  const hasPendingExceptions = sellInTarget?.exceptions?.some(({ exceptionStatus }) => exceptionStatus === 'pending')

  const stats = [
    { label: 'Total Suggested', value: sellInTarget?.suggestedQty || 0 },
    { label: 'Total Accepted', value: sellInTarget?.finalQty || 0 }
  ]

  const renderForm = useMemo(() => {
    return !isEnded && !hasPendingExceptions && (sellInTarget?.status === 'pending' || exceptionType)
  }, [hasPendingExceptions, exceptionType, isEnded, sellInTarget?.status])

  const canSendRetailerEmail = useMemo(() => {
    return (
      isVolumeProgram &&
      !exceptionType &&
      (isEnded ? sellInTarget.status === 'accepted' : sellInTarget?.status !== 'rejected')
    )
  }, [isVolumeProgram, exceptionType, isEnded, sellInTarget?.status])

  const RenderCustomerSellInProgram = renderForm ? SellInCustomerForm : SellInCustomerDetails

  useEffect(() => {
    addAction({
      getPill: () => (
        <Pill key={1} color={sellInStatusColors[sellInTarget?.status]}>
          {sellInTarget?.status}
        </Pill>
      ),
      getSellInProgramName: () => sellInProgram?.name || '',
      getCustomerName: () => customer?.name || ''
    })
  }, [customer?.name, sellInProgram?.name])

  const handleSendSkuBreakdown = async () => {
    if (!canSendRetailerEmail) return
    setEmailSending(true)
    await sendSellInRetailerEmail(sellInTarget)
      .then(() => {
        setShowConfirmEmailSend(false)
        setEmailSending(false)
      })
      .catch((err) => {
        setEmailSending(false)
        console.log(err)
      })
  }

  const closeModal = () => {
    setSelectedException()
    setShowModal(false)
  }

  if ((!sellInTarget || !sellInProgram) && !isLoading) return <Navigate to={`..`} />

  if (isLoading)
    return (
      <div className="relative">
        <LoadingCard dataKey={customerDataKey} />
      </div>
    )

  return (
    <>
      <Container>
        {isEnded && <SellInEndedAlert isVolumeProgram={isVolumeProgram} />}
        {isVolumeProgram && <StatsDash stats={stats} />}
        {isVolumeProgram && (
          <div>
            <BasicAccordion title="Brand totals" preExpand>
              <BrandTable brands={sellInTarget?.brandTotals} />
            </BasicAccordion>
          </div>
        )}
        <SectionDivider />
        <Card>
          <RenderCustomerSellInProgram
            isVolumeProgram={isVolumeProgram && sellInTarget?.skus?.length > 0}
            sellInTarget={sellInTarget}
            sellInProgram={sellInProgram}
            setExceptionType={setExceptionType}
            exceptionType={exceptionType || ''}
            allowException={!hasPendingExceptions && !isEnded}
          />
        </Card>
        {canSendRetailerEmail && (
          <Button color={sellInProgram.color} type="button" onClick={() => setShowConfirmEmailSend(true)}>
            Email SKU breakdown
          </Button>
        )}
        {Boolean(sellInTarget?.exceptions?.length) && (
          <>
            <SectionDivider title="Exceptions" />
            <ExceptionTable data={sellInTarget.exceptions} exceptionActionClick={setSelectedException} />
          </>
        )}
      </Container>
      <ConfirmActionSheet
        title="Confirm Email"
        visible={showConfirmEmailSend}
        message="Are you sure you want to email a SKU breakdown to this retailer?"
        confirmAction={handleSendSkuBreakdown}
        cancelAction={() => setShowConfirmEmailSend(false)}
        isLoading={emailSending}
      />
      {showModal && selectedException && (
        <SellInExceptionModal exception={selectedException} open={showModal} onHandleClose={closeModal} />
      )}
    </>
  )
}

SellInCustomerView.propTypes = {
  connectedGetCustomer: func,
  connectedFetchSellInProgram: func
}

export default connect(null, {
  connectedGetCustomer: getCustomer,
  connectedFetchSellInProgram: fetchSellInProgram
})(SellInCustomerView)
