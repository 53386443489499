import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { transparentize } from 'polished'
import { func, number } from 'prop-types'
import { Pie, PieChart } from 'recharts'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchOrderCompletion } from 'store/Sellin/actions'
import { sellinOrderCompletionData } from 'store/Sellin/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage, parseNumberString } from 'utils/helpers'

import SellinContext from './SellinContext'

const DataLabel = tw.span`block text-2xs font-medium text-slate-500 text-center`

const ChartWrapper = tw.div`relative flex min-h-fit h-[200px] grow flex-col items-center justify-center`

const DataXL = tw.span`block text-xl font-medium text-slate-900 text-center`

const Data2XL = tw.span`block text-3xl font-medium text-slate-900 text-center`

const Spacer = tw.div`shrink-0 h-9 w-full max-md:hidden`

const AmplifySellinOrderCompletion = ({ span, fetchOrderCompletion }) => {
  const { formatMessage } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { vapeCategory } = useContext(SellinContext)
  const [error, setError] = useState()
  const mounted = useRef(false)

  const dataKey = createDataKey(DATAKEY_TYPES.SELL_IN_ORDER_COMPLETION, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const { completionPercentage, actuals, gap } = useSelector((state) =>
    sellinOrderCompletionData(state, {
      activeProductType: currentProductType
    })
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchOrderCompletion(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          vapeCategory
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType])

  const circularData = useMemo(() => {
    const percentage = (completionPercentage || 0) * 100
    return [
      {
        name: 'completionPercentage',
        value: Math.min(percentage, 100),
        fill: '#E08330'
      },
      {
        name: 'gapPercentage',
        value: Math.max(100 - percentage, 0),
        fill: transparentize(0.8, '#E08330')
      }
    ]
  }, [completionPercentage])

  const renderContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title="An error occured" subtitle={error} />

    return (
      <div className="flex h-full flex-col justify-between">
        <Spacer />
        <div className="flex h-full pt-8">
          <div className="my-auto w-16 min-w-fit space-y-5 max-md:hidden">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.actuals' })}</DataLabel>
              <DataXL>{actuals || '-'}</DataXL>
            </div>
          </div>
          <ChartWrapper>
            <div className="absolute">
              <PieChart width={200} height={200} margin={0}>
                <Pie
                  data={circularData}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value"
                  innerRadius="90%"
                  outerRadius="100%"
                  isAnimationActive={false}
                />
              </PieChart>
            </div>
            <DataLabel>{formatMessage({ messageId: 'sellIn.today' })}</DataLabel>
            <Data2XL>{completionPercentage ? parseNumberString(completionPercentage * 100) : 0}%</Data2XL>
            <DataLabel>{formatMessage({ messageId: 'sellIn.ofTarget' })}</DataLabel>
          </ChartWrapper>
          <div className="my-auto w-16 min-w-fit space-y-5 max-md:hidden">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.gap' })}</DataLabel>
              <DataXL>
                {gap > 0 ? '+' : ''}
                {!isNaN(gap) ? gap : '-'}
              </DataXL>
            </div>
          </div>
        </div>
        <Spacer />
        <div className="flex pt-8 md:hidden">
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.actuals' })}</DataLabel>
              <DataXL>{actuals ? parseNumberString(actuals) : '-'}</DataXL>
            </div>
          </div>
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="space-y-2">
              <DataLabel>{formatMessage({ messageId: 'sellIn.gap' })}</DataLabel>
              <DataXL>{completionPercentage ? parseNumberString(completionPercentage * 100) : 0}%</DataXL>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card title="Order completion" span={span} displayAmplify>
      {renderContent()}
    </Card>
  )
}

AmplifySellinOrderCompletion.propTypes = {
  span: number,
  fetchOrderCompletion: func
}

export default connect(null, { fetchOrderCompletion })(AmplifySellinOrderCompletion)
