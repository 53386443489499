import { darken, lighten, mix } from 'polished'

// Primary
export const red = '#FA403C'
export const blue = '#3B98F2'
export const goBlue = '#1999d5'
export const deepBlue = '#162a5f'

// Greys
export const greyDark = '#2A3242'
export const grey = '#58626D'
export const greyLight = '#9DA6B0'
export const greyLightest = '#E0E3E7'
export const offWhite = '#F3F4F7'

// Misc
export const white = '#fff'
export const black = '#000'
export const yellow = '#FAB613'
export const green = '#15CD96'
export const limeGreen = '#4DCD2D'
export const extraPrimary = '#1aafe6' // blue
export const extraSecondary = '#00aaab' // teal
export const violet = '#7F00FF'
export const extraHubBlue = '#0E2B63'

// Set colors
export const primaryColor = red
export const secondaryColor = blue
export const backgroundColor = offWhite
export const borderColor = greyLightest

export const sellInStatusColors = {
  pending: yellow,
  accepted: green,
  refused: red
}

export const calendarEventColors = {
  missed: yellow,
  future: darken(0.1, violet),
  moved: darken(0.1, violet),
  telemarketing: lighten(0.1, yellow),
  telemarketingFulfilled: darken(0.3, limeGreen),
  telemarketingPast: mix(0.6, red, yellow),
  'in-person': lighten(0.1, limeGreen),
  'in-personFulfilled': darken(0.1, limeGreen),
  'in-personPast': darken(0.2, red),
  training: '#27A6DC',
  'time-off': '#C4C4C4',
  lunch: '#C4C4C4',
  generatedCalls: darken(0.1, blue),
  adhoc: black
}

/*
  TCP CLIENT REQUESTED COLORS
  Scheduled Calls not completed: #4472c4 (blue)
  In person app logged call: #c6e0b4 (neon green)
  Telemarketing app logged call: #548235 (dark green)
  In person past call: red
  Telemarketing past call: #ed7d31 (orange)
  Missed call: yelow
  OOT: #d0cece (grey)
*/
