import React, { useContext } from 'react'

import SectorContext from 'context/SectorContext'

import SegmentControl from 'components/SegmentControl'

import { PRODUCT_TYPE_OPTIONS } from 'utils/constants'

const ProductTypePicker = () => {
  const { currentProductType, setProductType } = useContext(SectorContext) || {}
  if (!currentProductType) return null
  return (
    <SegmentControl
      label="Product type"
      name="product-type"
      onChange={(e) => setProductType(e.target.value)}
      value={currentProductType}
      options={PRODUCT_TYPE_OPTIONS}
    />
  )
}

export default ProductTypePicker
