import { css } from 'styled-components'

// Breakpoints
export const breakpoints = {
  breakpoint: 560
}

export const media = Object.entries(breakpoints).reduce(
  (acc, [label, size]) => ({
    ...acc,
    [label]: (...args) =>
      css`
        @media (min-width: ${size}px) {
          ${css(...args)};
        }
      `
  }),
  {}
)
