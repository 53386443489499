import React, { useContext } from 'react'
import { Link, matchPath, Outlet, useLocation, useParams } from 'react-router-dom'
import tw, { styled } from 'twin.macro'

import ActionHeaderContext, { ActionHeaderProvider } from 'context/ActionHeaderContext'
import LangContext from 'context/LangContext'

import EmptyState from 'components/EmptyState'
import PageStatus from 'components/PageStatus'

import { SECTOR_LEVELS } from 'utils/constants'

import HEADER_CONTENT from './HeaderContent'

const Header = tw.h1`font-semibold text-4xl`

const Breadcrumb = styled(Link)(({ isActive }) => [
  tw`flex space-x-2`,
  isActive ? tw`text-slate-900 pointer-events-none cursor-auto` : tw`text-slate-500 hover:text-slate-800`
])

const PageHeader = () => {
  const location = useLocation()

  const { actions } = useContext(ActionHeaderContext)

  const root = ':sectorType/:sectorId/actions'
  // find the Header Content that matches the current action route
  const path = location.pathname.substring()
  const match = Object.keys(HEADER_CONTENT).find((key) => matchPath(`${root}/${key}`, path))
  if (!match) return null

  // extract Header Content informations
  const { params } = matchPath(`${root}/${match}`, path)
  const headerContent = HEADER_CONTENT[match]
  const header = headerContent.header?.(params, actions)
  const breadcrumbs = headerContent.breadcrumbs?.(params, actions)
  const headerActions = headerContent.headerActions?.(params, actions)

  return (
    <div className="space-y-3 p-6 max-md:p-4 max-md:pt-20">
      <div className="space-y-4">
        <div className="flex justify-between">
          <div className="flex space-x-2">
            {breadcrumbs.map((breadcrumb, i) => {
              const isLast = i + 1 === breadcrumbs.length

              return (
                <Breadcrumb to={breadcrumb.href} key={i} isActive={isLast}>
                  <span>{breadcrumb.text}</span>
                  {!isLast && <span>/</span>}
                </Breadcrumb>
              )
            })}
          </div>
          <PageStatus error={false} timeUpdated={Date()} />
        </div>
        <div className="flex items-end justify-between">
          <Header>{header}</Header>
          {Boolean(headerActions?.length) && <div className="mb-1 flex space-x-4">{headerActions}</div>}
        </div>
      </div>
      <hr className="w-full" />
    </div>
  )
}

const Wrapper = tw.div`space-y-6 overflow-y-scroll h-full`

const Content = tw.div`space-y-4 flex flex-col`

const ActionsLayout = () => {
  const { sectorType } = useParams()
  const { formatMessage } = useContext(LangContext)

  return (
    <Wrapper>
      <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:p-1">
        <div className="col-span-12">
          {[SECTOR_LEVELS.CUSTOMER, SECTOR_LEVELS.TERRITORY].includes(sectorType) ? (
            <>
              <ActionHeaderProvider>
                <PageHeader />
                <Content>
                  <Outlet />
                </Content>
              </ActionHeaderProvider>
            </>
          ) : (
            <EmptyState
              title={formatMessage({ messageId: 'actions.wrongSector' })}
              subtitle={formatMessage({ messageId: 'actions.wrongSector.description' })}
            />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default ActionsLayout
