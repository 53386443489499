import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

const productTypeFromProps = (state, props) => props.activeProductType
const geographyFromProps = (state, props) => props.geography

export const trendedDistroData = createSelector(
  productTypeFromProps,
  dataSourceFromSectorLevel,
  (productType, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`distro-trended-${productType}`] || {}

    return {
      ...amounts
    }
  }
)

export const brandDistroData = createSelector(
  productTypeFromProps,
  dataSourceFromSectorLevel,
  (productType, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`distro-brand-${productType}`] || {}

    return {
      ...amounts
    }
  }
)

export const materialDistroData = createSelector(
  productTypeFromProps,
  dataSourceFromSectorLevel,
  (productType, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`distro-material-${productType}`] || {}

    return {
      ...amounts
    }
  }
)

export const geographyDistroData = createSelector(
  geographyFromProps,
  dataSourceFromSectorLevel,
  (geography, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`distro-geography-${geography}`] || {}

    return {
      ...amounts
    }
  }
)
