// Note: Style template code.

import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object, string } from 'prop-types'
import tw from 'twin.macro'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchActivity } from 'store/extraHub/actions'
import { getCurrentActivity } from 'store/extraHub/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const Data = ({ data, additional }) => (
  <div className="w-full space-y-4">
    <Eyebrow />
    <div className="flex flex-col items-center space-y-2">
      <span className="text-3xl font-medium text-slate-900">{data}</span>
      <span>{additional}</span>
    </div>
  </div>
)

Data.propTypes = {
  data: string,
  additional: string
}

const TopSection = tw.div`flex w-full space-x-4`

const BottomSection = tw.div`space-y-5`

const Activity = ({ span, fetchActivity }) => {
  const [error, setError] = useState()
  const { sectorType, sectorId } = useParams()
  const currentActivity = useSelector((state) => getCurrentActivity(state))

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.ACTIVITY, { sectorType, sectorId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchActivity({ dataKey, sectorType, sectorId })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId])

  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    return (
      <div className="flex h-full flex-col justify-around">
        <TopSection>
          <Data data={`${currentActivity.erp}%`} additional={`of ${currentActivity.totalErp} stores`} />
          <Data data={`${currentActivity.user}%`} additional={`of ${currentActivity.totalUser} users`} />
        </TopSection>
        <BottomSection>
          <Data data={`$${currentActivity.coins}`} additional="available to be redeemed" />
        </BottomSection>
      </div>
    )
  }

  return (
    <Card title="Extra hub activity" span={span} displayAmplify>
      {getContent()}
    </Card>
  )
}

Activity.propTypes = {
  span: object,
  fetchActivity: func
}

export default connect(null, {
  fetchActivity
})(Activity)
