import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { denormalize } from 'normalizr'
import { createSelector } from 'reselect'

import { customerIdFromProps } from 'store/customers/selectors'
import { goProgramTargetFromUrl } from 'store/goFundPrograms/selectors'
import { currentTerritory } from 'store/territories/selectors'

import { GO_FUND_PAYMENT_TYPES } from 'utils/constants'

import { territoryGoFundAllocations } from './schema'
const fullState = (state) => state

export const programIdFromUrl = (state, props) => props.programId

const allGoFundAllocations = (state) => omit(state.goFundAllocations, '_persist')

export const territoryGoFunds = createSelector(
  currentTerritory,
  allGoFundAllocations,
  fullState,
  (currentTerritory, allGoFundAllocations, fullState) => {
    if (!currentTerritory) return []
    const goFundAllocations = Object.values(allGoFundAllocations)
      .filter(
        ({ regionId, territoryId }) => currentTerritory.id === territoryId || currentTerritory.regionId === regionId
      )
      .map((allocation) => ({ ...allocation, goFund: allocation.goFundId }))
    const denormalizedAllocations = uniqBy(goFundAllocations, 'id').map((gfa) =>
      denormalize(gfa, territoryGoFundAllocations, fullState)
    )
    const territoryAllocations = denormalizedAllocations
      .map(({ goFundPrograms = [], ...rest }) => ({
        ...rest,
        goFundPrograms: goFundPrograms.filter(({ customerId }) => currentTerritory.customers.includes(customerId))
      }))
      .filter(({ goFundPrograms }) => goFundPrograms?.length)
    return territoryAllocations
  }
)

export const lastProgramActionDate = ({ officialPaymentDate, paymentExtractedAt, updatedAt }) =>
  moment(officialPaymentDate?.split('T')[0] || paymentExtractedAt || updatedAt).valueOf()

export const territoryGoFundDetails = createSelector(
  territoryGoFunds,
  fullState,
  (territoryGoFunds, { auth, customers }) => {
    const preferredLanguage = auth.user.preferredLanguage
    return orderBy(territoryGoFunds, [({ endDate }) => new Date(endDate).valueOf()], ['desc']).map(
      ({ goFund, goFundPrograms, allocatedBudget, territoryId, ...rest }) => {
        const isEnded = moment().isAfter(goFund.endDate, 'day')
        const fundName = goFund[`${preferredLanguage}Name`]
        const spent = sumBy(goFundPrograms, ({ finalCost, officialPaymentAmount, payments }) => {
          const offlinePaymentAmount =
            !officialPaymentAmount && officialPaymentAmount !== 0 ? +finalCost : +officialPaymentAmount
          const instantPayments = sumBy(payments, 'paymentAmount')
          return goFund.paymentType === GO_FUND_PAYMENT_TYPES.instant ? instantPayments : offlinePaymentAmount
        })
        const reserved = sumBy(goFundPrograms, ({ maxPayout, finalCost }) => (+finalCost ? 0 : +maxPayout))
        const underReview = goFund.status === 'allocation'
        const availableFunds = allocatedBudget - reserved - spent
        const isExpired = moment().subtract(30, 'days').isAfter(goFund.endDate, 'day')

        return {
          fundName,
          activity: goFund.activity,
          territoryId,
          goFundId: goFund.id,
          startDate: goFund.startDate,
          endDate: goFund.endDate,
          spent,
          availableFunds,
          allocatedBudget,
          underReview,
          isEnded,
          isExpired,
          programs: orderBy(goFundPrograms, lastProgramActionDate, 'desc').map(
            ({
              customerId,
              finalCost,
              maxPayout,
              paymentExtractedAt,
              endDate,
              officialPaymentDate,
              officialPaymentAmount,
              transactionType,
              payments,
              activity,
              ctnsBooked,
              perCartonCost,
              targetVolume,
              brandFocus,
              ...rest
            }) => {
              const perCartonMaxPayout = perCartonCost && targetVolume ? targetVolume * perCartonCost : allocatedBudget
              return {
                customerId,
                customer: customers[customerId]?.name || '',
                finalCost,
                maxPayout: maxPayout || perCartonMaxPayout,
                cartonPayout: ctnsBooked * perCartonCost,
                paymentExtractedAt,
                endDate,
                officialPaymentAmount,
                officialPaymentDate,
                totalPayments: sumBy(payments, 'paymentAmount'),
                transactionType,
                brandFocus
              }
            }
          )
        }
      }
    )
  }
)

export const territoryGoFundTotals = createSelector(territoryGoFundDetails, (goDetails) => {
  return [
    { label: 'Total budget', total: sumBy(goDetails, 'allocatedBudget') },
    { label: 'Total spent', total: sumBy(goDetails, 'spent') }
  ]
})

export const territoryGoFundFormDetails = createSelector(territoryGoFundDetails, (territoryGoFundDetails) => {
  return territoryGoFundDetails.reduce((acc, { goFundId, underReview, availableFunds, ...rest }) => {
    return {
      [goFundId]: {
        goFundId,
        availableFunds: underReview ? 0 : availableFunds,
        underReview,
        ...pick(rest, ['fundName', 'territoryId', 'startDate', 'endDate', 'isEnded'])
      },
      ...acc
    }
  }, {})
})

export const goFundOptions = createSelector(territoryGoFundFormDetails, (territoryGoFundDetails) => {
  return Object.values(territoryGoFundDetails).reduce(
    (acc, { fundName, territoryId, availableFunds, goFundId, endDate, underReview, isEnded }) => {
      if (isEnded) return acc
      const label = fundName + (underReview ? ' (Under review)' : ` ($${availableFunds})`)
      return [
        { label, value: JSON.stringify({ territoryId, goFundId }), disabled: !availableFunds || underReview },
        ...acc
      ]
    },
    [{ label: 'Emergency funds', value: '{}' }]
  )
})

export const programForm = ({ form }) => form.createProgram?.values || form.editProgram?.values || {}

export const budgetFromUrl = createSelector(programIdFromUrl, fullState, (programId, { goFunds }) => {
  return goFunds[programId.split('_')[1]]
})

export const goFundExceptionInitialValues = createSelector(
  programIdFromUrl,
  customerIdFromProps,
  (programId, customerId) => ({
    programId: programId.split('_')[0],
    goFundId: programId.split('_')[1],
    customerId,
    payeeId: '',
    requestedAmount: ''
  })
)

export const goFundPaymentInitialValues = createSelector(
  programIdFromUrl,
  customerIdFromProps,
  fullState,
  (programId, customerId, state) => ({
    goFundId: programId.split('_')[1],
    customerId,
    payAmount: '',
    payeeId: '',
    carton_qty: '',
    requested_by: state.auth.user.id,
    programId: programId.split('_')[0]
  })
)

export const goFundCartonsInitialValues = createSelector(
  programIdFromUrl,
  customerIdFromProps,
  (programId, customerId) => {
    return { programId: programId.split('_')[0], customerId }
  }
)

export const goFundTargetInitialValues = createSelector(programIdFromUrl, (programId) => ({
  goFundProgramId: programId.split('_')[0]
}))

export const goFundTargetUpdateInitialValues = createSelector(
  programIdFromUrl,
  goProgramTargetFromUrl,
  (programId, target) => ({
    goFundProgramId: programId.split('_')[0],
    target
  })
)
