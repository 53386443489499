import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchFilters, fetchGeography } from 'store/priceCaptureCompliance/actions'
import { getFilters, getGeography } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import DataTable from 'components/DataTable/DataTable'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import GeographyToggle from '../GeographyToggle'

const COLS = [
  {
    field: 'name',
    headerName: 'Region'
  },
  {
    field: 'storesUpdated',
    headerName: 'Stores updated'
  },
  {
    field: 'storesRemaining',
    headerName: 'Remaining stores'
  },
  {
    field: 'storesCount',
    headerName: 'Total stores'
  },
  {
    field: 'skusUpdated',
    headerName: 'Updated SKUs'
  },
  {
    field: 'skusRemaining',
    headerName: 'Remaining SKUs'
  },
  {
    field: 'skusTotal',
    headerName: 'Total SKUs'
  }
]

function makeRow({
  name,
  storesUpdated,
  storesPercent,
  storesRemaining,
  storesCount,
  skusUpdated,
  skusPercent,
  skusRemaining,
  skusTotal
}) {
  return {
    name,
    storesUpdated: <DataCombined primary={storesUpdated.toLocaleString('default')} secondary={`${storesPercent}%`} />,
    storesRemaining: storesRemaining.toLocaleString('default'),
    storesCount: storesCount.toLocaleString('default'),
    skusUpdated: <DataCombined primary={skusUpdated.toLocaleString('default')} secondary={`${skusPercent}%`} />,
    skusRemaining: skusRemaining.toLocaleString('default'),
    skusTotal: skusTotal.toLocaleString('default')
  }
}

const createOptions = (filters) => {
  let options = []
  if (typeof filters[0] === 'string') {
    options = filters.map((filter) => ({ label: filter, value: filter }))
  } else {
    options = filters.map((filter) => ({ label: filter.name, value: filter.id }))
  }
  return sortBy(options, 'label')
}

const GeographyTable = ({ span, fetchFilters, fetchGeography }) => {
  const { sectorType, sectorId } = useParams()
  const { currentProductType: category } = useContext(SectorContext)
  const [error, setError] = useState()
  const [geography, setGeography] = useState('territory')
  const [selectedChannel, setSelectedChannel] = useState('all')
  const [selectedOwnershipType, setSelectedOwnershipType] = useState('all')
  const [selectedHeadOffice, setSelectedHeadOffice] = useState('all')
  const [selectedBanner, setSelectedBanner] = useState('all')

  const filterDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.FILTERS, { sectorType, sectorId })
  const areFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filterDataKey }))
  const filters = useSelector((state) => getFilters(state))

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.GEOGRAPHY, {
    sectorType,
    sectorId,
    category,
    geography,
    channel: selectedChannel,
    ownershipType: selectedOwnershipType,
    banner: selectedBanner,
    headoffice: selectedHeadOffice
  })

  const areGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))
  const rawGeographies = useSelector((state) => getGeography(state) || [])

  const geographies = useMemo(() => {
    const geographies =
      rawGeographies[
        `geography-${geography}-${category}-${selectedChannel}-${selectedOwnershipType}-${selectedBanner}-${selectedHeadOffice}`
      ] || []
    return geographies.map(makeRow)
  }, [
    rawGeographies,
    sectorType,
    sectorId,
    geography,
    category,
    selectedChannel,
    selectedOwnershipType,
    selectedBanner,
    selectedHeadOffice,
    areGeographyLoading
  ])

  const channelOptions = useMemo(() => {
    const options = createOptions(filters?.channels || [])
    options.splice(0, 0, { label: 'All Channels', value: 'all' })
    return options
  }, [sectorType, sectorId, areFiltersLoading])
  const ownershipOptions = useMemo(() => {
    const options = createOptions(filters?.ownershipTypes || [])
    options.splice(0, 0, { label: 'All Ownership Types', value: 'all' })
    return options
  }, [sectorType, sectorId, areFiltersLoading])
  const headofficeOptions = useMemo(() => {
    const options = createOptions(filters?.headoffices || [])
    options.splice(0, 0, { label: 'All Head Offices', value: 'all' })
    return options
  }, [sectorType, sectorId, areFiltersLoading])
  const bannerOptions = useMemo(() => {
    const options = createOptions(filters?.banners || [])
    options.splice(0, 0, { label: 'All Banners', value: 'all' })
    return options
  }, [sectorType, sectorId, areFiltersLoading])

  const isMounted = useRef()

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchFilters({ sectorType, sectorId, dataKey: filterDataKey })
      .then(() => setError())
      .catch((e) => setError(e))
  }, [sectorType, sectorId])

  useEffect(() => {
    fetchGeography({
      dataKey: geographyDataKey,
      sectorType,
      sectorId,
      category,
      geography,
      channel: selectedChannel,
      ownershipType: selectedOwnershipType,
      banner: selectedBanner,
      headoffice: selectedHeadOffice
    })
      .then(() => setError())
      .catch((e) => setError(e))
  }, [
    sectorType,
    sectorId,
    category,
    geography,
    selectedChannel,
    selectedOwnershipType,
    selectedBanner,
    selectedHeadOffice
  ])

  const getContent = () => {
    if (areGeographyLoading) return <WrappedSpinner icon="spinner" />

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    if (!geographies || !geographies.length) return <EmptyState title="Nothing to display" />

    return <DataTable columns={COLS} rows={geographies} fillContainer />
  }

  if (areFiltersLoading)
    return (
      <Card title="Region performance" span={span}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )

  return (
    <Card
      hideOnSmallScreens={true}
      title={`${capitalize(geography)} performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown
          key={0}
          options={[
            {
              label: 'Tier',
              value: 'tier'
            }
          ]}
        />,
        <Dropdown
          key={1}
          options={channelOptions}
          onChange={(e) => setSelectedChannel(e.target.value)}
          value={selectedChannel}
        />,
        <Dropdown
          key={2}
          options={ownershipOptions}
          onChange={(e) => setSelectedOwnershipType(e.target.value)}
          value={selectedOwnershipType}
        />,
        <Dropdown
          key={2}
          options={bannerOptions}
          onChange={(e) => setSelectedBanner(e.target.value)}
          value={selectedBanner}
        />,
        <Dropdown
          key={2}
          options={headofficeOptions}
          onChange={(e) => setSelectedHeadOffice(e.target.value)}
          value={selectedHeadOffice}
        />,
        <GeographyToggle
          key={5}
          includeChannel
          includeBrand
          includeOwnership
          geography={geography}
          setGeography={setGeography}
        />
      ]}
    >
      {getContent()}
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object,
  fetchFilters: func,
  fetchGeography: func
}

export default connect(null, {
  fetchFilters,
  fetchGeography
})(GeographyTable)
