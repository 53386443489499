import React, { useContext } from 'react'
import PriceCheckCard from 'views/plan/PriceCheckCard'

import MediaContext from 'context/MediaContext'

import EdlpCompliance from './EdlpCompliance'
import GeographyCompliance from './GeographyCompliance'
import PriceComplianceHeader from './PriceComplianceHeader'
import StrategyCompliance from './StrategyCompliance'

const AmplifyPriceComplianceLayout = () => {
  const { isWideLayout } = useContext(MediaContext)
  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5">
      <PriceComplianceHeader parentHeader="Amplify" header="Price Compliance" />
      <PriceCheckCard span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 4 }} onlyTop={true} />
      <StrategyCompliance span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 4 }} />
      <EdlpCompliance span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 4 }} />
      {isWideLayout && <GeographyCompliance span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />}
    </div>
  )
}
export default AmplifyPriceComplianceLayout
