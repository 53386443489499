/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import { bool, func, object } from 'prop-types'
import styled from 'styled-components'

import { deleteEmployeeOOT, upsertEmployeeOOT } from 'store/employeeOots/actions'
import * as ootSelector from 'store/employeeOots/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import DatePicker from 'components/DatePicker'
import Dropdown from 'components/Dropdown'
import FieldError from 'components/ErrorMessage'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import Label from 'components/Label'
import Toggle from 'components/Toggle'

import { HOUR_OPTIONS, MINUTE_OPTIONS, OOT_CATEGORIES } from 'utils/constants'

import * as spacing from 'styles/spacing'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Divider = styled.span`
  padding: 0 ${spacing.tiny};
`

const FloatingFieldError = styled(FieldError)`
  padding: ${spacing.small};
  padding-top: 0;
  margin-top: 0;
`

const CreateOOTSheet = ({
  visibleScheduleScreen,
  setVisibleScheduleScreen,
  employeeWorkHours,
  ootDetails,
  upsertEmployeeOOT,
  deleteEmployeeOOT,
  employeeKm,
  canEdit,
  isGlobal
}) => {
  const allOots = useSelector((state) => ootSelector.allOots(state))
  const employeeTimezone = useSelector(
    (state) => state.auth.user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [startWorkHour, startWorkMinute] = (employeeWorkHours?.start || '09:00').split(':')
  const [endWorkHour, endWorkMinute] = (employeeWorkHours?.end || '17:00').split(':')
  const [ootStart, setOotStart] = useState(
    moment().set({ hour: +startWorkHour, minute: +startWorkMinute, seconds: 0, millisecond: 0 })
  )
  const [ootEnd, setOotEnd] = useState(
    moment().set({ hour: +startWorkHour + 1, minute: +startWorkMinute, seconds: 0, millisecond: 0 })
  )

  const [isAllDay, setIsAllDay] = useState(false)
  const [category, setCategory] = useState()
  const [name, setName] = useState('')
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)

  const [confirmDeleteOot, setConfirmDeleteOot] = useState(false)
  const [kms, setKms] = useState()

  const isModifying = Boolean(ootDetails?.id)

  const startDate = moment(ootStart).format('YYYY-MM-DD')
  const startHour = moment(ootStart).format('HH')
  const startMinute = moment(ootStart).format('mm')

  const endDate = moment(ootEnd).format('YYYY-MM-DD')
  const endHour = moment(ootEnd).format('HH')
  const endMinute = moment(ootEnd).format('mm')

  const [globalError, setGlobalError] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)

  useEffect(() => {
    if ([0, 6].includes(ootStart.day())) {
      const nextValidDay = moment(ootStart).add(1, 'day').day(1)
      setOotStart(
        nextValidDay.set({
          hour: moment(ootStart).hour(),
          minute: moment(ootStart).minute(),
          seconds: 0,
          millisecond: 0
        })
      )
      if (!isAllDay) {
        setOotEnd(
          nextValidDay.set({ hour: moment(ootEnd).hour(), minute: moment(ootEnd).minute(), seconds: 0, millisecond: 0 })
        )
      }
    }
  }, [ootStart, isAllDay, ootEnd])

  useEffect(() => {
    if ([0, 6].includes(ootEnd.day()) && isAllDay) {
      const lastValidDay = moment.max([moment(ootEnd).subtract(1, 'day').day(5), moment(ootStart)])
      setOotEnd(
        lastValidDay.set({ hour: moment(ootEnd).hour(), minute: moment(ootEnd).minute(), seconds: 0, millisecond: 0 })
      )
    }
  }, [ootEnd, isAllDay, ootStart])

  useEffect(() => {
    const datesDifferentForSingleDayOOT = !isAllDay && !moment(ootStart).isSame(ootEnd, 'date')
    const startAfterEndForMultiDayOOT = isAllDay && moment(ootStart).isAfter(ootEnd, 'date')
    if (datesDifferentForSingleDayOOT || startAfterEndForMultiDayOOT) {
      setOotEnd(
        moment(ootEnd).set({
          year: moment(ootStart).year(),
          month: moment(ootStart).month(),
          date: moment(ootStart).date()
        })
      )
    }
  }, [isAllDay, ootStart, ootEnd])

  const endHourOptions = useMemo(() => {
    if (isAllDay) return []
    const filteredHourOptions = HOUR_OPTIONS.filter(({ value }) => {
      const isValidMinHour = Number(value) >= moment(ootStart).add(5, 'minutes').hour()
      return isValidMinHour // && isValidMaxHour
    })
    return filteredHourOptions
  }, [isAllDay, ootStart])

  useEffect(() => {
    if (!endHourOptions.length) return
    const firstHourAllowed = endHourOptions[0].value
    if (Number(firstHourAllowed) > moment(ootEnd).hour()) {
      const ootMinHour = moment(ootEnd).set({ hour: +firstHourAllowed })
      setOotEnd(ootMinHour)
    }
  }, [endHourOptions, ootEnd])

  const endMinuteOptions = useMemo(() => {
    if (isAllDay) return []
    if (moment(ootEnd).isAfter(ootStart, 'hour')) return MINUTE_OPTIONS

    const filteredMinuteOptions = MINUTE_OPTIONS.filter(({ value }) => {
      return Number(value) > moment(ootStart).minute()
    })
    return filteredMinuteOptions
  }, [isAllDay, ootStart, ootEnd])

  useEffect(() => {
    if (!endMinuteOptions.length) return
    const firstMinuteAllowed = endMinuteOptions[0].value
    if (Number(firstMinuteAllowed) > moment(ootEnd).minute()) {
      setOotEnd(moment(ootEnd).set({ minute: Number(firstMinuteAllowed) }))
    }
  }, [endMinuteOptions, ootEnd])

  const fieldsDefaultValues = useCallback(() => {
    setOotStart(moment().set({ hour: +startWorkHour, minute: +startWorkMinute, seconds: 0, millisecond: 0 }))
    setOotEnd(moment().set({ hour: +startWorkHour + 1, minute: +startWorkMinute, seconds: 0, millisecond: 0 }))
  }, [startWorkHour, startWorkMinute])

  const clearFields = useCallback(() => {
    if (isEmpty(ootDetails)) {
      fieldsDefaultValues()
      setIsAllDay(false)
      setCategory('')
      setName('')
      setReason('')
      setSubmitAttempted(false)
    }
  }, [fieldsDefaultValues, ootDetails])

  useEffect(() => {
    if (!isEmpty(ootDetails)) {
      setOotStart(moment(ootDetails.startAt))
      setOotEnd(moment(ootDetails.endAt))
      setIsAllDay(ootDetails.isAllDay)
      setCategory(ootDetails.category)
      setName(ootDetails.name)
      setReason(ootDetails.reason)
    } else {
      clearFields()
    }
  }, [ootDetails, clearFields])

  useEffect(() => {
    if (employeeKm) {
      setKms(employeeKm?.kms)
    } else {
      setKms()
    }
  }, [employeeKm])

  const isDuringAnotherOot = (oot, startDate, endDate) => {
    const isEditingOot = ootDetails && ootDetails.id && ootDetails.id === oot.id
    return (
      (moment(startDate).isBetween(oot.startAt, oot.endAt, 'day', '[]') ||
        moment(endDate).isBetween(oot.startAt, oot.endAt, 'day', '[]')) &&
      !isEditingOot
    )
  }

  const isDuringStatHoliday = useMemo(() => {
    if (!startDate || !endDate) return null
    if (
      category === 'OOT_STATUTORY_HOLIDAYS' &&
      allOots.some((oot) => isDuringAnotherOot(oot, startDate, endDate) && oot.category === 'OOT_STATUTORY_HOLIDAYS')
    ) {
      return 'This statutory OOT has already been logged'
    }
    return null
  }, [startDate, endDate, category, allOots])

  const isDuringGlobalOot = useMemo(() => {
    if (!startDate || !endDate) return null
    return allOots.some((oot) => {
      return isDuringAnotherOot(oot, startDate, endDate) && (oot.isGlobal || oot.globalOotId)
    })
  }, [startDate, endDate])

  const hasKms = useMemo(() => {
    if (!category) return false
    return !['OOT_VACATION', 'OOT_STATUTORY_HOLIDAYS'].includes(category)
  }, [category])

  const nameError = useMemo(() => {
    if (!name || !name.trim()) return 'Name is required'
    return null
  }, [name])

  const categoryError = useMemo(() => {
    if (!category) return 'Category is required'
    return null
  }, [category])

  const kmsError = useMemo(() => {
    if (hasKms && !kms && kms !== 0) return 'Kms are required'
    return null
  }, [kms, hasKms])

  const timeError = useMemo(() => {
    if (!moment(ootStart).isValid() || !moment(ootEnd).isValid()) return 'Please enter a valid start and end'
    if (!isAllDay && moment(ootStart).isSameOrAfter(ootEnd, 'minute')) return 'The OOT end must be after the start'
    if (isDuringGlobalOot && moment(ootEnd).diff(ootStart, 'hours') > 4)
      return 'An OOT cannot be more than 4 hours (if scheduled during a global OOT)'
    else if (!isAllDay && moment(ootEnd).diff(ootStart, 'hours') > 8)
      return 'Your OOT is 8 hours long or more, please schedule a full day OOT'
    return null
  }, [ootStart, ootEnd, isAllDay, isDuringGlobalOot])

  const dateError = useMemo(() => {
    if (!moment(ootStart).isValid() || !moment(ootEnd).isValid()) return 'Please enter a valid start and end'
    if (isAllDay && moment(ootEnd).isBefore(ootStart, 'day')) return 'End date must end same or after start'
    return null
  }, [ootStart, ootEnd, isAllDay])

  const hasError = useMemo(() => {
    return timeError || dateError || nameError || categoryError || kmsError || isDuringStatHoliday
  }, [timeError, dateError, nameError, categoryError, kmsError, isDuringStatHoliday])

  const submitOOT = async () => {
    setSubmitAttempted(true)
    if (hasError) return
    try {
      setLoading(true)

      const startAt = isAllDay
        ? moment(ootStart).set({ hour: startWorkHour, minute: startWorkMinute, second: 0, millisecond: 0 }) // if all day, set start to work start time
        : moment(ootStart)
      const endAt = isAllDay
        ? moment(ootEnd).set({ hour: endWorkHour, minute: endWorkMinute, second: 0, millisecond: 0 }) // if all day, set endtime to work end time
        : moment.min([moment(ootEnd), moment(ootStart).add(8, 'hour')]) // if not all day, cap OOT length to 8 hrs

      const ootUpdate = {
        ...(ootDetails || null),
        startAt: moment.tz(startAt, employeeTimezone).toISOString(),
        endAt: moment.tz(endAt, employeeTimezone).toISOString(),
        isAllDay,
        category,
        reason,
        name
      }

      if (hasKms && kms >= 0) {
        ootUpdate.employeeKm = {
          ...employeeKm,
          kms,
          dateDriven: moment(ootStart).toISOString(),
          type: 'OOT'
        }
      }
      await upsertEmployeeOOT(ootUpdate)

      clearFields()
      setVisibleScheduleScreen(null)
    } catch (e) {
      console.log(e)
      setGlobalError(`
        An error occurred when trying to ${isModifying ? 'update' : 'create'} your OOT, please try again!
        Error details : ${JSON.stringify(e.message)}
      `)
    } finally {
      setLoading(false)
    }
  }

  const cancelOot = () => {
    clearFields()
    setVisibleScheduleScreen(null)
  }

  const deleteOOT = async () => {
    await deleteEmployeeOOT(ootDetails)
    clearFields()
    setVisibleScheduleScreen()
    setConfirmDeleteOot(false)
  }

  const setStartDate = (d) => {
    setOotStart(
      moment(ootStart).set({
        year: moment(d).year(),
        month: moment(d).month(),
        date: moment(d).date()
      })
    )
  }
  const setStartHour = (hour) => {
    setOotStart(moment(ootStart).set({ hour }))
  }
  const setStartMinute = (minute) => {
    setOotStart(moment(ootStart).set({ minute }))
  }

  const setEndDate = (d) => {
    setOotEnd(
      moment(ootEnd).set({
        year: moment(d).year(),
        month: moment(d).month(),
        date: moment(d).date()
      })
    )
  }
  const setEndHour = (hour) => {
    setOotEnd(moment(ootEnd).set({ hour }))
  }
  const setEndMinute = (minute) => {
    setOotEnd(moment(ootEnd).set({ minute }))
  }

  return (
    <div>
      <ActionSheet
        title={isModifying ? 'Edit OOT' : 'Create OOT'}
        action={
          <button type="button" onClick={cancelOot}>
            Cancel
          </button>
        }
        visible={visibleScheduleScreen}
      >
        <div>
          <Fieldset>
            {isGlobal && (
              <FieldsetItem>
                <GlobalAlert warning>This Global OOT can only be edited by an admin!</GlobalAlert>
              </FieldsetItem>
            )}
            <FieldsetItem>
              <Input
                text
                label="OOT Name"
                value={name}
                onChange={({ target }) => setName(target.value)}
                maxLength={255}
                disabled={!canEdit}
              />
              {submitAttempted && nameError && <FieldError>{nameError}</FieldError>}
            </FieldsetItem>
            <FieldsetItem>
              <DatePicker
                label="Start date"
                value={startDate}
                onChange={(val) => setStartDate(val)}
                isOutsideRange={() => false}
                isDayBlocked={(d) => [0, 6].includes(moment(d).day())}
                disabled={!canEdit}
              />
            </FieldsetItem>
            {isAllDay ? (
              <FieldsetItem>
                <DatePicker
                  label="End date"
                  value={endDate}
                  onChange={(val) => setEndDate(val)}
                  isOutsideRange={() => false}
                  isDayBlocked={(d) => [0, 6].includes(moment(d).day())}
                  disabled={!canEdit}
                />
                {dateError && <FieldError>{dateError}</FieldError>}
              </FieldsetItem>
            ) : (
              <>
                <FieldsetItem half>
                  <Label>Start time</Label>
                  <Row>
                    <Dropdown
                      noIcon
                      options={HOUR_OPTIONS}
                      value={startHour || ''}
                      onChange={({ target }) => {
                        setStartHour(target.value)
                      }}
                      disabled={!canEdit}
                    />
                    <Divider>:</Divider>
                    <Dropdown
                      noIcon
                      options={MINUTE_OPTIONS}
                      value={startMinute || ''}
                      onChange={({ target }) => {
                        setStartMinute(target.value)
                      }}
                      disabled={!canEdit}
                    />
                  </Row>
                </FieldsetItem>
                <FieldsetItem half>
                  <Label>End time</Label>
                  <Row>
                    <Dropdown
                      noIcon
                      name="End time"
                      options={endHourOptions}
                      value={endHour || ''}
                      onChange={({ target }) => {
                        setEndHour(target.value)
                      }}
                      disabled={!canEdit}
                    />
                    <Divider>:</Divider>
                    <Dropdown
                      noIcon
                      options={endMinuteOptions}
                      value={endMinute || ''}
                      onChange={({ target }) => {
                        setEndMinute(target.value)
                      }}
                      disabled={!canEdit}
                    />
                  </Row>
                </FieldsetItem>
                {submitAttempted && timeError && <FloatingFieldError>{timeError}</FloatingFieldError>}
              </>
            )}
            {!isDuringGlobalOot && (
              <FieldsetItem>
                <Label small>All day</Label>
                <Toggle checked={isAllDay} onClick={() => setIsAllDay(!isAllDay)} disabled={!canEdit} />
              </FieldsetItem>
            )}
            <FieldsetItem>
              <Dropdown
                // secondary
                placeholder="Select a category"
                onChange={({ target }) => setCategory(target.value)}
                value={category}
                options={
                  !isDuringGlobalOot
                    ? OOT_CATEGORIES
                    : OOT_CATEGORIES.filter((c) => c.value !== 'OOT_STATUTORY_HOLIDAYS')
                }
                disabled={!canEdit}
              />
              {submitAttempted && categoryError && <FieldError>{categoryError}</FieldError>}
            </FieldsetItem>
            {hasKms && (
              <FieldsetItem>
                <Input
                  label="Mileage"
                  name="mileage"
                  type="number"
                  placeholder="0"
                  min={0}
                  value={kms}
                  onChange={({ target }) => setKms(target.value)}
                  disabled={!canEdit}
                />
                {submitAttempted && kmsError && <FieldError>{kmsError}</FieldError>}
              </FieldsetItem>
            )}
            <FieldsetItem>
              <Input
                textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                maxLength={255}
                disabled={!canEdit}
              />
            </FieldsetItem>
            {isDuringStatHoliday && (
              <FieldsetItem>
                <GlobalAlert>{isDuringStatHoliday}</GlobalAlert>
              </FieldsetItem>
            )}
            {globalError && <GlobalAlert>{globalError}</GlobalAlert>}
            {canEdit && (
              <FieldsetItem>
                <Button
                  full
                  primary
                  disabled={loading || (submitAttempted && hasError)}
                  onClick={submitOOT}
                  isLoading={loading}
                >
                  {isModifying ? 'Save' : 'Create'}
                </Button>
              </FieldsetItem>
            )}
            {isModifying && canEdit && (
              <FieldsetItem>
                <Button full secondary onClick={() => setConfirmDeleteOot(true)}>
                  Delete OOT
                </Button>
              </FieldsetItem>
            )}
          </Fieldset>
        </div>
      </ActionSheet>
      <ActionSheet title="Cancel call" visible={confirmDeleteOot}>
        <div>
          Are you sure you want to delete your OOT?
          <Fieldset>
            <FieldsetItem>
              <Button full primary onClick={deleteOOT}>
                Yes
              </Button>
            </FieldsetItem>
            <FieldsetItem>
              <Button full onClick={() => setConfirmDeleteOot(false)}>
                No
              </Button>
            </FieldsetItem>
          </Fieldset>
        </div>
      </ActionSheet>
    </div>
  )
}

CreateOOTSheet.propTypes = {
  visibleScheduleScreen: bool.isRequired,
  setVisibleScheduleScreen: func.isRequired,
  upsertEmployeeOOT: func.isRequired,
  deleteEmployeeOOT: func.isRequired,
  ootDetails: object,
  employeeWorkHours: object,
  employeeKm: object,
  canEdit: bool,
  isGlobal: bool
}

export default connect(null, {
  upsertEmployeeOOT,
  deleteEmployeeOOT
})(CreateOOTSheet)
