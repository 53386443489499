import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import moment from 'moment'
import { arrayOf, func, number, shape } from 'prop-types'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchDistroTrended } from 'store/distro/actions'
import { trendedDistroData } from 'store/distro/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

function generateYAxis({ entries }) {
  if (entries.length && entries[0].data) {
    const max = maxBy(entries, 'data')
    const min = minBy(entries, 'data')

    return [Math.floor(min.data / 10) * 10, Math.ceil(max.data / 10) * 10]
  }
  return [0, 10]
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload[0])
    return (
      <div>
        <div className="w-auto -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white">{`${payload[0]?.value}`}</div>
      </div>
    )
}

CustomTooltip.propTypes = {
  active: func,
  payload: arrayOf(shape({ value: number }))
}

const AmplifyDistroTrendCard = ({ span, fetchDistroTrended }) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const [error, setError] = useState()

  const trendedDistro = useSelector((state) => trendedDistroData(state, { activeProductType: currentProductType }))

  const dataKey = createDataKey(DATAKEY_TYPES.DISTRO_TRENDED, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType
  })

  const isTrendLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    let mounted = true
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchDistroTrended(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType
        },
        dataKey
      ).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }

    return () => {
      mounted = false
    }
  }, [currentSector, selectedLevel, currentProductType])

  const graphData = useMemo(() => {
    if (trendedDistro && !isEmpty(trendedDistro)) {
      return Object.values(trendedDistro)
        .sort((a, b) => moment(a.month) - moment(b.month))
        .map((trendValue) => {
          return { name: moment(trendValue.month).format('MMM'), data: trendValue.sum }
        })
    }
    return []
  }, [trendedDistro])

  const renderContent = (rows) => {
    if (isTrendLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    return rows.length ? (
      <LineChart data={rows} margin={{ bottom: 0, left: -25, right: 10, top: 10 }}>
        <CartesianGrid stroke={theme.slate[300]} vertical={false} />
        <XAxis
          style={{
            fontSize: '12px'
          }}
          axisLine={false}
          dataKey="name"
          dy={10}
          stroke={theme.slate[500]}
          tickLine={false}
        />
        <YAxis
          domain={generateYAxis({ entries: rows })}
          interval="preserveStartEnd"
          style={{
            fontSize: '12px'
          }}
          axisLine={false}
          dx={-8}
          stroke={theme.slate[500]}
          tickLine={false}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{
            outline: 'none'
          }}
          animationDuration={300}
          allowEscapeViewBox={{ x: true }}
          cursor={false}
          offset={0}
        />
        <Line
          dataKey="data"
          strokeWidth={3}
          stroke="#69AD55"
          dot={false}
          activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
          isAnimationActive={false}
        />
      </LineChart>
    ) : (
      <EmptyState title="No data yet" />
    )
  }

  return (
    <Card title="Trended view" span={span}>
      <div className="h-[300px] w-full pt-5">
        <ResponsiveContainer height="99%" width="99%">
          {renderContent(graphData)}
        </ResponsiveContainer>
      </div>
    </Card>
  )
}

AmplifyDistroTrendCard.propTypes = {
  span: number,
  fetchDistroTrended: func
}

export default connect(null, { fetchDistroTrended })(AmplifyDistroTrendCard)
