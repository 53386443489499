import React, { Fragment, useEffect, useState } from 'react'
import { array, bool, func, object, string } from 'prop-types'
import { FieldArray } from 'redux-form'

import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import FileInput from 'components/FileInput'
import GlobalAlert from 'components/GlobalAlert'
import IntelDisclaimer from 'components/IntelDisclaimer'
import RenderFieldArray from 'components/RenderFieldArray'

import {
  COMMERCIAL_AND_GROWTH,
  getCategoryManufacturers,
  NONCTMC,
  OTHER,
  TWO_PACK_BANDED_DUOS,
  TWO_PACK_PROGRAM,
  VAPE_SPECIALTY_STORE
} from 'utils/constants'

import Card from './Card'
import {
  ApplyToMultiErps,
  BackMargin,
  Brand,
  Comments,
  // PlainPack,
  Covid,
  CustomBrand,
  Details,
  EndDate,
  ImpactRating,
  ImpactSummary,
  IsAppliedToRsp,
  Manufacturer,
  MfctRebate,
  MultiErps,
  OffInvoice,
  ProductCategory,
  Regulations,
  RspDiscount20,
  RspDiscount25,
  Source,
  StartDate,
  StoreName,
  Type
} from './IntelInputs'

const IntelForm = ({
  change,
  startDate,
  intelType,
  existingImages = [],
  brandOptions,
  manufacturer,
  feedback,
  error,
  scopeFields = {},
  productCategory,
  isCreate
}) => {
  const [isMultiErp, setIsMultiErp] = useState(false)

  useEffect(() => {
    if (isMultiErp) change('scopeErps', [])
  }, [isMultiErp, change])

  const is2packIntel = [TWO_PACK_PROGRAM, TWO_PACK_BANDED_DUOS].includes(intelType)
  const isCommercialGrowth = intelType === COMMERCIAL_AND_GROWTH
  const isVapeSpecialtyStore = intelType === VAPE_SPECIALTY_STORE
  const requireBrandInput = [NONCTMC, OTHER].includes(manufacturer)
  const scopeFieldNames = Object.keys(scopeFields) || []
  const categoryManufacturers = getCategoryManufacturers(productCategory)

  return (
    <Container padded>
      <Card>
        <Fieldset>
          {error && (
            <FieldsetItem>
              <GlobalAlert>{error}</GlobalAlert>
            </FieldsetItem>
          )}
          <ProductCategory change={change} scopeFieldNames={scopeFieldNames} />
          <Manufacturer change={change} scopeFieldNames={scopeFieldNames} manufacturers={categoryManufacturers} />
          {requireBrandInput ? (
            <CustomBrand change={change} scopeFieldNames={scopeFieldNames} />
          ) : (
            <Brand change={change} scopeFieldNames={scopeFieldNames} brandOptions={brandOptions} />
          )}
          <Type change={change} scopeFieldNames={scopeFieldNames} />
          <Covid change={change} scopeFields={scopeFields} />
          <Regulations change={change} scopeFields={scopeFields} />
          {is2packIntel && (
            <Fragment>
              <MfctRebate change={change} scopeFieldNames={scopeFieldNames} />
              <RspDiscount20 change={change} scopeFieldNames={scopeFieldNames} />
              <RspDiscount25 change={change} scopeFieldNames={scopeFieldNames} />
            </Fragment>
          )}
          {isCommercialGrowth && (
            <Fragment>
              <OffInvoice change={change} scopeFieldNames={scopeFieldNames} />
              <IsAppliedToRsp change={change} scopeFieldNames={scopeFieldNames} />
              <BackMargin change={change} scopeFieldNames={scopeFieldNames} />
            </Fragment>
          )}
          {isVapeSpecialtyStore && (
            <Fragment>
              <StoreName change={change} scopeFieldNames={scopeFieldNames} />
              <Comments change={change} scopeFieldNames={scopeFieldNames} />
            </Fragment>
          )}
          <Source change={change} scopeFieldNames={scopeFieldNames} />
          <Details change={change} scopeFieldNames={scopeFieldNames} />
          <StartDate scopeFieldNames={scopeFieldNames} />
          <EndDate startDate={startDate} scopeFieldNames={scopeFieldNames} />
          <ImpactRating change={change} scopeFieldNames={scopeFieldNames} />
          <ImpactSummary change={change} scopeFieldNames={scopeFieldNames} />
          <FieldsetItem>
            <FileInput existingImages={existingImages} change={change} />
          </FieldsetItem>
          {!scopeFields?.scopeId && isCreate && (
            <ApplyToMultiErps isMultiErp={isMultiErp} setIsMultiErp={setIsMultiErp} />
          )}
          {isMultiErp && <MultiErps change={change} />}
          {feedback && feedback.length > 0 && (
            <FieldArray name="feedback" component={RenderFieldArray} rerenderOnEveryChange />
          )}
          <IntelDisclaimer />
        </Fieldset>
      </Card>
    </Container>
  )
}

IntelForm.propTypes = {
  change: func,
  startDate: string,
  intelType: string,
  manufacturer: string,
  productCategory: string,
  existingImages: array,
  brandOptions: array,
  feedback: array,
  error: bool,
  scopeFields: object,
  isCreate: bool
}
export default IntelForm
