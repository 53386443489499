import React, { useContext } from 'react'
import { connect, useSelector } from 'react-redux'
import { string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Spinner from 'components/Spinner'

import { white } from 'styles/colors'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  bottom: 10%;
  position: fixed;
  background-color: #162a5f;
  border-radius: 30px;
  padding: ${spacing.small} ${spacing.small} ${spacing.small} ${spacing.medium};
  color: ${white};
  display: flex;
  align-items: center;
  gap: ${spacing.small};
`

function LoadingCard({ dataKey }) {
  const { formatMessage } = useContext(LangContext)

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  if (!isLoading) return null

  return (
    <Container>
      <span>{formatMessage({ messageId: 'currentlyUpdating' })}</span>
      <Spinner icon="spinner" />
    </Container>
  )
}

LoadingCard.propTypes = {
  dataKey: string.isRequired
}

export default connect()(LoadingCard)
