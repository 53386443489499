import React from 'react'
import { connect } from 'react-redux'
import needsAuth from 'layouts/authWrappers/needsAuth'
import Main from 'layouts/Main'
import { bool, object } from 'prop-types'

import { DistrictManagerProvider } from 'context/DistrictManagerContext'
import { GoogleMapsProvider } from 'context/GoogleMapsContext'
import { ProtectedProvider } from 'context/ProtectedContext'
import { ScheduleProvider } from 'context/ScheduleContext'

// import AppContent from 'components/AppContent'
import EmptyState from 'components/EmptyState'

const ProtectedLayout = ({ employee, isUserLoading }) => {
  if (!employee) {
    return isUserLoading ? (
      <EmptyState isLoading={isUserLoading} title="Fetching..." subtitle="Just a moment while we fetch your data" />
    ) : null
  }

  return (
    <ProtectedProvider>
      <GoogleMapsProvider>
        <ScheduleProvider employee={employee}>
          <DistrictManagerProvider user={employee}>
            {/* <AppContent> */}
            <Main employee={employee} />
            {/* </AppContent> */}
          </DistrictManagerProvider>
        </ScheduleProvider>
      </GoogleMapsProvider>
    </ProtectedProvider>
  )
}

ProtectedLayout.propTypes = {
  employee: object,
  isUserLoading: bool
}

const ConnectedProtectedLayout = connect((state, props) => ({
  employee: state.auth.user,
  isUserLoading: state.auth.loading
}))(ProtectedLayout)
export default needsAuth(ConnectedProtectedLayout)
