import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { bool } from 'prop-types'
import BatLogo from 'static/logos/bat-logo.svg'
import InlineSVG from 'svg-inline-react'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import IconButton from 'components/button/IconButton'
import Icon from 'components/Icon'
import Profile from 'components/Sidebar/Profile'

import MobileLink from './MobileLink'
import MobileLinkMenu from './MobileLinkMenu'

const overlayVariants = {
  initial: {
    opacity: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  },
  enter: {
    opacity: 1,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  }
}

const menuVariants = {
  initial: {
    opacity: 0,
    x: 30,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  }
}

const MenuButton = tw.button`relative z-10 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-brand text-white shadow-lg`

const MenuButtonWide = tw.button`relative z-10 flex h-10 min-w-0 grow items-center rounded-full bg-brand text-left text-white shadow-lg ring-1 ring-brand/20`

const MobileNav = ({ isNavOnly }) => {
  const { formatMessage } = useContext(LangContext)
  const [open, setOpen] = useState(false)

  const { user } = useSelector((state) => state.auth)
  const isCustomerService = user && user.groupCode === 'customerService'
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      {/* Trigger */}
      <Dialog.Trigger asChild>
        {isNavOnly ? (
          <MenuButtonWide>
            <div className=" -mt-0.5 ml-2 w-full min-w-0 p-2">
              <InlineSVG src={BatLogo} className="h-[18px]" raw />
            </div>
            <div className="flex h-10 min-w-[40px] shrink-0 items-center pl-2 pr-3">
              <Icon icon="menu" />
            </div>
          </MenuButtonWide>
        ) : (
          <MenuButton>
            <Icon icon="menu" />
          </MenuButton>
        )}
      </Dialog.Trigger>

      {/* Portal */}
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay forceMount asChild>
              <motion.div
                className="fixed left-0 top-0 z-50 h-full w-full bg-black/60 md:hidden"
                key="overlay"
                variants={overlayVariants}
                initial="initial"
                animate="enter"
                exit="initial"
              />
            </Dialog.Overlay>
            <Dialog.Content forceMount asChild>
              <motion.div
                className="fixed bottom-0 right-0 top-0 z-50 flex w-11/12 max-w-sm flex-col overflow-y-auto bg-brand p-6 shadow-xl md:hidden"
                key="content"
                variants={menuVariants}
                initial="initial"
                animate="enter"
                exit="initial"
              >
                <div>
                  <InlineSVG src={BatLogo} className="h-[26px] w-[76px] shrink-0" raw />
                  <div className="absolute right-4 top-4 flex">
                    <Dialog.Close asChild>
                      <IconButton icon="close" dark />
                    </Dialog.Close>
                  </div>
                </div>
                <div className="-mx-12 mt-6 flex h-full flex-col justify-between overflow-y-scroll px-12">
                  <div className="h-full min-h-0 space-y-4">
                    {!isCustomerService && (
                      <>
                        <div className="space-y-3">
                          <span className="ml-1 text-xs font-medium uppercase text-white/60">Pace</span>
                          <div className="space-y-1">
                            <MobileLink
                              name={formatMessage({ messageId: 'pace.plan' })}
                              url="/pace/plan"
                              icon="dashboard"
                              onClick={() => setOpen(false)}
                            />
                            <MobileLinkMenu
                              name={formatMessage({ messageId: 'pace.amplify' })}
                              icon="graph-window"
                              links={[
                                {
                                  name: 'Sell-In',
                                  url: '/pace/amplify/sell-in',
                                  onClick: () => setOpen(false)
                                },
                                {
                                  name: 'Sell-Out',
                                  url: '/pace/amplify/sell-out',
                                  onClick: () => setOpen(false)
                                },
                                {
                                  name: 'OOS & Inv',
                                  url: '/pace/amplify/inventory',
                                  onClick: () => setOpen(false)
                                },
                                {
                                  name: 'Distro',
                                  url: '/pace/amplify/distro',
                                  onClick: () => setOpen(false)
                                },
                                {
                                  name: 'Price capture',
                                  url: '/pace/amplify/price-capture',
                                  onClick: () => setOpen(false)
                                },
                                {
                                  name: 'Price compliance',
                                  url: '/pace/amplify/price-compliance',
                                  onClick: () => setOpen(false)
                                },
                                {
                                  name: 'Extra Hub Activity',
                                  url: '/pace/amplify/extra-hub',
                                  onClick: () => setOpen(false)
                                }
                              ]}
                            />
                            <MobileLink
                              name={formatMessage({ messageId: 'pace.close' })}
                              url="/pace/close"
                              icon="checklist"
                              onClick={() => setOpen(false)}
                            />
                            <MobileLink
                              name={formatMessage({ messageId: 'pace.evaluate' })}
                              url="/pace/evaluate"
                              icon="clipboard"
                              onClick={() => setOpen(false)}
                            />
                          </div>
                        </div>
                        <hr className="w-full bg-white opacity-10" />
                      </>
                    )}
                    <MobileLink
                      name={formatMessage({ messageId: 'actions.title' })}
                      url="/actions"
                      icon="actions"
                      onClick={() => setOpen(false)}
                    />
                    <hr className="w-full bg-white opacity-10" />
                    <div className="space-y-1">
                      <MobileLink
                        name={formatMessage({ messageId: 'alerts.title' })}
                        url="/alerts"
                        icon="notification"
                        onClick={() => setOpen(false)}
                      />
                      <MobileLink name="Schedule" url="/schedule" icon="calendar" onClick={() => setOpen(false)} />
                      <MobileLink name="Payouts" url="/payouts" icon="stacked-coins" onClick={() => setOpen(false)} />
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <hr className="w-full bg-white opacity-10" />
                  <Profile />
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

MobileNav.propTypes = {
  isNavOnly: bool
}

export default MobileNav
