import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { func } from 'prop-types'

import { cancelSAQ } from 'store/orders/actions'
import { orderFromUrl } from 'store/orders/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import GlobalAlert from 'components/GlobalAlert'
import SAQDetailRow from 'components/SAQDetailRow'
import SAQItem, { SAQItemsWrap } from 'components/SAQItem'
import Status from 'components/Status'

const mapActionCreators = {
  cancelSAQ
}

const SAQDetails = ({ cancelSAQ }) => {
  const navigate = useNavigate()
  const { orderId } = useParams()

  const order = useSelector((state) => orderFromUrl(state, { orderId }))
  const language = useSelector((state) => state.auth.user.preferredLanguage)

  const [isCancellationConfirmationVisible, setCancellationConfirmationVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const showCancellationConfirmation = () => {
    setCancellationConfirmationVisible(true)
  }

  const hideCancellationConfirmation = () => {
    setCancellationConfirmationVisible(false)
  }

  const cancelOrder = (order) => {
    setLoading(true)
    cancelSAQ(order)
      .then(() => {
        setLoading(false)
        navigate('..')
      })
      .catch(({ response }) => {
        setLoading(false)
        const errorMessage =
          !response || response.status === 500 || !response.data.message
            ? 'An error occured while cancelling your order.'
            : response.data.message
        setError(errorMessage)
      })
  }

  if (!order) {
    return <Navigate to={'..'} />
  }

  const isAwaitingApproval = ['needsApproval', 'awaitingManagerApproval', 'awaitingRetailerApproval'].some(
    (status) => status === order.status
  )

  const isPo98Order = order.type === 'po98'

  const cancellationAllowed = isPo98Order
    ? order.status === 'created'
    : ['new', 'pending', 'processing'].some((status) => status === order.status) &&
      (!order.type || order.type === 'saq')

  const orderType = isPo98Order ? 'PO98 order' : 'SAQ/order'
  const orderedQuantity = (qty, po98RevisedQty) => {
    if (isPo98Order) {
      if (!['created', 'processing'].includes(order.status) && po98RevisedQty) {
        return po98RevisedQty
      }
    }
    return qty
  }

  return (
    <>
      <ActionSheet
        title="Details"
        action={
          <ButtonGroup>
            {!error && order.status === 'created' ? (
              <button onClick={() => navigate(`/actions/orders/${order.id}/edit`)}>Edit</button>
            ) : null}
            <button onClick={() => navigate('..')}>Done</button>
          </ButtonGroup>
        }
        visible
      >
        <div>
          <SAQDetailRow label="Order #" content={<p>{order.id}</p>} />
          <SAQDetailRow label="Type" content={<p>{(order.type || 'saq').toUpperCase()}</p>} />
          {order.employee && (
            <SAQDetailRow
              label="Placed by"
              content={
                <p>
                  {order.employee.firstName} {order.employee.lastName}
                </p>
              }
            />
          )}
          <SAQDetailRow label="Status" content={<Status status={order.status} />} />
          <SAQDetailRow label="Created at" content={<p>{new Date(order.createdAt).toDateString()}</p>} />
          {isPo98Order && order.updatedAt ? (
            <SAQDetailRow label="Updated at" content={<p>{new Date(order.updatedAt).toDateString()}</p>} />
          ) : null}
          {isPo98Order && order.status !== 'created' && (
            <SAQDetailRow label="Status updated at" content={<p>{new Date(order.updatedAt).toDateString()}</p>} />
          )}
          {order.confirmationDate && (
            <SAQDetailRow label="Confirmed at" content={<p>{new Date(order.confirmationDate).toDateString()}</p>} />
          )}
          {isPo98Order && order.po98PoNumber && (
            <SAQDetailRow label="PO Number" content={<p>{order.po98PoNumber}</p>} />
          )}
          {order.purchaseOrder && <SAQDetailRow label="Purchase order:" content={<p>{order.purchaseOrder}</p>} />}
        </div>
        <SAQItemsWrap>
          {order.entries.map(({ qty, po98RevisedQty, product }, i) => (
            <SAQItem
              key={i}
              item={product[`${language}Name`] || `${product[language + 'Brand']} - #${product.id}`}
              quantity={orderedQuantity(qty, po98RevisedQty)}
            />
          ))}
        </SAQItemsWrap>
        {error && (
          <GlobalAlert>
            <p>{error}</p>
          </GlobalAlert>
        )}
        {!error && (cancellationAllowed || isAwaitingApproval) && (
          <div>
            {isCancellationConfirmationVisible ? (
              <ButtonGroup>
                <Button primary onClick={() => cancelOrder(order)} isLoading={loading} full>
                  {`Yes, cancel ${orderType}`}
                </Button>
                <Button secondary onClick={hideCancellationConfirmation} disabled={loading} full>
                  {`No, keep ${orderType}`}
                </Button>
              </ButtonGroup>
            ) : (
              <Button secondary onClick={showCancellationConfirmation} full>
                {`Cancel this ${orderType}`}
              </Button>
            )}
          </div>
        )}
      </ActionSheet>
    </>
  )
}

SAQDetails.propTypes = {
  cancelSAQ: func.isRequired
}

export default connect(null, mapActionCreators)(SAQDetails)
