import React from 'react'
import { bool, func, number, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import VariationIndicator from 'components/VariationIndicator'

import { formatNumber } from 'utils/formatters'

const VariationWrapper = styled.div(({ peer, stacked, withTimeframe }) => [
  tw`h-full flex`,
  stacked ? tw`flex-row-reverse justify-end` : tw`items-center`,
  peer ? tw`gap-1` : tw`gap-1.5`,
  withTimeframe ? tw`text-sm` : tw`text-sm`
])

const Text = styled.span(({ peer }) => [peer ? tw`text-slate-500` : tw`text-slate-900`])

const DataVariation = ({ variation, peer, stacked, isPercent, withTimeframe, timeframe, formatter = formatNumber }) => {
  const timeFrameLabel = timeframe === '1' ? 'vs PW' : 'vs P4'
  return (
    <VariationWrapper peer={peer} stacked={stacked} withTimeframe={withTimeframe}>
      {withTimeframe && <Text peer={peer}>{timeFrameLabel}</Text>}
      <VariationIndicator variation={variation} />
      <Text peer={peer}>
        {variation > 0 ? '+' : '-'}
        {formatter(Math.abs(variation))}
        {isPercent && '%'}
      </Text>
    </VariationWrapper>
  )
}

DataVariation.propTypes = {
  variation: number,
  peer: bool,
  stacked: bool,
  isPercent: bool,
  withTimeframe: bool,
  formatter: func,
  timeframe: string
}

export default DataVariation
