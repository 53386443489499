import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import { customerAssortment } from 'store/customers/selectors'
import { createSelector } from 'store/selectors'

import { ALLOWED_PROGRAM_BRANDS_BY_LANGUAGE, DOUBLE_NESTED_BRANDS } from 'utils/constants'

const preferredLanguage = (state) => state.auth.user.preferredLanguage.substring(0, 2)

export const productsByBrand = createSelector(customerAssortment, (assortment) => {
  if (isEmpty(assortment)) return {}
  const orderedAssortment = orderBy(assortment, [
    ({ brand }) => (brand || '').toLowerCase(),
    ({ name }) => (name || '').toLowerCase()
  ])
  const brandGroupedProducts = groupBy(orderedAssortment, 'brand')
  return Object.entries(brandGroupedProducts).reduce(
    (acc, [brand, products]) => ({
      ...acc,
      [brand.replace("'", '')]: DOUBLE_NESTED_BRANDS.includes(brand)
        ? groupBy(
            orderBy(products, [({ variant }) => (variant || '').toLowerCase()]),
            ({ brand, variant }) => variant || brand
          )
        : products
    }),
    {}
  )
})

// export const zfg100000products = createSelector(customerAssortment, assortment => {
//   if (isEmpty(assortment)) return []
//   return assortment.filter(item => item.materialGroupCode === 'ZFG100000').map(({ id }) => `p${id}`)
// })

export const brandFocusOptions = createSelector(preferredLanguage, (language) => {
  if (!language) return []
  return ALLOWED_PROGRAM_BRANDS_BY_LANGUAGE[language].reduce(
    (acc, brand) => [...acc, { label: brand, value: brand }],
    []
  )
})

const programForm = (state) => state.form.createProgram || state.form.editProgram

const brandFocus = createSelector(programForm, (form) => {
  if (!form || !form.values) return null
  return form.values.brandFocus
})

export const variantFocusOptions = createSelector(productsByBrand, brandFocus, (productsByBrand, brandFocus) => {
  const brandVariants = productsByBrand[brandFocus]
  if (!brandFocus || !brandVariants) return null

  const productsByVariant = groupBy(flatten(Object.values(brandVariants)), 'variant')
  return Object.keys(productsByVariant).reduce((acc, variant) => [...acc, { label: variant, value: variant }], [])
})
