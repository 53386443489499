import React, { useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { capitalize, debounce, isEmpty } from 'lodash'
import { arrayOf, bool, func, number, object, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

// import { SectorBrowserContext } from 'context/SectorBrowserContext'
// import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { fetchAllSectors } from 'store/sector/actions'

import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'

import { GroupedSearch } from './GroupedSearch'

const SEARCH_LIMIT = 50

const ItemWrapper = styled.button(({ selected }) => [
  tw`flex w-full items-center justify-between rounded-md px-4 py-3 transition text-left`,
  selected
    ? tw`bg-brand-50 ring-1 ring-brand-700/60 text-brand-900`
    : tw`bg-white ring-1 ring-slate-900/10 hover:ring-slate-900/20 text-slate-900`
])

const Name = tw.span`text-sm font-medium text-slate-900`

const Additional = tw.span`text-2xs text-slate-900/60`

const ItemContainer = styled.div(({ amount }) => [
  tw`relative h-[410px] space-y-3 p-px`,
  amount > 5 && tw`overflow-auto overflow-y-scroll`
])

const Item = ({ selected, result, handleItemClick }) => {
  return (
    <ItemWrapper selected={selected} disabled={selected} className="group" onClick={handleItemClick}>
      {result && (
        <>
          <div>
            <div className="space-x-2">
              <Name>{result.name}</Name>
              {/* <ID>{result.id}</ID> */}
            </div>
            <Additional>
              {capitalize(result.type)} {result.additional}
            </Additional>
          </div>

          {!selected && (
            <div className="flex text-slate-400 transition group-hover:text-slate-500">
              <Icon icon="right-chevron" />
            </div>
          )}

          {selected && (
            <div className="mr-2 flex items-center gap-0.5">
              <Icon icon="checkmark-small" compact />
              <span className="text-xs">Selected</span>
            </div>
          )}
        </>
      )}
    </ItemWrapper>
  )
}

Item.propTypes = {
  selected: bool,
  name: string,
  // id: string,
  additional: string,
  handleItemClick: func,
  result: object
}

const EndMessageWrapper = styled.div(({ amount }) => [
  tw`flex w-full items-center justify-center text-sm text-slate-500`,
  amount > 5 ? tw`h-16` : tw`h-12`
])

const EndMessage = ({ amount }) => {
  function Message(amount) {
    if (amount > SEARCH_LIMIT)
      return (
        <div className="space-y-0.5 text-center">
          <p className="block">{`Showing 20 out of ${amount} items`}</p>
          <span className="block text-2xs text-slate-400">Refine your search to view more specific results</span>
        </div>
      )
    else return 'End of results'
  }
  return <EndMessageWrapper amount={amount}>{Message(amount)}</EndMessageWrapper>
}

EndMessage.propTypes = {
  amount: number
}

const Content = styled.div(({ isMobile }) => [
  tw`flex flex-col space-y-6`,
  isMobile && tw`h-full min-h-0 overflow-y-clip p-px`
])

const Results = styled.div(({ isMobile }) => [tw`space-y-4`, isMobile && tw`h-full min-h-0 pb-24`])

const SectorBrowser = ({
  onHandleClose,
  fetchAllSectors,
  sectorType,
  setSectorType,
  queryString,
  setQueryString,
  searchResults,
  setSearchResults,
  clearFields,
  isMobile
}) => {
  // const { formatMessage } = useContext(LangContext)
  const { fetchSector, selectedLevel, currentSector } = useContext(SectorContext)
  const mounted = useRef()

  // const { clearFields, sectorType, setSectorType, queryString, setQueryString } = useContext(SectorBrowserContext)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const handleItemClick = async (result) => {
    try {
      await fetchSector(result)
      if (mounted.current) {
        clearFields()
        onHandleClose()
      }
    } catch (err) {
      console.log(`Unable to fetch ${result.type} with ID ${result.id}`)
    }
  }

  const fetchSectors = async (sectorType, queryString) => {
    try {
      const sectors = await fetchAllSectors({ limit: SEARCH_LIMIT, sectorType, queryString })
      if (mounted.current) setSearchResults(sectors)
    } catch (err) {
      if (mounted.current) setSearchResults([])
    }
  }

  const debouncedSectorFetch = debounce(fetchSectors, 300, { trailing: true, leading: false })
  useEffect(() => {
    debouncedSectorFetch(sectorType, queryString)
  }, [sectorType, queryString])

  return (
    <Content isMobile={isMobile}>
      {!isEmpty(currentSector) && (
        <div>
          <Item selected result={currentSector[selectedLevel]} />
        </div>
      )}
      <Results isMobile={isMobile}>
        {/* <span className="text-sm font-medium">{formatMessage({ messageId: 'sector.change' })}</span> */}
        <div>
          <GroupedSearch setSectorType={setSectorType} setQueryString={setQueryString} />
        </div>
        {searchResults && Boolean(searchResults.length) && (
          <ItemContainer amount={searchResults.length}>
            {searchResults.slice(0, SEARCH_LIMIT).map((result, i) => (
              <Item key={i} result={result} handleItemClick={() => handleItemClick(result)} />
            ))}
            {searchResults.length > 5 && <EndMessage amount={searchResults.length} />}
          </ItemContainer>
        )}
        {(!searchResults || !searchResults.length) && (
          <EmptyState title="No sector available to you" subtitle="You might not have any territories assigned" />
        )}
      </Results>
    </Content>
  )
}

SectorBrowser.propTypes = {
  fetchAllSectors: func.isRequired,
  onHandleClose: func.isRequired,
  sectorType: string,
  setSectorType: func.isRequired,
  queryString: string,
  setQueryString: func.isRequired,
  clearFields: func.isRequired,
  searchResults: arrayOf(object),
  setSearchResults: func.isRequired,
  isMobile: bool
}

export default connect(null, { fetchAllSectors })(SectorBrowser)
