import uniqBy from 'lodash/uniqBy'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { addEntities, mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import { setDataFetch } from 'store/dataFetches/actions'
import outbox from 'store/outbox'
import { customerFetchSchema } from 'store/schema'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS, validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const addOrderToCustomer = createAction('Add order to customer')
export const removeCustomerOrders = createAction('Remove overwritten orders from customer')

export const addProgramToCustomer = createAction('Add program to customer')
export const removeProgramFromCustomer = createAction('Delete customer program')

export const addIntelToCustomer = createAction('Add intel to customer')
export const removeIntelFromCustomer = createAction('Delete customer intel')
export const updateCustomerSurvey = createAction('Update customer survey')
export const updateAutofilledCustomerSurveys = createAction('Update autofilled surveys')
export const updateCustomerProject = createAction('Update customer project')
export const addCallToCustomer = createAction('Add Call To Customer')
export const addCallsToCustomer = createAction('Add Calls To Customer')
export const removeCallFromCustomer = createAction('Remove deleted call from customer')
export const addPlanningSellinValuesToCustomer = createAction('Add Planning Sell-in Values to customer')
export const addPlanningSelloutValuesToCustomer = createAction('Add Planning Sell-out Values to customer')
export const addSellinGeographyToCustomer = createAction('Add Sell-in Geography to customer')
export const addSellinOrderCompletionToCustomer = createAction('Add Sell-in Order Completion to customer')
export const addSellinRunRateToCustomer = createAction('Add Sell-in Run Rate to customer')
export const addPlanningHealthCheckValuesToCustomer = createAction('Add Planning Health Check Values to customer')
export const addPlanningPriceCheckValuesToCustomer = createAction('Add Planning Price Check Values to customer')
export const addPlanningEngagementValuesToCustomer = createAction('Add Planning Engagement Values to customer')
export const addDistroTrendedDataToCustomer = createAction('Add Distro Trended Data to customer')
export const addDistroBrandDataToCustomer = createAction('Add Distro Brand Data to customer')
export const addDistroMaterialDataToCustomer = createAction('Add Distro Material Data to customer')
export const addDistroGeographyDataToCustomer = createAction('Add Distro Geography Data to customer')
export const addAmplifySelloutValuesToCustomer = createAction('Add Amplify Sell-out Values to customer')
export const addAmplifySelloutGeographyValuesToCustomer = createAction(
  'Add Amplify Sell-out Geography Values to customer'
)

export const addAmplifyInventoryOosTrendingValuesToCustomer = createAction(
  'Add Amplify Inventory OOS Trending Values to customer'
)
export const addAmplifyInventoryOosBrandsValuesToCustomer = createAction(
  'Add Amplify Inventory OOS Brand Values to customer'
)
export const addAmplifyInventoryOosMaterialValuesToCustomer = createAction(
  'Add Amplify Inventory OOS Material Values to customer'
)
export const addAmplifyInventoryOosGeographyValuesToCustomer = createAction(
  'Add Amplify Inventory OOS Geography Values to customer'
)
export const addAmplifyInventoryOosFiltersValuesToCustomer = createAction(
  'Add Amplify Inventory OOS Filters Values to customer'
)
export const addAmplifyExtraHubTrendingValuesToCustomer = createAction(
  'Add Amplify Extra Hub Trending Values to Customer'
)
export const addAmplifyExtraHubEngagementValuesToCustomer = createAction(
  'Add Amplify Extra Hub Engagement Values to Customer'
)
export const addAmplifyExtraHubGeographyValuesToCustomer = createAction(
  'Add Amplify Extra Hub Geography Values to Customer'
)
export const addAmplifyExtraHubFiltersValuesToCustomer = createAction(
  'Add Amplify Extra Hub Filters Values to Customer'
)
export const addAmplifyExtraHubActivityValuesToCustomer = createAction(
  'Add Amplify Extra Hub Activity Values to Customer'
)
export const addAmplifyPriceCaptureComplianceFootprintValuesToCustomer = createAction(
  'Add Amplify Price Capture Compliance Footprint Values to Customer'
)
export const addAmplifyPriceCaptureComplianceBrandCompletionValuesToCustomer = createAction(
  'Add Amplify Price Capture Compliance Brand Completion Values to Customer'
)
export const addAmplifyPriceCaptureComplianceFiltersValuesToCustomer = createAction(
  'Add Amplify Price Capture Compliance Filters Values to Customer'
)
export const addAmplifyPriceCaptureComplianceGeographyValuesToCustomer = createAction(
  'Add Amplify Price Capture Compliance Geography Values to Customer'
)
export const addAmplifyPriceComplianceStrategyComplianceValuesToCustomer = createAction(
  'Add Amplify Price Compliance Strategy Compliance Values to Customer'
)
export const addAmplifyPriceComplianceEdlpComplianceValuesToCustomer = createAction(
  'Add Amplify Price Compliance Edlp Compliance Values to Customer'
)
export const addAmplifyPriceComplianceGeographyComplianceValuesToCustomer = createAction(
  'Add Amplify Price Compliance Geography Compliance Values to Customer'
)
export const addAmplifyPriceComplianceGeographyFiltersValuesToCustomer = createAction(
  'Add Amplify Price Compliance Geography Filters Values to Customer'
)
const queueOfflineCustomer = async (customer) => {
  const customerOutbox = (await outbox.getItem('customer')) || {}
  await outbox.setItem('customer', { ...customerOutbox, [customer.id]: customer })
  return registerSync('submit-pending-customer')
}

const createQueuedCustomer = async (customer) => {
  const tempCustomer = { [customer.id]: { ...customer } }
  await queueOfflineCustomer(customer)
  return tempCustomer
}

export const updateCustomerHours = createAction(
  'Update customer opening hours',
  (customerId, hours) => async (dispatch) => {
    let customers
    try {
      if (window.navigator.onLine) {
        const { data } = await api.updateCustomerHours({ customerId, hours })
        const { entities } = normalize(data, customerFetchSchema)
        customers = entities
      } else {
        customers = await createQueuedCustomer({ id: customerId, openingHours: hours })
      }
      // console.log(customers)
      dispatch(mergeEntities(customers))
    } catch (err) {
      console.dir(err)
    }
  }
)

export const getCustomer = createAction(
  'Fetch customer by id',
  (customerId, dataKey) => async (dispatch, getState) =>
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!customerId) throw ERRORS.missingCustomerId
      const { data } = await api.getCustomer(customerId)
      const { entities } = normalize(data, customerFetchSchema)

      dispatch(addEntities(entities))
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    })
)

export const updateCustomerScope = createAction('Update customer scope request', (details) => async (dispatch) => {
  const { data } = await api.submitCustomerScopeUpdate(details)
  const { entities } = normalize(data, customerFetchSchema)
  dispatch(mergeEntities(entities))
})

export const addPricingCaptureToCustomer = createAction('Add pricing capture to customer')

export const addCustomerMessage = createAction('Add message to customer')

export const submitCustomerProjectResponse = createAction(
  'Submit project customerProjectResonse',
  (customerProjectResonse) => async (dispatch) => {
    dispatch(updateCustomerProject(customerProjectResonse))
    try {
      if (window.navigator.onLine) {
        const {
          data: { projectLink }
        } = await api.submitProjectResponse(customerProjectResonse)
        dispatch(updateCustomerProject(projectLink))
      } else {
        console.log('OFFLINE: submit failed')
        // await queueOfflineCustomerProject(customerProjectResonse)
      }
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
      console.log(err)
      // await queueOfflineCustomerProject(customerProjectResonse)
      dispatch(updateCustomerProject(customerProjectResonse))
    }
  }
)

export const fetchCustomers = async ({ match, customerId }) => {
  const { data } = await api.getCustomerSuggestions(match, customerId)
  return { data: uniqBy(data, 'id') }
}

export const fetchCustomerGoFunds = createAction('Fetch customer go funds', (customerId) => async (dispatch) => {
  if (!window.navigator.onLine || !customerId) return
  const { data } = await api.fetchCustomerGoFunds(customerId)
  const { entities } = normalize(data.customerGoFunds, customerFetchSchema)
  dispatch(mergeEntities(entities))
})

export const fetchCustomerSellInPrograms = createAction(
  'Fetch customer Sell-In Programs',
  (customerId, key) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches
    if (dataIsStillValid(dataFetchesState, key)) return
    try {
      if (!window.navigator.onLine) throw ERRORS.offline
      dispatch(setDataFetch({ dataKey: key, status: DATA_UPDATE_STATUS.LOADING }))
      if (window.navigator.onLine) {
        const { data } = await api.getCustomerSellInPrograms(customerId)
        const {
          entities: { sellInPrograms }
        } = normalize(data, customerFetchSchema)
        const customers = { [customerId]: { sellInPrograms } }
        dispatch(mergeEntities({ customers }))
        dispatch(setDataFetch({ dataKey: key, status: DATA_UPDATE_STATUS.OVER }))
      }
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey: key, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)
