import { addEntities } from 'store/actions'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToNation,
  addAmplifyExtraHubEngagementValuesToNation,
  addAmplifyExtraHubFiltersValuesToNation,
  addAmplifyExtraHubGeographyValuesToNation,
  addAmplifyExtraHubTrendingValuesToNation,
  addAmplifyInventoryOosBrandsValuesToNation,
  addAmplifyInventoryOosFiltersValuesToNation,
  addAmplifyInventoryOosGeographyValuesToNation,
  addAmplifyInventoryOosMaterialValuesToNation,
  addAmplifyInventoryOosTrendingValuesToNation,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToNation,
  addAmplifyPriceCaptureComplianceFiltersValuesToNation,
  addAmplifyPriceCaptureComplianceFootprintValuesToNation,
  addAmplifyPriceCaptureComplianceGeographyValuesToNation,
  addAmplifyPriceComplianceEdlpComplianceValuesToNation,
  addAmplifyPriceComplianceGeographyComplianceValuesToNation,
  addAmplifyPriceComplianceGeographyFiltersValuesToNation,
  addAmplifyPriceComplianceStrategyComplianceValuesToNation,
  addAmplifySelloutGeographyValuesToNation,
  addAmplifySelloutValuesToNation,
  addDistroBrandDataToNation,
  addDistroGeographyDataToNation,
  addDistroMaterialDataToNation,
  addDistroTrendedDataToNation,
  addPlanningEngagementValuesToNation,
  addPlanningHealthCheckValuesToNation,
  addPlanningPriceCheckValuesToNation,
  addPlanningSellinValuesToNation,
  addPlanningSelloutValuesToNation,
  addSellinGeographyToNation,
  addSellinOrderCompletionToNation,
  addSellinRunRateToNation
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.nations) return state

    return Object.entries(payload.nations).reduce((acc, [id, nation]) => {
      return Object.assign(acc, {
        [id]: nation
      })
    }, Object.assign({}, state))
  },
  [addSellinRunRateToNation]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addSellinGeographyToNation]: (
    state,
    { payload: { sectorId: id, currentProductType, geography, period, range, vapeCategory, offset, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${range}-${offset}`]: result
      }
    })
  },
  [addSellinOrderCompletionToNation]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion-${activeProductType}`]: result
      }
    })
  },
  [addDistroTrendedDataToNation]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}`]: result
      }
    })
  },
  [addDistroBrandDataToNation]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}`]: result
      }
    })
  },
  [addDistroGeographyDataToNation]: (state, { payload: { sectorId: id, geography, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${geography}`]: result
      }
    })
  },
  [addDistroMaterialDataToNation]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}`]: result
      }
    })
  },
  [addPlanningSellinValuesToNation]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToNation]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToNation]: (state, { payload: { id, activeProductType, channel, dataType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`amplify-sellout-${activeProductType}-${channel}-${dataType}`]: result
      }
    })
  },
  [addAmplifySelloutGeographyValuesToNation]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToNation]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${activeProductType}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToNation]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToNation]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },
  [addAmplifyInventoryOosTrendingValuesToNation]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToNation]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToNation]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToNation]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToNation]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToNation]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToNation]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToNation]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToNation]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToNation]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToNation]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToNation]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToNation]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToNation]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToNation]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToNation]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToNation]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToNation]: priceCaptureCompliance.priceComplianceGeographyFilters
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
