import React, { useContext } from 'react'
import sumBy from 'lodash/sumBy'
import { bool, node, object, string } from 'prop-types'
import styled, { css } from 'styled-components'

import LangContext from 'context/LangContext'

import Status from 'components/Status'

import { borderColor, greyDark, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontBold, H3, smallFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};

  & + & {
    margin-top: ${spacing.medium};
  }

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const Header = styled.header`
  margin-bottom: ${spacing.medium};
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${spacing.small};
`

const Item = styled.div`
  display: block;
  padding: ${spacing.small};
  width: 50%;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`

const Label = styled.span`
  display: block;
  font-size: ${smallFontSize};
  color: ${greyDark};
  font-weight: ${fontBold};
`

const Stat = styled.span`
  display: block;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: ${spacing.small};
  }
`

const Actions = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};
`

const Heading = styled(H3)`
  margin-bottom: ${spacing.small};
  font-weight: bold;
`

const OrderApprovalBody = ({ header, order, lang, children, isRetailer, isActionable }) => {
  const { formatMessage } = useContext(LangContext)
  const ctnQty = sumBy(order.entries, 'qty')
  const wtdToAdd = order.type === 'saq' || !order.wtdAtCreate ? 0 : order.wtdAtCreate
  return (
    <>
      <Container>
        <Header>{formatMessage(header)}</Header>
        <Inner>
          <Item fullWidth>
            <Label>{formatMessage({ messageId: 'orderApproval.details.orderId' })}</Label>
            <Stat>{order.id}</Stat>
          </Item>
          {!isActionable && (
            <>
              <Item fullWidth>
                <Label>Status</Label>
                <Stat>
                  <Status status={order.status} />
                </Stat>
              </Item>
              {order.rejectReason && (
                <Item fullWidth>
                  <Label>Reason</Label>
                  <Stat>{order.rejectReason}</Stat>
                </Item>
              )}
            </>
          )}
          <Item>
            <Label>{formatMessage({ messageId: 'orderApproval.details.createdAt' })}</Label>
            <Stat>{new Date(order.createdAt).toDateString()}</Stat>
          </Item>
          <Item>
            <Label>{formatMessage({ messageId: 'orderApproval.details.totalQuantity' })}</Label>
            <Stat>{ctnQty}</Stat>
          </Item>
          {!isRetailer && (
            <>
              <Item>
                <Label>AWR</Label>
                <Stat>{order.awr13AtCreate || '-'}</Stat>
              </Item>
              <Item>
                <Label>% above AWR</Label>
                <Stat>
                  {order.awr13AtCreate && order.awr13AtCreate > 0
                    ? `${Math.round(((wtdToAdd + ctnQty) / order.awr13AtCreate) * 100)}%`
                    : '-'}
                </Stat>
              </Item>
            </>
          )}
        </Inner>
      </Container>
      <Container>
        <Heading>{formatMessage({ messageId: 'orderApproval.details.orderDetails' })}</Heading>
        <div>
          {order.entries.map(({ qty, product }, i) => (
            <Row key={i}>
              <span>{product[`${lang}Name`] || `${product[lang + 'Brand']} - #${product.id}`}</span>
              <span>× {qty}</span>
            </Row>
          ))}
        </div>
      </Container>
      {children && (
        <Container>
          <Actions>{children}</Actions>
        </Container>
      )}
    </>
  )
}

OrderApprovalBody.propTypes = {
  header: object,
  order: object,
  children: node,
  lang: string,
  isActionable: bool,
  isRetailer: bool
}

export default OrderApprovalBody
