import camelCase from 'lodash/camelCase'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import startCase from 'lodash/startCase'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { DEFAULT_TABLE_PAGE_SIZE, PACE_SELLOUT_MANUFACTURER_FILTERS, SECTOR_LEVELS } from 'utils/constants'

const manufacturerFromProps = (state, props) => props.currentManufacturer
const productTypeFromProps = (state, props) => props.activeProductType || props.currentProductType
const currentChannelFromProps = (state, props) => props.currentChannelFilter || props.currentChannel || props.channel
const currentMetricFromProps = (state, props) => props.currentMetric
const dataTypeFromProps = (state, props) => props.dataType
const currentProportionFromProps = (state, props) => props.currentProportion
const currentTimeDisplayFromProps = (state, props) => props.currentTimeDisplay
const currentVapeCategoryFromProps = (state, props) => props.currentVapeCategory
const geographyFromProps = (state, props) => props.geography
const sortAndPageProps = (state, props) => pick(props, ['sortColumn', 'sortDirection', 'offset', 'limit'])

const l13DataCols = [
  'l13w1',
  'l13w2',
  'l13w3',
  'l13w4',
  'l13w5',
  'l13w6',
  'l13w7',
  'l13w8',
  'l13w9',
  'l13w10',
  'l13w11',
  'pw',
  'lw'
]

const VAPE_SPECIALTY_BRANDS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty.map(({ value }) => value)
const VAPE_POS_BRANDS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos.map(({ value }) => value)
const FMC_MANUFACTURERS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos.map(({ value }) => value)

export const selloutDataFromStore = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  dataTypeFromProps,
  dataSourceFromSectorLevel,
  (activeProductType, activeChannelFilter, activeDataType, sector) => {
    if (!sector) return null
    const selloutData = sector[`amplify-sellout-${activeProductType}-${activeChannelFilter}-${activeDataType}`]
    // console.log({ selloutData })
    return selloutData
  }
)

function getVapeCategoriesForSelection(vapeCategory) {
  if (!vapeCategory) return null
  const consumables = ['openConsumables', 'closedConsumables', 'disposables']
  if (vapeCategory === 'allConsumables') return consumables
  return [vapeCategory]
}

export const brandManufacturerGroupedSelloutData = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  currentVapeCategoryFromProps,
  selloutDataFromStore,
  (activeProductType, activeChannelFilter, vapeCategory, amplifySelloutSectorData) => {
    if (isEmpty(amplifySelloutSectorData)) return null
    const isFmc = activeProductType === 'fmc'
    const vapeBrandsForChannel = activeChannelFilter === 'specialty' ? VAPE_SPECIALTY_BRANDS : VAPE_POS_BRANDS
    const vapeCategoriesToInclude = getVapeCategoriesForSelection(vapeCategory)
    const filteredData = isFmc
      ? amplifySelloutSectorData.filter(({ manufacturer }) => FMC_MANUFACTURERS.includes(manufacturer))
      : amplifySelloutSectorData.filter(
          (d) => !vapeCategory || vapeCategoriesToInclude.includes(camelCase(d.vapeCategory))
        )
    return groupBy(filteredData, ({ manufacturer, brand }) => {
      if (isFmc) return manufacturer.toUpperCase()
      return vapeBrandsForChannel.some((b) => b.toUpperCase() === brand.toUpperCase()) ? brand.toUpperCase() : 'OTHER'
    })
  }
)

export const selloutL13TrendGraphData = createSelector(
  productTypeFromProps,
  manufacturerFromProps,
  currentChannelFromProps,
  currentProportionFromProps,
  brandManufacturerGroupedSelloutData,
  (activeProductType, manufacturer, activeChannelFilter, activeProportion, sectorData) => {
    if (isEmpty(sectorData)) return generateDefaultState()
    const resultForBrandManufacturer = sectorData[manufacturer?.toUpperCase()]
    if (isEmpty(resultForBrandManufacturer)) return generateDefaultState()
    const manufacturerTotals = getAmplifyTotals(resultForBrandManufacturer, l13DataCols, {})
    const allBrandTotals = getAmplifyTotals(flatten(Object.values(sectorData)), l13DataCols, {})
    const totals =
      activeProportion === 'share'
        ? l13DataCols.reduce(
            (acc, l13Col) => ({
              ...acc,
              [l13Col]: (manufacturerTotals[l13Col] / (allBrandTotals[l13Col] || 1)) * 100
            }),
            {}
          )
        : manufacturerTotals
    return rechartsFormat({ result: totals })
  }
)

function rechartsFormat({ result }) {
  return [
    {
      data: +result.l13w1 || null,
      name: moment()
        .day(-7 * 13)
        .format('M/D')
    },
    {
      data: +result.l13w2 || null,
      name: moment()
        .day(-7 * 12)
        .format('M/D')
    },
    {
      data: +result.l13w3 || null,
      name: moment()
        .day(-7 * 11)
        .format('M/D')
    },
    {
      data: +result.l13w4 || null,
      name: moment()
        .day(-7 * 10)
        .format('M/D')
    },
    {
      data: +result.l13w5 || null,
      name: moment()
        .day(-7 * 9)
        .format('M/D')
    },
    {
      data: +result.l13w6 || null,
      name: moment()
        .day(-7 * 8)
        .format('M/D')
    },
    {
      data: +result.l13w7 || null,
      name: moment()
        .day(-7 * 7)
        .format('M/D')
    },
    {
      data: +result.l13w8 || null,
      name: moment()
        .day(-7 * 6)
        .format('M/D')
    },
    {
      data: +result.l13w9 || null,
      name: moment()
        .day(-7 * 5)
        .format('M/D')
    },
    {
      data: +result.l13w10 || null,
      name: moment()
        .day(-7 * 4)
        .format('M/D')
    },
    {
      data: +result.l13w11 || null,
      name: moment()
        .day(-7 * 3)
        .format('M/D')
    },
    {
      data: +result.pw || null,
      name: moment()
        .day(-7 * 2)
        .format('M/D')
    },
    { data: +result.lw || null, name: moment().day(-7).format('M/D') }
  ]
}

function generateDefaultState() {
  return [
    {
      data: null,
      name: moment()
        .day(-7 * 13)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 12)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 11)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 10)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 9)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 8)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 7)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 6)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 5)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 4)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 3)
        .format('M/D')
    },
    {
      data: null,
      name: moment()
        .day(-7 * 2)
        .format('M/D')
    },
    { data: null, name: moment().day(-7).format('M/D') }
  ]
}

const COLORS = ['#F5BE41', '#4FAEE6', '#B4C941', '#ff5050', '#9999ff', '#669999']

function getAmplifyTotals(data, timeKeys, staticData = null) {
  return timeKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: sumBy(data, (d) => +d[key])
    }),
    { ...staticData }
  )
}

export const amplifySelloutSOMData = createSelector(
  currentMetricFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  currentVapeCategoryFromProps,
  brandManufacturerGroupedSelloutData,
  (metric, proportion, timeDisplay, vapeCategory, amplifyData) => {
    if (isEmpty(amplifyData)) return null
    // console.log(Object.keys(amplifyData))
    const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
    const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

    const manufacturerTotals = Object.entries(amplifyData).map(([manufacturer, manufacturerData], idx) =>
      getAmplifyTotals(manufacturerData, [mainPeriod, previousPeriod], {
        name: startCase(manufacturer),
        fill: COLORS[idx]
      })
    )

    const mainPeriodTotal = sumBy(manufacturerTotals, (t) => +t[mainPeriod]) || 1
    const previousPeriodTotal = sumBy(manufacturerTotals, (t) => +t[previousPeriod]) || 1
    return manufacturerTotals.map((d) => {
      const mainPeriodData = d[mainPeriod] || 0
      const previousPeriodData = d[previousPeriod] || 0
      const isShare = proportion === 'share'
      return isShare
        ? {
            ...d,
            mainPeriod: +(mainPeriodData / mainPeriodTotal),
            previousPeriod: previousPeriodData,
            variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
          }
        : {
            ...d,
            mainPeriod: +mainPeriodData,
            previousPeriod: previousPeriodData,
            variation: +(mainPeriodData - previousPeriodData)
          }
    })
  }
)

const AMPLIFY_FMC_BRANDS = [
  'du Maurier Core',
  'Belmont',
  'Export A',
  "Player's Blue",
  'Marlboro VFM',
  'Canadian Classics',
  'Macdonald SP',
  'Pall Mall',
  'John Player Standard',
  'Next',
  'LD',
  'Philip Morris by PMI',
  'Viceroy',
  'Matinee',
  'Vogue',
  'B&H',
  'Number7',
  'Mark Ten',
  'Quebec Classique'
]

function amplifySellOutBrandPerfFmcData(amplifyData, metric, proportion, timeDisplay) {
  const dataToTotal = metric === 'cost' ? 'cost' : proportion === 'share' ? 'qty' : 'pack_qty'
  const relevantData = amplifyData.filter(({ dataType }) => dataType === dataToTotal)
  const resultByManufacturer = groupBy(relevantData, ({ brand, dataType }) =>
    AMPLIFY_FMC_BRANDS.includes(brand) ? brand : 'Other'
  )
  const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
  const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

  const manufacturerTotals = Object.entries(resultByManufacturer).map(([manufacturer, manufacturerData]) =>
    getAmplifyTotals(manufacturerData, [mainPeriod, previousPeriod], { name: manufacturer })
  )
  const mainPeriodTotal = sumBy(manufacturerTotals, (t) => +t[mainPeriod]) || 1
  const previousPeriodTotal = sumBy(manufacturerTotals, (t) => +t[previousPeriod]) || 1
  return manufacturerTotals.map((d) => {
    const mainPeriodData = d[mainPeriod] || 0
    const previousPeriodData = d[previousPeriod] || 0
    const isShare = proportion === 'share'
    return isShare
      ? {
          ...d,
          mainPeriod: +(mainPeriodData / mainPeriodTotal),
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
        }
      : {
          ...d,
          mainPeriod: +mainPeriodData,
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData - previousPeriodData)
        }
  })
}

function amplifySellOutBrandPerfVapeData(amplifyData, metric, proportion, timeDisplay, vapeCategory) {
  const dataToTotal = metric === 'cost' ? 'cost' : 'qty'
  const vapeCategoriesToInclude =
    vapeCategory === 'allConsumables' ? ['openConsumables', 'closedConsumables', 'disposables'] : [vapeCategory]
  const relevantData = amplifyData.filter(
    ({ vapeCategory, dataType }) =>
      vapeCategoriesToInclude.includes(camelCase(vapeCategory)) && dataType === dataToTotal
  )
  const resultByBrand = groupBy(relevantData, 'variantLabel')
  const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
  const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

  const brandTotals = Object.entries(resultByBrand).map(([brand, brandData]) =>
    getAmplifyTotals(brandData, [mainPeriod, previousPeriod], { name: brand })
  )
  const mainPeriodTotal = sumBy(brandTotals, (t) => +t[mainPeriod]) || 1
  const previousPeriodTotal = sumBy(brandTotals, (t) => +t[previousPeriod]) || 1
  return brandTotals.map((d) => {
    const mainPeriodData = d[mainPeriod] || 0
    const previousPeriodData = d[previousPeriod] || 0
    const isShare = proportion === 'share'
    return isShare
      ? {
          ...d,
          mainPeriod: +(mainPeriodData / mainPeriodTotal),
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
        }
      : {
          ...d,
          mainPeriod: +mainPeriodData,
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData - previousPeriodData)
        }
  })
}

export const amplifySelloutBrandPerfData = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  currentMetricFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  currentVapeCategoryFromProps,
  selloutDataFromStore,
  (
    activeProductType,
    activeChannelFilter,
    activeMetric,
    activeProportion,
    activeTimeDisplay,
    activeVapeCategory,
    amplifyData
  ) => {
    if (isEmpty(amplifyData)) return null
    if (activeProductType === 'fmc')
      return amplifySellOutBrandPerfFmcData(amplifyData, activeMetric, activeProportion, activeTimeDisplay)
    if (activeProductType === 'vape')
      return amplifySellOutBrandPerfVapeData(
        amplifyData,
        activeMetric,
        activeProportion,
        activeTimeDisplay,
        activeVapeCategory
      )
  }
)

export const amplifySelloutDataForKeyAccounts = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  dataTypeFromProps,
  dataSourceFromSectorLevel,
  (activeProductType, activeChannelFilter, activeDataType, sector) => {
    if (!sector) return null
    const resultByManufacturer =
      sector[`amplify-sellout-${activeProductType}-${activeChannelFilter}-${activeDataType}-headoffice`]
    return resultByManufacturer
  }
)

function amplifySellOutKAFmcData(amplifyData, metric, proportion, timeDisplay) {
  const dataToTotal = metric === 'cost' ? 'cost' : proportion === 'share' ? 'qty' : 'pack_qty'
  const relevantData = amplifyData.filter(({ dataType }) => dataType === dataToTotal)
  const resultByManufacturer = groupBy(relevantData, 'headOffice')
  const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
  const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

  const manufacturerTotals = Object.entries(resultByManufacturer).map(([headOffice, headOfficeData]) =>
    getAmplifyTotals(headOfficeData, [mainPeriod, previousPeriod], { name: headOffice })
  )
  const mainPeriodTotal = sumBy(manufacturerTotals, (t) => +t[mainPeriod]) || 1
  const previousPeriodTotal = sumBy(manufacturerTotals, (t) => +t[previousPeriod]) || 1
  return manufacturerTotals.map((d) => {
    const mainPeriodData = d[mainPeriod] || 0
    const previousPeriodData = d[previousPeriod] || 0
    const isShare = proportion === 'share'
    return isShare
      ? {
          ...d,
          mainPeriod: +(mainPeriodData / mainPeriodTotal),
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
        }
      : {
          ...d,
          mainPeriod: +mainPeriodData,
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData - previousPeriodData)
        }
  })
}

function amplifySellOutKAVapeData(amplifyData, metric, proportion, timeDisplay, vapeCategory) {
  const dataToTotal = metric === 'cost' ? 'cost' : 'qty'
  const vapeCategoriesToInclude =
    vapeCategory === 'allConsumables' ? ['openConsumables', 'closedConsumables', 'disposables'] : [vapeCategory]
  const relevantData = amplifyData.filter(
    ({ vapeCategory, dataType }) =>
      vapeCategoriesToInclude.includes(camelCase(vapeCategory)) && dataType === dataToTotal
  )
  const resultByBrand = groupBy(relevantData, 'headOffice')
  const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
  const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

  const brandTotals = Object.entries(resultByBrand).map(([headOffice, headOfficeData]) =>
    getAmplifyTotals(headOfficeData, [mainPeriod, previousPeriod], { name: headOffice })
  )
  const mainPeriodTotal = sumBy(brandTotals, (t) => +t[mainPeriod]) || 1
  const previousPeriodTotal = sumBy(brandTotals, (t) => +t[previousPeriod]) || 1
  return brandTotals.map((d) => {
    const mainPeriodData = d[mainPeriod] || 0
    const previousPeriodData = d[previousPeriod] || 0
    const isShare = proportion === 'share'
    return isShare
      ? {
          ...d,
          mainPeriod: +(mainPeriodData / mainPeriodTotal),
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal)
        }
      : {
          ...d,
          mainPeriod: +mainPeriodData,
          previousPeriod: previousPeriodData,
          variation: +(mainPeriodData - previousPeriodData)
        }
  })
}

export const amplifySelloutKAData = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  currentMetricFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  currentVapeCategoryFromProps,
  amplifySelloutDataForKeyAccounts,
  (
    activeProductType,
    activeChannelFilter,
    activeMetric,
    activeProportion,
    activeTimeDisplay,
    activeVapeCategory,
    amplifyData
  ) => {
    if (isEmpty(amplifyData)) return null
    if (activeProductType === 'fmc')
      return amplifySellOutKAFmcData(amplifyData, activeMetric, activeProportion, activeTimeDisplay)
    if (activeProductType === 'vape')
      return amplifySellOutKAVapeData(
        amplifyData,
        activeMetric,
        activeProportion,
        activeTimeDisplay,
        activeVapeCategory
      )
  }
)

const tableColsByTimeDisplay = {
  rolling: {
    lShort: 'lw',
    pShort: 'pw',
    lMid: 'l4',
    pMid: 'p4',
    lLong: 'l13',
    pLong: 'p13'
  },
  pointInTime: {
    lShort: 'cc',
    pShort: 'pc',
    lMid: 'cq',
    pMid: 'pq',
    lLong: 'cy',
    pLong: 'py'
  }
}

const formatTrendlineData = ({
  l13w1,
  l13w2,
  l13w3,
  l13w4,
  l13w5,
  l13w6,
  l13w7,
  l13w8,
  l13w9,
  l13w10,
  l13w11,
  pw,
  lw
}) => {
  return [
    { d: +l13w1.toFixed(2) },
    { d: +l13w2.toFixed(2) },
    { d: +l13w3.toFixed(2) },
    { d: +l13w4.toFixed(2) },
    { d: +l13w5.toFixed(2) },
    { d: +l13w6.toFixed(2) },
    { d: +l13w7.toFixed(2) },
    { d: +l13w8.toFixed(2) },
    { d: +l13w9.toFixed(2) },
    { d: +l13w10.toFixed(2) },
    { d: +l13w11.toFixed(2) },
    { d: +pw.toFixed(2) },
    { d: +lw.toFixed(2) }
  ]
}

const rowHeaderColumnMapping = {
  store: {
    key: 'customerId',
    label: (c) => `${c.customer} (${c.customerId})`,
    linkTo: (c) => `/${SECTOR_LEVELS.CUSTOMER}/${c.customerId}/pace/amplify/sell-out`
  },
  headoffice: { key: 'headOffice', label: (d) => d.headOffice },
  territory: {
    key: 'territoryName',
    label: (d) => d.territoryName,
    linkTo: (d) => `/${SECTOR_LEVELS.TERRITORY}/${d.territoryId}/pace/amplify/sell-out`
  },
  district: {
    key: 'districtName',
    label: (d) => d.districtName,
    linkTo: (d) => `/${SECTOR_LEVELS.DISTRICT}/${d.districtId}/pace/amplify/sell-out`
  },
  region: {
    key: 'regionName',
    label: (d) => d.regionName,
    linkTo: (d) => `/${SECTOR_LEVELS.REGION}/${d.regionId}/pace/amplify/sell-out`
  },
  province: { key: 'province', label: (d) => d.province },
  brand: { key: 'brand', label: (d) => d.brand },
  variantLabel: { key: 'variantLabel', label: (d) => d.variantLabel },
  sku: { key: 'productId', label: (d) => d.productName }
}

function amplifySellOutTableFmcData(
  amplifyData,
  metric,
  proportion,
  timeDisplay,
  rowHeader,
  manufacturer,
  sortAndPageProps
) {
  if (!rowHeader) return null
  const manufacturerFilteredData =
    manufacturer === 'ALL'
      ? amplifyData?.geographyData
      : amplifyData?.geographyData?.filter((d) => manufacturer === d.manufacturer)
  const rowHeaderColumn = rowHeaderColumnMapping[rowHeader]

  const resultByHeader = groupBy(manufacturerFilteredData, rowHeaderColumn.key)
  const dataCols = tableColsByTimeDisplay[timeDisplay]
  // console.log(resultByHeader)
  // console.log({ dataCols })
  const rowTotals = orderBy(
    Object.entries(resultByHeader).map(([header, headerData]) => {
      return getAmplifyTotals(headerData, Object.values(dataCols).concat(l13DataCols).concat('l13'), {
        geo: rowHeaderColumn.label(headerData[0]),
        linkTo: rowHeaderColumn.linkTo && rowHeaderColumn.linkTo(headerData[0])
      })
    }),
    [sortAndPageProps.sortColumn || 'l13'],
    [sortAndPageProps.sortDirection || 'desc']
  ).slice(sortAndPageProps.offset, sortAndPageProps.offset + (sortAndPageProps.limit || DEFAULT_TABLE_PAGE_SIZE))

  const relevantDataMainData =
    manufacturer === 'ALL'
      ? amplifyData?.mainData
      : amplifyData?.mainData?.filter((d) => manufacturer === d.manufacturer)

  const lShortTotal = sumBy(relevantDataMainData, (t) => +t[dataCols.lShort]) || 1
  const pShortTotal = sumBy(relevantDataMainData, (t) => +t[dataCols.pShort]) || 1
  const lMidTotal = sumBy(relevantDataMainData, (t) => +t[dataCols.lMid]) || 1
  const pMidTotal = sumBy(relevantDataMainData, (t) => +t[dataCols.pMid]) || 1
  const lLongTotal = sumBy(relevantDataMainData, (t) => +t[dataCols.lLong]) || 1
  const pLongTotal = sumBy(relevantDataMainData, (t) => +t[dataCols.pLong]) || 1
  const l13 = sumBy(relevantDataMainData, (t) => +t.l13)

  return rowTotals.map((d) => {
    const lShort = +d[dataCols.lShort] || 0
    const lMid = +d[dataCols.lMid] || 0
    const lLong = +d[dataCols.lLong] || 0
    const pShort = +d[dataCols.pShort] || 0
    const pMid = +d[dataCols.pMid] || 0
    const pLong = +d[dataCols.pLong] || 0
    const base = {
      geo: d.geo,
      linkTo: d.linkTo,
      rowL13: +d.l13,
      totalL13: +l13,
      l13Trendline: formatTrendlineData(d)
    }
    return proportion === 'share'
      ? {
          ...base,
          lShort: lShort / lShortTotal,
          lShortVariation: lShort / lShortTotal - pShort / pShortTotal,
          lMid: lMid / lMidTotal,
          lMidVariation: lMid / lMidTotal - pMid / pMidTotal,
          lLong: lLong / lLongTotal,
          lLongVariation: lLong / lLongTotal - pLong / pLongTotal
        }
      : {
          ...base,
          lShort,
          lShortVariation: lShort - pShort,
          lMid,
          lMidVariation: lMid - pMid,
          lLong,
          lLongVariation: lLong - pLong
        }
  })
}

function amplifySellOutTableVapeData(
  amplifyData,
  metric,
  proportion,
  timeDisplay,
  vapeCategory,
  channel,
  rowHeader,
  sortAndPageProps
) {
  const includeTargetData = channel === 'pos' && ['allConsumables', 'disposables'].includes(vapeCategory)
  const targetDataColsToInclude = includeTargetData ? ['ccTarget', 'ccTargetActuals'] : []
  const dataToTotal = metric === 'cost' ? 'cost' : 'qty'
  const vapeCategoriesToInclude =
    vapeCategory === 'allConsumables' ? ['openConsumables', 'closedConsumables', 'disposables'] : [vapeCategory]
  const relevantData = amplifyData?.geographyData?.filter(
    ({ vapeCategory, dataType }) =>
      vapeCategoriesToInclude.includes(camelCase(vapeCategory)) && dataType === dataToTotal
  )
  const rowHeaderColumn = rowHeaderColumnMapping[rowHeader]
  console.log(rowHeaderColumn)
  // console.log(relevantData)
  const resultByBrand = groupBy(relevantData, rowHeaderColumn.key)
  const dataCols = tableColsByTimeDisplay[timeDisplay]

  const rowTotals = orderBy(
    Object.entries(resultByBrand).map(([header, headerData]) =>
      getAmplifyTotals(
        headerData,
        Object.values(dataCols).concat(l13DataCols).concat('l13').concat(targetDataColsToInclude),
        {
          geo: rowHeaderColumn.label(headerData[0]),
          linkTo: rowHeaderColumn.linkTo && rowHeaderColumn.linkTo(headerData[0])
        }
      )
    ),
    [sortAndPageProps.sortColumn || 'l13'],
    [sortAndPageProps.sortDirection || 'desc']
  ).slice(sortAndPageProps.offset, sortAndPageProps.offset + (sortAndPageProps.limit || DEFAULT_TABLE_PAGE_SIZE))

  const mainDataTotals = amplifyData?.mainData?.filter(
    ({ vapeCategory, dataType }) =>
      vapeCategoriesToInclude.includes(camelCase(vapeCategory)) && dataType === dataToTotal
  )
  const lShortTotal = sumBy(mainDataTotals, (t) => +t[dataCols.lShort]) || 1
  const pShortTotal = sumBy(mainDataTotals, (t) => +t[dataCols.pShort]) || 1
  const lMidTotal = sumBy(mainDataTotals, (t) => +t[dataCols.lMid]) || 1
  const pMidTotal = sumBy(mainDataTotals, (t) => +t[dataCols.pMid]) || 1
  const lLongTotal = sumBy(mainDataTotals, (t) => +t[dataCols.lLong]) || 1
  const pLongTotal = sumBy(mainDataTotals, (t) => +t[dataCols.pLong]) || 1
  const l13 = sumBy(mainDataTotals, (t) => +t.l13)
  // const fixedDecimals = metric === 'cost' ? 2 : 0

  return rowTotals.map((d) => {
    const lShort = +d[dataCols.lShort] || 0
    const lMid = +d[dataCols.lMid] || 0
    const lLong = +d[dataCols.lLong] || 0
    const pShort = +d[dataCols.pShort] || 0
    const pMid = +d[dataCols.pMid] || 0
    const pLong = +d[dataCols.pLong] || 0

    const base = {
      geo: d.geo,
      linkTo: d.linkTo,
      rowL13: +d.l13,
      totalL13: +l13,
      l13Trendline: formatTrendlineData(d),
      ccTarget: d.ccTarget,
      ccTargetActuals: d.ccTargetActuals
    }
    return proportion === 'share'
      ? {
          ...base,
          lShort: +(lShort / lShortTotal),
          lShortVariation: +(lShort / lShortTotal) - +(pShort / pShortTotal),
          lMid: lMid / lMidTotal,
          lMidVariation: lMid / lMidTotal - pMid / pMidTotal,
          lLong: lLong / lLongTotal,
          lLongVariation: lLong / lLongTotal - pLong / pLongTotal
        }
      : {
          ...base,
          lShort: +lShort,
          lShortVariation: +(lShort - pShort),
          lMid: +lMid,
          lMidVariation: +(lMid - pMid),
          lLong: +lLong,
          lLongVariation: +lLong - +pLong
        }
  })
}

export const amplifySelloutDataForGeography = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  geographyFromProps,
  dataTypeFromProps,
  dataSourceFromSectorLevel,
  (activeProductType, activeChannelFilter, activeGeography, activeDataType, sector) => {
    if (!sector) return null
    const mainData = sector[`amplify-sellout-${activeProductType}-${activeChannelFilter}-${activeDataType}`]
    const geographyData =
      sector[`amplify-sellout-${activeProductType}-${activeChannelFilter}-${activeDataType}-${activeGeography}`]
    return { mainData, geographyData: geographyData || mainData }
  }
)
export const amplifySelloutTableData = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  currentMetricFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  currentVapeCategoryFromProps,
  geographyFromProps,
  manufacturerFromProps,
  amplifySelloutDataForGeography,
  sortAndPageProps,
  (
    activeProductType,
    activeChannelFilter,
    activeMetric,
    activeProportion,
    activeTimeDisplay,
    activeVapeCategory,
    activeGeography,
    activeManufacturer,
    amplifyData,
    sortAndPageProps
  ) => {
    if (isEmpty(amplifyData)) return null
    if (activeProductType === 'fmc')
      return amplifySellOutTableFmcData(
        amplifyData,
        activeMetric,
        activeProportion,
        activeTimeDisplay,
        activeGeography,
        activeManufacturer,
        sortAndPageProps
      )
    if (activeProductType === 'vape')
      return amplifySellOutTableVapeData(
        amplifyData,
        activeMetric,
        activeProportion,
        activeTimeDisplay,
        activeVapeCategory,
        activeChannelFilter,
        activeGeography,
        sortAndPageProps
      )
  }
)
