import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import template from 'lodash/template'
import { node, string } from 'prop-types'
import messagesEn from 'translations/en.json'
import messagesFr from 'translations/fr.json'

import { parseSearchQuery } from 'utils/helpers'

const LangContext = React.createContext()
export default LangContext

const messages = {
  en: messagesEn,
  fr: messagesFr
}

const validateLang = (lang) => {
  const validLangs = Object.keys(messages)
  const isValidLang = lang && validLangs.includes(lang)
  return isValidLang && lang
}

const LangProviderInner = ({ children, employeeLanguage }) => {
  const location = useLocation()
  const localLang = sessionStorage.getItem('lang')
  const [lang, setLang] = useState(validateLang(localLang) || 'en')
  const [preferredLanguage, setPreferredLanguage] = useState(employeeLanguage)

  const setValidLang = (lang) => {
    const validLang = validateLang(lang)
    if (!validLang) return
    setLang(validLang)
    sessionStorage.setItem('lang', lang)
  }

  useEffect(() => {
    const { lang } = parseSearchQuery(location.search) || {}
    if (lang) setValidLang(lang)
  }, [location.search])

  useEffect(() => {
    if (employeeLanguage) setValidLang(employeeLanguage.substring(0, 2))
    setPreferredLanguage(employeeLanguage)
  }, [employeeLanguage])

  const langMessages = messages[lang]
  const formatMessage = ({ messageId, variables }) => {
    if (!variables) return langMessages[messageId]
    const compiled = template(langMessages[messageId])
    return compiled(variables)
  }

  const value = {
    lang,
    setLang: setValidLang,
    formatMessage,
    preferredLanguage
  }

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>
}

LangProviderInner.propTypes = {
  children: node.isRequired,
  employeeLanguage: string
}

export const LangProvider = connect((state) => ({ employeeLanguage: state.auth?.user?.preferredLanguage }))(
  LangProviderInner
)
