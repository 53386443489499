import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { bool, func, string } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import Icon from 'components/Icon'

import { SECTOR_LEVELS } from 'utils/constants'

const Container = tw.div`grow space-y-4`

const delimiter = ' - '
const getSectorName = (input) => input.split(delimiter)[1]
const getSectorID = (input) => input.split(delimiter)[0]
const BreadcrumbItem = ({ label, id, isActive, onClick, disabled }) => {
  return (
    <button
      className={`flex h-[76px] w-full items-center justify-between rounded-md p-4 text-left ring-1 ring-slate-900/10 transition-all ${
        isActive && 'bg-brand-50 text-brand ring-brand-900 '
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        <p>{label}</p>
        <p className="text-xs opacity-70">{id}</p>
      </div>
      <div className={`flex items-center gap-2 ${!isActive && 'hidden'}`}>
        <p className="text-sm ">Selected</p>
        <Icon icon="checkmark-small" />
      </div>
    </button>
  )
}

BreadcrumbItem.propTypes = {
  label: string.isRequired,
  id: string,
  isActive: bool.isRequired,
  onClick: func.isRequired,
  disabled: bool
}

const MobileBreadcrumbs = () => {
  const { selectedLevel, setSelectedLevel, currentSector, setModalOpen } = useContext(SectorContext)
  const {
    user: { isDM, isSalesRep, isTM }
  } = useSelector((state) => state.auth)
  if (isEmpty(currentSector)) return null
  const { district, territory, customer, region } = currentSector

  const updateSelectionAndCloseModal = (newSectorLevel) => {
    setSelectedLevel(newSectorLevel)
    setModalOpen(false)
  }

  return (
    <Container>
      <p>Select your scope or change it</p>
      <div className="space-y-3">
        <BreadcrumbItem
          label={'Canada'}
          isActive={selectedLevel === SECTOR_LEVELS.NATIONAL}
          // onClick={() => updateSelectionAndCloseModal(SECTOR_LEVELS.NATIONAL)}
          disabled //= {isRSM || isDM || isSalesRep || isTM}
        />
        {region && (
          <BreadcrumbItem
            label={region.name}
            isActive={selectedLevel === SECTOR_LEVELS.REGION}
            onClick={() => updateSelectionAndCloseModal(SECTOR_LEVELS.REGION)}
            disabled={isDM || isSalesRep || isTM}
          />
        )}
        {district && (
          <BreadcrumbItem
            label={getSectorName(district.name)}
            id={getSectorID(district.name)}
            isActive={selectedLevel === SECTOR_LEVELS.DISTRICT}
            onClick={() => updateSelectionAndCloseModal(SECTOR_LEVELS.DISTRICT)}
            disabled={isSalesRep || isTM}
          />
        )}
        {territory && (
          <BreadcrumbItem
            label={getSectorName(territory.name)}
            id={getSectorID(territory.name)}
            isActive={selectedLevel === SECTOR_LEVELS.TERRITORY}
            onClick={() => updateSelectionAndCloseModal(SECTOR_LEVELS.TERRITORY)}
          />
        )}
        {customer && (
          <BreadcrumbItem
            label={customer.name}
            id={customer.id}
            isActive={selectedLevel === SECTOR_LEVELS.CUSTOMER}
            onClick={() => updateSelectionAndCloseModal(SECTOR_LEVELS.CUSTOMER)}
          />
        )}
      </div>
    </Container>
  )
}

export default MobileBreadcrumbs
