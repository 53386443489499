import React, { useContext } from 'react'

import MediaContext from 'context/MediaContext'

import AmplifyDistroBrandCard from './AmplifyDistroBrandCard'
import AmplifyDistroCard from './AmplifyDistroCard'
import AmplifyDistroMaterialCard from './AmplifyDistroMaterialCard'
import AmplifyDistroTableCard from './AmplifyDistroTableCard'
import AmplifyDistroTrendCard from './AmplifyDistroTrendCard'
import DistroHeader from './DistroHeader'

const AmplifyDistroLayout = () => {
  const { isWideLayout } = useContext(MediaContext)
  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5">
      <DistroHeader parentHeader="Amplify" header="Distro" />
      <AmplifyDistroCard span={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }} />
      <AmplifyDistroTrendCard span={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 4 }} />
      <AmplifyDistroBrandCard span={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }} />
      <AmplifyDistroMaterialCard span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 3 }} />
      {isWideLayout && <AmplifyDistroTableCard span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />}
    </div>
  )
}
export default AmplifyDistroLayout
