import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { arrayOf, bool, func, number, object, shape } from 'prop-types'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'
import tw from 'twin.macro'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchTrendingActivities } from 'store/extraHub/actions'
import { getCurrentTrending } from 'store/extraHub/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const TooltipContainer = tw.div`w-32 -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white`

const DataTitle = tw.span`text-slate-300`

const Data = tw.span``

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload[0])
    return (
      <div>
        <TooltipContainer>
          <div className="flex w-full justify-between">
            <DataTitle>ERP</DataTitle>
            <Data>{payload[0]?.payload.erp}</Data>
          </div>{' '}
          <div className="flex w-full justify-between">
            <DataTitle>User</DataTitle>
            <Data>{payload[0]?.payload.user}</Data>
          </div>
        </TooltipContainer>
      </div>
    )
}

CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ value: number }))
}

const Trended = ({ span, fetchTrendingActivities }) => {
  const { sectorType, sectorId } = useParams()
  const [error, setError] = useState()

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.TRENDING, { sectorType, sectorId })
  const isTrendingLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const data = useSelector((state) => getCurrentTrending(state))

  useEffect(() => {
    fetchTrendingActivities({ sectorType, sectorId, dataKey })
      .then(() => isMounted.current && setError())
      .catch((error) => isMounted.current && setError(error))
  }, [sectorType, sectorId])

  const getContent = () => {
    if (isTrendingLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    return (
      <div className="h-[300px] w-full pt-5">
        <ResponsiveContainer height="99%" width="99%">
          <LineChart data={data} margin={{ bottom: 0, left: 0, right: 10, top: 10 }}>
            <CartesianGrid stroke={theme.slate[300]} vertical={false} />
            <XAxis
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              dataKey="name"
              dy={10}
              stroke={theme.slate[500]}
              tickLine={false}
            />
            <YAxis
              interval="preserveStartEnd"
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              dx={-8}
              stroke={theme.slate[500]}
              tickLine={false}
            />
            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{
                outline: 'none'
              }}
              animationDuration={300}
              allowEscapeViewBox={{ x: true }}
              cursor={false}
              offset={0}
            />
            <Line
              dataKey="erp"
              strokeWidth={3}
              stroke="#1F4E9A"
              dot={false}
              activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
              isAnimationActive={false}
              connectNulls={true}
            />
            <Line
              dataKey="user"
              strokeWidth={3}
              stroke="#4FAEE6"
              dot={false}
              activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
              isAnimationActive={false}
              connectNulls={true}
            />
            <Legend iconType="circle" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
  return (
    <Card title="Trended view" span={span}>
      {getContent()}
    </Card>
  )
}

Trended.propTypes = {
  span: object,
  fetchTrendingActivities: func
}

export default connect(null, {
  fetchTrendingActivities
})(Trended)
