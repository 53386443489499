import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { bool, func, string } from 'prop-types'

import SegmentControl from 'components/SegmentControl'

import { SECTOR_LEVELS } from 'utils/constants'

const GEOGRAPHIES = [
  {
    label: 'Region',
    value: 'region'
  },
  {
    label: 'Province',
    value: 'province'
  },
  {
    label: 'District',
    value: 'district'
  },
  {
    label: 'Territory',
    value: 'territory'
  },
  {
    label: 'HO',
    value: 'headoffice'
  },
  {
    label: 'Channel',
    value: 'channel'
  },
  {
    label: 'Ownership',
    value: 'ownership'
  },
  {
    label: 'Store',
    value: 'store'
  },
  {
    label: 'Activity',
    value: 'campaign'
  },
  {
    label: 'Brand',
    value: 'brand'
  }
]

const GeographyToggle = ({
  geography,
  setGeography,
  includeActivity = false,
  includeBrand = false,
  includeChannel = false,
  includeOwnership = false
}) => {
  const { sectorType } = useParams()

  const geographyOptions = useMemo(() => {
    let options = GEOGRAPHIES

    if (sectorType === SECTOR_LEVELS.DISTRICT) {
      options = options.filter((opt) => !['province', 'region'].includes(opt.value))
    } else if (sectorType === SECTOR_LEVELS.TERRITORY) {
      options = options.filter((opt) => !['province', 'region', 'district'].includes(opt.value))
    } else if (sectorType === SECTOR_LEVELS.CUSTOMER) {
      options = options.filter(
        (opt) =>
          !['headoffice', 'channel', 'region', 'district', 'territory', 'province', 'ownership'].includes(opt.value)
      )
    } else if (sectorType === SECTOR_LEVELS.REGION) {
      options = options.filter((opt) => !['store'].includes(opt.value))
    }

    if (!includeActivity) {
      options = options.filter((opt) => !['campaign'].includes(opt.value))
    }

    if (!includeBrand) {
      options = options.filter((opt) => !['brand'].includes(opt.value))
    }

    if (!includeChannel) {
      options = options.filter((opt) => !['channel'].includes(opt.value))
    }

    if (!includeOwnership) {
      options = options.filter((opt) => !['ownership'].includes(opt.value))
    }

    return options
  }, [includeActivity, includeBrand, sectorType])

  useEffect(() => {
    if (!geographyOptions.some((opt) => opt.value === geography)) setGeography(geographyOptions[0].value)
  }, [geographyOptions])

  return (
    <SegmentControl
      key={3}
      name="type"
      onChange={(e) => setGeography(e.target.value)}
      value={geography}
      options={geographyOptions}
    />
  )
}

GeographyToggle.propTypes = {
  geography: string,
  setGeography: func,
  includeActivity: bool,
  includeBrand: bool,
  includeChannel: bool,
  includeOwnership: bool
}

export default GeographyToggle
