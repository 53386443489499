import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS } from 'utils/helpers'

import * as api from './endpoints'

export const fetchTableFilters = createAction(
  'Fetch inventory table filters',
  ({ dataKey, sectorType, sectorId, productType }) =>
    async (dispatch, getState) => {
      const dataFetchesState = getState().dataFetches
      if (dataIsStillValid(dataFetchesState, dataKey)) return
      try {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        if (!window.navigator.onLine) throw ERRORS.offline
        const {
          data: { ...filters }
        } = await api.getFilters({ sectorType, sectorId, productType })
        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos?.filters
        dispatch(
          action({
            id: sectorId,
            productType,
            filters
          })
        )
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
      } catch (e) {
        console.error(e)
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
        throw e
      }
    }
)

export const fetchTrendingInventory = createAction(
  'Fetch trending inventory',
  ({ sectorType, sectorId, dataKey }) =>
    async (dispatch, getState) => {
      const dataFetchesState = getState().dataFetches
      if (dataIsStillValid(dataFetchesState, dataKey)) return
      try {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        if (!window.navigator.onLine) throw ERRORS.offline
        const {
          data: { trending }
        } = await api.getTrending({ sectorType, sectorId })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos?.trending
        dispatch(
          action({
            id: sectorId,
            trending
          })
        )
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
      } catch (e) {
        console.error(e)
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
        throw e
      }
    }
)

export const fetchBrandsInventory = createAction(
  'Fetch brands inventory',
  ({ dataKey, sectorType, sectorId, productType, timeframe }) =>
    async (dispatch, getState) => {
      const dataFetchesState = getState().dataFetches
      if (dataIsStillValid(dataFetchesState, dataKey)) return
      try {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        if (!window.navigator.onLine) throw ERRORS.offline
        const {
          data: { brands }
        } = await api.getBrands({ sectorType, sectorId, productType, timeframe })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos?.brand
        dispatch(
          action({
            id: sectorId,
            productType,
            timeframe,
            brands
          })
        )
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
      } catch (e) {
        console.error(e)
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
        throw e
      }
    }
)

export const fetchMaterialsInventory = createAction(
  'Fetch materials inventory',
  ({ dataKey, sectorType, sectorId, productType, timeframe }) =>
    async (dispatch, getState) => {
      const dataFetchesState = getState().dataFetches
      if (dataIsStillValid(dataFetchesState, dataKey)) return
      try {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        if (!window.navigator.onLine) throw ERRORS.offline
        const {
          data: { materials }
        } = await api.getMaterials({ sectorType, sectorId, productType, timeframe })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos?.materials
        dispatch(
          action({
            id: sectorId,
            productType,
            timeframe,
            materials
          })
        )
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
      } catch (e) {
        console.error(e)
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
        throw e
      }
    }
)

export const fetchGeographyInventory = createAction(
  'Fetch region inventory',
  ({ dataKey, sectorType, sectorId, geography, ownership, brand, material, productType, timeframe }) =>
    async (dispatch, getState) => {
      const dataFetchesState = getState().dataFetches
      if (dataIsStillValid(dataFetchesState, dataKey)) return
      try {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        if (!window.navigator.onLine) throw ERRORS.offline
        const {
          data: { geographies }
        } = await api.getGeography({
          sectorType,
          sectorId,
          geography,
          ownership,
          brand,
          material,
          productType,
          timeframe
        })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos?.geography
        dispatch(
          action({
            id: sectorId,
            geographies,
            geography,
            ownership,
            brand,
            material,
            productType,
            timeframe
          })
        )
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
      } catch (e) {
        console.error(e)
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
        throw e
      }
    }
)
