import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { currentVersion } from 'appVersion'
import isEqual from 'lodash/isEqual'
import { func } from 'prop-types'

import AppVersionContext from 'context/AppVersionContext'

import {
  setAddress,
  setLanguage,
  setNotificationPermission,
  setTrackingPermission,
  setWorkHours
} from 'store/auth/actions'

import AddressSheet from 'components/AddressSheet'
import Announcement from 'components/Announcement'
import Button from 'components/button/Button'
import Container from 'components/Container'
import Icon from 'components/Icon'
import LanguagePicker, { languages } from 'components/LanguagePicker'
import MobileHeader from 'components/MobileHeader'
import SmallCaps from 'components/SmallCaps'
import TableRow, { ActionableTableRow, LinkableTableRow, TouchableTableRow } from 'components/tableRow/TableRow'
import TableRowGroup from 'components/tableRow/TableRowGroup'
import Toggle from 'components/Toggle'
import WorkHoursSheet from 'components/WorkHoursSheet'

import { isAutoSchedulingEnabled } from 'utils/featureFlags'
import { getAddressString } from 'utils/helpers'

import { blue } from 'styles/colors'

const Settings = ({ setTrackingPermission, setNotificationPermission, setLanguage, setAddress, setWorkHours }) => {
  const employee = useSelector(({ auth }) => auth.user)
  const {
    notificationsAllowed,
    trackingAllowed,
    preferredLanguage,
    isTeamLead,
    isBam,
    address,
    workHours,
    latitude,
    longitude,
    updateSchedule = false
  } = employee
  const [languagePickerVisible, setLanguagePickerVisible] = useState(false)
  const [addressSheetVisible, setAddressSheetVisible] = useState(false)
  const [workHoursSheetVisible, setWorkHoursSheetVisible] = useState(false)
  const [userUpdatedSchedule, setUserUpdatedSchedule] = useState(false)
  const { refreshCacheAndReload, isStaleVersion, loading: appVersionLoading } = useContext(AppVersionContext)
  const autoSchedulingEnabled = isAutoSchedulingEnabled(employee)

  const toggleLanguagePicker = () => {
    setLanguagePickerVisible(!languagePickerVisible)
  }

  const setUserLanguage = (selection) => {
    if (selection !== preferredLanguage) setLanguage(selection)
    toggleLanguagePicker()
  }

  const toggleAddressSheet = () => {
    setAddressSheetVisible(!addressSheetVisible)
  }

  const setUserAddress = ({ latitude: newLatitude, longitude: newLongitude, ...newAddress }) => {
    if (!isEqual(newAddress.address, address) || newLatitude !== latitude || newLongitude !== longitude) {
      setAddress({ ...newAddress, latitude: newLatitude, longitude: newLongitude })
      if (autoSchedulingEnabled) setUserUpdatedSchedule(true)
    }
    toggleAddressSheet()
  }

  const toggleWorkHoursSheet = () => {
    setWorkHoursSheetVisible(!workHoursSheetVisible)
  }

  const setUserWorkHours = (userWorkHours) => {
    if (!isEqual(userWorkHours, workHours)) {
      setWorkHours(userWorkHours)
      if (autoSchedulingEnabled) setUserUpdatedSchedule(true)
    }
    toggleWorkHoursSheet()
  }

  useEffect(() => {
    setUserUpdatedSchedule(updateSchedule)
  }, [updateSchedule])

  return (
    <>
      <MobileHeader isNavOnly />
      <div className="mt-16 w-full">
        <Container>
          {userUpdatedSchedule && (
            <Announcement
              icon="notification"
              text={'You made changes to your settings and schedule, your schedule will be regenerated tonight.'}
              color={blue}
              link="/schedule"
            />
          )}
          <TableRowGroup heading="Settings">
            <ActionableTableRow
              onClick={toggleWorkHoursSheet}
              AddOn={workHours && <span>{`${workHours.start} - ${workHours.end}`}</span>}
            >
              <span>Work Hours</span>
            </ActionableTableRow>
            <ActionableTableRow onClick={toggleAddressSheet} AddOn={getAddressString(address)}>
              <span>Address</span>
            </ActionableTableRow>
            <TableRow>
              <span>Notifications</span>
              <Toggle checked={notificationsAllowed} onClick={() => setNotificationPermission(!notificationsAllowed)} />
            </TableRow>
            <TableRow>
              <span>Location settings</span>
              <Toggle checked={trackingAllowed} onClick={() => setTrackingPermission(!trackingAllowed)} />
            </TableRow>
            <ActionableTableRow
              onClick={toggleLanguagePicker}
              AddOn={<span>{languages.find((l) => l.name === preferredLanguage).label}</span>}
            >
              <span>Preferred Language</span>
            </ActionableTableRow>
            <TouchableTableRow link="/settings/last-sync">
              <span>Last sync time</span>
            </TouchableTableRow>
            <TouchableTableRow link="/settings/mileage">
              <span>Mileage</span>
            </TouchableTableRow>
          </TableRowGroup>

          {(isTeamLead || isBam) && (
            <TableRowGroup heading="Management">
              <TouchableTableRow link="/delegations">
                <span>Delegations</span>
              </TouchableTableRow>
            </TableRowGroup>
          )}

          <TableRowGroup heading="Help">
            <LinkableTableRow href="mailto:narrosupport@bat.com">
              <span>Report a problem</span>
            </LinkableTableRow>
            <TableRow>
              <SmallCaps>v{currentVersion}</SmallCaps>
              <Button
                primary={isStaleVersion}
                tertiary={!isStaleVersion}
                small
                onClick={refreshCacheAndReload}
                isLoading={appVersionLoading}
              >
                <Icon icon="refresh" />
              </Button>
            </TableRow>
          </TableRowGroup>

          {/*
        KEEP JUST IN CASE
        <TableRowGroup>
          <ActionableTableRow centered onClick={requestLogout}>
            <span style={{ color: primaryColor }}>{ongoingCall ? 'Cancel call and log out' : 'Log out'}</span>
          </ActionableTableRow>
        </TableRowGroup>
        */}
        </Container>
        <LanguagePicker
          visible={languagePickerVisible}
          setLanguage={setUserLanguage}
          preferredLanguage={preferredLanguage}
        />
        <WorkHoursSheet
          visible={workHoursSheetVisible}
          setWorkHours={(update) => setUserWorkHours({ ...update, updateSchedule: autoSchedulingEnabled })}
          workHours={workHours}
          closeWorkHourSheet={toggleWorkHoursSheet}
          autoSchedulingEnabled={autoSchedulingEnabled}
        />
        <AddressSheet
          visible={addressSheetVisible}
          setUserAddress={(update) => setUserAddress({ ...update, updateSchedule: autoSchedulingEnabled })}
          userAddress={address}
          latLong={{ latitude, longitude }}
          toggleAddressVisible={toggleAddressSheet}
          autoSchedulingEnabled={autoSchedulingEnabled}
        />
      </div>
    </>
  )
}

Settings.propTypes = {
  setTrackingPermission: func.isRequired,
  setNotificationPermission: func.isRequired,
  setLanguage: func.isRequired,
  setAddress: func.isRequired,
  setWorkHours: func.isRequired
}

const mapActionCreators = {
  setTrackingPermission,
  setNotificationPermission,
  setLanguage,
  setAddress,
  setWorkHours
}

export default connect(null, mapActionCreators)(Settings)
