import React, { useState } from 'react'
import { stripUnit } from 'polished'
import { bool, string } from 'prop-types'
import styled from 'styled-components'

import { borderColor, calendarEventColors, grey, white } from 'styles/colors'
import { borderRadius, boxShadow, square } from 'styles/global'
import * as spacing from 'styles/spacing'
import { smallFontSize } from 'styles/typography'

import Button from '../button/Button'

const Container = styled.div`
  position: relative;
  // z-index: 99;
  // margin-left: ${spacing.small};
`

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 220px;
  background-color: ${white};
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  padding: ${spacing.medium};
  margin-top: ${spacing.tiny};
  display: grid;
  grid-gap: ${stripUnit(spacing.small) * 1.5 + 'px'};
  ${boxShadow};
  z-index: 99;
`

const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${smallFontSize};
  color: ${grey};
`

const LegendItemSwatch = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: ${spacing.small};
  ${square('12px')};
`

const LegendItemSwatchLine = styled.div`
  background-color: ${(props) => props.color};
  width: 12px;
  height: 3px;
  margin-right: ${spacing.small};
`

const LegendItem = ({ name, color, line = false }) => (
  <LegendItemContainer>
    {line ? <LegendItemSwatchLine color={color} /> : <LegendItemSwatch color={color} />}
    {name}
  </LegendItemContainer>
)

LegendItem.propTypes = {
  name: string.isRequired,
  color: string.isRequired,
  line: bool
}

const LegendDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <Button onClick={() => setIsOpen(!isOpen)} icon="legend" secondary>
        Legend
      </Button>
      {isOpen && (
        <Dropdown>
          <LegendItem name="App Scheduled call" color={calendarEventColors.generatedCalls} />
          <LegendItem name="TM Scheduled call" color={calendarEventColors.future} />
          <LegendItem name="Missed call" color={calendarEventColors.missed} />
          <LegendItem name="App-logged in-person" color={calendarEventColors['in-personFulfilled']} />
          <LegendItem name="App-logged telemarketing" color={calendarEventColors.telemarketingFulfilled} />
          <LegendItem name="TM-logged in-person" color={calendarEventColors['in-personPast']} />
          <LegendItem name="TM-logged telemarketing" color={calendarEventColors.telemarketingPast} />
          <LegendItem name="OOT" color={calendarEventColors['time-off']} />
          <LegendItem name="TM rescheduled" color={calendarEventColors.future} line />
          <LegendItem name="Out of territory" color={calendarEventColors.adhoc} line />
        </Dropdown>
      )}
    </Container>
  )
}

export default LegendDropdown
