import { flatten, groupBy, isEmpty, omit, sortBy, sumBy } from 'lodash'
import moment from 'moment'
import { denormalize } from 'normalizr'
import { createSelector } from 'reselect'

import { preferredLanguage } from 'store/auth/selectors'
import { customerAssortment } from 'store/customers/selectors'
import { currentTerritory, territorySellInPrograms } from 'store/territories/selectors'

import { sellInProgram as sellInProgramSchema } from './schema'

const fullState = (state) => state
const allSellInPrograms = (state) => omit(state.sellInPrograms, '_persist')
const allScopes = (state) => omit(state.scopes, '_persist')
const allProducts = (state) => omit(state.products, '_persist')

export const sellInProgramIdFromProps = (state, props) => props.sellInProgramId
export const customerIdFromProps = (state, props) => props.customerId

export const sellInProgramFromProps = createSelector(
  sellInProgramIdFromProps,
  allSellInPrograms,
  (sellInProgramId, sellInPrograms) => {
    if (!sellInProgramId) return null
    return sellInPrograms[sellInProgramId]
  }
)

export const sellInProgramForTerritory = createSelector(
  sellInProgramFromProps,
  currentTerritory,
  (sellInProgram, territory) => {
    if (!sellInProgram) return null
    const targetCustomerIds =
      (sellInProgram.targetsByTerritory && sellInProgram.targetsByTerritory[territory?.id]) || territory?.customers
    if (!targetCustomerIds?.length) return null
    const targets = sellInProgram.targets.filter(({ customerId }) => targetCustomerIds.includes(customerId))
    const exceptions = targets.reduce((acc, { exceptions }) => acc.concat(exceptions || []), [])
    return {
      ...sellInProgram,
      targets,
      exceptions
    }
  }
)

export const fullSellInProgram = createSelector(sellInProgramForTerritory, fullState, (sellInProgram, state) => {
  if (!sellInProgram) return null
  return denormalize(sellInProgram, sellInProgramSchema, state)
})

export const isVolumeSellInProgram = createSelector(
  sellInProgramFromProps,
  (sellInProgram) => sellInProgram?.type?.toLowerCase() === 'volume'
)

const calculateBrandTotals = (skus, language, acceptedSkus) => {
  const brandGroupedSkus = groupBy(skus, `product.${language}Brand`)
  const brandGroupedAcceptedSkus = acceptedSkus && groupBy(acceptedSkus, `product.${language}Brand`)
  const brandTotals = Object.entries(brandGroupedSkus).map(([brand, skus]) => {
    const finalQtySkus = brandGroupedAcceptedSkus ? brandGroupedAcceptedSkus[brand] : skus
    return {
      brand,
      suggestedQty: sumBy(skus, 'suggestedQty') || 0,
      finalQty: finalQtySkus ? sumBy(finalQtySkus, 'finalQty') || 0 : 0
    }
  })
  return sortBy(brandTotals, ({ brand }) => brand.toLowerCase())
}
export const sellInProgramBrandTotals = createSelector(
  fullSellInProgram,
  preferredLanguage,
  (sellInProgram, preferredLanguage) => {
    if (sellInProgram?.type?.toLowerCase() !== 'volume') return {}
    const allSkus = flatten(sellInProgram.targets.map(({ skus }) => skus))
    const totalSuggestedQty = sumBy(allSkus, 'suggestedQty')
    const acceptedSkus = flatten(
      sellInProgram.targets.filter(({ status }) => status === 'accepted').map(({ skus }) => skus)
    )
    const totalFinalQuantity = sumBy(acceptedSkus, 'finalQty')
    const brandTotals = calculateBrandTotals(allSkus, preferredLanguage, acceptedSkus)
    return { totalSuggestedQty, totalFinalQuantity, brandTotals }
  }
)

const createSellInSkuList = ({ sellInProgram, skus, assortment, language }) => {
  if (!sellInProgram?.fullAssortment) return skus
  const { defaultMinQty, defaultMaxQty } = sellInProgram
  const filtered = assortment.filter(({ id }) => !skus.some(({ productId }) => id === productId))
  const allSkus = skus
    .map(({ maxQty, minQty, suggestedQty, isAssigned, ...s }) => ({
      maxQty: isAssigned ? maxQty : defaultMaxQty,
      minQty: isAssigned ? minQty : defaultMinQty,
      suggestedQty: isAssigned ? suggestedQty : 0,
      isAssigned,
      isAssortment: false, // products that were not assigned but may have had a finalQty added in a previous edit would be part of the skus.
      ...s
    }))
    .concat(
      filtered.map((p) => ({
        productId: p.id,
        suggestedQty: 0,
        minQty: defaultMinQty,
        maxQty: defaultMaxQty,
        product: p,
        finalQty: null,
        isAssigned: false,
        isAssortment: true
      }))
    )
  return sortBy(allSkus, ({ product }) => product[`${language}Name`].toLowerCase())
}

export const sellInProgramTarget = createSelector(
  fullSellInProgram,
  preferredLanguage,
  customerIdFromProps,
  customerAssortment,
  allProducts,
  (fullSellInProgram, language, customerId, customerAssortment, products) => {
    // console.log({ fullSellInProgram, language, customerId, products, customerAssortment })
    if (!fullSellInProgram || !customerId) return {}
    const { targets, ...sellInProgram } = fullSellInProgram
    const sellInTarget = targets.find((t) => t.customerId === +customerId)
    if (!sellInTarget) return {}
    if (sellInTarget && sellInProgram.type.toLowerCase() === 'volume') {
      sellInTarget.skus = sellInTarget.skus.map((sku) => {
        if (sku.product) return sku
        const product = Object.values(products).find(({ id }) => id === sku.productId)
        return {
          product,
          ...sku
        }
      })
      const skuList = createSellInSkuList({
        sellInProgram,
        skus: sellInTarget.skus,
        assortment: customerAssortment,
        language
      })
      sellInTarget.skus = skuList
      sellInTarget.suggestedQty = sumBy(sellInTarget.skus, 'suggestedQty')
      sellInTarget.finalQty = sumBy(sellInTarget.skus, 'finalQty')
      sellInTarget.brandTotals = calculateBrandTotals(sellInTarget.skus, language)
    }
    return { sellInTarget, sellInProgram }
  }
)

const exceptionFromProps = (state, props) => props.exception
export const exceptionSkuProducts = createSelector(
  exceptionFromProps,
  allScopes,
  preferredLanguage,
  (exception, products, preferredLanguage) => {
    if (isEmpty(exception?.skus)) return []
    return Object.entries(exception.skus).map(([sku, maxMin]) => {
      const product = products[sku.replace(/[^0-9.]/g, '')]
      const name = product ? product[`${preferredLanguage}Name`] : `SKU ${sku.replace(/[^0-9.]/g, '')}`
      return { name, ...maxMin }
    })
  }
)

const exceptionIdFromProps = (state, props) => props.exceptionId
export const exceptionFromId = createSelector(
  sellInProgramTarget,
  exceptionIdFromProps,
  (programTarget, exceptionId) => {
    return programTarget.sellInTarget.exceptions.find((exception) => exception.id === +exceptionId)
  }
)

const programIsActive = (program) =>
  moment(Date.now()).isBetween(moment(program.startDate), moment(program.endDate), 'day', '[]')
export const activeTerritorySellInPrograms = createSelector(territorySellInPrograms, (programs) =>
  programs?.length ? programs.filter((program) => programIsActive(program)) : []
)
