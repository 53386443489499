import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import { func, object } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchGeographyInventory, fetchTableFilters } from 'store/inventory/actions'
import { getCurrentFilters, getCurrentGeography } from 'store/inventory/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, createOptions, getErrorMessage } from 'utils/helpers'

import GeographyToggle from '../GeographyToggle'

import InventoryCaptureContext from './InventoryCaptureContext'

const COLS = [
  {
    field: 'geo',
    headerName: 'Region'
  },
  {
    field: 'oos',
    headerName: 'OOS'
  },
  {
    field: 'missedsales',
    headerName: 'Missed pack sales'
  },
  {
    field: 'invcap',
    headerName: 'Inventory capture'
  },
  {
    field: 'trend',
    headerName: 'OOS Trend L13'
  }
]

const Geography = ({ span, fetchGeographyInventory, fetchTableFilters }) => {
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [rows, setRows] = useState([])
  const [ownership, setOwnership] = useState('all')
  const [brand, setBrand] = useState('all')
  const [material, setMaterial] = useState('all')

  const { currentProductType: productType } = useContext(SectorContext)
  const { timeframe } = useContext(InventoryCaptureContext)
  const { sectorId, sectorType } = useParams()
  COLS[0].headerName = capitalize(geography)

  const filtersDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.FILTERS, {
    sectorType,
    sectorId,
    productType
  })
  const isFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filtersDataKey }))
  const filters = useSelector((state) => getCurrentFilters(state))

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    ownership,
    brand,
    material,
    productType,
    timeframe
  })
  const isRegionLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))
  const geographies = useSelector((state) => getCurrentGeography(state))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchTableFilters({ dataKey: filtersDataKey, sectorType, sectorId, productType })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId])

  useEffect(() => {
    fetchGeographyInventory({
      dataKey: geographyDataKey,
      sectorType,
      sectorId,
      geography,
      ownership,
      brand,
      material,
      productType,
      timeframe
    })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId, geography, ownership, material, brand, productType, timeframe])

  useEffect(() => {
    if (isRegionLoading || isFiltersLoading) return
    const newSector = geographies[`${geography}-${ownership}-${brand}-${material}-${productType}`]
    if (!newSector) return
    const newRows = newSector.map((row) => {
      return {
        geo: row.name,
        oos: <DataCompare last={row.oos} variation={row.oosDiff} withTimeframe timeframe={timeframe} isPercent />,
        missedsales: (
          <DataCompare
            last={row.missedPacks}
            variation={row.missedPacksDiff}
            withTimeframe
            timeframe={timeframe}
            isPercent
          />
        ),
        invcap: (
          <DataCompare
            last={row.inventoryCapture}
            variation={row.inventoryCaptureDiff}
            isPercent
            withTimeframe
            timeframe={timeframe}
          />
        ),
        trend: <DataGraph color="#53CCF8" data={row.trendedOos.map((i) => ({ d: i.data }))} />
      }
    })
    setRows(newRows)
  }, [geographies, geography, ownership, brand, material, productType, isRegionLoading, isFiltersLoading])

  const getContent = () => {
    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    if (isRegionLoading) return <WrappedSpinner icon="spinner" />

    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }

  if (isFiltersLoading)
    return (
      <Card span={span} title="Region performance">
        <WrappedSpinner icon="spinner" />
      </Card>
    )

  const allDescriptionLabel = productType === 'fmc' ? 'All BVLS' : 'All Material description'
  const allBrandLabel = productType === 'fmc' ? 'All Brands' : 'All Brand variants'

  const ownershipOptions = [{ label: 'All Ownership Types', value: 'all' }].concat(
    createOptions(filters[productType]?.ownershipTypes || [])
  )
  const brandsOptions = [{ label: allBrandLabel, value: 'all' }].concat(
    createOptions(filters[productType]?.brands || [])
  )
  const materialOptions = [{ label: allDescriptionLabel, value: 'all' }].concat(
    createOptions(filters[productType]?.materials || [])
  )

  return (
    <Card
      title={`${capitalize(geography)} performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown
          key={0}
          options={ownershipOptions}
          value={ownership}
          onChange={(e) => setOwnership(e.target.value)}
        />,
        <Dropdown key={1} options={brandsOptions} value={brand} onChange={(e) => setBrand(e.target.value)} />,
        <Dropdown key={2} options={materialOptions} value={material} onChange={(e) => setMaterial(e.target.value)} />,
        <GeographyToggle key={3} includeBrand includeOwnership geography={geography} setGeography={setGeography} />
      ]}
      hideOnSmallScreens={true}
    >
      {getContent()}
    </Card>
  )
}

Geography.propTypes = {
  span: object,
  fetchGeographyInventory: func,
  fetchTableFilters: func
}

export default connect(null, {
  fetchGeographyInventory,
  fetchTableFilters
})(Geography)
