import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS, PLANNING_SELECTOR_ACTIONS } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS } from 'utils/helpers'

import * as api from './endpoints'

export const fetchDistroTrended = createAction(
  'Fetch Distro Trended Data',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchDistroTrended(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].distroTrended
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchDistroBrand = createAction(
  'Fetch Distro Brand Data',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchDistroBrand(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].distroBrand
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchDistroMaterial = createAction(
  'Fetch Distro Material Data',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchDistroMaterial(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].distroMaterial
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchDistroGeographyData = createAction(
  'Fetch Distro Table Data',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchDistroGeography(props)
      const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorType].distro.geography
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)
