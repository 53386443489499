import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object } from 'prop-types'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchStoreFootprint } from 'store/priceCaptureCompliance/actions'
import { getStoreFootprint } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const COLS = [
  {
    field: 'y',
    headerName: ''
  },
  {
    field: 'pos',
    headerName: 'POS'
  },
  {
    field: 'ind',
    headerName: 'IND'
  },
  {
    field: 'vs',
    headerName: 'VS'
  }
]

const StoreFootprint = ({ span, fetchStoreFootprint }) => {
  const [error, setError] = useState()
  const { sectorType, sectorId } = useParams()

  const storeFootprint = useSelector((state) => getStoreFootprint(state))

  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.STORE_FOOTPRINT, {
    sectorType,
    sectorId
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  useEffect(() => {
    fetchStoreFootprint({ dataKey, sectorType, sectorId })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId])

  const getContent = () => {
    if (sectorType === 'customer') return <EmptyState title="No relevant data" />

    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    const rows = [
      {
        y: 'Stores updated',
        pos: storeFootprint.POS.storesUpdated.toLocaleString('default'),
        ind: storeFootprint.IND.storesUpdated.toLocaleString('default'),
        vs: storeFootprint.VS.storesUpdated.toLocaleString('default')
      },
      {
        y: 'Stores remaining',
        pos: storeFootprint.POS.storesRemaining.toLocaleString('default'),
        ind: storeFootprint.IND.storesRemaining.toLocaleString('default'),
        vs: storeFootprint.VS.storesRemaining.toLocaleString('default')
      },
      {
        y: 'Stores updated (%)',
        pos: `${storeFootprint.POS.storesPercent.toLocaleString('default')}%`,
        ind: `${storeFootprint.IND.storesPercent.toLocaleString('default')}%`,
        vs: `${storeFootprint.VS.storesPercent.toLocaleString('default')}%`
      }
    ]

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }
  return (
    <Card title="Store footprint" span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

StoreFootprint.propTypes = {
  span: object,
  fetchStoreFootprint: func
}

export default connect(null, {
  fetchStoreFootprint
})(StoreFootprint)
