import React from 'react'
import { Provider } from 'react-redux'
import { Routes } from 'react-router'
import { BrowserRouter as Router, Navigate, Route } from 'react-router-dom'
import ActionsLayout from 'layouts/ActionsLayout'
import { ManagerExceptionApprovalLayout } from 'layouts/ExceptionApprovalLayout'
import { ManagerOrderApproval, RetailerOrderApproval } from 'layouts/OrderApprovalLayout'
import ProtectedLayout from 'layouts/ProtectedLayout'
import PublicLayout from 'layouts/PublicLayout'
import SectorLayout from 'layouts/SectorLayout'
import { object } from 'prop-types'
import { PersistGate } from 'redux-persist/es/integration/react'
import FAQ from 'views/about/FAQ'
import LastSync from 'views/about/LastSync'
import Mileage from 'views/about/Mileage'
import Privacy from 'views/about/Privacy'
import Terms from 'views/about/Terms'
import ActionIndex from 'views/actions'
import CommentActionSwitcher from 'views/actions/comments/CommentActionSwitcher'
import ExtraFundSwitcher from 'views/actions/extraFunds'
import IntelActionSwitcher from 'views/actions/intel'
import OrderActionSwitcher from 'views/actions/orders'
import PricingActionSwitcher from 'views/actions/pricing/PricingActionSwitcher'
import SellInActionSwitcher from 'views/actions/sell-in/SellInActionSwitcher'
import SellInProgramSwitcher from 'views/actions/sell-in/SellInProgramSwitcher'
import SurveyActionSwitcher from 'views/actions/surveys/SurveyActionSwitcher'
import SurveysActionSwitcher from 'views/actions/surveys/SurveysActionSwitcher'
import AmplifyIndex from 'views/Amplify'
import AmplifyDistroLayout from 'views/Amplify/Distro'
import AmplifyExtraHubLayout from 'views/Amplify/ExtraHub'
import InventoryCaptureLayout from 'views/Amplify/InventoryCaptureOos'
import AmplifyPriceCaptureLayout from 'views/Amplify/PriceCapture'
import AmplifyPriceComplianceLayout from 'views/Amplify/PriceCompliance'
import AmplifySellinLayout from 'views/Amplify/Sellin'
import AmplifySelloutLayout from 'views/Amplify/Sellout'
import ForgotPassword from 'views/auth/ForgotPassword'
import Login from 'views/auth/Login'
import PasswordResetRequested from 'views/auth/PasswordResetRequested'
import ResetPassword from 'views/auth/ResetPassword'
import RetailerVerification from 'views/auth/RetailerVerification'
import Signup from 'views/auth/SignUp'
import SignupConfirmationRequired from 'views/auth/SignupConfirmationRequired'
import VerifySignup from 'views/auth/VerifySignup'
import CreateSAQ from 'views/CreateSAQ'
import PricingCard, { ThankYouCard } from 'views/Customer/Performance/PricingCard'
import SAQDetails from 'views/Customer/SAQDetails'
import Alerts from 'views/inbox/InboxAlerts'
import CreateIntel from 'views/intelForm/CreateIntel'
import EditIntel from 'views/intelForm/EditIntel'
import Payouts from 'views/Payouts'
import PlanLayout from 'views/plan'
import GoProgramCarton from 'views/programForm/GoProgramCarton'
import GoProgramException from 'views/programForm/GoProgramException'
import GoProgramPayment from 'views/programForm/GoProgramPayment'
import GoProgramTargets from 'views/programForm/GoProgramTargets'
import ViewGoProgram from 'views/programForm/ViewGoProgram'
import Schedule from 'views/Schedule'
import Settings from 'views/Settings'
import Autofill from 'views/SurveyForm/Autofill'

import { AppVersionProvider } from 'context/AppVersionContext'
import { LangProvider } from 'context/LangContext'
import { MediaProvider } from 'context/MediaContext'

import EmptyState from 'components/EmptyState'

import { isTCPEnabled } from 'utils/featureFlags'

import CloseLayout from '../layouts/CloseLayout'
import EvaluateLayout from '../layouts/EvaluateLayout'

import '../styles.css'

const App = ({ store, persistor }) => {
  const state = store.getState()
  const employee = state.auth.user
  const isCustomerService = employee && employee.groupCode === 'customerService'

  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        loading={<EmptyState isLoading title="Fetching..." subtitle="Just a moment while we fetch your data" />}
      >
        <Router>
          <LangProvider>
            <Routes>
              {/* TO KEEP, CHECK WITH BAT WHAT TO DO WITH RETAILER LAYOUT */}
              {/*
                <Route path="retailer">
                  <Route path=":customerId" element={<RetailerLayout />}>
                    <Route path="image-upload" element={<ImageUpload />} />
                    <Route path="pricing" element={<RetailerPricings />}>
                      <Route path="thank-you" element={<RetailerThankYou />} />
                      <Route path=":upc" element={<RetailerPricingCard />} />
                    </Route>
                  </Route>
                </Route>
              */}
              <Route path="/order-approval/:orderId" element={<ManagerOrderApproval />} />
              <Route path="/exception-approval/:exceptionId" element={<ManagerExceptionApprovalLayout />} />

              <Route path="retailer">
                <Route path="order-approval/:retailerOrderToken" element={<RetailerOrderApproval />} />
              </Route>

              <Route path="auth" element={<PublicLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="verify-signup/:registrationToken" element={<VerifySignup />} />
                <Route path="signup-requested" element={<SignupConfirmationRequired />} />
                <Route path="signup" element={<Signup />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="password-reset-requested" element={<PasswordResetRequested />} />
                <Route path="reset-password/:resetToken" element={<ResetPassword />} />
                <Route path="retailer/:target/:retailerId?" element={<RetailerVerification />} />
              </Route>

              <Route element={<ProtectedLayout />}>
                <Route path={':sectorType?/:sectorId?'} element={<SectorLayout />}>
                  {isCustomerService ? (
                    <Route path="*" index element={<Navigate to="actions" />} />
                  ) : (
                    <>
                      <Route path="*" index element={<Navigate to="/pace/plan" />} />
                      <Route path="pace" element={null}>
                        <Route path="plan" element={<PlanLayout />} />
                        <Route path="amplify">
                          <Route index element={<AmplifyIndex />} />
                          <Route path="sell-in" element={<AmplifySellinLayout />} />
                          <Route path="sell-out" element={<AmplifySelloutLayout />} />
                          <Route path="inventory" element={<InventoryCaptureLayout />} />
                          <Route path="distro" element={<AmplifyDistroLayout />} />
                          <Route path="price-capture" element={<AmplifyPriceCaptureLayout />} />
                          <Route path="price-compliance" element={<AmplifyPriceComplianceLayout />} />
                          <Route path="extra-hub" element={<AmplifyExtraHubLayout />} />
                        </Route>
                        <Route path="close" element={<CloseLayout />} />
                        <Route path="evaluate" element={<EvaluateLayout />} />
                      </Route>
                    </>
                  )}
                  <Route path="actions" element={<ActionsLayout />}>
                    <Route index element={<ActionIndex />} />
                    <Route path="pricing">
                      <Route index element={<PricingActionSwitcher />} />
                      <Route path=":upc" element={<PricingCard />} />
                      <Route path="thank-you" element={<ThankYouCard />} />
                    </Route>
                    <Route path="orders">
                      <Route index element={<OrderActionSwitcher />} />
                      <Route path=":orderId">
                        <Route index element={<SAQDetails />} />
                        <Route path="edit" element={<CreateSAQ isEdit />} />
                      </Route>
                      <Route path="create" element={<CreateSAQ />} />
                    </Route>
                    <Route path="comments" element={<CommentActionSwitcher />} />
                    <Route path="surveys">
                      <Route index element={<SurveysActionSwitcher />} />
                      <Route path=":surveyId">
                        <Route index element={<SurveyActionSwitcher />} />
                        <Route path="autofill" element={<Autofill />} />
                      </Route>
                    </Route>
                    <Route path="intel/*">
                      <Route path="create" index element={<CreateIntel />} />
                      <Route path="edit/:intelId" index element={<EditIntel />} />
                      <Route path="*" index element={<IntelActionSwitcher />} />
                    </Route>
                    <Route path="funds/*">
                      <Route path=":programId" element={<ViewGoProgram />} />
                      <Route path="exception/:programId" index element={<GoProgramException />} />
                      <Route path="payment/:programId" index element={<GoProgramPayment />} />
                      <Route path="target/:programId" index element={<GoProgramTargets />} />
                      <Route path="carton/:programId" index element={<GoProgramCarton />} />
                      <Route path="*" element={<ExtraFundSwitcher />} />
                    </Route>
                    <Route path="comments" element={null} />
                    <Route path="sell-in">
                      <Route index element={<SellInActionSwitcher />} />
                      <Route path=":programId" element={<SellInProgramSwitcher />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="payouts" element={<Payouts />} />
                {isTCPEnabled(state.auth.user) && <Route path="schedule" element={<Schedule />} />}
                <Route path="alerts" element={<Alerts />} />

                <Route path="settings">
                  <Route path="*" index element={<Settings />} />
                  <Route path="last-sync" element={<LastSync />} />
                  <Route path="mileage" element={<Mileage />} />
                </Route>

                <Route path="about">
                  <Route path="*" index element={<Navigate to="terms" />} />
                  <Route path="faq" element={<FAQ />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="privacy" element={<Privacy />} />
                </Route>
              </Route>
            </Routes>
          </LangProvider>
        </Router>
      </PersistGate>
    </Provider>
  )
}

App.propTypes = {
  store: object.isRequired,
  persistor: object.isRequired
}

const AppContainer = (props) => (
  <AppVersionProvider>
    <MediaProvider>
      <App {...props} />
    </MediaProvider>
  </AppVersionProvider>
)

export default AppContainer
