import Axios from 'axios'
import config from 'config'
import { store } from 'main'
import queryString from 'query-string'

import { requestLogout } from 'store/auth/actions'
import outbox from 'store/outbox'

import { imageMimeTypeToExtension } from 'utils/constants'

export const api = Axios.create({
  baseURL: config.apiEndpoint
})

function setInterceptor(apiInstance) {
  apiInstance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response) {
        const { data, config, status } = error.response
        if (data) {
          const { error: err, message } = error.response.data
          if (err) error.response.data.message = err
          if (message) error.response.data.error = message
        }
        const isLogoutRequest = config.method === 'delete' && config.url.endsWith('auth')
        if (status === 401 && !isLogoutRequest) {
          return store.dispatch(requestLogout())
        }
      }
      return Promise.reject(error)
    }
  )
}

setInterceptor(api)

export const registerSync = async (tag) => {
  if (!window.navigator.serviceWorker) return
  navigator.serviceWorker.ready.then(function (swRegistration) {
    return swRegistration.sync.register(tag)
  })
}

if (localStorage.getItem('token')) {
  api.defaults.headers.common.Authorization = localStorage.getItem('token')
  outbox.setItem('token', localStorage.getItem('token'))
}

export const setToken = async (token) => {
  localStorage.setItem('token', token)
  api.defaults.headers.common.Authorization = token
  await outbox.setItem('token', token)
}

export const setRetailerId = async (retailerId) => {
  sessionStorage.setItem('retailerId', retailerId)
  api.defaults.headers.common.Authorization = retailerId
}

export const clearToken = async () => {
  localStorage.removeItem('token')
  delete api.defaults.headers.common.Authorization
  await outbox.removeItem('token')
}

// IMAGE UPLOADING
export const getPresignedPostUrls = (newImages) => {
  const queryParams = {
    imgExtensions: newImages.map(({ type }) => imageMimeTypeToExtension[type] || type.split('/').pop()),
    now: Date.now()
  }

  return api.get(`/aws?${queryString.stringify(queryParams)}`)
}

export const uploadImage = async (url, data) => {
  try {
    await api.post(url, data, {
      transformRequest: (data, headers) => {
        delete headers.Authorization
        return data
      }
    })
  } catch (error) {
    console.error(error)
  }
} // this is a currently open axios bug. Instance headers are applied globally

export const uploadImagesGetKeys = async (newImages) => {
  if (!newImages || !newImages.length) return []

  try {
    const {
      data: { presignedPostUrls }
    } = await getPresignedPostUrls(newImages)

    return Promise.all(
      presignedPostUrls.map(async (ppu, idx) => {
        const { url, imageKey, fields } = ppu
        const form = new FormData()
        Object.entries(fields).forEach(([key, value]) => {
          form.append(key, value)
        })
        form.append('key', imageKey)
        form.append('file', newImages[idx])
        form.set('content-type', 'image/*')
        await uploadImage(url, form)
        return { key: imageKey, url }
      })
    )
  } catch (error) {
    console.error(error)
  }
}
