import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { func, object, string } from 'prop-types'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchStrategyCompliance } from 'store/priceCaptureCompliance/actions'
import { getStrategyCompliance } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const Brand = tw.span`w-36 text-base font-medium text-slate-900`

const CompletedAmount = tw.span`basis-16 text-right text-sm font-medium text-slate-900`

const TotalAmount = tw.span`basis-16 text-sm font-medium text-slate-500`

const BarContainer = tw.div`relative grow h-6 w-24 overflow-clip rounded bg-brand-50`

const Line = ({ brand, completed, total }) => {
  const CompletedPercent = ((completed / total) * 100).toFixed(1)
  const chartData = [{ name: brand, value: CompletedPercent }]

  return (
    <div className="flex items-center gap-4">
      <Brand>{brand}</Brand>
      <div className="flex w-full items-center space-x-4">
        <CompletedAmount>{CompletedPercent}%</CompletedAmount>
        <BarContainer>
          <div className="absolute h-full w-full">
            <ResponsiveContainer width={'100%'} height={'100%'}>
              <BarChart
                barCategoryGap="0%"
                layout="vertical"
                data={chartData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <XAxis type="number" hide domain={['dataMin', 100]} />
                <YAxis type="category" hide domain={['dataMin', 'dataMax']} />
                <Bar dataKey="value" stackId="a" fill="#4FAEE6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </BarContainer>
        <TotalAmount>of {total}</TotalAmount>
      </div>
    </div>
  )
}

Line.propTypes = {
  brand: string.isRequired,
  completed: string.isRequired,
  total: string.isRequired
}

const LineTable = ({ brands }) => {
  return (
    <div className="flex h-full w-full flex-col justify-between pt-8">
      {Object.entries(brands).map(([brand, value]) => (
        <Line key={brand} brand={brand} completed={value?.compliantStores} total={value?.totalStores} />
      ))}
    </div>
  )
}
LineTable.propTypes = {
  brands: object
}

const StrategyCompliance = ({ span, fetchStrategyCompliance }) => {
  const { sectorType, sectorId } = useParams()
  const { currentProductType: category } = useContext(SectorContext)
  const [error, setError] = useState()

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.STRATEGY_COMPLIANCE, {
    sectorType,
    sectorId,
    category
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const strategyCompliance = useSelector((state) => getStrategyCompliance(state, { category }))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchStrategyCompliance({ dataKey, sectorType, sectorId, category })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId, category])

  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (isEmpty(strategyCompliance)) return <EmptyState title="Nothing to display" />

    return <LineTable brands={strategyCompliance} />
  }
  return (
    <Card title="Strategy compliance" span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

StrategyCompliance.propTypes = {
  span: object.isRequired,
  fetchStrategyCompliance: func
}

export default connect(null, {
  fetchStrategyCompliance
})(StrategyCompliance)
