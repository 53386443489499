import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { submitPasswordResetRequest } from 'store/auth/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { isEmail, required } from 'utils/validators'

const errorMessages = {
  registrationRequired: (
    <GlobalAlert>
      You must <Link to="/auth/signup">sign up</Link> to access this site.
    </GlobalAlert>
  ),
  permissionDenied: <GlobalAlert>You do not have the necessary permissions to access this site.</GlobalAlert>,
  offlineError: <GlobalAlert>You cannot login without an internet connection.</GlobalAlert>,
  generic: <GlobalAlert>An error has occured. Please try again later.</GlobalAlert>
}

function ForgotPassword({ submitPasswordResetRequest }) {
  const navigate = useNavigate()
  const [error, setError] = useState()
  const { formatMessage } = useContext(LangContext)

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setError()
    try {
      await submitPasswordResetRequest(values, navigate)
    } catch ({ response }) {
      const passworResetError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[passworResetError] || errorMessages.generic)
    } finally {
      setSubmitting(false)
    }
  }

  const handleEmailChange = (value, setFieldValue) => {
    const email = value.trim().toLowerCase()
    setFieldValue('email', email)
  }

  const validateEmail = (value) => {
    return required(value) || isEmail(value)
  }

  return (
    <Formik initialValues={{ email: '' }} onSubmit={handleFormSubmit}>
      {({ isSubmitting, errors, setFieldValue }) => {
        return (
          <Form>
            <p>{formatMessage({ messageId: 'auth.resetPassword' })}</p>
            <Fieldset>
              <FieldsetItem>
                <Field
                  component={Input}
                  icon="mail"
                  placeholder="Email address"
                  name="email"
                  validate={validateEmail}
                  onChange={(event) => handleEmailChange(event.target.value, setFieldValue)}
                />
              </FieldsetItem>

              {error && <FieldsetItem>{error}</FieldsetItem>}

              <FieldsetItem>
                <Button primary full type="submit" disabled={Object.keys(errors).length > 0} isLoading={isSubmitting}>
                  {formatMessage({ messageId: 'send' })}
                </Button>
              </FieldsetItem>

              <FieldsetItem>
                <Link to="/auth/login">
                  <small>{formatMessage({ messageId: 'auth.returnToLogin' })}</small>
                </Link>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

ForgotPassword.propTypes = {
  submitPasswordResetRequest: func.isRequired
}

const mapActionCreators = {
  submitPasswordResetRequest
}

export default connect(null, mapActionCreators)(ForgotPassword)
