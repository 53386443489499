/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { connect, useSelector } from 'react-redux'
import config from 'config'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import moment from 'moment'
import { func, node } from 'prop-types'

import { CallDurationContextProvider } from 'context/CallDurationContext'
import GoogleMapsContext from 'context/GoogleMapsContext'

import { setCoords } from 'store/auth/actions'
import * as taskSelector from 'store/callTasks/selectors'
import { upsertCustomerCall } from 'store/customerCalls/actions'
import * as callSelector from 'store/customerCalls/selectors'

import ActiveCallDetails from 'components/schedule/ActiveCallDetails'
import CancelCallConfirmation from 'components/schedule/CancelCallConfirmation'
import EndOngoingCallWarning from 'components/schedule/EndOngoingCallWarning'
import ForcedCompleteSheet from 'components/schedule/ForcedCompleteSheet'
import MinifiedCall from 'components/schedule/MinifiedCall'
import StartCallSheet from 'components/schedule/StartCall'

import { CALL_CUSTOMER_ATTRS } from 'utils/constants'
import { calculatedDistance, getAddressString, getDeviceType } from 'utils/helpers'

// 1 PERMISSION_DENIED The acquisition of the geolocation information failed because the page didn't have the permission to do it.
// 2 POSITION_UNAVAILABLE  The acquisition of the geolocation failed because at least one internal source of position returned an internal error.
// 3 TIMEOUT The time allowed to acquire the geolocation was reached before the information was obtained.

const setUserOngoingCall = (ongoingCall) => {
  localStorage.setItem('ongoingCall', JSON.stringify(ongoingCall))
}

const locationPermissions = {
  1: 'denied',
  2: 'unavailable',
  3: 'timeout'
}

const ActiveCallContext = React.createContext()
export default ActiveCallContext

async function getCallLocation(setCoords) {
  if (navigator.permissions) {
    const permission = await navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
      return result.state
    })
    if (permission === 'denied') return Promise.resolve({ err: 'denied' })
    if (permission !== 'granted') return Promise.resolve({ err: permission })
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        const {
          coords: { latitude, longitude, accuracy },
          timestamp
        } = res
        setCoords({ coords: { latitude, longitude }, timestamp, accuracy })
        resolve({ coords: { latitude, longitude }, timestamp, accuracy })
      },
      (error) => {
        resolve({ error })
      },
      { enableHighAccuracy: true, maximumAge: 60000, timeout: 30000 }
    )
  })
}

const UnconnectedActiveCallProvider = ({ children, upsertCustomerCall, toggleVisibleApptDetails, setCoords }) => {
  const employee = useSelector((state) => state.auth.user)
  const allCalls = useSelector((state) => callSelector.allCustomerCalls(state))
  const tasksByOutletSubtype = useSelector((state) => taskSelector.tasksByOutletSubtype(state))
  const coords = useSelector((state) => state.auth.coords || {})

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])
  const debouncedUpsertCustomerCall = useMemo(
    () => debounce(upsertCustomerCall, 500, { leading: true, trailing: false }),
    [upsertCustomerCall]
  )

  const employeeTimezone = employee?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

  const callIsCompleted = (call) => {
    const actualCall = allCalls.find(({ id }) => id === call.id)
    return actualCall && actualCall.callEnd
  }

  // To fix NOD-1034 and NOD-1012 and other ongoing call issues
  const getOrClearOngoingCall = () => {
    const ongoingCallFromLocalStorage =
      localStorage.getItem('ongoingCall') && JSON.parse(localStorage.getItem('ongoingCall'))
    if (isEmpty(ongoingCallFromLocalStorage)) return null
    const { ongoingCall: call } = ongoingCallFromLocalStorage
    // We want to remove the call if it was started yesterday or earlier
    if (moment(call.callStart).diff(moment(), 'day') < 0) {
      setUserOngoingCall(null)
      return null
    }
    // Checking if we have an actual call that's completed
    const ongoingCallCompleted = callIsCompleted(call)
    if (ongoingCallCompleted) {
      setUserOngoingCall(null)
      return null
    }
    return ongoingCallFromLocalStorage
  }

  const { getDrivingDistance } = useContext(GoogleMapsContext)
  const storageOngoingCall = getOrClearOngoingCall()
  // Call starting
  const [callToStart, setCallToStart] = useState(null)
  const [startCallScreenVisible, setStartCallScreenVisible] = useState(false)
  const [inPersonAllowed, setInPersonAllowed] = useState(employee.callRadiusExempt)
  const [loadingLocation, setLoadingLocation] = useState(false)
  const [inPersonCallLocationError, setInPersonCallLocationError] = useState()
  const [currentCallWarningCustomer, setCurrentCallWarningCustomer] = useState()

  const [ongoingCall, setOngoingCall] = useState(storageOngoingCall?.ongoingCall || null)
  const [callLocation, setCallLocation] = useState(null)

  const [kms, setKms] = useState(storageOngoingCall?.kms?.toString() || null)
  const [suggestedKms, setSuggestedKms] = useState(storageOngoingCall?.suggestedKms || null)
  const [note, setNote] = useState(storageOngoingCall?.note || '')
  const [completedTasks, setCompletedTasks] = useState(storageOngoingCall?.completedTasks || [])

  const [forcedComplete, setForcedComplete] = useState()
  const [confirmCancelCallVisible, setConfirmCancelCallVisible] = useState(false)
  const [isMinimized, setIsMinimized] = useState(storageOngoingCall?.isMinimized || false)

  const callType = useMemo(() => {
    return ongoingCall?.callType || null
  }, [ongoingCall?.callType])

  const callStartTime = useMemo(() => {
    return ongoingCall?.callStart ? new Date(ongoingCall.callStart).valueOf() : null
  }, [ongoingCall?.callStart])

  const getDistanceToCustomer = (customer, coords, accuracy = 0) => {
    const distanceInKms = calculatedDistance([customer.latitude, customer.longitude], coords)
    const accuracyInKMetres = accuracy / 1000
    return Math.max(distanceInKms - accuracyInKMetres, 0)
  }

  const checkCoordsWithinRadius = (customer, coords, accuracy = 0) => {
    return getDistanceToCustomer(customer, coords, accuracy) <= 1.51 // kms
  }

  const geolockRequired = isMobile || config.geolockEnabledForAll

  const verifyCallLocation = async () => {
    setInPersonAllowed(false)
    setInPersonCallLocationError()
    setLoadingLocation(false)
    if (employee.callRadiusExempt || !geolockRequired) {
      setInPersonAllowed(true)
      return
    }
    if (!employee.trackingAllowed) {
      setInPersonAllowed(false)
      setInPersonCallLocationError('denied')
      return
    }
    if (callToStart?.customer && (!callToStart?.customer?.latitude || !callToStart?.customer?.longitude)) {
      setInPersonAllowed(true)
      setInPersonCallLocationError('unavailable')
      return
    }

    if (callToStart?.customer) {
      setLoadingLocation(true)
      if (coords?.coords) {
        if (geolockRequired) {
          const coordsWithinRadius = checkCoordsWithinRadius(callToStart.customer, coords.coords, coords.accuracy)
          if (!coordsWithinRadius) {
            setInPersonCallLocationError('outsideRadius')
          }
          setInPersonAllowed(coordsWithinRadius)
        }
        setCallLocation(coords)
        setLoadingLocation(false)
      } else {
        getCallLocation(setCoords)
          .then(async (location) => {
            if (mounted?.current) {
              if (location.error) {
                const { error } = location
                const err = locationPermissions[error.code]
                setInPersonAllowed(err !== 'denied')
                setInPersonCallLocationError(JSON.stringify(error))
                setLoadingLocation(false)
              } else {
                if (geolockRequired) {
                  const isWithinCallRadius = checkCoordsWithinRadius(
                    callToStart.customer,
                    location.coords,
                    location.accuracy
                  )
                  if (!isWithinCallRadius) {
                    setInPersonCallLocationError('outsideRadius')
                  }
                  setInPersonAllowed(isWithinCallRadius)
                }
                setCallLocation(location)
                setLoadingLocation(false)
              }
            }
          })
          .catch((err) => {
            console.log(err)
            setLoadingLocation(false)
          })
      }
    }
  }

  useEffect(() => {
    if (!callStartTime && startCallScreenVisible) {
      verifyCallLocation()
    }
    if (!startCallScreenVisible) {
      setCallToStart(null)
      setCallLocation(null)
      setLoadingLocation(false)
      setInPersonCallLocationError(null)
      setInPersonAllowed(false)
    }
  }, [startCallScreenVisible, callStartTime])

  useEffect(() => {
    if (callType === 'in-person' && !employee.callRadiusExempt && ongoingCall && coords?.coords && geolockRequired) {
      const isWithinCallRadius = checkCoordsWithinRadius(ongoingCall.customer, coords.coords, coords.accuracy)
      if (!isWithinCallRadius) {
        completeCall({ forced: true })
        setForcedComplete({
          kms: getDistanceToCustomer(ongoingCall.customer, coords.coords, coords.accuracy),
          customer: ongoingCall?.customer?.name
        })
      }
    }
  }, [
    coords.timestamp,
    ongoingCall?.customer?.id,
    employee?.callRadiusExempt,
    callType,
    ongoingCall,
    coords?.coords,
    coords?.accuracy,
    checkCoordsWithinRadius
  ])

  const setUserOngoingDebounce = debounce(
    (ongoingCallInfo) => {
      setUserOngoingCall(ongoingCallInfo)
    },
    500,
    { trailing: true }
  )

  const setUserOngoingCallCallback = useCallback(setUserOngoingDebounce, [setUserOngoingCall, setUserOngoingDebounce])

  const saveCallToLocalStorage = () => {
    const { customer, ...call } = ongoingCall
    const ongoingCallInfo = {
      ongoingCall: {
        ...call,
        customer: pick(customer, CALL_CUSTOMER_ATTRS)
      },
      kms,
      suggestedKms,
      note,
      completedTasks,
      isMinimized
    }
    setUserOngoingCallCallback(ongoingCallInfo)
  }

  const removeCallFromLocalStorage = () => {
    setUserOngoingDebounce.cancel()
    setUserOngoingCall(null)
  }

  useEffect(() => {
    if (!isEmpty(ongoingCall) && callStartTime) saveCallToLocalStorage()
  }, [isMinimized, kms, note, completedTasks, callStartTime])

  const showStartCallScreen = async ({ call, callId, customerId }) => {
    const callToStartToSet = callId ? allCalls.find(({ id }) => id === callId) : {}
    setCallToStart({
      customerId,
      ...(call || null),
      ...callToStartToSet
    })

    setStartCallScreenVisible(true)
  }

  const endCall = () => {
    setOngoingCall()
    setSuggestedKms()
    setKms()
    setNote()
    setCompletedTasks()
    setIsMinimized(false)
  }

  const completeCall = async (params = { forced: false, overrideDuration: 0 }) => {
    removeCallFromLocalStorage()

    const { forced, overrideDuration } = params

    const insertEmployeeKm =
      kms || suggestedKms
        ? {
            kms: kms || 0,
            suggestedKms,
            dateDriven: new Date(callStartTime),
            type: 'CALL'
          }
        : null

    const insertMessage = note?.trim()
      ? {
          text: note,
          customerId: ongoingCall.customerId || ongoingCall.customer?.id
        }
      : null

    const meta =
      ongoingCall.callType === 'in-person' && !employee.callRadiusExempt && coords
        ? {
            callEnd: {
              ...coords.coords,
              accuracy: coords.accuracy,
              timestamp: coords.timestamp,
              endedByGeolock: forced,
              deviceType: getDeviceType()
            }
          }
        : null

    const callEndTime = overrideDuration
      ? moment(callStartTime).add(overrideDuration, 'seconds').tz(employeeTimezone)
      : moment().tz(employeeTimezone)

    await debouncedUpsertCustomerCall({
      ...omit(ongoingCall, ['customer', 'employee', 'type']),
      customerId: ongoingCall.customerId || ongoingCall.customer?.id,
      callStart: moment.tz(callStartTime, employeeTimezone).toISOString(),
      callEnd: moment(callEndTime).toISOString(),
      completedTasks,
      online: window.navigator.onLine,
      orders: ongoingCall.orders || [],
      employeeKm: insertEmployeeKm,
      message: insertMessage,
      meta,
      employeeId: employee.id,
      callStartError: inPersonCallLocationError
    })

    endCall()
  }

  const confirmCancelCall = () => {
    removeCallFromLocalStorage()
    setConfirmCancelCallVisible(false)
    endCall()
  }

  const getPreviousCallDistance = async ({ start, address, coords }) => {
    const employeeValidAddressString = !isEmpty(employee.address) && getAddressString(employee.address)
    const validUserAddress = (employeeValidAddressString || (employee.latitude && employee.longitude)) && {
      ...employee.address,
      address: employeeValidAddressString,
      ...pick(employee, ['latitude', 'longitude'])
    }
    const prevCalls = allCalls.filter((c) => {
      const type = c.callType || c.scheduledType
      if (type !== 'in-person') return false
      const date = c.callEnd || c.scheduledEnd
      return moment(start).isSame(date, 'day') && moment(start).isAfter(date)
    })
    const prevCall =
      prevCalls && prevCalls.length > 0
        ? prevCalls.reduce((prev, curr) => (getEndDate(curr).isAfter(getEndDate(prev)) ? curr : prev), {})
        : {}

    const previousAddress = !isEmpty(prevCall?.customer)
      ? {
          ...prevCall.customer.address,
          address: getAddressString(prevCall.customer.address),
          ...pick(prevCall.customer, ['latitude', 'longitude'])
        }
      : validUserAddress

    const distance = previousAddress
      ? await getDrivingDistance({
          from: previousAddress,
          to: { ...address, ...coords, address: getAddressString(address) }
        })
      : 0

    return distance
  }

  const getEndDate = (call) => {
    if (isEmpty(call)) return moment(0)
    const endDate = call.callEnd || call.scheduledEnd
    return moment(endDate)
  }

  const startCall = async (callType) => {
    const newCallStartTime = new Date().toISOString()
    let drivingDistance = null
    if (callType === 'in-person') {
      const distance = await getPreviousCallDistance({
        start: newCallStartTime,
        address: callToStart.customer.address,
        coords: pick(callToStart.customer, ['latitude', 'longitude'])
      })
      drivingDistance = distance ? distance / 1000 : null

      setSuggestedKms(drivingDistance)
      setKms()
    }

    const callTasks =
      tasksByOutletSubtype &&
      callToStart?.customer?.outletSubtype &&
      tasksByOutletSubtype[callToStart.customer.outletSubtype]
    if (callTasks?.length) {
      setCompletedTasks(
        callTasks?.map((task) => ({
          id: task.id,
          label: task.task,
          checked: false
        }))
      )
    }

    const locationVals =
      callType === 'in-person' && !employee.callRadiusExempt && callLocation?.coords
        ? { ...callLocation.coords, locationAccuracy: callLocation.accuracy }
        : null
    setOngoingCall({
      ...callToStart,
      ...locationVals,
      deviceType: getDeviceType(),
      callType,
      callStart: newCallStartTime
    })
    setConfirmCancelCallVisible(false)
    setStartCallScreenVisible(false)
    // saveCallToLocalStorage() // stored when callStartTime is set so no need to do it here.
  }

  const showEndCallWarning = (customer) => {
    setCurrentCallWarningCustomer(customer)
  }

  const cancelActiveCall = () => {
    setConfirmCancelCallVisible(true)
  }

  const debouncedCompleteCall = debounce(completeCall, 500, { leading: true, trailing: false })

  const value = {
    ongoingCall,
    setOngoingCall,
    showStartCallScreen,
    showEndCallWarning,
    getPreviousCallDistance
  }

  return (
    <ActiveCallContext.Provider value={value}>
      {children}
      <StartCallSheet
        startCallScreenVisible={startCallScreenVisible}
        editCall={toggleVisibleApptDetails}
        setStartCallScreenVisible={setStartCallScreenVisible}
        call={callToStart}
        inPersonCallLocationError={inPersonCallLocationError}
        inPersonAllowed={inPersonAllowed}
        startCall={startCall}
        inOngoingCall={Boolean(ongoingCall && callStartTime)}
        loadingLocation={loadingLocation}
        employee={employee}
      />
      {callStartTime && (
        <CallDurationContextProvider
          callStartTime={callStartTime}
          completeCall={debouncedCompleteCall}
          callType={callType}
        >
          {isMinimized ? (
            <MinifiedCall call={ongoingCall} setIsMinimized={setIsMinimized} />
          ) : (
            <ActiveCallDetails
              call={ongoingCall}
              updateCall={() => debouncedCompleteCall()}
              callType={callType}
              callStartTime={callStartTime}
              setIsMinimized={setIsMinimized}
              kms={kms}
              note={note}
              completedTasks={completedTasks}
              cancelCall={cancelActiveCall}
              setKms={setKms}
              setNote={setNote}
              setCompletedTasks={setCompletedTasks}
              timezone={employeeTimezone}
              canEdit
              isOngoingCall
            />
          )}
        </CallDurationContextProvider>
      )}
      <CancelCallConfirmation
        confirmCancelCallVisible={confirmCancelCallVisible}
        setConfirmCancelCallVisible={setConfirmCancelCallVisible}
        confirmCancelCall={confirmCancelCall}
      />
      {ongoingCall && callStartTime && (
        <EndOngoingCallWarning
          visible={Boolean(currentCallWarningCustomer)}
          close={() => showEndCallWarning()}
          ongoingCall={ongoingCall}
        />
      )}
      <ForcedCompleteSheet forcedCompleteDetails={forcedComplete} setForcedCompleteDetails={setForcedComplete} />
    </ActiveCallContext.Provider>
  )
}

UnconnectedActiveCallProvider.propTypes = {
  children: node.isRequired,
  upsertCustomerCall: func.isRequired,
  toggleVisibleApptDetails: func.isRequired,
  setCoords: func.isRequired
}

const mapActionCreators = {
  upsertCustomerCall,
  setCoords
}

export const ActiveCallProvider = connect(null, mapActionCreators)(UnconnectedActiveCallProvider)
