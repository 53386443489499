import React from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { bool, func, node, number, object, oneOfType, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'

import { getInputFieldProps, getMeta } from 'utils/helpers'

export const Container = styled.div(({ disabled }) => [
  tw`flex flex-col w-full text-slate-900`,
  disabled && tw`opacity-50 pointer-events-none select-none`
])

const LabelGroup = tw.div`flex justify-between text-2xs mb-1`

const MaxLength = tw.span``

const Label = tw.span``

export const Field = styled.input(({ dollar, icon, error, addon }) => [
  tw`w-full h-full rounded-md bg-slate-500/5 border-none placeholder:text-slate-500 focus:bg-white focus:outline-none focus:ring-2 focus:ring-brand-500`,
  error && tw`ring-red-500 ring-1 text-red-500 placeholder:text-red-400`,
  dollar && tw`pl-8`,
  icon && tw`pr-10`,
  addon && tw`pr-14`,
  icon && addon && tw`pr-24`
])

const Textarea = tw(Field)`p-6 min-h-[120px] max-h-[300px] resize-y`

const DollarSign = styled.div(({ hasError }) => [
  tw`absolute bottom-0 left-0 top-0 text-lg px-3`,
  hasError && tw`text-red-500`
])

const RightAddonsWrapper = tw.div`absolute bottom-0 right-0 top-0 flex`

export const IconWrap = styled.div(({ hasError }) => [
  tw`flex items-center px-2 text-slate-700`,
  hasError && tw`text-red-500`
])

const Addon = tw.div`flex items-center justify-center px-2 w-12 overflow-clip`

export const InputIcon = tw(Icon)`transition`

const ErrorMessage = tw.small`text-red-500 w-full text-left block mt-2 text-2xs`

const Input = React.forwardRef(
  ({ label, icon, textarea, small, disabled, dollar, addon, maxLength, enableCounter, ...rest }, ref) => {
    const formLibPropNames = ['field', 'form', 'meta', 'input', 'name', 'onChange', 'onBlur', 'value']
    const formLibProps = pick(rest, formLibPropNames)
    const inputFieldProps = getInputFieldProps(formLibProps)
    const inputFieldMeta = getMeta(formLibProps) || {}
    const hasLabelGroup = (enableCounter && maxLength) || label
    const allowedRest = omit(rest, formLibPropNames)

    const hasError = inputFieldMeta.error && inputFieldMeta.touched

    return (
      <Container className={rest.className} disabled={disabled} small={small}>
        {hasLabelGroup && (
          <LabelGroup>
            {label && <Label>{label}</Label>}
            {enableCounter && maxLength && (
              <MaxLength>
                {inputFieldProps.value?.length || 0}/{maxLength}
              </MaxLength>
            )}
          </LabelGroup>
        )}
        <div className="relative h-full">
          {!textarea ? (
            <Field
              ref={ref}
              disabled={disabled}
              icon={icon}
              addon={addon}
              {...inputFieldProps}
              error={hasError}
              dollar={dollar}
              small={small}
              maxLength={maxLength}
              {...allowedRest}
            />
          ) : (
            <Textarea ref={ref} as="textarea" error={hasError} maxLength={maxLength} {...inputFieldProps} {...rest} />
          )}
          {dollar && <DollarSign>$</DollarSign>}
          <RightAddonsWrapper>
            {addon && <Addon>{addon}</Addon>}
            {icon && (
              <IconWrap small={small} hasError={hasError}>
                <InputIcon icon={icon} compact />
              </IconWrap>
            )}
          </RightAddonsWrapper>
        </div>
        {inputFieldMeta.error && inputFieldMeta.touched && !inputFieldMeta.active && (
          <ErrorMessage>{inputFieldMeta.error}</ErrorMessage>
        )}
      </Container>
    )
  }
)

Input.propTypes = {
  icon: string,
  label: string,
  input: object,
  field: object,
  textarea: bool,
  dollar: bool,
  disabled: bool,
  small: bool,
  enableCounter: bool,
  addon: node,
  maxLength: number,
  meta: object,
  form: object,
  name: string,
  onChange: func,
  onBlur: func,
  value: oneOfType([string, number])
}

Input.displayName = 'Input'
export default Input
