import { setCurrentSector, setProductType, setSelectedLevel } from './actions'

const ACTION_HANDLERS = {
  [setCurrentSector]: (state, action) =>
    Object.assign({}, state, {
      ...state,
      currentSector: action.payload
    }),
  [setSelectedLevel]: (state, action) =>
    Object.assign({}, state, {
      ...state,
      selectedLevel: action.payload
    }),
  [setProductType]: (state, action) =>
    Object.assign({}, state, {
      ...state,
      productType: action.payload
    })
}

const initialState = {}
export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
