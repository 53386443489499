import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { bool, func, object, string } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySelloutGeographyData } from 'store/Sellout/actions'
import { amplifySelloutKAData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatCompactCurrency, formatCompactNumber, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const FIRST_COL = {
  field: 'headOffice',
  headerName: 'Head office'
}

const TIME_COLS = {
  rolling: [
    {
      field: 'mainPeriod',
      headerName: 'L4'
    },
    {
      field: 'variation',
      headerName: 'vs. P4'
    }
  ],
  pointInTime: [
    {
      field: 'mainPeriod',
      headerName: 'CTD'
    },
    {
      field: 'variation',
      headerName: 'vs. PC'
    }
  ]
}

function generateRows(data, dataFormatter) {
  if (!data) return []

  return data.map(({ name, mainPeriod, variation }) => ({
    headOffice: name,
    mainPeriod: dataFormatter(mainPeriod),
    variation: <DataVariation variation={variation} formatter={dataFormatter} />
  }))
}
const AmplifySelloutKACard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  fetchAmplifySelloutGeographyData,
  dataType,
  selloutDataLoading
}) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const [error, setError] = useState()
  if (error) console.log(error)
  const cols = [FIRST_COL].concat(TIME_COLS[currentTimeDisplay])
  const dataKey = useMemo(
    () =>
      createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_OUT.GEOGRAPHY, {
        sectorType: selectedLevel,
        sectorId: currentSector[selectedLevel]?.id,
        productType: currentProductType,
        channel: currentChannel,
        geography: 'headoffice',
        dataType
      }),
    [currentSector, selectedLevel, currentProductType, currentChannel, dataType]
  )

  useEffect(() => {
    let mounted = true
    setError()
    if (currentSector[selectedLevel]?.id && selectedLevel !== 'customer') {
      fetchAmplifySelloutGeographyData(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          channel: currentChannel,
          geography: 'headoffice',
          dataType
        },
        dataKey
      ).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }

    return () => {
      mounted = false
    }
  }, [currentSector, selectedLevel, currentProductType, currentChannel, dataType, dataKey])
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const selloutData = useSelector((state) =>
    amplifySelloutKAData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      currentVapeCategory,
      dataType
    })
  )

  if (selectedLevel === 'customer')
    return (
      <Card title="KA" span={span} displayAmplify={false}>
        <EmptyState title="No relevant data" />
      </Card>
    )

  if (selloutDataLoading || isLoading) {
    return (
      <Card title="KA" span={span} displayAmplify={false}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  if (isEmpty(selloutData)) {
    return (
      <Card title="KA" span={span} displayAmplify={false}>
        <EmptyState title="No data" />
      </Card>
    )
  }

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, decimalPlaces: 2 })
      : currentMetric === 'cost'
      ? formatCompactCurrency
      : formatCompactNumber
  return (
    <Card title="KA" span={span} displayAmplify={false}>
      <div className="max-h-72 overflow-y-auto">
        <DataTable columns={cols} rows={generateRows(selloutData, dataFormatter)} fillContainer />
      </div>
    </Card>
  )
}

AmplifySelloutKACard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  fetchAmplifySelloutGeographyData: func,
  selloutDataLoading: bool
}

export default connect(null, { fetchAmplifySelloutGeographyData })(AmplifySelloutKACard)
