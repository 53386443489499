import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// import countBy from 'lodash/countBy'
import groupBy from 'lodash/groupBy'
import { array, func, object } from 'prop-types'

import { territoryPricing } from 'store/pricings/selectors'
import { fetchTerritoryCustomerList } from 'store/territories/actions'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import LoadingCard from 'components/LoadingCard'
import PricingAnalyticsDisclaimer from 'components/PricingAnalyticsDisclaimer'
import PricingCustomers from 'components/PricingCustomers'
import SectionDivider from 'components/SectionDivider'

// import StatDetails from 'components/StatDetails'
import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const getPercent = (numerator, denominator) =>
  numerator && denominator ? ((numerator / denominator) * 100).toFixed() + '%' : '0%'

// const CeilingCompliance = ({ customers }) => {
//   const { nonCompliant = 0, compliant = 0 } = countBy(customers, 'ceilingCompliance')
//   const complianceEligible = nonCompliant + compliant
//   const ceilingNonCompliantCustomers = customers?.filter(
//     ({ ceilingCompliance }) => ceilingCompliance === 'nonCompliant'
//   )
//   return (
//     <BasicAccordion
//       title="Ceiling Compliance"
//       addOn={
//         <span>
//           <Icon icon="store" />
//           {complianceEligible} ({getPercent(complianceEligible, customers.length)})
//         </span>
//       }
//       details={
//         <StatDetails
//           data={[
//             {
//               label: 'Compliant',
//               stats: `${compliant} (${getPercent(compliant, complianceEligible)})`
//             },
//             {
//               label: 'Non-Compliant',
//               stats: `${nonCompliant} (${getPercent(nonCompliant, complianceEligible)})`
//             }
//           ]}
//         />
//       }
//     >
//       {ceilingNonCompliantCustomers.length > 0 && <PricingCustomers customers={ceilingNonCompliantCustomers} />}
//     </BasicAccordion>
//   )
// }

// CeilingCompliance.propTypes = {
//   customers: array
// }

const PricingCompetitiveness = ({ customers, competitiveCounts }) => {
  return (
    <>
      <SectionDivider title="Competitiveness" columns={['Yes', 'No', ' ']} />
      {Object.entries(competitiveCounts).map(([product, { notCompetitive, isCompetitive }], idx) => {
        return (
          <BasicAccordion
            key={`competitivness-${idx}`}
            title={product.replace(/_/g, ' ')}
            details={
              <div style={{ display: 'inline-flex', flexGrow: 2, justifyContent: 'space-around' }}>
                <span>{isCompetitive.length}</span>
                <span>{notCompetitive.length}</span>
              </div>
            }
          >
            {notCompetitive.length > 0 && (
              <PricingCustomers customers={customers.filter(({ id }) => notCompetitive.includes(id))} />
            )}
          </BasicAccordion>
        )
      })}
    </>
  )
}

PricingCompetitiveness.propTypes = {
  customers: array,
  competitiveCounts: object
}

const PricingPerf = ({ connectedFetchTerritoryCustomerList }) => {
  const { sectorId: territoryId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const isMounted = useRef(false)

  const { customers } = useSelector((state) => territoryPricing(state, { territoryId }))
  const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY, { territoryId })

  const getTerritory = async (territoryId) => {
    await connectedFetchTerritoryCustomerList(territoryId, dataKey)
  }

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getTerritory(territoryId).then(() => {
      isMounted.current && setIsLoading(false)
    })
  }, [territoryId])

  if (isLoading) return <LoadingCard dataKey={dataKey} />

  if (!customers?.length && !isLoading) return <EmptyState title="No stores" />

  const { outdated = [], inProgress = [], notRequired = [], updated = [] } = groupBy(customers, 'pricingStatus')

  return (
    <div className="flex h-full flex-col">
      <div className="overflow-y-auto">
        <Container className="overflow-y-auto">
          <BasicAccordion
            title="Outdated"
            addOn={
              <span>
                <Icon icon="store" />
                {outdated.length} ({getPercent(outdated.length, customers.length)})
              </span>
            }
          >
            {outdated.length > 0 && <PricingCustomers customers={outdated} />}
          </BasicAccordion>
          <BasicAccordion
            title="In Progress"
            addOn={
              <span>
                <Icon icon="store" />
                {inProgress.length} ({getPercent(inProgress.length, customers.length)})
              </span>
            }
          >
            {inProgress.length > 0 && <PricingCustomers customers={inProgress} />}
          </BasicAccordion>
          {/* <CeilingCompliance customers={customers} /> */}
          <BasicAccordion
            title="Not required"
            addOn={
              <span>
                <Icon icon="store" />
                {notRequired.length} ({getPercent(notRequired.length, customers.length)})
              </span>
            }
          >
            {notRequired.length > 0 && <PricingCustomers customers={notRequired} />}
          </BasicAccordion>
          <BasicAccordion
            title="Updated"
            addOn={
              <span>
                <Icon icon="store" />
                {updated.length} ({getPercent(updated.length, customers.length)})
              </span>
            }
          >
            {updated.length > 0 && <PricingCustomers customers={updated} />}
          </BasicAccordion>
        </Container>
      </div>
      <PricingAnalyticsDisclaimer />
    </div>
  )
}

PricingPerf.propTypes = {
  connectedFetchTerritoryCustomerList: func
}

export default connect(null, {
  connectedFetchTerritoryCustomerList: fetchTerritoryCustomerList
})(PricingPerf)
