import React, { useContext, useMemo } from 'react'
import { func, string } from 'prop-types'

// import tw from 'twin.macro'
import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import Dropdown from 'components/Dropdown'
import ProductTypePicker from 'components/ProductTypePicker'
import SegmentControl from 'components/SegmentControl'
import ViewHeader from 'components/ViewHeader'

const CHANNEL_OPTS_BY_TYPE = {
  fmc: [
    {
      value: 'pos',
      label: 'POS'
    }
  ],
  vape: [
    {
      value: 'pos',
      label: 'POS'
    },
    {
      value: 'specialty',
      label: 'Specialty'
    }
  ]
}

const SelloutHeader = ({
  parentHeader,
  header,
  currentProportion,
  setProportion,
  currentChannel,
  setChannel,
  currentMetric,
  setMetric,
  currentVapeCategory,
  setVapeCategory,
  setTimeDisplay,
  currentTimeDisplay
}) => {
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)
  const metricOpts = useMemo(() => {
    if (currentProductType === 'vape' && currentChannel === 'specialty') {
      return [
        {
          value: 'cost',
          label: '$'
        }
      ]
    }
    return [
      {
        value: 'cost',
        label: '$'
      },
      {
        value: 'volume',
        label: 'Volume'
      }
    ]
  }, [currentProductType, currentChannel])

  return (
    <ViewHeader
      parentHeader={parentHeader}
      header={header}
      filters={[
        !isWideLayout && <ProductTypePicker key="amplify-sell-out-product-type-picker" />,
        <SegmentControl
          key="proportion"
          name="proportion"
          onChange={(e) => {
            setProportion(e.target.value)
          }}
          value={currentProportion}
          options={[
            {
              value: 'absolute',
              label: 'Absolute'
            },
            {
              value: 'share',
              label: 'Share'
            }
          ]}
        />,
        <SegmentControl
          key="channel"
          name="channel"
          onChange={(e) => {
            setChannel(e.target.value)
          }}
          disabled={currentProductType === 'fmc'}
          value={currentChannel}
          options={CHANNEL_OPTS_BY_TYPE[currentProductType]}
        />,
        <SegmentControl
          key="metric"
          name="metric"
          onChange={(e) => {
            setMetric(e.target.value)
          }}
          disabled={currentProductType === 'vape' && currentChannel === 'specialty'}
          value={currentMetric}
          options={metricOpts}
        />,
        <SegmentControl
          key="timeDisplay"
          name="timeDisplay"
          onChange={(e) => {
            setTimeDisplay(e.target.value)
          }}
          value={currentTimeDisplay}
          options={[
            {
              value: 'rolling',
              label: 'Rolling'
            },
            {
              value: 'pointInTime',
              label: 'Point in time'
            }
          ]}
        />,
        currentProductType === 'fmc' ? null : (
          <Dropdown
            key="vapeCategory"
            name="vapeCategory"
            onChange={(e) => {
              setVapeCategory(e.target.value)
            }}
            disabled={currentProductType === 'fmc'}
            value={currentProductType === 'fmc' ? 'allConsumables' : currentVapeCategory}
            options={[
              {
                value: 'allConsumables',
                label: 'All consumables'
              },
              {
                value: 'openConsumables',
                label: 'Open consumables'
              },
              {
                value: 'closedConsumables',
                label: 'Closed consumables'
              },
              {
                value: 'disposables',
                label: 'Disposables'
              },
              {
                value: 'devices',
                label: 'Devices'
              }
            ]}
          />
        )
      ].filter(Boolean)}
    />
  )
}

SelloutHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired,
  setChannel: func.isRequired,
  setMetric: func.isRequired,
  setProportion: func.isRequired,
  setVapeCategory: func.isRequired,
  setTimeDisplay: func.isRequired,
  currentTimeDisplay: string.isRequired,
  currentChannel: string.isRequired,
  currentMetric: string.isRequired,
  currentProportion: string.isRequired,
  currentVapeCategory: string.isRequired
}

export default SelloutHeader
