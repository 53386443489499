import moment from 'moment'

import { STATUS } from 'utils/constants'

export const formatPhone = (phone) => {
  if (!phone) return ''
  const onlyNumbers = phone.replace(/\D/, '')
  const last4 = onlyNumbers.slice(-4)
  const first3 = onlyNumbers.slice(-7, -4)
  const areaCode = onlyNumbers.slice(-10, -7)
  const countryCode = onlyNumbers.slice(-11, -10) || '1'
  return `+${countryCode}-${areaCode}-${first3}-${last4}`
}

export const composeGraphData = (performanceToDate, preferredLanguage) => {
  const labels = performanceToDate.series.map(({ name }) => name[preferredLanguage])
  return {
    labels,
    data: performanceToDate.weeks.map((week, weekIndex) =>
      performanceToDate.series.reduce((acc, { values }, i) => ({ ...acc, [`${labels[i]}`]: values[weekIndex] }), {
        week: moment(week).format('DD-MMM')
      })
    )
  }
}

export const formatIntelGroupTitle = (grouping) => {
  const [category, manufacturer, brand, type] = grouping.split('|')
  return `${category}: ${manufacturer} / ${brand} - ${type.split(' ').join('\xa0')}` // nbsp in whitespaces of type to keep it on one line
}

export const getTotalProfitPerWeek = (priceObj) => {
  if (!priceObj || !priceObj.price || !priceObj.netCustomerPrice || !priceObj.storeAwr) return ''
  return '$' + ((priceObj.price - priceObj.netCustomerPrice) * priceObj.storeAwr).toFixed(2)
}

export const secondsToMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60)
    .toFixed()
    .padStart(2, 0)
  const leftoverSeconds = Math.floor(seconds % 60)
    .toFixed()
    .padStart(2, 0)
  return `${minutes}:${leftoverSeconds}`
}

export const getExceptionType = (record) => {
  if (!record) return ''
  const termsException = Boolean(record.questions) && 'Terms/Tier'
  const skusException = Boolean(record.skus) && 'SKU min/max'
  const statusException = Boolean(record.requestedStatus || record.type === STATUS) && 'Status'
  return [statusException, termsException, skusException].filter(Boolean).join(' & ')
}

export function formatPercent(val, opts) {
  const multiplicationFactor = opts?.convertDecimal ? 100 : 1
  const decimalPlaces = opts?.decimalPlaces || 0
  if (val === 0) return '0%'
  if (!val) return null
  return +(val * multiplicationFactor).toFixed(decimalPlaces) + '%'
}

export function formatCurrency(val) {
  if (!val && val !== 0) return null
  return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(val)
}

export function formatCompactCurrency(val) {
  if (!val && val !== 0) return null
  return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD', notation: 'compact' }).format(val)
}

export function formatCompactNumber(val) {
  if (!val && val !== 0) return null
  return new Intl.NumberFormat('en-CA', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger'
  }).format(val)
}

export function formatNumber(val) {
  if (!val && val !== 0) return null
  return new Intl.NumberFormat('en-CA', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger'
  }).format(val)
}
