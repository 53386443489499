import React from 'react'
import { bool, func } from 'prop-types'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

const CancelCallConfirmation = ({ confirmCancelCallVisible, setConfirmCancelCallVisible, confirmCancelCall }) => {
  return (
    <ActionSheet title="Cancel call" visible={confirmCancelCallVisible}>
      <div>
        Are you sure you want to cancel your call?
        <Fieldset>
          <FieldsetItem>
            <Button full primary onClick={confirmCancelCall}>
              Yes
            </Button>
          </FieldsetItem>
          <FieldsetItem>
            <Button full onClick={() => setConfirmCancelCallVisible(false)}>
              No
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

CancelCallConfirmation.propTypes = {
  confirmCancelCallVisible: bool,
  setConfirmCancelCallVisible: func.isRequired,
  confirmCancelCall: func.isRequired
}

export default CancelCallConfirmation
