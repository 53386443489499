import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

const Container = tw.div`flex items-center h-9 rounded-md bg-slate-900/5 p-1`

const Option = styled.label(({ disabled }) => [
  tw`relative h-full flex flex-1 justify-center`,
  disabled && tw`opacity-50`
])

const OptionLabel = tw.span`flex h-full w-full select-none items-center justify-center rounded px-4 text-xs text-slate-500 cursor-pointer transition hover:text-slate-900 peer-checked:bg-white peer-checked:text-slate-800 peer-checked:shadow-sm peer-checked:ring-1 peer-checked:ring-slate-900/10 peer-focus-visible:ring-2 peer-focus-visible:ring-brand-500 `

export const SegmentedControl = ({ label, value, name, options, onChange }) => {
  return (
    <Container>
      {options.map((option) => (
        <Option key={option.value} disabled={option.disabled}>
          <input
            aria-describedby={`${option.value}-description`}
            name={name}
            type="radio"
            defaultChecked={value === option.value}
            value={option.value}
            onChange={onChange}
            disabled={option.disabled}
            className="peer sr-only"
          />
          <OptionLabel>{option.label}</OptionLabel>
        </Option>
      ))}
    </Container>
  )
}

SegmentedControl.propTypes = {
  label: string,
  name: string.isRequired,
  options: arrayOf(shape({ value: string })),
  onChange: func.isRequired,
  value: string
}
