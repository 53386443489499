import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { func, number } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchDistroBrand } from 'store/distro/actions'
import { brandDistroData } from 'store/distro/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const COLS = [
  {
    field: 'brand',
    headerName: 'Brand'
  },
  {
    field: 'wdistro',
    headerName: 'Wght. Distro'
  },
  {
    field: 'storedistro',
    headerName: 'Store Distro'
  }
]

function toFixed(value) {
  return (value * 100).toFixed(2)
}

const AmplifyDistroBrandCard = ({ span, fetchDistroBrand }) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const [error, setError] = useState()
  const mounted = useRef(false)

  const brandData = useSelector((state) => brandDistroData(state, { activeProductType: currentProductType }))

  const brandLabel = currentProductType === 'fmc' ? 'Brand performance' : 'Brand variant'

  const dataKey = createDataKey(DATAKEY_TYPES.DISTRO_BRAND, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType
  })

  const isBrandLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchDistroBrand(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType])

  const graphRows = useMemo(() => {
    if (Object.values(brandData)?.length) {
      return Object.values(brandData).map((d) => {
        return {
          brand: d.brand,
          wdistro: <DataCompare last={`${toFixed(d.distro.value)}`} isPercent variation={d.distro.diff} stacked />,
          storedistro: (
            <DataCompare last={`${toFixed(d.storeDistro.value)}`} isPercent variation={d.storeDistro.diff} stacked />
          )
        }
      })
    }
    return []
  }, [brandData])

  const renderContent = (rows) => {
    if (isBrandLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    return rows.length ? (
      <div className="max-h-72 overflow-y-auto">
        <DataTable columns={COLS} rows={graphRows} fillContainer />
      </div>
    ) : (
      <EmptyState title="No data yet" />
    )
  }

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  return (
    <Card title={brandLabel} span={span} displayAmplify={false}>
      {renderContent(graphRows)}
    </Card>
  )
}

AmplifyDistroBrandCard.propTypes = {
  span: number,
  fetchDistroBrand: func
}

export default connect(null, { fetchDistroBrand })(AmplifyDistroBrandCard)
