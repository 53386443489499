import React from 'react'
import tw from 'twin.macro'

import AmplifyTile from './AmplifyTile'
const Container = tw.div`grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-5 gap-4 justify-center`

const TILES = [
  {
    title: 'Sell-In',
    description: '',
    to: 'sell-in'
  },
  {
    title: 'Sell-Out',
    description: '',
    to: 'sell-out'
  },
  {
    title: 'OOS & Inv',
    description: '',
    to: 'inventory'
  },
  {
    title: 'Distro',
    description: '',
    to: 'distro'
  },
  {
    title: 'Price capture',
    description: '',
    to: 'price-capture'
  },
  {
    title: 'Price compliance  ',
    description: '',
    to: 'price-compliance'
  },
  {
    title: 'Extra Hub Activity ',
    description: '',
    to: 'extra-hub'
  }
]

const AmplifyIndex = () => {
  return (
    <Container>
      {TILES.map((tile) => (
        <AmplifyTile key={tile.title} content={tile} />
      ))}
    </Container>
  )
}

export default AmplifyIndex
