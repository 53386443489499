// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { func, node, number, string } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { fetchPlanningHealthCheck } from 'store/HealthCheck/actions'
import { planningHealthCheckData } from 'store/HealthCheck/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import VariationIndicator from 'components/VariationIndicator'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const Header = tw.span`text-sm font-semibold text-slate-500`

const DataL = ({ header, children, variation }) => (
  <div className="flex w-full grow flex-col items-center space-y-3">
    <Header>{header}</Header>
    <div className="flex flex-col items-center space-y-2">
      <span className="text-5xl font-medium text-slate-900">{children}</span>
      <div className="flex items-center space-x-2">
        <VariationIndicator variation={variation} />
        <span className="text-2xs font-medium text-slate-500">{Math.abs(variation)} vs PW</span>
      </div>
    </div>
  </div>
)

DataL.propTypes = {
  header: string,
  children: node,
  variation: number
}

const DataSm = ({ header, children }) => (
  <div className="flex flex-col items-center space-y-2">
    <Header>{header}</Header>
    {children}
  </div>
)

DataSm.propTypes = {
  header: string,
  children: node
}

const AmplifyDistroCard = ({ fetchPlanningHealthCheck, span }) => {
  const { currentSector, selectedLevel } = useContext(SectorContext)

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const [error, setError] = useState()
  const activeProductType = 'fmc'
  const healthCheckData = useSelector((state) =>
    planningHealthCheckData(state, {
      activeProductType
    })
  )

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_ENGAGEMENT, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id
  })

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchPlanningHealthCheck(
        { id: currentSector[selectedLevel].id, sectorLevel: selectedLevel, activeProductType },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, activeProductType])

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  return (
    <Card title="Distro" span={span} displayAmplify>
      <div className="flex h-full flex-col">
        <div className="mb-8 flex grow items-center">
          <DataL header="Distro" variation={healthCheckData.distro.variation}>
            {healthCheckData.distro.percent}%
          </DataL>
        </div>
        <div className="grid grid-cols-2">
          <DataSm header="Stores">
            <span className="text-3xl font-medium text-slate-900">{healthCheckData.stores}%</span>
          </DataSm>
          <DataSm header="SKUs">
            <span className="text-3xl font-medium text-slate-900">{healthCheckData.skus}%</span>
          </DataSm>
        </div>
      </div>
    </Card>
  )
}

AmplifyDistroCard.propTypes = {
  fetchPlanningHealthCheck: func,
  span: number
}

const mapActionCreators = {
  fetchPlanningHealthCheck
}

export default connect(null, mapActionCreators)(AmplifyDistroCard)
