import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import { arrayOf, func, number, object, string } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { fetchCycleCampaignData, fetchCycleCampaigns } from 'store/cycleCampaigns/actions'
import { activeCycleCampaignOptions, campaignDetails } from 'store/cycleCampaigns/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import VariationIndicator from 'components/VariationIndicator'

import { CYCLE_CAMPAIGN_KPI_TYPES, DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const Eyebrow = tw.hr`w-full h-px bg-slate-100`
const Header = tw.span`block text-lg font-semibold text-slate-500`
const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const Data1Up = ({ data }) => {
  if (!data || (!data.data && data.data !== 0)) return

  const { description, data: kpiData, isPercentage, variation, target, additional } = data
  return (
    <div className="w-full space-y-4">
      <Eyebrow />
      <Header>{description}</Header>
      <div>
        <span className="text-4xl font-medium">
          {kpiData.toFixed(2)}
          {isPercentage && '%'}
        </span>
        <div className="flex items-center space-x-2">
          {Boolean(variation) && (
            <>
              <VariationIndicator variation={variation} />
              <span className="text-2xs font-medium text-slate-500">
                {Math.abs(variation).toFixed(2)} {additional}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="space-y-2">
        {Boolean(target) && (
          <span className="text-2xs font-medium text-slate-500">
            Target: {target}
            {isPercentage && '%'}
          </span>
        )}
        {Boolean(target) && (
          <>
            {kpiData < target ? (
              <div className="flex items-center space-x-2">
                <div className="text-yellow-500">
                  <Icon compact icon="warning-filled" />
                </div>
                <span>Close OPP</span>
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <div className="text-green-500">
                  <Icon compact icon="check-circle" />
                </div>
                <span>Target met</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

Data1Up.propTypes = {
  data: object
}

const Data2Up = ({ data, description }) => (
  <div className="flex w-full flex-col space-y-4">
    <Eyebrow />
    <Header>{description}</Header>
    {data &&
      data.slice(0, 2).map((data) => (
        <div key={data.description} className="flex flex-col space-y-2">
          <span className="text-3xl font-medium text-slate-900">
            {data?.data?.toFixed(2)}
            {data?.isPercentage && '%'}
          </span>
          <span className="text-sm font-medium text-slate-500">{data.description}</span>
        </div>
      ))}
  </div>
)

Data2Up.propTypes = {
  data: arrayOf(object),
  description: string
}

const Data3Up = ({ data, description }) => (
  <div className="flex w-full flex-col justify-between space-y-4">
    <Eyebrow />
    <Header>{description}</Header>
    {data &&
      data.slice(0, 3).map((data) => (
        <div key={data.description} className="flex flex-col space-y-1">
          <span className="text-xl font-medium text-slate-900">
            {data.data.toFixed(2)}
            {data.isPercentage && '%'}
          </span>
          <span className="text-sm font-medium text-slate-500">{data.description}</span>
        </div>
      ))}
  </div>
)

Data3Up.propTypes = {
  data: arrayOf(object),
  description: string
}

const CampaignKpiList = ({ campaignId, fetchCycleCampaignData }) => {
  const { sectorId, sectorType } = useParams()
  const { currentProductType } = useContext(SectorContext)
  const currentCampaign = useSelector((state) => campaignDetails(state, { campaignId }))

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_CYCLE_FOCUS, {
    sectorType,
    sectorId,
    productType: currentProductType,
    campaignId
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    if (sectorId && sectorType && currentProductType && campaignId)
      fetchCycleCampaignData({ campaignId, sectorId, sectorType, currentProductType }, dataKey)
  }, [campaignId, sectorType, sectorId, currentProductType])

  const getComponentByType = (type) => {
    switch (type) {
      case CYCLE_CAMPAIGN_KPI_TYPES.WEIGHTED_DISTRO:
        return Data3Up
      case CYCLE_CAMPAIGN_KPI_TYPES.RATES_OF_SELL:
      case CYCLE_CAMPAIGN_KPI_TYPES.SELL_OUT:
      case CYCLE_CAMPAIGN_KPI_TYPES.EXTRA_HUB:
      case CYCLE_CAMPAIGN_KPI_TYPES.PRICE_COMPLIANCE:
      case CYCLE_CAMPAIGN_KPI_TYPES.PRICE_UPDATE:
      case CYCLE_CAMPAIGN_KPI_TYPES.PRICE_SIGN:
        return Data1Up
      case CYCLE_CAMPAIGN_KPI_TYPES.SELL_IN:
        return Data2Up
    }
  }
  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner icon="spinner" />
      </SpinnerContainer>
    )

  if (!currentCampaign || !currentCampaign.kpis || !currentCampaign.kpis.length)
    return <EmptyState title="No KPI for this campaign" />

  return (
    <div className="flex space-x-4">
      {currentCampaign &&
        currentCampaign.kpis &&
        currentCampaign.kpis.map((kpi) => {
          const KpiComponent = getComponentByType(kpi.type)
          return <KpiComponent key={kpi.id} description={capitalize(kpi.type)} data={kpi.data} />
        })}
    </div>
  )
}

CampaignKpiList.propTypes = {
  campaignId: number,
  fetchCycleCampaignData: func
}

const ConnectedCampaignKpiList = connect(null, { fetchCycleCampaignData })(CampaignKpiList)

const CycleFocusCard = ({ fetchCycleCampaigns, span }) => {
  const [campaignId, setCampaignId] = useState()
  const campaignOptions = useSelector((state) => activeCycleCampaignOptions(state))

  useEffect(() => {
    fetchCycleCampaigns()
  }, [])

  useEffect(() => {
    if (campaignOptions?.length && !campaignId) {
      setCampaignId(campaignOptions[0].value)
    }
  }, [campaignOptions])

  const CycleFocusSelector = () => {
    return (
      <div className="flex items-center space-x-1 text-lg font-semibold ">
        <div className="space-x-2">
          <span className="text-slate-500">Cycle focus</span>
          <span className="text-slate-500">/</span>
        </div>
        <Dropdown onChange={(e) => setCampaignId(e.target.value)} value={campaignId} options={campaignOptions} />
      </div>
    )
  }

  if (!campaignId) return <EmptyState title="No Campaign" />

  return (
    <Card customTitle={<CycleFocusSelector />} span={span} displayAmplify hideOnSmallScreens={true}>
      <ConnectedCampaignKpiList campaignId={campaignId} />
    </Card>
  )
}

CycleFocusCard.propTypes = {
  span: object,
  fetchCycleCampaigns: func
}

export default connect(null, { fetchCycleCampaigns })(CycleFocusCard)
