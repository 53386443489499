import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel, getCurrentProductType } from 'store/sector/selectors'

function getSkusPercentage({ data }) {
  if (!data.numerator && !data.denominator) return '-'

  const { numerator, denominator } = data

  if (numerator && !denominator) return 100
  return Math.round((numerator * 100) / denominator, 2)
}

function formatOosData({ data }) {
  const { lastWeek, weekBeforeLast } = data

  const result = { percent: '-', variation: 0 }
  if (!lastWeek || !weekBeforeLast || !lastWeek.quantity) return result

  const lastWeekPercentage = Math.round((lastWeek.quantity / lastWeek.average) * 100, 2)
  result.percent = lastWeekPercentage
  if (!weekBeforeLast.average) return result

  const twoWeeksAgoPercentage = Math.round((weekBeforeLast.quantity / weekBeforeLast.average) * 100, 2)
  result.variation = lastWeekPercentage - twoWeeksAgoPercentage

  return result
}

function formatDistroData({ data }) {
  if (!data.globalUntilLastWeekSales || !data.globalUntilTwoWeeksAgoSales) return { percent: '-', variation: 0 }
  const lastWeekPercentage = Math.round((data.lastWeekActiveSales / data.globalUntilLastWeekSales) * 100, 2)
  const twoWeeksAgoPercentage = Math.round((data.twoWeeksAgoActiveSales / data.globalUntilTwoWeeksAgoSales) * 100, 2)
  return { percent: lastWeekPercentage, variation: lastWeekPercentage - twoWeeksAgoPercentage }
}

function formatInvCaptureData({ data }) {
  if (!data.lastWeekInv || !data.weekBeforeLast || !data.lastWeekInv.average || !data.weekBeforeLast.average)
    return { percent: '-', variation: 0 }

  const lastWeekPercentage = Math.round((data.lastWeekInv.quantity / data.lastWeekInv.average) * 100, 2)
  const twoWeeksAgoPercentage = Math.round((data.weekBeforeLast.quantity / data.weekBeforeLast.average) * 100, 2)

  return { percent: lastWeekPercentage, variation: lastWeekPercentage - twoWeeksAgoPercentage }
}

const healthCheckDefaultValue = {
  skus: '-',
  stores: '-',
  oos: { percent: '-', variation: 0 },
  distro: { percent: '-', variation: 0 },
  inventoryCaptures: { percent: '-', variation: 0 }
}

export const planningHealthCheckData = createSelector(
  dataSourceFromSectorLevel,
  getCurrentProductType,
  (sector, activeProductType) => {
    if (isEmpty(sector) || !sector) return healthCheckDefaultValue
    const healthCheckData = sector[`healthcheck-${activeProductType}`]
    if (!healthCheckData) return healthCheckDefaultValue

    return {
      skus: getSkusPercentage({ data: healthCheckData.skus }),
      stores: getSkusPercentage({ data: healthCheckData.stores }),
      oos: formatOosData({ data: healthCheckData.oos }),
      distro: formatDistroData({ data: healthCheckData.distro }),
      inventoryCaptures: formatInvCaptureData({ data: healthCheckData.inv })
    }
  }
)
