import React, { useState } from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'
import { arrayOf, func, shape, string } from 'prop-types'

import Icon from 'components/Icon'

import MobileLink from './MobileLink'

function MobileLinkMenu({ name, icon, links }) {
  const [open, setOpen] = useState(false)

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <div className="relative -mx-1 flex h-12 items-center justify-between rounded-md px-4 font-medium text-white transition hover:bg-white/5 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500">
          <div className="flex items-center gap-3 ">
            <span className="-ml-[3px] flex">
              <Icon compact icon={icon} />
            </span>
            <span>{name}</span>
          </div>
          <span className="-mr-[3px] flex">
            <Icon
              icon="down-chevron"
              className="h-4 w-4 transform transition-transform duration-200"
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          </span>
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content>
        {links.map((link, i) => {
          return <MobileLink isSubLink key={i} name={link.name} url={link.url} onClick={link.onClick} />
        })}
      </Collapsible.Content>
    </Collapsible.Root>
  )
}

MobileLinkMenu.propTypes = {
  name: string.isRequired,
  icon: string.isRequired,
  links: arrayOf(
    shape({
      name: string.isRequired,
      url: string.isRequired,
      onClick: func.isRequired
    }).isRequired
  )
}

export default MobileLinkMenu
