import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import { signup } from 'store/auth/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { isBatEmail, isEmail, minPasswordLength, required } from 'utils/validators'

const errorMessages = {
  employeeAlreadyExists: (
    <GlobalAlert>
      This employee is already registered. Try to <Link to="/auth/login">login</Link> instead.
    </GlobalAlert>
  ),
  offlineError: <GlobalAlert>You cannot signup without an internet connection.</GlobalAlert>,
  invalidEmployeeDetails: <GlobalAlert>Please check your details and try again.</GlobalAlert>,
  permissionDenied: <GlobalAlert>You do not have the necessary permissions to access this site.</GlobalAlert>,
  generic: <GlobalAlert>An error has occured. Please try again later.</GlobalAlert>
}

export function SignUp({ signup }) {
  const navigate = useNavigate()
  const [error, setError] = useState()

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setError()
    try {
      await signup(values)
      navigate('/auth/signup-requested')
    } catch ({ response }) {
      const signupError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[signupError] || errorMessages.generic)
    } finally {
      setSubmitting(false)
    }
  }

  const handleEmailChange = (value, setFieldValue) => {
    const email = value.trim().toLowerCase()
    setFieldValue('email', email)
  }

  const validateEmail = (value) => {
    return required(value) || isEmail(value) || isBatEmail(value)
  }

  const validatePassword = (value) => {
    return required(value) || minPasswordLength(value)
  }

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '', oneId: '', password: '' }}
      onSubmit={handleFormSubmit}
    >
      {({ isSubmitting, errors, setFieldValue }) => {
        return (
          <Form>
            <Fieldset>
              <FieldsetItem>
                <h3>Sign up</h3>
                <p>
                  Already have an account? <Link to="/auth/login">Log in</Link>
                </p>
              </FieldsetItem>

              <FieldsetItem>
                <Field component={Input} placeholder="First name" name="firstName" validate={required} />
              </FieldsetItem>

              <FieldsetItem>
                <Field component={Input} placeholder="Last name" name="lastName" validate={required} />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  placeholder="Email address"
                  name="email"
                  onChange={(event) => handleEmailChange(event.target.value, setFieldValue)}
                  validate={validateEmail}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field component={Input} placeholder="OneID" name="oneId" type="number" validate={required} />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  placeholder="Password"
                  type="password"
                  name="password"
                  validate={validatePassword}
                />
              </FieldsetItem>

              {error && <FieldsetItem>{error}</FieldsetItem>}

              <FieldsetItem>
                <Button type="submit" primary full disabled={Object.keys(errors).length > 0} isLoading={isSubmitting}>
                  Sign up
                </Button>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

const mapActionCreators = {
  signup
}

SignUp.propTypes = {
  signup: func.isRequired
}

export default connect(null, mapActionCreators)(SignUp)
