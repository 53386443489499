import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import { func, number } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchDistroGeographyData } from 'store/distro/actions'
import { geographyDistroData } from 'store/distro/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, createOptions, getErrorMessage } from 'utils/helpers'

import GeographyToggle from '../GeographyToggle'

const SAMPLE_GRAPH_DATA = [{ d: 32 }, { d: 24 }, { d: 28 }, { d: 29 }, { d: 31 }, { d: 30 }, { d: 24 }, { d: 25 }]

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const COLS = [
  {
    field: 'geo',
    headerName: 'Region'
  },
  {
    field: 'stores',
    headerName: 'Stores not in distro'
  },
  {
    field: 'storedistro',
    headerName: 'Store distro'
  },
  {
    field: 'skus',
    headerName: 'SKUs not in distro'
  },
  {
    field: 'skudistro',
    headerName: 'SKU distro'
  },
  {
    field: 'weighteddistro',
    headerName: 'Wght. distro'
  },
  {
    field: 'trend',
    headerName: 'Wght. distro trend'
  }
]

function toFixed(value) {
  return (value * 100).toFixed(2)
}

const AmplifyDistroTableCard = ({ span, fetchDistroGeographyData }) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const mounted = useRef(false)
  // const filters = useSelector((state) => getCurrentFilters(state))

  const geographyData = useSelector((state) =>
    geographyDistroData(state, { activeProductType: currentProductType, geography })
  )

  COLS[0].headerName = capitalize(geography)

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.DISTRO.GEOGRAPHY, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType
  })

  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchDistroGeographyData(
        {
          sectorId: currentSector[selectedLevel].id,
          sectorType: selectedLevel,
          geography
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, geography])

  const graphRows = useMemo(() => {
    if (Object.values(geographyData)?.length) {
      return Object.values(geographyData).map((d) => {
        return {
          geo: d.name,
          stores: `${d.storeInDistro} / ${d.storeCount}`,
          storedistro: <DataCompare last={toFixed(d.storeDistro)} variation={d.skuDistroDiff} isPercent />,
          skus: `${d.skuInDistro} / ${d.skuCount}`,
          skudistro: <DataCompare last={toFixed(d.skuDistro)} variation={d.storeDistroDiff} isPercent />,
          weighteddistro: <DataCompare last={toFixed(d.amountDistro)} variation={d.amountDistroDiff} isPercent />,
          trendl13: <DataGraph color="#53CCF8" data={SAMPLE_GRAPH_DATA} />
        }
      })
    }
    return []
  }, [geographyData])

  const allDescriptionLabel = currentProductType === 'fmc' ? 'All BVLS' : 'All Material description'
  const allBrandLabel = currentProductType === 'fmc' ? 'All Brands' : 'All Brand variants'

  const ownershipOptions = [{ label: 'All Ownership Types', value: 'all' }].concat(
    // TODO implement filters
    // createOptions(filters[currentProductType]?.ownershipTypes || [])
    createOptions([])
  )
  const brandsOptions = [{ label: allBrandLabel, value: 'all' }].concat(
    // TODO implement filters
    // createOptions(filters[currentProductType]?.brands || [])
    createOptions([])
  )
  const materialOptions = [{ label: allDescriptionLabel, value: 'all' }].concat(
    // TODO implement filters
    // createOptions(filters[currentProductType]?.materials || [])
    createOptions([])
  )

  const getContent = (rows) => {
    if (error) {
      return <EmptyState title="An error occured" subtitle={getErrorMessage(error)} />
    }

    if (isGeographyLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    if (!rows || !rows.length) {
      return <EmptyState title="Nothing to display" />
    }

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }

  return (
    <Card
      title={`${capitalize(geography)} performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown key={0} options={ownershipOptions} />,
        <Dropdown key={1} options={brandsOptions} />,
        <Dropdown key={2} options={materialOptions} />,
        <GeographyToggle
          key={3}
          includeBrand
          includeChannel
          includeOwnership
          geography={geography}
          setGeography={setGeography}
        />
      ]}
      hideOnSmallScreens={true}
    >
      {getContent(graphRows)}
    </Card>
  )
}

AmplifyDistroTableCard.propTypes = {
  span: number,
  fetchDistroGeographyData: func
}

export default connect(null, { fetchDistroGeographyData })(AmplifyDistroTableCard)
