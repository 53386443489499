import React, { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
// import maxBy from 'lodash/maxBy'
// import minBy from 'lodash/minBy'
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { selloutL13TrendGraphData } from 'store/Sellout/selectors'

import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import { WrappedSpinner } from 'components/Spinner'

import { CHANNEL_FILTERS, PACE_SELLOUT_MANUFACTURER_FILTERS } from 'utils/constants'
import { formatCompactCurrency, formatCompactNumber, formatNumber, formatPercent } from 'utils/formatters'

function generateYAxis({ entries, dataFormat }) {
  if (dataFormat === 'percent') return [0, 100]
  if (entries.length && entries.some(({ data }) => data)) {
    // const max = maxBy(entries, 'data')
    // const min = minBy(entries, 'data')
    // const interval = +max.data - +min.data
    // const minimal = Math.max(+min.data - interval / 8, 0)

    // return [Math.floor(+min.data), Math.ceil(+max.data)]
    return ['auto', 'auto']
  }
  return [0, 10000]
}

const FMC_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos
const VAPE_POS_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos
const VAPE_SPECIALTY_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty

const formatterByDataType = {
  percent: formatPercent,
  cost: formatCompactCurrency,
  qty: formatNumber,
  pack_qty: formatNumber
}

const CustomTooltip = ({ active, payload = [], label, dataFormat }) => {
  const formatter = formatterByDataType[dataFormat] || formatNumber
  if (!active || !payload || !payload.length) return null
  const content = `${formatter(payload[0]?.value)}\nWE: ${label}`
  if (active && payload && payload[0])
    return (
      <div>
        <div
          className="w-auto -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white"
          style={{ whiteSpace: 'pre' }}
        >
          {content}
        </div>
      </div>
    )
}

CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ value: number })),
  label: string,
  dataFormat: string
}

const Controls = tw.div`w-full flex space-x-3`

export const SelloutL13TrendGraphCard = ({
  span,
  amplifyAction,
  title,
  currentChannel,
  setChannel,
  currentProportion,
  error,
  dataType,
  dataKey,
  selloutDataLoading
}) => {
  const { currentProductType } = useContext(SectorContext)
  const [currentManufacturer, setCurrentManufacturer] = useState()

  useLayoutEffect(() => {
    setChannel('pos')
    const defaultManufacturerOptions = currentProductType === 'fmc' ? FMC_OPTIONS : VAPE_POS_OPTIONS
    setCurrentManufacturer(defaultManufacturerOptions[0].value)
  }, [currentProductType])

  const MANUFACTURERS_DROPDOWN_OPTIONS = useMemo(() => {
    if (currentProductType === 'fmc') return FMC_OPTIONS
    if (currentChannel === 'specialty') return VAPE_SPECIALTY_OPTIONS
    return VAPE_POS_OPTIONS
  }, [currentChannel, currentProductType])

  const selloutData = useSelector((state) => {
    return selloutL13TrendGraphData(state, {
      currentManufacturer,
      activeProductType: currentProductType,
      currentChannel,
      currentProportion,
      dataType
    })
  })

  const dataFormat = currentProportion === 'share' ? 'percent' : dataType
  const yAxisFormatter = dataFormat === 'percent' ? formatPercent : formatCompactNumber

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  const cardProps = {
    title,
    span,
    amplifyAction
  }
  if (selloutDataLoading) {
    return (
      <Card {...cardProps}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }
  if (isEmpty(selloutData)) {
    return (
      <Card {...cardProps}>
        <EmptyState title="No data" />
      </Card>
    )
  }

  return (
    <Card {...cardProps}>
      <Controls>
        {currentProductType === 'vape' && amplifyAction && (
          <Dropdown onChange={(e) => setChannel(e.target.value)} value={currentChannel} options={CHANNEL_FILTERS} />
        )}
        <Dropdown
          onChange={(e) => setCurrentManufacturer(e.target.value)}
          value={currentManufacturer}
          options={MANUFACTURERS_DROPDOWN_OPTIONS}
        />
      </Controls>
      <div className="h-[244px] w-full pt-5">
        <ResponsiveContainer height="99%" width="99%">
          <LineChart data={selloutData} margin={{ bottom: 0, left: 0, right: 10, top: 10 }}>
            <CartesianGrid stroke={theme.slate[300]} vertical={false} />
            <XAxis
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              dataKey="name"
              dy={10}
              stroke={theme.slate[500]}
              tickLine={false}
            />
            <YAxis
              domain={generateYAxis({ entries: selloutData, dataFormat })}
              interval="preserveStartEnd"
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              dx={-8}
              stroke={theme.slate[500]}
              tickLine={false}
              tickFormatter={yAxisFormatter}
            />
            <Tooltip
              content={<CustomTooltip dataFormat={dataFormat} />}
              wrapperStyle={{
                outline: 'none'
              }}
              animationDuration={300}
              allowEscapeViewBox={{ x: true }}
              cursor={false}
              offset={0}
            />
            <Line
              dataKey="data"
              strokeWidth={3}
              stroke="#69AD55"
              dot={false}
              activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <LoadingCard dataKey={dataKey} />
    </Card>
  )
}

SelloutL13TrendGraphCard.propTypes = {
  span: object,
  amplifyAction: func,
  title: string,
  currentChannel: string,
  setChannel: func,
  currentProportion: string,
  error: string,
  dataType: string,
  dataKey: string,
  selloutDataLoading: bool
}

export default SelloutL13TrendGraphCard
