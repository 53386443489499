import React from 'react'
import { Outlet } from 'react-router-dom'
import noAuth from 'layouts/authWrappers/noAuth'
import nodLogo from 'static/images/logo.svg'
import styled from 'styled-components'
import InlineSVG from 'svg-inline-react'

import { greyLight } from 'styles/colors'
import * as spacing from 'styles/spacing'

const Content = styled.div`
  padding: ${spacing.xxxLarge} ${spacing.xLarge};
  margin: 0 auto;
  max-width: 440px;
  text-align: center;
`

const Logo = styled(InlineSVG)`
  display: inline-block;
  width: 56px;
  fill: ${greyLight};
  margin-bottom: ${spacing.xxLarge};
`

const PublicLayout = () => (
  <Content>
    <Logo src={nodLogo} raw />
    <Outlet />
  </Content>
)

export default noAuth(PublicLayout)
