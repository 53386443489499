export const trendingReducer = (state, { payload: { id, trending } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        trending
      }
    }
  })
}

export const brandReducer = (state, { payload: { id, productType, timeframe, brands } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        brands: {
          ...state[id]?.inventory?.brands,
          [`${productType}-${timeframe}`]: brands
        }
      }
    }
  })
}

export const materialReducer = (state, { payload: { id, productType, timeframe, materials } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        materials: {
          ...state[id]?.inventory?.materials,
          [`${productType}-${timeframe}`]: materials
        }
      }
    }
  })
}

export const geographyReducer = (
  state,
  { payload: { id, geographies, geography, ownership, brand, material, productType, timeframe } }
) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        geographies: {
          ...state[id]?.inventory?.geographies,
          [`${geography}-${ownership}-${brand}-${material}-${productType}-${timeframe}`]: geographies
        }
      }
    }
  })
}

export const filtersReducer = (state, { payload: { id, filters, productType } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        filters: {
          ...state[id]?.inventory?.filters,
          [productType]: filters
        }
      }
    }
  })
}
