import queryString from 'query-string'

import { api } from 'store/api'

const getRoot = (sectorType, sectorId) => `/inventory-oos/${sectorType}/${sectorId}`
export const getTrending = ({ sectorType, sectorId }) => api.get(`/inventory-oos/${sectorType}/${sectorId}/trending`)

export const getBrands = ({ sectorType, sectorId, productType, timeframe }) =>
  api.get(`${getRoot(sectorType, sectorId)}/brands?${queryString.stringify({ productType, timeframe })}`)

export const getMaterials = ({ sectorType, sectorId, productType, timeframe }) =>
  api.get(`${getRoot(sectorType, sectorId)}/materials?${queryString.stringify({ productType, timeframe })}`)

export const getGeography = ({ sectorType, sectorId, geography, ownership, brand, material, productType, timeframe }) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/geography?${queryString.stringify({
      geography,
      ownership,
      brand,
      material,
      productType,
      timeframe
    })}`
  )

export const getFilters = ({ sectorType, sectorId, productType }) =>
  api.get(`${getRoot(sectorType, sectorId)}/filters?${queryString.stringify({ productType })}`)
