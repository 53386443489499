import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'

import { sectorFetchSchema } from '../schema'

import * as api from './endpoints'

export const setCurrentSector = createAction('Set Current Sector')
export const setSelectedLevel = createAction('Set Selected Level')
export const setProductType = createAction('Set Product Type')

export const fetchAllSectors = createAction('Fetch all sectors', (params) => async (dispatch) => {
  try {
    const { data } = await api.fetchAllSectors(params)
    return data.sectors
  } catch (err) {
    console.log({ err })
  }
})

export const fetchCurrentSector = createAction(
  'Fetch current sector',
  ({ sectorType, sectorId }) =>
    async (dispatch) => {
      try {
        const { data } = await api.fetchCurrentSector({ sectorType, sectorId })
        const { entities } = normalize(data, sectorFetchSchema)
        dispatch(mergeEntities(entities))
        dispatch(setCurrentSector(data))
        dispatch(setSelectedLevel(sectorType))
      } catch (err) {
        console.log({ err })
      }
    }
)
