import React from 'react'
import { useParams } from 'react-router-dom'
import SurveyForm from 'views/SurveyForm'
import TerritorySurveyCustomers from 'views/TerritorySurveyCustomers'

import { SECTOR_LEVELS } from 'utils/constants'

const SurveyActionSwitcher = () => {
  const { sectorType } = useParams()

  return (
    <>
      {sectorType === SECTOR_LEVELS.CUSTOMER && <SurveyForm />}
      {sectorType === SECTOR_LEVELS.TERRITORY && <TerritorySurveyCustomers />}
    </>
  )
}

export default SurveyActionSwitcher
