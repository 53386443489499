import styled from 'styled-components'

import { greyDark } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontSemibold, tinyFontSize } from 'styles/typography'

export default styled.label`
  display: block;
  margin-bottom: ${spacing.small};
  font-size: ${tinyFontSize};
  font-weight: ${fontSemibold};
  text-align: left;
  color: ${greyDark};
`
