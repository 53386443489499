import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { capitalize, isEmpty } from 'lodash'
import { func, number, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchSellinGeography } from 'store/Sellin/actions'
import { sellinGeographicalData } from 'store/Sellin/selectors'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import Pagination from 'components/Pagination'
import SegmentControl from 'components/SegmentControl'
import Spinner from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import {
  CURRENT_COMPARISONS_OPTIONS,
  DATAKEY_TYPES,
  DEFAULT_TABLE_PAGE_SIZE,
  DISABLE_TOGO_PERIOD,
  PERIOD_FILTERS
} from 'utils/constants'
import { createDataKey, getErrorMessage, parseNumberString } from 'utils/helpers'

import GeographyToggle from '../GeographyToggle'

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const OrderCompletionBar = ({ orderCompletion, completedOrders, completedGap }) => {
  const completedPercent = orderCompletion * 100

  return (
    <div className="flex">
      <div className="h-10 w-40 rounded-md bg-slate-100 ring-1 ring-slate-500">
        <div
          className="h-full rounded-md"
          style={{ backgroundColor: '#53CCF8', width: `${Math.max(completedPercent, 25)}%` }}
        >
          <div className="p-2 pl-3 text-lg font-bold text-slate-100">{`${completedOrders || '0'}`}</div>
        </div>
      </div>
      <div className="p-2 pl-3 text-lg font-bold">{completedGap ? completedOrders - completedGap : '0'}</div>
    </div>
  )
}

OrderCompletionBar.propTypes = {
  orderCompletion: number,
  completedOrders: number,
  completedGap: number
}

const AmplifySellinTableCard = ({ span, fetchSellinGeography, vapeCategory }) => {
  const { formatMessage } = useContext(LangContext)

  const translatedPeriods = Object.values(PERIOD_FILTERS).map((p) => ({
    value: p,
    label: formatMessage({ messageId: `period.${p}` })
  }))

  const [range, setRange] = useState(CURRENT_COMPARISONS_OPTIONS[0].value)
  const [period, setPeriod] = useState(translatedPeriods[0].value)
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [page, setPage] = useState(1)

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const COLS = [
    {
      field: 'name',
      headerName: 'Geo'
    },
    {
      field: 'contribution',
      headerName: formatMessage({ messageId: 'sellIn.contribution' })
    },
    {
      field: 'booked',
      headerName: formatMessage({ messageId: 'sellIn.booked' })
    },
    {
      field: 'gap',
      headerName: formatMessage({ messageId: 'sellIn.gap' })
    },
    {
      field: 'vstarget',
      headerName: formatMessage({ messageId: 'sellIn.vsTarget' })
    },
    {
      field: 'vssply',
      headerName: formatMessage({ messageId: 'sellIn.vsSPLY' })
    },
    {
      field: 'bookedtrend',
      headerName: formatMessage({ messageId: 'sellIn.bookedL13' })
    },
    {
      field: 'orderCompletion',
      headerName: formatMessage({ messageId: 'sellIn.orderCompletion' })
    }
  ]

  const { sectorId, sectorType } = useParams()
  const { currentProductType } = useContext(SectorContext)

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_IN.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    currentProductType,
    vapeCategory,
    period,
    range,
    offset
  })

  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const geographyData = useSelector((state) =>
    sellinGeographicalData(state, {
      activeProductType: currentProductType,
      geography,
      vapeCategory,
      period,
      range,
      offset
    })
  )

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  COLS[0].headerName = capitalize(geography)

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setPage(1)
  }, [geography])

  useEffect(() => {
    fetchSellinGeography(
      {
        sectorType,
        sectorId,
        geography,
        period,
        currentProductType,
        vapeCategory,
        range,
        offset,
        limit: DEFAULT_TABLE_PAGE_SIZE
      },
      dataKey
    )
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorId, sectorType, geography, currentProductType, period, vapeCategory, range, offset])

  const tableData = useMemo(() => {
    if (isGeographyLoading) return []
    if (Object.values(geographyData)?.length) {
      return Object.values(geographyData).map(
        ({
          linkTo,
          name,
          contribution,
          vsTarget,
          booked,
          gap,
          vsLastYear,
          orderCompletion,
          completedOrders,
          completedGap,
          trended
        }) => {
          return {
            name: linkTo ? <Link to={linkTo}>{name}</Link> : name,
            contribution: `${contribution ? parseNumberString(contribution * 100) : '-'} %`,
            booked: (
              <Tooltip isNumber hint={booked}>
                {booked ? parseNumberString(booked) : '-'}
              </Tooltip>
            ),
            gap: (
              <Tooltip isNumber hint={gap}>
                {!isNaN(gap) && gap > 0 ? '+' : ''}
                {!isNaN(gap) ? parseNumberString(gap) : '-'}
              </Tooltip>
            ),
            vstarget: (
              <Tooltip isNumber hint={vsTarget * 100}>
                {vsTarget ? parseNumberString(vsTarget * 100) : '-'} %
              </Tooltip>
            ),
            vssply: <DataVariation peer variation={vsLastYear ? vsLastYear * 100 : 0} isPercent stacked />,
            bookedtrend: <DataGraph color="#53CCF8" data={trended} />,
            orderCompletion: (
              <OrderCompletionBar
                orderCompletion={orderCompletion}
                completedOrders={completedOrders}
                completedGap={completedGap}
              />
            )
          }
        }
      )
    }
    return []
  }, [geographyData, geography])

  const renderContent = (rows) => {
    if (isGeographyLoading) {
      return (
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      )
    }

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    if (!rows || !rows.length) {
      return <EmptyState title="Nothing to display" />
    }

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }

  return (
    <Card
      title={`${capitalize(geography)} performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown key={0} value={period} onChange={(e) => setPeriod(e.target.value)} options={translatedPeriods} />,
        <SegmentControl
          key={1}
          name="range"
          onChange={(e) => setRange(e.target.value)}
          value={range}
          options={CURRENT_COMPARISONS_OPTIONS}
          disabled={DISABLE_TOGO_PERIOD.includes(period)}
        />,
        <GeographyToggle key={2} geography={geography} setGeography={setGeography} includeBrand includeOwnership />
      ]}
      hideOnSmallScreens={true}
      actions={[
        <Pagination
          key="pagination"
          currentPage={page}
          onClickPrev={prevPage}
          onClickNext={nextPage}
          disabled={isGeographyLoading}
        />
      ]}
    >
      {renderContent(tableData)}
    </Card>
  )
}

AmplifySellinTableCard.propTypes = {
  span: number,
  fetchSellinGeography: func,
  vapeCategory: string
}

export default connect(null, { fetchSellinGeography })(AmplifySellinTableCard)
