import React from 'react'
import { useParams } from 'react-router-dom'
import ProgramList from 'views/Customer/ProgramList'
import TerritoryExtraFund from 'views/TerritoryPerformance/TerritoryExtraFund'

import { SECTOR_LEVELS } from 'utils/constants'

const ExtraFundSwitcher = () => {
  const { sectorType } = useParams()

  return (
    <>
      {sectorType === SECTOR_LEVELS.TERRITORY && <TerritoryExtraFund />}
      {sectorType === SECTOR_LEVELS.CUSTOMER && <ProgramList />}
    </>
  )
}

export default ExtraFundSwitcher
