import React from 'react'
import { mix } from 'polished'
import { bool, number, object } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { borderColor, greyDark, offWhite, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 104px;
  margin-right: 10px;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  `};
`

const Button = styled.button`
  border-radius: ${borderRadius};
  flex-shrink: 0;
  width: ${spacing.xLarge};
  height: ${spacing.xLarge};
  background-color: ${white};
  box-shadow: inset 0 0 0 1px ${borderColor};
  transition: background-color ${animationTime} ${animationCurve};

  &:hover,
  &:focus,
  &:active {
    background-color: ${mix(0.4, offWhite, white)};
  }
`

const Input = styled.input`
  width: ${spacing.xxxLarge};
  text-align: center;
  color: ${greyDark};
  border: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const getInputFieldProps = ({ input, field, name, onChange, onBlur, value }) => {
  return input || field || { name, onChange, onBlur, value } // redux-form provides input, formik provides field
}

function QuantitySelect({ disabled, min, max, defaultValue, ...props }) {
  const inputFieldProps = getInputFieldProps(props)

  const updateValue = (value) => {
    if (props.form) {
      // Formik
      props.form.setFieldValue(inputFieldProps.name, value)
    } else {
      // Redux-Forms
      inputFieldProps.onChange(value)
    }
  }

  const increment = () => {
    const value = inputFieldProps.value || 0
    if ((max || max === 0) && inputFieldProps.value >= max) {
      updateValue(max)
    } else {
      updateValue(value + 1)
    }
  }

  const decrement = () => {
    const value = inputFieldProps.value || 0
    if ((min || min === 0) && value <= min) {
      updateValue(min)
    } else {
      updateValue(value - 1)
    }
  }

  return (
    <Container disabled={disabled}>
      <Button type="button" onClick={decrement} disabled={min && inputFieldProps.value <= min}>
        <Icon icon="minus-small" accent />
      </Button>
      <Input type="number" {...inputFieldProps} {...props} value={inputFieldProps.value || defaultValue || 0} />
      <Button type="button" onClick={increment} disabled={max && inputFieldProps.value >= max}>
        <Icon icon="plus-small" accent />
      </Button>
    </Container>
  )
}

QuantitySelect.propTypes = {
  form: object,
  input: object,
  field: object,
  disabled: bool,
  min: number,
  max: number,
  defaultValue: number
}
export default QuantitySelect
