import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import config from 'config'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import { SECTOR_LEVELS } from 'utils/constants'

import ActionTile from './ActionTile'

const Container = tw.div`grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-5 gap-4 justify-center`

const getScanHref = (sectorType, sectorId, employee) => {
  let goScanHref = `${config.ppScannerUrl}/${employee.preferredLanguage.substring(0, 2)}`

  if (sectorType === SECTOR_LEVELS.CUSTOMER) {
    goScanHref += `/erp/${sectorId}`
  }

  const userToken = localStorage.getItem('token')
  goScanHref += `?sessionToken=${userToken}`

  return goScanHref
}
const ActionIndex = () => {
  const { formatMessage } = useContext(LangContext)
  const { sectorType, sectorId } = useParams()
  const employee = useSelector((state) => state.auth.user)

  const TILES = [
    {
      title: formatMessage({ messageId: 'actions.pricing' }),
      description: formatMessage({ messageId: 'actions.pricing.description' }),
      to: 'pricing',
      order: 1
    },
    {
      title: formatMessage({ messageId: 'actions.orders' }),
      description: formatMessage({ messageId: 'actions.orders.description' }),
      to: 'orders',
      order: 2
    },
    {
      title: formatMessage({ messageId: 'actions.surveys' }),
      description: formatMessage({ messageId: 'actions.surveys.description' }),
      to: 'surveys',
      order: 4
    },
    {
      title: formatMessage({ messageId: 'actions.funds' }),
      description: formatMessage({ messageId: 'actions.funds.description' }),
      to: 'funds',
      order: 5
    },
    {
      title: formatMessage({ messageId: 'actions.intel' }),
      description: formatMessage({ messageId: 'actions.intel.description' }),
      to: 'intel',
      order: 6
    },
    {
      title: formatMessage({ messageId: 'actions.sellIn' }),
      description: formatMessage({ messageId: 'actions.sellIn.description' }),
      to: 'sell-in',
      order: 7
    },
    {
      title: formatMessage({ messageId: 'actions.comments' }),
      description: formatMessage({ messageId: 'actions.comments.description' }),
      to: 'comments',
      order: 8
    }
  ]

  const tiles = useMemo(() => {
    return TILES.concat({
      title: formatMessage({ messageId: 'actions.returns' }),
      description: formatMessage({ messageId: 'actions.returns.description' }),
      order: 3,
      href: getScanHref(sectorType, sectorId, employee)
    })
  }, [sectorType, sectorId, employee])

  return (
    <Container>
      {tiles.map((tile) => (
        <ActionTile key={tile.title} content={tile} />
      ))}
    </Container>
  )
}

export default ActionIndex
