import React, { useContext } from 'react'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import Dropdown from 'components/Dropdown'
import ProductTypePicker from 'components/ProductTypePicker'
import ViewHeader from 'components/ViewHeader'

import { VAPE_PRODUCT_CATEGORY } from 'utils/constants'

import SellinContext from './SellinContext'
const SellinHeader = ({ parentHeader, header }) => {
  const { vapeCategory, setVapeCategory } = useContext(SellinContext)
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)
  const filters = []
    .concat(!isWideLayout && <ProductTypePicker key="amplify-sell-in-product-type-picker" />)
    .concat(
      currentProductType === 'vape' && (
        <Dropdown
          key="amplify-sell-in-vape-category-picker"
          name="vape-category"
          onChange={(e) => setVapeCategory(e.target.value)}
          value={vapeCategory}
          options={VAPE_PRODUCT_CATEGORY}
        />
      )
    )
    .filter(Boolean)
  return <ViewHeader parentHeader={parentHeader} header={header} filters={filters} />
}

SellinHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default SellinHeader
