import React, { useContext, useMemo } from 'react'
import { Field, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import { arrayOf, bool, number, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Input from 'components/Input'
import Table, { StyledTable } from 'components/table'

import { AGREEMENT_TERMS } from 'utils/constants'

const NumberInput = ({ form, field, max, min = 0, ...props }) => {
  const { value, name } = field
  if (max && value > max) {
    form.setFieldValue(name, max)
  }
  if (value < min) form.setFieldValue(name, min)
  return <Input {...{ form, field, ...props }} />
}

NumberInput.propTypes = {
  form: object,
  field: object,
  max: number,
  min: number
}

const SkuExceptionTable = ({ skus }) => {
  const { preferredLanguage } = useContext(LangContext)
  const formikContext = useFormikContext()

  const resetToSuggested = () => {
    formikContext.setFieldValue('exception.skus', formikContext?.initialValues.exception.skus)
  }
  return (
    <Table
      data={skus}
      columns={[
        {
          name: 'productId',
          header: 'Product',
          body: {
            labelGenFn: (record) => record.product[`${preferredLanguage}Name`]
          },
          footer: {
            type: 'customcell',
            render: (record) => <td colSpan="3">&nbsp;</td>
          }
        },
        {
          name: 'minQty',
          header: 'Min Qty',
          footer: {
            type: 'customcell',
            render: (record) => null
          }
        },
        {
          name: 'newMinQty',
          header: 'New min',
          body: {
            type: 'customcell',
            render: (record) => {
              return (
                <Field
                  name={`exception.skus.p${record.productId}.minQty`}
                  component={NumberInput}
                  small
                  type="number"
                  minQty={0}
                  validate={(v) => {
                    if (!v && v !== 0) return 'Required'
                    if (v < 0) return `Min: 0`
                  }}
                />
              )
            }
          },
          footer: {
            type: 'customcell',
            render: (record) => null
          }
        },
        {
          name: 'maxQty',
          header: 'Max Qty',
          footer: {
            type: 'customcell',
            render: (records, column) => {
              return (
                <td colSpan="2">
                  <Button type="button" primary onClick={resetToSuggested}>
                    Reset min/max
                  </Button>
                </td>
              )
            }
          }
        },
        {
          name: 'newMaxQty',
          header: 'New max',
          body: {
            type: 'customcell',
            render: (record) => {
              return (
                <Field
                  name={`exception.skus.p${record.productId}.maxQty`}
                  component={NumberInput}
                  small
                  type="number"
                  min={0}
                  validate={(v) => {
                    const { minQty } = formikContext.values.exception.skus[`p${record.productId}`]
                    if (!v && v !== 0) return 'Required'
                    if (v <= minQty) return 'Max too low'
                  }}
                />
              )
            }
          },
          footer: {
            type: 'customcell',
            render: (record) => null
          }
        }
      ]}
    />
  )
}

SkuExceptionTable.propTypes = {
  skus: arrayOf(object)
}

const RenderSkuTableForm = ({ skus, preferredLanguage, totalSuggestedQty }) => {
  const formikContext = useFormikContext() // todo: verify that this doesn't break. used to be `isForm && useFormikContext()`

  const totalFinalQty = useMemo(() => {
    if (!isEmpty(formikContext?.values?.finalQtys)) {
      return sum(Object.values(formikContext.values.finalQtys).filter(Boolean))
    }
    return sumBy(skus, ({ finalQty }) => +finalQty || 0)
  }, [formikContext?.values?.finalQtys, skus])

  const originalSuggestedQtys = useMemo(
    () => skus.reduce((acc, { productId, suggestedQty }) => ({ ...acc, [`p${productId}`]: suggestedQty }), {}),
    [skus]
  )
  const resetToSuggested = () => {
    formikContext && formikContext.setFieldValue('finalQtys', originalSuggestedQtys)
  }

  return (
    <StyledTable>
      <tbody>
        <tr>
          <th>Product</th>
          <th>Suggested Qty</th>
          <th>Final Qty</th>
          <th>Min Qty</th>
          <th>Max Qty</th>
        </tr>
        {skus.map(({ product, productId, suggestedQty, finalQty, minQty, maxQty, isAssigned }) => {
          return (
            <tr key={productId}>
              <td>{product[`${preferredLanguage}Name`]}</td>
              <td>{suggestedQty}</td>
              <td>
                <Field
                  name={`finalQtys.p${productId}`}
                  component={NumberInput}
                  placeholder="0"
                  small
                  type="number"
                  min={isAssigned ? minQty : 0}
                  max={maxQty}
                  validate={(v) => {
                    if (!isAssigned && !v) return
                    if (v < minQty) return `Min: ${minQty}`
                    if (v > maxQty) return `Max: ${maxQty}`
                  }}
                />
              </td>
              <td>{minQty}</td>
              <td>{maxQty}</td>
            </tr>
          )
        })}
        <tr>
          <th className="footer">Total</th>
          <td>{totalSuggestedQty}</td>
          <td>
            <b>{totalFinalQty}</b>
          </td>
          <td colSpan="2">
            <Button type="button" primary onClick={resetToSuggested}>
              Reset to suggested
            </Button>
          </td>
        </tr>
      </tbody>
    </StyledTable>
  )
}

RenderSkuTableForm.propTypes = {
  skus: arrayOf(object),
  preferredLanguage: string,
  totalSuggestedQty: number
}

const ReadOnlySkuTable = ({ skus, preferredLanguage, totalSuggestedQty }) => {
  const totalFinalQty = useMemo(() => {
    return sumBy(skus, ({ finalQty }) => +finalQty || 0)
  }, [skus])

  return (
    <StyledTable>
      <tbody>
        <tr>
          <th>Product</th>
          <th>Suggested Qty</th>
          <th>Final Qty</th>
          <th>Min Qty</th>
          <th>Max Qty</th>
        </tr>
        {skus.map(({ product, productId, suggestedQty, finalQty, minQty, maxQty, isAssigned }) => {
          return (
            <tr key={productId}>
              <td>{product[`${preferredLanguage}Name`]}</td>
              <td>{suggestedQty}</td>
              <td>
                <b>{finalQty || '-'}</b>
              </td>
              <td>{minQty}</td>
              <td>{maxQty}</td>
            </tr>
          )
        })}
        <tr>
          <th className="footer">Total</th>
          <td>{totalSuggestedQty}</td>
          <td>
            <b>{totalFinalQty}</b>
          </td>
          <td colSpan="2"></td>
        </tr>
      </tbody>
    </StyledTable>
  )
}

ReadOnlySkuTable.propTypes = {
  skus: arrayOf(object),
  preferredLanguage: string,
  totalSuggestedQty: number
}

const SkuTable = ({ skus, isForm, exceptionType }) => {
  const { preferredLanguage } = useContext(LangContext)
  const totalSuggestedQty = sumBy(skus, ({ suggestedQty }) => +suggestedQty || 0)
  const isMinMaxException = exceptionType === AGREEMENT_TERMS
  if (isMinMaxException) return <SkuExceptionTable skus={skus} />
  const RenderSkuTable = isForm ? RenderSkuTableForm : ReadOnlySkuTable
  return <RenderSkuTable skus={skus} preferredLanguage={preferredLanguage} totalSuggestedQty={totalSuggestedQty} />
}

SkuTable.propTypes = {
  skus: arrayOf(object),
  isForm: bool,
  exceptionType: string
}

export default SkuTable
