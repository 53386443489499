import React, { Children, cloneElement, useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { stripUnit } from 'polished'
import { any, bool, node, number, object, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'
import Pill from 'components/Pill'
import QuantitySelect from 'components/QuantitySelect'

import { MAX_ZONNIC_CTNS, SKU_MAX_QTY_BY_BANNER, ZONNIC_MATERIAL_GROUP_CODE } from 'utils/constants'

import { borderColor, red, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};

  ${(props) =>
    !props.isSubrow &&
    media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:last-child {
    border-bottom: ${(props) => (props.isSubrow ? '0' : '1')}px solid ${borderColor};
    ${(props) =>
      props.isSubrow &&
      `
      padding-bottom: 0;
    `};
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacing.medium};
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled.main`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  padding: ${spacing.medium};
  padding-top: 0;
  ${(props) =>
    props.hasSubrows &&
    `
    padding-bottom: 0;
  `};
`

const Quantity = styled(Pill)`
  margin-left: auto;
`

const Entry = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing.xLarge};
  margin: 0;

  & + & {
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  margin-right: ${spacing.small};
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(90deg)' : 'none')};
`
const ErrorMessage = styled.small`
  color: ${red};
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.small};
  font-size: ${tinyFontSize};
`
function productIsZonnicAndRestricted(materialGroupCode) {
  return materialGroupCode === ZONNIC_MATERIAL_GROUP_CODE && new Date() < new Date('2023-11-18')
}

function SAQAccordion({
  brand,
  products,
  order,
  children,
  isSubrow,
  parentCollapsed,
  parent,
  hasSubrows,
  totalProducts,
  isRestrictedBannerInRestrictedPeriod
}) {
  const [expanded, setExpanded] = useState(false)
  const minimalValue = 0
  const formikContext = useFormikContext()

  useEffect(() => {
    parentCollapsed && setExpanded(false)
  }, [parentCollapsed])

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const validateQuantity = (quantity, materialGroupCode) => {
    if (+quantity < minimalValue) return `You can't order less than ${minimalValue} items`
    if (productIsZonnicAndRestricted(materialGroupCode) && +quantity > MAX_ZONNIC_CTNS)
      return `You cannot order more than ${MAX_ZONNIC_CTNS} Zonnic products`
  }

  return (
    <Container expanded={expanded} isSubrow={isSubrow}>
      <Header onClick={toggleExpanded}>
        <TitleWrap>
          <Chevron icon="right-chevron" accent expanded={expanded} />
          <H3 className="notranslate" translate="no">
            {brand.replace('Players', "Player's")}
          </H3>
        </TitleWrap>
        {totalProducts > 0 && <Quantity>{totalProducts}</Quantity>}
      </Header>
      <Content expanded={expanded} hasSubrows={Boolean(children)}>
        {children && Children.map(children, (child) => cloneElement(child, { parentCollapsed: !expanded }))}
        {products &&
          products.length &&
          products.map(({ name, id, materialGroupCode }) => {
            const disabled = isRestrictedBannerInRestrictedPeriod && SKU_MAX_QTY_BY_BANNER.skus.includes(id)
            const max = productIsZonnicAndRestricted(materialGroupCode) ? { max: 10 } : null
            return (
              <Entry key={`${brand}-${id}`}>
                <Row>
                  <small className="notranslate" translate="no">
                    {name}
                  </small>
                  <Field
                    name={`${parent}.${brand}.p${id}`}
                    component={QuantitySelect}
                    type="number"
                    min={minimalValue}
                    validate={(value) => validateQuantity(value, materialGroupCode)}
                    disabled={disabled}
                    {...max}
                  />
                </Row>
                {formikContext.errors &&
                  formikContext.errors[parent] &&
                  formikContext.errors[parent][brand] &&
                  formikContext.errors[parent][brand][`p${id}`] && (
                    <ErrorMessage>{formikContext.errors[parent][brand][`p${id}`]}</ErrorMessage>
                  )}
              </Entry>
            )
          })}
      </Content>
    </Container>
  )
}

SAQAccordion.propTypes = {
  products: any,
  brand: string,
  order: object,
  children: node,
  isSubrow: bool,
  parentCollapsed: bool,
  parent: string,
  hasSubrows: bool,
  totalProducts: number,
  isRestrictedBannerInRestrictedPeriod: bool
}

export default SAQAccordion
