import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment-timezone'
import { array, func, string } from 'prop-types'

import CalendarWrapper from 'components/CalendarWrapper'

const CalendarSchedule = ({
  events,
  onEventClick,
  selectedDate,
  setSelectedDate,
  workDayStart = '07:00:00',
  ...rest
}) => {
  const mounted = useRef(false)
  const calendarRef = useRef()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const [eventClickParams, setEventClickParams] = useState()

  useEffect(() => {
    if (eventClickParams) {
      onEventClick(eventClickParams)
      if (mounted.current) {
        setEventClickParams()
      }
    }
  }, [eventClickParams, onEventClick])

  const handleDateNavigation = ({ startStr, endStr }) => {
    if (!calendarRef?.current?.calendar) return
    const isInCurrentWorkday = moment().isBetween(moment(startStr), moment(endStr)) && moment().hour() < 20
    const timeToScroll = isInCurrentWorkday ? moment().subtract(1, 'hour').format('HH:mm:ss') : workDayStart
    calendarRef.current.calendar.scrollToTime(timeToScroll)
    setSelectedDate(startStr)
  }

  return (
    <CalendarWrapper>
      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin]}
        initialView={'timeGridWeek'}
        headerToolbar={{
          end: 'timeGridWeek,timeGridDay today prev,next'
        }}
        events={events}
        eventClick={onEventClick}
        navLinks
        allDaySlot={false}
        nowIndicator
        firstDay={1}
        height={'calc(100vh - 132px)'}
        expandRows
        scrollTime={workDayStart}
        slotMinTime={'05:00:00'}
        dayHeaderFormat={{ weekday: 'short', day: 'numeric' }}
        eventMinHeight={45}
        datesSet={handleDateNavigation}
        slotDuration={'00:10:00'}
      />
    </CalendarWrapper>
  )
}

CalendarSchedule.propTypes = {
  // timezone: string.isRequired,
  workDayStart: string,
  events: array,
  onEventClick: func,
  selectedDate: string,
  setSelectedDate: func
}

export default CalendarSchedule
