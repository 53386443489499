import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import styled from 'styled-components'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Button from 'components/button/Button'
import Container from 'components/Container'
import MileageRow from 'components/schedule/MileageRow'
import Section from 'components/Section'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H1 } from 'styles/typography'

const Overview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spacing.medium};

  ${media.breakpoint`
    padding: 0;
  `};
`

const Label = styled.small`
  display: block;
`

const getDateMonth = (kms) => {
  const date = new Date(kms.dateDriven)
  return new Date(date.getFullYear(), date.getMonth())
}

const Mileage = () => {
  const mileages = useSelector(({ auth }) => auth.user.mileage || [])

  const totalMileage = sumBy(mileages, 'kms')
  const groupedMileage = groupBy(mileages, getDateMonth)

  const renderGroupedMilleage = (groups) => {
    return Object.entries(groups).map(([date, mileageList]) => {
      const dateString = new Date(date).toLocaleString('default', { month: 'short', year: 'numeric' })
      const totalKms = sumBy(mileageList, 'kms')
      return (
        <BasicAccordion key={dateString} title={dateString} addOn={`${totalKms.toFixed(3)} km`}>
          {mileageList && renderMileageRows(mileageList)}
        </BasicAccordion>
      )
    })
  }

  const renderMileageRows = (rows) => {
    return rows.map((row, idx) => {
      const { dateDriven, type, kms, customerAddress } = row
      const address = customerAddress?.address ? Object.values(customerAddress?.address).join(', ') : null
      const dateString = new Date(dateDriven).toLocaleString('default', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })
      return (
        <MileageRow
          key={`mileage-row-${idx}`}
          date={dateString}
          address={address}
          type={type}
          distance={kms}
          mileage={row}
        />
      )
    })
  }

  return (
    <Fragment>
      <Container>
        <Section>
          <Overview>
            <div>
              <Label>This month</Label>
              <H1>{totalMileage.toFixed(3)} km</H1>
            </div>
            <Button href="https://insights.holman.com/Driver" target="_blank" secondary small>
              Holman Driver Insights
            </Button>
          </Overview>
        </Section>
        <Section>{groupedMileage && renderGroupedMilleage(groupedMileage)}</Section>
      </Container>
    </Fragment>
  )
}

export default Mileage
