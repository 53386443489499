import config from 'config'
import moment from 'moment'

// export const isPricingAnalyticsEnabled = user => {
//   if (config.pricingAnalyticsEnabled === 'ALL') return true
//   if (config.pricingAnalyticsEnabled === 'BETA' && user?.email && user?.isBetaTester) return true
//   return false
// }

const vfBlackoutEnd = moment.utc('2021-04-17T00:30:00') // Apr 16th, 8:30pm EDT
const nonVFBlackoutEnd = moment.utc('2021-04-20T00:30:00') // Apr 19th, 8:30pm EDT
const vfBlackoutStart = moment.utc('2021-04-14T00:30:00') // Apr 13th, 8:30 pm EDT
const nonVFBlackoutStart = moment.utc('2021-04-10T00:30:00') // Apr 9th, 8:30pm EDT

export const isSAQBlackout = (employee) => {
  const blackoutStart = ['telesalesRepresentative', 'customerService'].includes(employee.groupCode)
    ? vfBlackoutStart
    : nonVFBlackoutStart

  const blackoutEnd = employee.groupCode === 'telesalesRepresentative' ? vfBlackoutEnd : nonVFBlackoutEnd
  return moment().isBetween(blackoutStart, blackoutEnd)
}

export const isTCPEnabled = (employee) => {
  if (!config.tcpEnabled) return false
  if (config.tcpEnabled === 'PILOT') return employee.isBetaTester
  return config.tcpEnabled === 'ALL'
}

export const isAutoSchedulingEnabled = (employee) => {
  if (!config.autoSchedulingEnabled) return false
  if (config.autoSchedulingEnabled === 'PILOT') return employee.isBetaTester
  return config.autoSchedulingEnabled === 'ALL'
}
