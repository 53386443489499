// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bool, func, number, object, string } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { fetchPlanningHealthCheck } from 'store/HealthCheck/actions'
import { planningHealthCheckData } from 'store/HealthCheck/selectors'
import { getCurrentProductType } from 'store/sector/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import VariationIndicator from 'components/VariationIndicator'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import InventoryCaptureContext from './InventoryCaptureContext'

const Header = tw.span`block text-2xs font-semibold text-slate-500`

const Data = ({ description, data, isPercentage, variation, additional }) => (
  <div className="flex w-full flex-col items-center space-y-4">
    <Header>{description}</Header>
    <div>
      <span className="text-4xl font-medium">
        {data}
        {isPercentage && '%'}
      </span>
      <div className="flex items-center space-x-2">
        <VariationIndicator variation={variation} />
        <span className="text-2xs font-medium text-slate-500">
          {Math.abs(variation)}pp {additional}
        </span>
      </div>
    </div>
  </div>
)

Data.propTypes = {
  description: string,
  data: number,
  isPercentage: bool,
  variation: number,
  additional: string
}

const Cap = ({ span, fetchPlanningHealthCheck }) => {
  const { currentSector, selectedLevel } = useContext(SectorContext)
  const { timeframe } = useContext(InventoryCaptureContext)
  const isMounted = useRef()

  const [error, setError] = useState()

  const healthCheckData = useSelector((state) => planningHealthCheckData(state))

  const activeProductType = useSelector((state) => getCurrentProductType(state))

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_HEALTH_CHECK, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: activeProductType
  })

  useEffect(() => {
    isMounted.current = true
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchPlanningHealthCheck(
        { id: currentSector[selectedLevel].id, sectorLevel: selectedLevel, activeProductType },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [currentSector, selectedLevel, activeProductType])

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  return (
    <Card title="OOS and inventory capture" span={span} displayAmplify>
      <div className="mt-2 flex">
        <Data
          description="OOS"
          data={healthCheckData.oos.percent}
          isPercentage
          variation={healthCheckData.oos.variation}
          additional={`vs ${timeframe === '1' ? 'PW' : 'P4'}`}
        />
        <Data
          description="Inventory capture"
          data={healthCheckData.inventoryCaptures.percent}
          isPercentage
          variation={healthCheckData.inventoryCaptures.variation}
          additional={`vs ${timeframe === '1' ? 'PW' : 'P4'}`}
        />
      </div>
    </Card>
  )
}

Cap.propTypes = {
  span: object,
  fetchPlanningHealthCheck: func
}

export default connect(null, { fetchPlanningHealthCheck })(Cap)
