import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { bool, func, number } from 'prop-types'
import styled from 'styled-components'

import { saveUserMileage } from 'store/mileage/actions'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import DatePicker from 'components/DatePicker'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Input from 'components/Input'

const Spacer = styled.div`
  height: 175px;
`

const AddMileage = ({ visibleScheduleScreen, setVisibleScheduleScreen, suggestedKms, saveUserMileage }) => {
  const [mileage, setMileage] = useState(0)
  const [date, setDate] = useState(moment())

  const submitMileage = async () => {
    await saveUserMileage({
      dateDriven: moment(date).toISOString(),
      kms: +mileage,
      suggestedKms: +suggestedKms,
      type: 'EOD'
    })
    setVisibleScheduleScreen()
    resetFields()
  }

  const resetFields = () => {
    setMileage()
    setDate(moment())
  }

  const cancelMileage = () => {
    resetFields()
    setVisibleScheduleScreen()
  }

  useEffect(() => {
    setMileage(suggestedKms)
  }, [suggestedKms])

  return (
    <ActionSheet
      title="Add mileage"
      action={
        <button type="button" onClick={() => cancelMileage()}>
          Cancel
        </button>
      }
      visible={visibleScheduleScreen}
    >
      <div>
        <Fieldset>
          <Spacer />
          <FieldsetItem>
            <DatePicker
              label="Date"
              name="Date"
              placeholder="Select a date"
              openDirection="down"
              value={date}
              onChange={(value) => setDate(value)}
              isOutsideRange={() => false}
            />
          </FieldsetItem>
          <Spacer />
          <FieldsetItem>
            <Input
              label="Mileage"
              name="mileage"
              placeholder="0"
              type="number"
              min={0}
              value={String(mileage)}
              onChange={({ target }) => setMileage(+target.value)}
            />
          </FieldsetItem>
          <Spacer />
          <FieldsetItem>
            <Button full primary onClick={submitMileage}>
              Save
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

AddMileage.propTypes = {
  visibleScheduleScreen: bool,
  setVisibleScheduleScreen: func,
  suggestedKms: number,
  saveUserMileage: func.isRequired
}

export default connect(null, { saveUserMileage })(AddMileage)
