import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { rgba } from 'polished'
import { array, func, number, object, string } from 'prop-types'
import styled from 'styled-components'

import CallDurationContext from 'context/CallDurationContext'

import { deleteCustomerCall } from 'store/customerCalls/actions'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Section from 'components/Section'

import { getAddressString, getTimeStringFromSeconds } from 'utils/helpers'

import { greyDark, secondaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontSemibold, H1, smallFontSize } from 'styles/typography'

import Checklist from './Checklist'

const Title = styled(H1)`
  margin-bottom: ${spacing.tiny};

  small {
    display: inline-block;
    margin-left: ${spacing.small};
    vertical-align: text-bottom;
  }
`

const Address = styled.small`
  display: flex;
  align-items: center;
  gap: ${spacing.tiny};
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.medium};

  > div {
    flex: 1;
  }
`

const MetaLabel = styled.span`
  display: block;
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  color: ${greyDark};
`

const CallButton = styled.button`
  display: flex;
  padding: ${spacing.small};
  background-color: ${rgba(secondaryColor, 0.15)};
  border-radius: 50%;
  margin-left: auto;
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    user-select: none;
  `};
`

const CallDurationTimer = (props) => {
  const { ongoingCallSecondsElapsed } = useContext(CallDurationContext)
  return (
    <div>
      <MetaLabel>Duration</MetaLabel>
      <div>{getTimeStringFromSeconds(ongoingCallSecondsElapsed)}</div>
    </div>
  )
}

// call
// updateCall
// callType
// callStartTime
// setIsMinimized
// kms
// note
// completedTasks
// cancelCall
// setKms
// setNote
// setCompletedTasks
const CallDetails = ({
  call,
  updateCall,
  callStartTime,
  setIsMinimized,
  cancelCall,
  completedTasks,
  callType,
  kms,
  note,
  setKms,
  setNote,
  setCompletedTasks
}) => {
  const [saveLoading, setSaveLoading] = useState(false)
  const [globalError, setGlobalError] = useState(null)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const callStart = useMemo(() => call?.callStart || callStartTime, [call, callStartTime])

  const completeOrSaveCall = async () => {
    setSaveLoading(true)
    try {
      setGlobalError(null)
      await updateCall()
    } catch (error) {
      setGlobalError(`
        An error occurred when trying to end your call, please try again!
        Error details : ${JSON.stringify(error.message)}
      `)
    } finally {
      if (mounted.current) {
        setSaveLoading(false)
      }
    }
  }

  const isInPerson = useMemo(() => callType && callType === 'in-person', [callType])

  const validMileage = useMemo(() => (isInPerson ? kms : true), [kms, isInPerson])

  // const hasOrders = useMemo(() => {
  //   return call?.orders?.length || false
  // }, [call])

  const customerAddress = getAddressString(call?.customer?.address, ['line1', 'city'])
  return (
    <>
      <ActionSheet
        title="Ongoing call"
        action={
          <button type="button" onClick={() => setIsMinimized(true)}>
            Minimize
          </button>
        }
        visible
        onOverlayClick={() => setIsMinimized(true)}
      >
        <Container>
          <Section
            as={Link}
            to={{
              pathname: `/customers/${call?.customer?.id}/calls`,
              state: { prevRoute: { pathname: '/schedule' } }
            }}
            onClick={() => setIsMinimized(true)}
          >
            <Title>
              <span>{call?.customer?.name}</span>
              <small>{call?.customer?.id}</small>
              <small>{call?.customer?.storePhoneNumber}</small>
            </Title>
            <Address>
              <Icon icon="location" small accent />
              <span>{customerAddress}</span>
            </Address>
          </Section>
          <Section>
            <Meta>
              <div>
                <MetaLabel>Start time</MetaLabel>
                <div>{moment(callStart).format('h:mm a')}</div>
              </div>
              <CallDurationTimer />
              {callType === 'telemarketing' && (
                <CallButton disabled>
                  <Icon icon="phone" accent />
                </CallButton>
              )}
            </Meta>
          </Section>
          {Boolean(call.completedTasks?.length || completedTasks?.length) && (
            <Section>
              <Checklist
                items={call.completedTasks ? call.completedTasks : completedTasks}
                onChange={setCompletedTasks}
              />
            </Section>
          )}
          <Section>
            <Fieldset>
              {callType === 'in-person' && (
                <FieldsetItem>
                  <Input
                    label="Mileage"
                    name="mileage"
                    type="number"
                    placeholder="0"
                    value={kms || ''}
                    onChange={({ target }) =>
                      target.value || target.value === 0 ? setKms(target.value) : setKms(null)
                    }
                  />
                </FieldsetItem>
              )}
              <FieldsetItem>
                <Input
                  label="Call notes"
                  name="notes"
                  placeholder="Type notes here..."
                  textarea
                  value={note}
                  maxLength={700}
                  enableCounter
                  onChange={({ target }) => setNote(target.value)}
                />
              </FieldsetItem>
              {!validMileage && (
                <FieldsetItem>
                  <GlobalAlert>Mileage is required to end the call</GlobalAlert>
                </FieldsetItem>
              )}
              {globalError && (
                <FieldsetItem>
                  <GlobalAlert>{globalError}</GlobalAlert>
                </FieldsetItem>
              )}
            </Fieldset>
          </Section>
          <Section>
            <ButtonGroup full>
              <Button
                full
                primary
                isLoading={saveLoading}
                disabled={saveLoading || !validMileage}
                onClick={completeOrSaveCall}
              >
                End call and save
              </Button>
              <Button full secondary isLoading={saveLoading} disabled={saveLoading} onClick={cancelCall}>
                Cancel
              </Button>
            </ButtonGroup>
          </Section>
        </Container>
      </ActionSheet>
    </>
  )
}

CallDetails.propTypes = {
  call: object,
  setIsMinimized: func,
  cancelCall: func,
  callStartTime: number,
  updateCall: func,
  completedTasks: array,
  callType: string,
  kms: string,
  note: string,
  setKms: func,
  setNote: func,
  setCompletedTasks: func
}

export default connect(null, { deleteCustomerCall })(CallDetails)
// export default CallDetails
