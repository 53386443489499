import React, { useCallback, useEffect, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { connect, useSelector } from 'react-redux'
import { rgba } from 'polished'
import { array, bool, func } from 'prop-types'
import styled from 'styled-components'

import { addNewImages, removeImage } from 'store/auth/actions'

import Button from 'components/button/Button'
import Icon from 'components/Icon'

import { black, borderColor, grey, greyLight, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, cover } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: block;
  width: 100%;
  text-align: left;
`

const DropzoneContent = styled.div`
  padding: ${spacing.xLarge};
  border: 1px dashed ${borderColor};
  cursor: pointer;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${greyLight};
  border-radius: ${borderRadius};
  background-color: ${rgba(white, 0.5)};
  transition: color ${animationTime} ${animationCurve};
  margin-bottom: ${spacing.medium};

  &:hover,
  &:focus {
    color: ${grey};
  }

  ${media.breakpoint`
    padding: ${spacing.xxLarge};
  `};
`

const DropzoneIcon = styled(Icon)`
  margin-bottom: ${spacing.small};
`

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${spacing.tiny};
`

const GalleryItem = styled.div`
  width: 25%;
  padding: ${spacing.tiny};
`

const PhotoWrap = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  padding-bottom: 100%;
  overflow: hidden;
`

const Photo = styled.img`
  ${cover('absolute')};
  object-fit: cover;
`

const Remove = styled.button.attrs({
  type: 'button'
})`
  ${cover('absolute')};
  z-index: 10;
  background-color: ${rgba(black, 0.6)};
  color: ${white};
`

const AddButton = styled(Button).attrs({ type: 'button' })`
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.medium};
`

const FileInput = ({ change, addNewImages, existingImages, removeImage, formatMessage, loading }) => {
  const dropzoneRef = useRef()
  const newImages = useSelector(({ auth }) => auth.newImages)

  const removeExistingImage = (id) => {
    change(
      'images',
      existingImages.filter((img) => img.id !== id)
    )
  }

  const removeNewImage = useCallback(
    (preview, idx) => {
      window.URL.revokeObjectURL(preview)
      removeImage(preview)
    },
    [removeImage]
  )

  useEffect(() => {
    return () => {
      newImages.forEach(({ preview }) => {
        removeNewImage(preview)
      })
    }
  }, [])

  const onDrop = (files) => {
    const filesWithPreview = files.map((file) => {
      file.preview = URL.createObjectURL(file)
      return file
    })

    addNewImages(filesWithPreview)
  }

  const noImages = newImages.length + existingImages.length === 0
  const englishOnly = 'Drag and drop or tap to upload your photos'

  return (
    <Container>
      <Dropzone ref={dropzoneRef} onDrop={onDrop} multiple accept={{ 'image/*': [] }} hidden={!noImages}>
        {({ getRootProps, getInputProps }) => (
          <DropzoneContent {...getRootProps()}>
            <input {...getInputProps()} />
            <DropzoneIcon icon="add-photo" />
            {formatMessage ? <span>{formatMessage({ messageId: 'dragDropPhotos' })}</span> : <span>{englishOnly}</span>}
          </DropzoneContent>
        )}
      </Dropzone>
      {!noImages && (
        <>
          <Gallery>
            {existingImages.map(({ preview, id, createdAt }, idx) => (
              <GalleryItem key={idx}>
                <PhotoWrap>
                  <Photo src={preview} alt={new Date(createdAt).toString()} />
                  <Remove onClick={() => removeExistingImage(id)}>
                    <Icon icon="trash" />
                  </Remove>
                </PhotoWrap>
              </GalleryItem>
            ))}
            {newImages.map((imgFile, idx) => (
              <GalleryItem key={idx}>
                <PhotoWrap>
                  <Photo src={imgFile.preview} alt="Intel image" />
                  <Remove onClick={() => removeNewImage(imgFile.preview, idx)}>
                    <Icon icon="trash" />
                  </Remove>
                </PhotoWrap>
              </GalleryItem>
            ))}
          </Gallery>

          <AddButton secondary onClick={() => dropzoneRef.open()} disabled={loading}>
            {formatMessage ? formatMessage({ messageId: 'addMorePhotos' }) : 'Add more photos'}
          </AddButton>
        </>
      )}
    </Container>
  )
}

FileInput.propTypes = {
  addNewImages: func,
  removeImage: func,
  existingImages: array,
  change: func,
  formatMessage: func,
  loading: bool
}

const mapActionCreators = {
  addNewImages,
  removeImage
}

export default connect(null, mapActionCreators)(FileInput)
