import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { AnimatePresence, motion } from 'framer-motion'
import { capitalize } from 'lodash'
import { func, string } from 'prop-types'

import { requestLogout } from 'store/auth/actions'

import Icon from 'components/Icon'

const menuClasses = 'w-[220px] rounded-md shadow-xl bg-white focus-visible:outline-none z-100'

const menuContentClasses = 'p-2 space-y-2 ring-1 ring-slate-900/10 rounded-md'

const menuItemClasses =
  'text-slate-900 rounded-sm h-9 px-2 flex items-center font-medium gap-2 w-full hover:bg-slate-500/5 focus-visible:bg-slate-500/5 focus-visible:text-slate-900 focus-visible:outline-none radix-disabled:pointer-events-none radix-disabled:text-slate-600/30'

const menuVariants = {
  initial: {
    opacity: 0,
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  },
  enter: {
    opacity: 1,
    y: -8,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  }
}

const Item = ({ label, icon, onHandleClick }) => (
  <RadixDropdownMenu.Item asChild>
    <button className={menuItemClasses} onClick={onHandleClick}>
      <div className="flex opacity-90">
        <Icon icon={icon} small />
      </div>
      <span>{label}</span>
    </button>
  </RadixDropdownMenu.Item>
)

Item.propTypes = {
  label: string.isRequired,
  icon: string.isRequired,
  onHandleClick: func.isRequired
}

const rolesLabel = [
  { name: 'telesalesRepresentative', label: 'TM' },
  { name: 'accountRepresentative', label: 'Account rep.' },
  { name: 'regionalManager', label: 'RSM' },
  { name: 'districtManager', label: 'DM' }
]

export const Profile = ({ requestLogout }) => {
  const user = useSelector((state) => state.auth.user)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const userRole = rolesLabel.find((r) => user.groupCode === r.name)

  return (
    <div className="w-full">
      <RadixDropdownMenu.Root open={open} onOpenChange={setOpen}>
        <RadixDropdownMenu.Trigger asChild>
          <button className="flex w-full items-center space-x-4 rounded-md px-2 py-1 text-left transition hover:bg-white/5 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500 radix-state-open:bg-white/5">
            <div className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border border-slate-900/10">
              <img
                src="https://placehold.co/600x400/a5a5a5/FFFFFF/png"
                className="min-h-full min-w-full flex-shrink-0"
              />
            </div>
            <div className="space-y-1">
              <p className="font-medium text-white">
                {capitalize(user.firstName)} {capitalize(user.lastName[0])}.
              </p>
              <p className="text-xs text-white/75">{userRole ? userRole.label : 'Admin'}</p>
            </div>
          </button>
        </RadixDropdownMenu.Trigger>
        <AnimatePresence>
          {open && (
            <RadixDropdownMenu.Content forceMount asChild side="top" align="start">
              <motion.div
                key="menu"
                variants={menuVariants}
                initial="initial"
                animate="enter"
                exit="initial"
                className={menuClasses}
              >
                <div className={menuContentClasses}>
                  <Item label="Settings" icon="settings" onHandleClick={() => navigate('settings')} />
                  {/* <Item label="Help" icon="question-mark" onHandleClick={() => navigate('about')} /> */}
                  <hr className="w-full bg-slate-900/10" />
                  <Item label="Log out" icon="logout" onHandleClick={requestLogout} />
                </div>
              </motion.div>
            </RadixDropdownMenu.Content>
          )}
        </AnimatePresence>
      </RadixDropdownMenu.Root>
    </div>
  )
}

Profile.propTypes = {
  requestLogout: func.isRequired
}

export default connect(null, { requestLogout })(Profile)
