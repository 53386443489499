// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Promise } from 'bluebird'
import { arrayOf, func, object, string } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { fetchPlanningEngagement } from 'store/Engagement/actions'
import { planningEngagementData } from 'store/Engagement/selectors'
import { fetchActivity } from 'store/extraHub/actions'
import { getCurrentActivity } from 'store/extraHub/selectors'

import IconButton from 'components/button/IconButton'
import Card from 'components/card'
import EmptyState from 'components/EmptyState'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const HubData = ({ children, description }) => {
  return (
    <div className="flex w-full flex-col items-center">
      <span className="text-xl font-medium text-slate-900">{children}</span>
      <span className="text-2xs font-medium text-slate-500">{description}</span>
    </div>
  )
}

HubData.propTypes = {
  children: arrayOf(string),
  description: string
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const Header = tw.span`text-lg font-semibold text-slate-500 w-full flex justify-center`

const TopSection = tw.div`space-y-5`

const BottomSection = tw.div`space-y-5`

const THTop = tw.th`text-lg font-medium text-slate-500 text-center`

const THLeft = tw.th`text-lg font-medium text-slate-500 text-left`

const TData1 = tw.td`font-medium text-xl text-slate-900 text-center`

const TData2 = tw.td`font-medium text-xl text-slate-500 text-center`

const Title = tw.span`inline-flex items-center h-9 text-xl font-semibold text-slate-900`

const EngagementCard = ({ fetchPlanningEngagement, fetchActivity, span, amplifyAction }) => {
  const { currentSector, selectedLevel } = useContext(SectorContext)
  const { sectorType, sectorId } = useParams()
  const isMounted = useRef()

  const [error, setError] = useState()
  const engagementData = useSelector((state) => planningEngagementData(state))
  const currentActivity = useSelector((state) => getCurrentActivity(state))

  const dataKey = createDataKey(DATAKEY_TYPES.PLANNING_ENGAGEMENT, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id
  })

  useEffect(() => {
    isMounted.current = true
    setError()
    if (currentSector[selectedLevel]?.id) {
      Promise.all([
        fetchPlanningEngagement({ id: currentSector[selectedLevel].id, sectorLevel: selectedLevel }, dataKey),
        fetchActivity({ dataKey, sectorType, sectorId })
      ]).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [currentSector, selectedLevel])

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  return (
    <Card title="Engagement" span={span}>
      <TopSection>
        <Eyebrow />
        <div className="items-center space-y-3">
          <Header>Calls per day</Header>
          <table className="w-full table-auto border-separate border-spacing-y-2">
            <tbody>
              <tr>
                <th></th>
                <THTop>Visit</THTop>
                <THTop>Time</THTop>
              </tr>
              <tr>
                <THLeft>Week</THLeft>
                <TData1>
                  {engagementData.week.completionPercentage * 100
                    ? (engagementData.week.completionPercentage * 100).toLocaleString()
                    : '-'}
                  %
                </TData1>
                <TData1>
                  {engagementData.week.averageCallTim ? engagementData.week.averageCallTim.toLocaleString() : '-'} min
                </TData1>
              </tr>
              <tr>
                <THLeft>Cycle</THLeft>
                <TData2>
                  {engagementData.cycle.completionPercentage
                    ? (engagementData.cycle.completionPercentage * 100).toLocaleString()
                    : '-'}
                  %
                </TData2>
                <TData2>
                  {engagementData.cycle.averageCallTime ? engagementData.cycle.averageCallTime.toLocaleString() : '-'}{' '}
                  min
                </TData2>
              </tr>
            </tbody>
          </table>
        </div>
      </TopSection>
      <BottomSection>
        <Eyebrow />
        <div className="items-center space-y-3">
          <div className="flex items-center justify-between">
            <Title>Extra hub activity</Title>
            {amplifyAction && (
              <div className="space-x-3">
                <IconButton secondary icon="three-stars" onClick={amplifyAction}>
                  Amplify
                </IconButton>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex">
              <HubData description={`of ${currentActivity.totalErp || '0'} stores`}>
                {currentActivity.erp || '-'}%
              </HubData>
              <HubData description={`of ${currentActivity.totalUser || '0'} users`}>
                {currentActivity.user || '-'}%
              </HubData>
            </div>
            <HubData description="available to be redeemed">${currentActivity.coins || '-'}</HubData>
          </div>
        </div>
      </BottomSection>
    </Card>
  )
}

EngagementCard.propTypes = {
  fetchPlanningEngagement: func,
  fetchActivity: func,
  span: object,
  amplifyAction: func
}

const mapActionCreators = {
  fetchPlanningEngagement,
  fetchActivity
}

export default connect(null, mapActionCreators)(EngagementCard)
