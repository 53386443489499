import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import CycleFocusCard from './CycleFocusCard'
import EngagementCard from './EngagementCard'
import HealthCheckCard from './HealthCheckCard'
import PlanHeader from './PlanHeader'
import PriceCheckCard from './PriceCheckCard'
import SellinCard from './SellinCard'
import SelloutCard from './SelloutCard'

const PlanLayout = () => {
  const navigate = useNavigate()
  const { sectorType, sectorId } = useParams()
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5">
      {!isWideLayout && <PlanHeader header="Plan" />}
      <SellinCard
        span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }}
        amplifyAction={() => navigate(`/${sectorType}/${sectorId}/pace/amplify/sell-in`)}
      />
      <SelloutCard
        title="SOM"
        activeProductType={currentProductType}
        span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }}
        amplifyAction={() => navigate(`/${sectorType}/${sectorId}/pace/amplify/sell-out`)}
      />
      <HealthCheckCard
        span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }}
        amplifyAction={() => navigate(`/${sectorType}/${sectorId}/pace/amplify/distro`)}
        oosInvAmplify={() => navigate(`/${sectorType}/${sectorId}/pace/amplify/inventory`)}
      />
      <PriceCheckCard
        span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }}
        amplifyAction={() => navigate(`/${sectorType}/${sectorId}/pace/amplify/price-compliance`)}
        complianceAmplify={() => navigate(`/${sectorType}/${sectorId}/pace/amplify/price-capture`)}
      />
      <EngagementCard
        span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }}
        amplifyAction={() => navigate(`/${sectorType}/${sectorId}/pace/amplify/extra-hub`)}
      />
      <CycleFocusCard span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
    </div>
  )
}

export default PlanLayout
