import React, { useContext } from 'react'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'

import ProductTypePicker from 'components/ProductTypePicker'
import SegmentControl from 'components/SegmentControl'
import ViewHeader from 'components/ViewHeader'

import { TIME_FRAME_OPTIONS } from 'utils/constants'

import InventoryCaptureContext from './InventoryCaptureContext'

const InventoryCaptureOosHeader = ({ parentHeader, header }) => {
  const { timeframe, setTimeframe } = useContext(InventoryCaptureContext)
  const { isWideLayout } = useContext(MediaContext)
  const filters = isWideLayout ? [] : [<ProductTypePicker key="amplify-inventory-product-type-picker" />]
  return (
    <ViewHeader
      parentHeader={parentHeader}
      header={header}
      filters={filters.concat([
        <SegmentControl
          key="inventory-time-frame-picker"
          name="time-frame"
          onChange={(e) => setTimeframe(e.target.value)}
          value={timeframe}
          options={TIME_FRAME_OPTIONS}
        />
      ])}
    />
  )
}

InventoryCaptureOosHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default InventoryCaptureOosHeader
