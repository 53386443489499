import React from 'react'
import { bool, func } from 'prop-types'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

const ConfirmeDeleteCallSheet = ({
  confirmDeleteCall,
  deleteAppointment,
  setConfirmDeleteCall,
  isLoading,
  wasGenerated
}) => {
  const title = wasGenerated ? 'Unlog call' : 'Delete call'
  const confirmText = wasGenerated
    ? 'Are you sure you want to unlog this call? It will remove all completion details and appear in your calendar at the time it was scheduled.'
    : 'Are you sure you want to delete your call?'
  return (
    <ActionSheet title={title} visible={confirmDeleteCall}>
      <div>
        {confirmText}
        <Fieldset>
          <FieldsetItem>
            <Button full primary isLoading={isLoading} disabled={isLoading} onClick={deleteAppointment}>
              Yes
            </Button>
          </FieldsetItem>
          <FieldsetItem>
            <Button full isLoading={isLoading} disabled={isLoading} onClick={() => setConfirmDeleteCall(false)}>
              No
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

ConfirmeDeleteCallSheet.propTypes = {
  confirmDeleteCall: bool,
  deleteAppointment: func,
  setConfirmDeleteCall: func,
  isLoading: bool,
  wasGenerated: bool
}

export default ConfirmeDeleteCallSheet
