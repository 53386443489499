import React from 'react'
import { Link } from 'react-router-dom'
import { mix } from 'polished'
import { bool, func, node, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { borderColor, grey, greyLight, offWhite, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, inputHeight } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  background-color: ${white};
  height: ${inputHeight};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  padding: 0 ${spacing.medium};
  border-bottom: 1px solid ${borderColor};
  cursor: pointer;

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:first-child {
    border-top: 1px solid ${borderColor};
  }
`
const ContainerLink = Container.withComponent(Link)
const TouchableContainer = styled(ContainerLink)`
  color: ${grey};
  transition: background-color ${animationTime} ${animationCurve};

  &:hover,
  &:focus,
  &:active {
    background-color: ${mix(0.4, offWhite, white)};
  }
`

const ActionableContainer = styled(Container)`
  position: relative;
`

const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const AddOnWrap = styled.div`
  color: ${greyLight};
  margin-right: ${spacing.tiny};
`

const TableRow = ({ children, ...rest }) => <Container {...rest}>{children}</Container>

TableRow.propTypes = {
  children: node.isRequired
}

export const TouchableTableRow = ({ children, centered, link, onClick }) => (
  <TouchableContainer centered={centered ? 'true' : undefined} to={link} onClick={onClick}>
    {children}
    {!centered && <Icon icon="right-chevron" light />}
  </TouchableContainer>
)

TouchableTableRow.propTypes = {
  children: node.isRequired,
  link: string.isRequired,
  centered: bool,
  onClick: func
}

export const LinkableTableRow = ({ children, ...rest }) => (
  <ActionableContainer as="a" {...rest}>
    {children}
  </ActionableContainer>
)

LinkableTableRow.propTypes = {
  children: node.isRequired
}

export const ActionableTableRow = ({ children, centered, AddOn, onClick }) => (
  <ActionableContainer as="button" centered={centered} onClick={onClick}>
    {children}
    {AddOn && (
      <Right>
        {AddOn && <AddOnWrap>{AddOn}</AddOnWrap>}
        {!centered && <Icon icon="right-chevron" light />}
      </Right>
    )}
  </ActionableContainer>
)

ActionableTableRow.propTypes = {
  children: node.isRequired,
  AddOn: node,
  centered: bool,
  onClick: func
}

export default TableRow
