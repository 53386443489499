import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import BatLogo from 'static/logos/bat-logo.svg'
import InlineSVG from 'svg-inline-react'

import LangContext from 'context/LangContext'
import MediaContext from 'context/MediaContext'

import Profile from './Profile'
import SidebarLink from './SidebarLink'

const Sidebar = () => {
  const { formatMessage } = useContext(LangContext)
  const { isWideLayout } = useContext(MediaContext)
  const { user } = useSelector((state) => state.auth)
  const isCustomerService = user && user.groupCode === 'customerService'

  if (!isWideLayout) return null
  return (
    <nav className="flex h-screen w-[220px] shrink-0 flex-col overflow-y-scroll bg-brand p-6 pt-0 text-white scrollbar-hide">
      <div className="flex h-[76px] shrink-0 items-center border-b border-white/10">
        <div className="ml-1">
          <Link to="/pace" className="-m-1.5 flex rounded p-1.5 focus:outline-none focus:ring-2 focus:ring-brand-500">
            <InlineSVG src={BatLogo} className="h-[26px] w-[76px]" raw />
          </Link>
        </div>
      </div>
      <div className="mt-6 flex h-full flex-col justify-between">
        {/* Main links */}
        <div className="space-y-5">
          {!isCustomerService && (
            <>
              <div className="space-y-3">
                <span className="ml-1 text-xs font-medium uppercase text-white/60">Pace</span>
                <div className="space-y-1">
                  <SidebarLink name={formatMessage({ messageId: 'pace.plan' })} url="/pace/plan" icon="dashboard" />
                  <SidebarLink
                    name={formatMessage({ messageId: 'pace.amplify' })}
                    url="/pace/amplify"
                    icon="graph-window"
                  />
                  <SidebarLink name={formatMessage({ messageId: 'pace.close' })} url="/pace/close" icon="checklist" />
                  <SidebarLink
                    name={formatMessage({ messageId: 'pace.evaluate' })}
                    url="/pace/evaluate"
                    icon="clipboard"
                  />
                </div>
              </div>
              <hr className="w-full bg-white opacity-10" />
            </>
          )}
          <SidebarLink name={formatMessage({ messageId: 'actions.title' })} url="/actions" icon="actions" />
        </div>

        {/* User links */}
        <div className="mt-8 space-y-6">
          <div className="space-y-1">
            <SidebarLink name={formatMessage({ messageId: 'alerts.title' })} url="/alerts" icon="notification" />
            <SidebarLink name="Schedule" url="/schedule" icon="calendar" />
            <SidebarLink name="Payouts" url="/payouts" icon="stacked-coins" />
          </div>
          <hr className="w-full bg-white opacity-10" />
          <div className="relative after:absolute after:-bottom-6 after:block after:h-6 after:w-full after:content-['']">
            <Profile />
          </div>
        </div>
      </div>
    </nav>
  )
}
export default Sidebar
