import uniq from 'lodash/uniq'

import { addEntities, mergeEntities, setEntities } from 'store/actions'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToTerritory,
  addAmplifyExtraHubEngagementValuesToTerritory,
  addAmplifyExtraHubFiltersValuesToTerritory,
  addAmplifyExtraHubGeographyValuesToTerritory,
  addAmplifyExtraHubTrendingValuesToTerritory,
  addAmplifyInventoryOosBrandsValuesToTerritory,
  addAmplifyInventoryOosFiltersValuesToTerritory,
  addAmplifyInventoryOosGeographyValuesToTerritory,
  addAmplifyInventoryOosMaterialValuesToTerritory,
  addAmplifyInventoryOosTrendingValuesToTerritory,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToTerritory,
  addAmplifyPriceCaptureComplianceFiltersValuesToTerritory,
  addAmplifyPriceCaptureComplianceFootprintValuesToTerritory,
  addAmplifyPriceCaptureComplianceGeographyValuesToTerritory,
  addAmplifyPriceComplianceEdlpComplianceValuesToTerritory,
  addAmplifyPriceComplianceGeographyComplianceValuesToTerritory,
  addAmplifyPriceComplianceGeographyFiltersValuesToTerritory,
  addAmplifyPriceComplianceStrategyComplianceValuesToTerritory,
  addAmplifySelloutGeographyValuesToTerritory,
  addAmplifySelloutValuesToTerritory,
  addDistroBrandDataToTerritory,
  addDistroGeographyDataToTerritory,
  addDistroMaterialDataToTerritory,
  addDistroTrendedDataToTerritory,
  addIntelToTerritory,
  addPlanningEngagementValuesToTerritory,
  addPlanningHealthCheckValuesToTerritory,
  addPlanningPriceCheckValuesToTerritory,
  addPlanningSellinValuesToTerritory,
  addPlanningSelloutValuesToTerritory,
  addSellinGeographyToTerritory,
  addSellinOrderCompletionToTerritory,
  addSellinRunRateToTerritory,
  removeIntelFromTerritory,
  updatePerfSequence
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.territories) return state
    return Object.entries(payload.territories).reduce((acc, [id, territory]) => {
      const oldTerr = acc[id] || {}
      if (!territory.sellInPrograms && oldTerr.sellInPrograms) {
        territory.sellInPrograms = oldTerr.sellInPrograms
      }
      return Object.assign(acc, {
        [id]: territory
      })
    }, Object.assign({}, state))
  },
  [setEntities]: (state, action) => {
    if (!action.payload.territories) return state
    return Object.entries(action.payload.territories).reduce((acc, [id, territory]) => {
      const oldTerr = state[id]
      if (!territory.sellInPrograms && oldTerr?.sellInPrograms) {
        territory.sellInPrograms = oldTerr.sellInPrograms
      }
      acc[id] = territory
      return acc
    }, {})
  },
  [mergeEntities]: (state, { payload }) => {
    if (!payload.territories) return state
    return Object.entries(payload.territories).reduce((acc, [id, territory]) => {
      const oldTerr = acc[id] || {}
      return Object.assign(acc, {
        [id]: {
          ...oldTerr,
          ...territory,
          sellInPrograms: uniq([].concat(oldTerr.sellInPrograms || []).concat(territory.sellInPrograms || []))
        }
      })
    }, Object.assign({}, state))
  },
  [updatePerfSequence]: (state, { payload: { territoryId, sequence } }) => ({
    ...state,
    [territoryId]: {
      ...state[territoryId],
      sequence
    }
  }),
  [addIntelToTerritory]: (state, { payload: { territoryId, id } }) => ({
    ...state,
    [territoryId]: {
      ...state[territoryId],
      intel: uniq([...state[territoryId].intel, id])
    }
  }),
  [removeIntelFromTerritory]: (state, { payload: { territoryId, id, tempId = 0 } }) => ({
    ...state,
    [territoryId]: {
      ...state[territoryId],
      intel: state[territoryId].intel.filter((intelId) => ![id, tempId].includes(intelId))
    }
  }),
  [addSellinRunRateToTerritory]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addSellinGeographyToTerritory]: (
    state,
    { payload: { sectorId: id, currentProductType, geography, period, range, vapeCategory, offset, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${range}-${offset}`]: result
      }
    })
  },
  [addSellinOrderCompletionToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion-${activeProductType}`]: result
      }
    })
  },
  [addDistroTrendedDataToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}`]: result
      }
    })
  },
  [addDistroBrandDataToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}`]: result
      }
    })
  },
  [addDistroGeographyDataToTerritory]: (state, { payload: { sectorId: id, geography, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${geography}`]: result
      }
    })
  },
  [addDistroMaterialDataToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}`]: result
      }
    })
  },
  [addPlanningSellinValuesToTerritory]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToTerritory]: (state, { payload: { id, activeProductType, channel, dataType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`amplify-sellout-${activeProductType}-${channel}-${dataType}`]: result
      }
    })
  },
  [addAmplifySelloutGeographyValuesToTerritory]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${activeProductType}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToTerritory]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToTerritory]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },
  [addAmplifyInventoryOosTrendingValuesToTerritory]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToTerritory]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToTerritory]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToTerritory]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToTerritory]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToTerritory]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToTerritory]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToTerritory]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToTerritory]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToTerritory]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToTerritory]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToTerritory]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToTerritory]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToTerritory]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToTerritory]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToTerritory]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToTerritory]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToTerritory]: priceCaptureCompliance.priceComplianceGeographyFilters
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
