import React, { useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import pick from 'lodash/pick'
import moment from 'moment'
import { bool, func, number, object } from 'prop-types'

import * as callTaskSelector from 'store/callTasks/selectors'
import { deleteCustomerCall } from 'store/customerCalls/actions'
import * as customerSelector from 'store/customers/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import BasicCreateCallForm from 'components/schedule/BasicCreateCallForm'
import ConfirmeDeleteCallSheet from 'components/schedule/ConfirmeDeleteCallSheet'
import Section from 'components/Section'

import Checklist from './Checklist'

const CreatePastCallSheet = ({
  defaultCustomerId,
  createPastCallVisible,
  setCreatePastCallVisible,
  createCall,
  pastCallToEdit,
  setPastCallToEdit,
  deleteCustomerCall,
  canEdit
}) => {
  const tasksByOutletSubtype = useSelector((state) => callTaskSelector.tasksByOutletSubtype(state))
  const erpOptions = useSelector((state) => customerSelector.erpOptions(state))
  const employeeTimezone = useSelector(
    (state) => state.auth.user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [startDate, setStartDate] = useState()
  const [startHour, setStartHour] = useState()
  const [startMinute, setStartMinute] = useState()
  const [endHour, setEndHour] = useState()
  const [endMinute, setEndMinute] = useState()
  const [customer, setCustomer] = useState()
  const [type, setType] = useState()
  const [kms, setKms] = useState()
  const [note, setNote] = useState()
  const [completedTasks, setCompletedTasks] = useState()

  const [confirmDeleteCall, setConfirmDeleteCall] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [globalError, setGlobalError] = useState(false)

  useEffect(() => {
    if (moment({ hour: endHour, minute: endMinute }).isBefore(moment({ hour: startHour, minute: startMinute }))) {
      const newEnd = moment({ hour: startHour, minute: startMinute }).add(1, 'minute')
      setEndHour(String(newEnd.hours()).padStart(2, '0'))
      setEndMinute(String(newEnd.minutes()).padStart(2, '0'))
    }
  }, [startHour, startMinute, endHour, endMinute])

  const startDatetime = useMemo(
    () =>
      startDate &&
      startHour &&
      moment(startDate).set({ hour: startHour, minute: startMinute || 0, second: 0, millisecond: 0 }),
    [startDate, startHour, startMinute]
  )
  const endDatetime = useMemo(
    () =>
      startDate &&
      endHour &&
      moment(startDate).set({ hour: endHour, minute: endMinute || 0, second: 0, millisecond: 0 }),
    [startDate, endHour, endMinute]
  )

  useEffect(() => {
    if (!pastCallToEdit) {
      if (!startDatetime) {
        setStartDate(moment())
        setStartHour(String(moment().subtract(2, 'hour').hour()).padStart(2, '0'))
        setStartMinute('00')
      }
      if (!endDatetime) {
        setEndHour(String(moment().subtract(1, 'hours').hour()).padStart(2, '0'))
        setEndMinute('00')
      }
      if (!type) {
        setType('in-person')
      }
      if (defaultCustomerId) {
        setCustomer(erpOptions.find(({ id }) => id === defaultCustomerId))
      }
    } else {
      if (pastCallToEdit.callStart) {
        setStartDate(pastCallToEdit.callStart)
        setStartHour(`${moment(pastCallToEdit?.callStart).hour()}`.padStart(2, '0'))
        setStartMinute(`${moment(pastCallToEdit?.callStart).minute()}`.padStart(2, '0'))
      }
      if (pastCallToEdit.callEnd) {
        setEndHour(`${moment(pastCallToEdit?.callEnd).hour()}`.padStart(2, '0'))
        setEndMinute(`${moment(pastCallToEdit?.callEnd).minute()}`.padStart(2, '0'))
      }
      if (pastCallToEdit.type) {
        setType(pastCallToEdit.type)
      }
      if (pastCallToEdit.customer) {
        setCustomer(erpOptions.find(({ id }) => id === pastCallToEdit.customer.id) || null)
      }
      if (pastCallToEdit.employeeKm) {
        setKms(pastCallToEdit.employeeKm.kms || null)
      }
      if (pastCallToEdit.message) {
        setNote(pastCallToEdit.message.text || null)
      }
    }
  }, [pastCallToEdit, createPastCallVisible, defaultCustomerId])

  useEffect(() => {
    const tasks = tasksByOutletSubtype && customer?.outletSubtype && tasksByOutletSubtype[customer.outletSubtype]
    const existingTasks = pastCallToEdit ? pastCallToEdit.completedTasks : null

    if (tasks?.length) {
      setCompletedTasks(
        tasks?.map((task) => ({
          id: task.id,
          label: task.task,
          checked: existingTasks ? existingTasks.find(({ id }) => task.id === id)?.checked : false
        }))
      )
    }
  }, [customer, pastCallToEdit, tasksByOutletSubtype])

  const isInPerson = useMemo(() => type && type === 'in-person', [type])

  const timeError = useMemo(() => {
    const now = moment()
    if (endDatetime && startDatetime && endDatetime.isSameOrBefore(startDatetime))
      return 'End time must be after start time'

    if (endDatetime && startDatetime && (startDatetime.isAfter(now) || endDatetime.isAfter(now)))
      return 'Past call must be in the past'

    return null
  }, [startDatetime, endDatetime])

  const mileageError = useMemo(() => {
    if (isInPerson && !kms) return 'Mileage is required to save the call'
    return null
  }, [isInPerson, kms])

  const canSave = useMemo(
    () => startDatetime && endDatetime && customer && type && !timeError && !mileageError,
    [startDatetime, endDatetime, customer, type, timeError, mileageError]
  )

  const saveCall = async () => {
    setSaveLoading(true)
    try {
      await createCall({
        ...pick(pastCallToEdit, ['id']),
        callStart: moment.tz(startDatetime, employeeTimezone),
        callEnd: moment.tz(endDatetime, employeeTimezone),
        employeeKm: kms,
        message: note,
        callType: type,
        completedTasks,
        customer
      })
      clearFields()
      setPastCallToEdit()
      setCreatePastCallVisible()
    } catch (error) {
      console.log(error)
      setGlobalError(`
        An error occurred when trying to ${pastCallToEdit ? 'update' : 'create'} your OOT, please try again!
        Error details : ${JSON.stringify(error.message)}
      `)
    }
    setSaveLoading(false)
  }

  const deleteCall = async () => {
    setSaveLoading(true)
    await deleteCustomerCall(pastCallToEdit)
    setSaveLoading(false)
    clearFields()
    setPastCallToEdit()
    setCreatePastCallVisible()
    setConfirmDeleteCall()
  }

  const cancelPastCall = () => {
    clearFields()
    setCreatePastCallVisible()
    setPastCallToEdit()
  }

  const clearFields = () => {
    setStartDate()
    setStartHour()
    setStartMinute()
    setEndHour()
    setEndMinute()
    setCustomer()
    setType()
    setKms()
    setNote()
    setSaveLoading()
    setCompletedTasks()
  }

  const fulfilledGeneratedCall = Boolean(pastCallToEdit?.generatedCallId)
  return (
    <>
      <ActionSheet
        title={pastCallToEdit ? 'Past call details' : 'Log past call'}
        action={
          <button type="button" onClick={() => cancelPastCall()}>
            Cancel
          </button>
        }
        visible={createPastCallVisible}
      >
        <BasicCreateCallForm
          startDate={startDate}
          setStartDate={setStartDate}
          startHour={startHour}
          setStartHour={setStartHour}
          startMinute={startMinute}
          setStartMinute={setStartMinute}
          endHour={endHour}
          setEndHour={setEndHour}
          endMinute={endMinute}
          setEndMinute={setEndMinute}
          timeError={timeError}
          customer={customer}
          setCustomer={setCustomer}
          type={type}
          setType={setType}
          allowPastTime
          canEdit={canEdit}
          wasGenerated={fulfilledGeneratedCall}
        />
        {completedTasks?.length > 0 && (
          <Section>
            <Checklist items={completedTasks} onChange={setCompletedTasks} disabled={!canEdit} />
          </Section>
        )}
        <Section>
          <Fieldset>
            {isInPerson && (
              <FieldsetItem>
                <Input
                  label="Mileage"
                  name="mileage"
                  type="number"
                  placeholder="0"
                  value={kms}
                  onChange={({ target }) => setKms(target.value)}
                  disabled={!canEdit}
                />
              </FieldsetItem>
            )}
            <FieldsetItem>
              <Input
                label="Call notes"
                name="notes"
                placeholder="Type notes here..."
                textarea
                maxLength={700}
                enableCounter
                value={note}
                onChange={({ target }) => setNote(target.value)}
                disabled={!canEdit}
              />
            </FieldsetItem>
          </Fieldset>
        </Section>
        {globalError && <GlobalAlert warning>{globalError}</GlobalAlert>}
        {canEdit && (
          <Section>
            <Fieldset>
              {mileageError && (
                <FieldsetItem>
                  <GlobalAlert>{mileageError}</GlobalAlert>
                </FieldsetItem>
              )}
              <FieldsetItem>
                <Button full primary isLoading={saveLoading} onClick={saveCall} disabled={saveLoading || !canSave}>
                  Save
                </Button>
              </FieldsetItem>
              {pastCallToEdit && (
                <FieldsetItem>
                  <Button
                    full
                    isLoading={saveLoading}
                    onClick={() => setConfirmDeleteCall(true)}
                    disabled={saveLoading}
                  >
                    {fulfilledGeneratedCall ? 'Unlog call' : 'Delete call'}
                  </Button>
                </FieldsetItem>
              )}
            </Fieldset>
          </Section>
        )}
      </ActionSheet>
      <ConfirmeDeleteCallSheet
        confirmDeleteCall={confirmDeleteCall}
        deleteAppointment={deleteCall}
        setConfirmDeleteCall={setConfirmDeleteCall}
        isLoading={saveLoading}
        wasGenerated={fulfilledGeneratedCall}
      />
    </>
  )
}

CreatePastCallSheet.propTypes = {
  createPastCallVisible: bool,
  defaultCustomerId: number,
  setCreatePastCallVisible: func,
  createCall: func,
  pastCallToEdit: object,
  setPastCallToEdit: func,
  deleteCustomerCall: func,
  canEdit: bool
}

export default connect(null, { deleteCustomerCall })(CreatePastCallSheet)
