import React from 'react'
import { Field, useFormikContext } from 'formik'
import { bool, func, number, object } from 'prop-types'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import SegmentControl from 'components/SegmentControl'
import { ToggleField } from 'components/Toggle'

import { MAX_PROMO_QUANTITY } from 'utils/constants'
import { minCtnsForEmergencyOrder } from 'utils/validators'

function SAQActionSheet({
  customer,
  isEmergencyOrder,
  isMobileViewOnly,
  onClose,
  requiresApproval,
  showSecondEmergencyOrderWarning,
  showDtpBagsExceededWarning,
  threshold,
  totalQuantity,
  qtyToRemove
}) {
  const formikContext = useFormikContext()

  const submitIsEnabled =
    totalQuantity > 0 &&
    qtyToRemove <= MAX_PROMO_QUANTITY &&
    Object.keys(formikContext.errors).length === 0 &&
    !formikContext.isSubmitting &&
    !isMobileViewOnly &&
    !showDtpBagsExceededWarning

  const validateType = (newType) => {
    const { entries } = formikContext.values

    const error = minCtnsForEmergencyOrder(newType, entries, {
      saqTotalQuantity: totalQuantity,
      customer,
      qtyToRemove
    })

    return error
  }

  return (
    <ActionSheet
      title={`Create ${isEmergencyOrder ? 'Emergency order' : 'SAQ'}`}
      action={
        <button type="button" onClick={onClose}>
          <Icon icon="close" />
        </button>
      }
      visible
    >
      <Fieldset>
        <FieldsetItem>
          <Field
            component={SegmentControl}
            name="type"
            options={[
              {
                label: 'Add to next order (SAQ)',
                value: 'saq'
              },
              {
                label: 'Emergency Order',
                value: 'emergency'
              }
            ]}
            validate={validateType}
          />
        </FieldsetItem>
        {requiresApproval && (
          <FieldsetItem>
            <GlobalAlert warning>
              <p>
                This order exceeds the {threshold.toString()} carton threshold. An authorization email will be sent to{' '}
                <u>{customer?.primaryContact?.email}</u>. If this email address is incorrect, please do not submit and
                contact the Marketing Systems team.
              </p>
            </GlobalAlert>
          </FieldsetItem>
        )}
        {showSecondEmergencyOrderWarning && (
          <FieldsetItem>
            <GlobalAlert warning>
              An emergency order was already placed today, are you sure you want to place a second emergency order?
            </GlobalAlert>
          </FieldsetItem>
        )}
        {showDtpBagsExceededWarning && (
          <FieldsetItem>
            <GlobalAlert warning>The maximum amount of Pod Disposal Bags (20) has been exceeded.</GlobalAlert>
          </FieldsetItem>
        )}
        {qtyToRemove > MAX_PROMO_QUANTITY && (
          <FieldsetItem>
            <GlobalAlert>
              You can&apos;t order more than {MAX_PROMO_QUANTITY} promotional merchandise products.
            </GlobalAlert>
          </FieldsetItem>
        )}
        {totalQuantity <= 0 && (
          <FieldsetItem>
            <GlobalAlert warning>
              <p>You must submit at least one product</p>
            </GlobalAlert>
          </FieldsetItem>
        )}
        <FieldsetItem>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Send me a copy by email</span>
            <Field component={ToggleField} name="sendEmailCopy" disabled />
          </div>
        </FieldsetItem>
        <FieldsetItem>
          <Button
            onClick={formikContext.submitForm}
            type="submit"
            primary
            full
            disabled={!submitIsEnabled || formikContext.isSubmitting}
          >
            {showSecondEmergencyOrderWarning ? (
              <span>
                Submit <b>second</b> emergency order
              </span>
            ) : (
              'Submit'
            )}
          </Button>
        </FieldsetItem>
      </Fieldset>
    </ActionSheet>
  )
}

SAQActionSheet.propTypes = {
  customer: object.isRequired,
  isEmergencyOrder: bool,
  isMobileViewOnly: bool,
  onClose: func.isRequired,
  requiresApproval: bool,
  showSecondEmergencyOrderWarning: bool,
  showDtpBagsExceededWarning: bool,
  threshold: number,
  totalQuantity: number,
  qtyToRemove: number
}

export default SAQActionSheet
