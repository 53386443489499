import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchMaterialsInventory } from 'store/inventory/actions'
import { getCurrentMaterials } from 'store/inventory/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import InventoryCaptureContext from './InventoryCaptureContext'

const COLS = [
  {
    field: 'product',
    headerName: 'Product'
  },
  {
    field: 'oos',
    headerName: 'OOS'
  },
  {
    field: 'invcap',
    headerName: 'Inv. Cap.'
  }
]

const BVLS = ({ span, fetchMaterialsInventory }) => {
  // const [timeframe, setTimeframe] = useState('1')
  const [error, setError] = useState()
  const [rows, setRows] = useState([])

  const { currentProductType: productType } = useContext(SectorContext)
  const { timeframe } = useContext(InventoryCaptureContext)

  const { sectorType, sectorId } = useParams()

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.MATERIALS, {
    sectorType,
    sectorId,
    productType,
    timeframe
  })
  const isMaterialLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const materials = useSelector((state) => getCurrentMaterials(state))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchMaterialsInventory({ dataKey, sectorType, sectorId, timeframe: Number(timeframe), productType })
      .then(() => {
        if (isMounted.current) {
          setError()
        }
      })
      .catch((e) => {
        if (isMounted.current) {
          setError(e)
        }
      })
  }, [sectorType, sectorId, productType, timeframe])

  useEffect(() => {
    const values = materials[`${productType}-${timeframe}`] || []
    const newRows = []
    values?.forEach((product) => {
      newRows.push({
        product: product.material,
        oos: (
          <DataCompare
            last={`${product.oos}`}
            variation={product.oosDiff}
            stacked
            withTimeframe
            isPercent
            timeframe={timeframe}
          />
        ),
        invcap: (
          <DataCompare
            last={`${product.inventoryCapture}`}
            variation={product.inventoryCaptureDiff}
            stacked
            withTimeframe
            isPercent
            timeframe={timeframe}
          />
        )
      })
    })
    setRows(newRows)
  }, [materials, productType, timeframe])

  const renderContent = () => {
    if (isMaterialLoading) return <WrappedSpinner icon="spinner" />

    if (error) {
      return <EmptyState title={getErrorMessage(error)} />
    }

    return rows.length ? (
      <div className="max-h-72 overflow-y-auto">
        <DataTable columns={COLS} rows={rows} fillContainer />
      </div>
    ) : (
      <EmptyState title="No data yet" />
    )
  }

  return (
    <Card title={productType === 'fmc' ? 'BVLS' : 'Material Description'} span={span} displayAmplify={false}>
      {renderContent()}
    </Card>
  )
}

BVLS.propTypes = {
  span: object,
  fetchMaterialsInventory: func
}

export default connect(null, {
  fetchMaterialsInventory
})(BVLS)
