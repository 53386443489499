import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { bool, object, string } from 'prop-types'
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'

import SectorContext from 'context/SectorContext'

import { amplifySelloutSOMData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataLegend from 'components/DataTable/DataLegend'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { formatCompactCurrency, formatCompactNumber, formatPercent } from 'utils/formatters'

const COLS = {
  rolling: [
    {
      field: 'type',
      headerName: 'Type'
    },
    {
      field: 'mainPeriod',
      headerName: 'L4'
    },
    {
      field: 'previousPeriod',
      headerName: 'vs. P4'
    }
  ],
  pointInTime: [
    {
      field: 'type',
      headerName: 'Type'
    },
    {
      field: 'mainPeriod',
      headerName: 'CTD'
    },
    {
      field: 'previousPeriod',
      headerName: 'vs. PC'
    }
  ]
}

const generateRows = (data, dataFormatter) => {
  if (!data) return []

  return data.map((row) => ({
    type: <DataLegend color={row.fill} name={row.name} />,
    mainPeriod: dataFormatter(row.mainPeriod),
    previousPeriod: <DataVariation variation={row.variation} formatter={dataFormatter} />
  }))
}

const AmplifySelloutSOMCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  selloutDataLoading
}) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const selloutData = useSelector((state) =>
    amplifySelloutSOMData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      currentVapeCategory,
      dataType
    })
  )

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true })
      : currentMetric === 'cost'
      ? formatCompactCurrency
      : formatCompactNumber

  const dataFormat = currentProportion === 'share' ? 'percent' : dataType
  const yAxisFormatter =
    dataFormat === 'percent' ? (v) => formatPercent(v, { convertDecimal: true }) : formatCompactNumber

  if (selloutDataLoading) {
    return (
      <Card title={currentProductType === 'fmc' ? 'Manufacturer' : 'Brand'} span={span} displayAmplify={false}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  if (isEmpty(selloutData)) {
    return (
      <Card title={currentProductType === 'fmc' ? 'Manufacturer' : 'Brand'} span={span} displayAmplify={false}>
        <EmptyState title="No data" />
      </Card>
    )
  }
  return (
    <Card title={currentProductType === 'fmc' ? 'Manufacturer' : 'Brand'} span={span} displayAmplify={false}>
      <div className="flex h-full max-h-[300px] flex-col justify-between">
        <div className="-mt-4 h-full max-h-[128px] w-full">
          <ResponsiveContainer height="99%" width="99%">
            <BarChart data={selloutData} margin={{ bottom: 0, left: 0, right: 35, top: 15 }}>
              {/* <CartesianGrid stroke={theme.slate[300]} vertical={false} /> */}
              <XAxis
                style={{
                  fontSize: '12px'
                }}
                axisLine={false}
                dataKey="name"
                dy={10}
                stroke={theme.slate[500]}
                tickLine={false}
              />
              <YAxis
                hide
                // interval="preserveStartEnd"
                // style={{
                //   fontSize: '12px'
                // }}
                axisLine={false}
                // dx={-8}
                // // stroke={theme.slate[500]}
                tickLine={false}
                // tickFormatter={yAxisFormatter}
              />
              <Bar dataKey="mainPeriod" fill="#8884d8" isAnimationActive={false} minPointSize={1}>
                <LabelList dataKey="mainPeriod" position="top" formatter={yAxisFormatter} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="mt-6 h-full overflow-y-auto">
          <DataTable columns={COLS[currentTimeDisplay]} rows={generateRows(selloutData, dataFormatter)} fullWidth />
        </div>
      </div>
    </Card>
  )
}

AmplifySelloutSOMCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  selloutDataLoading: bool
}

export default AmplifySelloutSOMCard
