import React from 'react'
import { useParams } from 'react-router-dom'
import SellInCustomerView from 'views/sellInProgram/SellInCustomerView'
import StoresView from 'views/sellInProgram/StoresView'

import { SECTOR_LEVELS } from 'utils/constants'

const SellInProgramSwitcher = () => {
  const { sectorType } = useParams()

  return (
    <>
      {sectorType === SECTOR_LEVELS.CUSTOMER && <SellInCustomerView />}
      {sectorType === SECTOR_LEVELS.TERRITORY && <StoresView />}
    </>
  )
}

export default SellInProgramSwitcher
