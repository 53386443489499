import React from 'react'
import { bool, func, string } from 'prop-types'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

const ConfirmActionSheet = ({ title, visible, message, confirmAction, cancelAction, isLoading }) => {
  return (
    <ActionSheet title={title} visible={visible}>
      <div>
        {message}
        <Fieldset>
          <FieldsetItem>
            <Button full primary isLoading={isLoading} disabled={isLoading} onClick={confirmAction}>
              Yes
            </Button>
          </FieldsetItem>
          <FieldsetItem>
            <Button full isLoading={isLoading} disabled={isLoading} onClick={cancelAction}>
              No
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

ConfirmActionSheet.propTypes = {
  title: string,
  message: string,
  visible: bool,
  confirmAction: func,
  cancelAction: func,
  isLoading: bool
}

export default ConfirmActionSheet
