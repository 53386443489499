import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { array, bool, func } from 'prop-types'
import styled from 'styled-components'

import { submitSwapScheduleDays } from 'store/customerCalls/actions'
import { allOots } from 'store/employeeOots/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import DatePicker from 'components/DatePicker'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'

const Spacer = styled.div`
  height: 150px;
`

const SwapScheduleDays = ({
  visibleScheduleScreen,
  setVisibleScheduleScreen,
  allOots,
  submitSwapScheduleDays,
  canEdit
}) => {
  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('')
  const [loading, setLoading] = useState(false)

  const [globalError, setGlobalError] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)

  const employee = useSelector((state) => state.auth.user)

  const clearFields = () => {
    setDate1('')
    setDate2('')
  }

  useEffect(() => {
    clearFields()
    setGlobalError(false)
    setSubmitAttempted(false)
  }, [visibleScheduleScreen])

  useEffect(() => {
    setGlobalError(false)
    setSubmitAttempted(false)
  }, [date1, date2])

  const submitSwap = async () => {
    setSubmitAttempted(true)
    try {
      setLoading(true)

      await submitSwapScheduleDays({ date1, date2 })

      clearFields()
      setVisibleScheduleScreen(null)
    } catch (e) {
      console.log(e)
      setGlobalError(`
        An error occurred when trying to swap the selected scheduled days, please try again!
        Error details : ${JSON.stringify(e.message)}
      `)
    } finally {
      setLoading(false)
    }
  }

  const checkIfDayBlocked = (d) => {
    // const isWeekend = [0, 6].includes(moment(d).day())
    const isOutOfCycle =
      employee.currentCycle && employee.currentCycle.endTime && moment(d).isAfter(employee.currentCycle.endTime)
    const isAllDayOOT = allOots.some(
      ({ startAt, endAt, isAllDay }) => isAllDay && moment(d).isBetween(startAt, endAt, 'day', '[]')
    )
    return isAllDayOOT || isOutOfCycle
  }

  return (
    <ActionSheet
      title="Swap day schedules"
      action={
        <button type="button" onClick={() => setVisibleScheduleScreen(false)}>
          Cancel
        </button>
      }
      visible={visibleScheduleScreen}
    >
      <div>
        <Fieldset>
          <FieldsetItem>
            <GlobalAlert warning>All visits on the selected days will be swapped. OOT will not be touched.</GlobalAlert>
          </FieldsetItem>
          {employee.updateSchedule && (
            <FieldsetItem>
              <GlobalAlert warning>Your schedule will be regenerated. Any changes will be overwritten.</GlobalAlert>
            </FieldsetItem>
          )}
          <FieldsetItem>
            <DatePicker
              label="From"
              value={date1}
              onChange={(val) => setDate1(val)}
              isOutsideRange={(d) => moment().isAfter(d, 'day')}
              isDayBlocked={checkIfDayBlocked}
              openDirection="down"
              // disabled={!canEdit}
            />
          </FieldsetItem>
          <Spacer />
          <FieldsetItem>
            <DatePicker
              label="To"
              value={date2}
              onChange={(val) => setDate2(val)}
              isOutsideRange={(d) => moment().isAfter(d, 'day')}
              isDayBlocked={(d) => checkIfDayBlocked(d) || (date1 && d.isSame(date1, 'day'))}
              openDirection="up"
              // disabled={!canEdit}
            />
          </FieldsetItem>
          <Spacer />
          {globalError && <GlobalAlert>{globalError}</GlobalAlert>}
          <Spacer />
          <FieldsetItem>
            <Button full primary disabled={loading || submitAttempted} onClick={submitSwap} isLoading={loading}>
              Submit
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

SwapScheduleDays.propTypes = {
  visibleScheduleScreen: bool.isRequired,
  setVisibleScheduleScreen: func.isRequired,
  allOots: array.isRequired,
  submitSwapScheduleDays: func.isRequired,
  canEdit: bool
}

const mapStateToProps = (state, props) => ({
  allOots: allOots(state, props)
})

export default connect(mapStateToProps, {
  submitSwapScheduleDays
})(SwapScheduleDays)
