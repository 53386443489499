import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities, setEntities } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'
import { cycleCampaign } from 'store/schema'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS } from 'utils/helpers'

import * as api from './endpoints'

export const addCycleCampaigns = createAction('Add Cycle Campaigns')

export const fetchCycleCampaigns = createAction('Fetch Cycle Campaigns', () => async (dispatch) => {
  try {
    if (!window.navigator.onLine) throw ERRORS.offline
    const { data } = await api.fetchCycleCampaigns()
    const { entities } = normalize(data.cycleCampaigns, [cycleCampaign])
    dispatch(setEntities(entities))
  } catch (e) {
    console.error(e)
    throw e
  }
})

export const fetchCycleCampaignData = createAction(
  'Fetch Cycle Campaigns',
  ({ campaignId, sectorId, sectorType, currentProductType }, dataKey) =>
    async (dispatch, getState) => {
      const dataFetchesState = getState().dataFetches

      if (dataIsStillValid(dataFetchesState, dataKey)) return
      try {
        if (!window.navigator.onLine) throw ERRORS.offline
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
        const { data } = await api.fetchCycleCampaignData(campaignId, sectorId, sectorType, currentProductType)
        const { entities } = normalize(data.cycleCampaign, cycleCampaign)
        dispatch(mergeEntities(entities))
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
      } catch (e) {
        dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
        console.error(e)
        throw e
      }
    }
)
