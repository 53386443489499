import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'
import tw from 'twin.macro'

import { activeTerritorySellInPrograms } from 'store/sellInPrograms/selectors'
import { fetchTerritorySellInPrograms } from 'store/territories/actions'

import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import { TouchableTableRow } from 'components/tableRow/TableRow'
import TableRowGroup from 'components/tableRow/TableRowGroup'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const Container = tw.div`w-full h-full p-8`

const TerritorySellInAction = ({ connectedFetchTerritorySellInPrograms }) => {
  const { sectorId: territoryId } = useParams()
  const activeSellInPrograms = useSelector((state) => activeTerritorySellInPrograms(state, { territoryId }))

  const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY_SELL_IN_PROGRAMS, { territoryId })
  useEffect(() => {
    connectedFetchTerritorySellInPrograms(territoryId, dataKey)
  }, [territoryId])

  return (
    <Container>
      {activeSellInPrograms.length ? (
        <TableRowGroup heading="Sell-in Programs">
          {activeSellInPrograms.map((program, i) => (
            <TouchableTableRow key={i} link={`${program.id}`}>
              <span>{program.name}</span>
            </TouchableTableRow>
          ))}
        </TableRowGroup>
      ) : (
        <EmptyState title="There is no active Sell In program for this territory" />
      )}
      <LoadingCard dataKey={dataKey} />
    </Container>
  )
}

TerritorySellInAction.propTypes = {
  connectedFetchTerritorySellInPrograms: func
}

export default connect(null, {
  connectedFetchTerritorySellInPrograms: fetchTerritorySellInPrograms
})(TerritorySellInAction)
