import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS, PLANNING_SELECTOR_ACTIONS } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS } from 'utils/helpers'

import * as api from './endpoints'

export const fetchPlanningSellout = createAction(
  'Get Planning Sell-out Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const { data } = await api.fetchPlanningSellout(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].sellout
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )

      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

const geographyIdCol = {
  store: 'customerId',
  headoffice: 'headOffice',
  territory: 'territoryId',
  district: 'districtId',
  region: 'regionId',
  province: 'province',
  sku: 'productId'
}

const dataTypeIdCols = {
  fmc: ['brand', 'manufacturer'],
  vape: ['brand', 'vapeCategory', 'variantLabel']
}

const getUniqByCols = ({ geography, activeProductType }) => {
  return [].concat(geographyIdCol[geography]).concat(dataTypeIdCols[activeProductType]).filter(Boolean)
}
export const fetchAmplifySellout = createAction(
  'Get Amplify Sell-out Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const { data } = await api.fetchAmplifySellout(props)
      const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.main
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )

      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchAmplifySelloutGeographyData = createAction(
  'Get Amplify Sell-out Geography Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const fetchFunction =
        props.geography === 'sku' ? api.fetchAmplifySelloutSkuData : api.fetchAmplifySelloutGeographyData
      const { data } = await fetchFunction(props)
      // console.log('SELECTOR LEVEL', props.sectorLevel, AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout)
      const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.geography
      // console.log({ data, props })
      console.log(getUniqByCols(props))

      dispatch(
        addResultToScope({
          ...data,
          ...props,
          uniqByCols: getUniqByCols(props)
        })
      )

      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

// export const fetchAmplifySelloutSkuData = createAction(
//   'Get Amplify Sell-out Sku Values',
//   (props, dataKey) => async (dispatch, getState) => {
//     const dataFetchesState = getState().dataFetches

//     if (dataIsStillValid(dataFetchesState, dataKey)) return
//     try {
//       dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
//       if (!window.navigator.onLine) throw ERRORS.offline
//       const { data } = await api.fetchAmplifySelloutSkuData(props)
//       // console.log('SELECTOR LEVEL', props.sectorLevel, AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout)
//       const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.geography
//       // console.log({ data, props })
//       dispatch(
//         addResultToScope({
//           ...data,
//           ...props,
//           geography: 'sku'
//         })
//       )

//       dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
//     } catch (e) {
//       console.error(e)
//       dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
//       throw e
//     }
//   }
// )
