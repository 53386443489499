import queryString from 'query-string'

import { api } from 'store/api'

export const fetchPlanningHealthCheck = ({ id, sectorLevel, activeProductType }) =>
  api.get(
    `/health-check?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType
      },
      { skipNull: true }
    )}`
  )
