import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

const periodFromProps = (state, props) => props.period
const rangeFromProps = (state, props) => props.range
const offsetFromProps = (state, props) => props.offset
const productTypeFromProps = (state, props) => props.activeProductType
const vapeCategoryFromProps = (state, props) => props.vapeCategory
const comparisonFromProps = (state, props) => props.currentComparison
const geographyFromProps = (state, props) => props.geography

export const planningSellinData = createSelector(
  periodFromProps,
  productTypeFromProps,
  comparisonFromProps,
  vapeCategoryFromProps,
  dataSourceFromSectorLevel,
  (periodFilter, productType, currentComparison, vapeCategory, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`sellin-${productType}-${vapeCategory}`] || {}
    if (!amounts.length) return {}
    const periodData = amounts.find((amount) => amount.period === periodFilter) || {}
    if (!periodData) return {}

    return {
      actuals: periodData.actuals,
      gap: currentComparison === 'to_date' ? periodData.todayGap : periodData.periodGap,
      completionPercentage:
        currentComparison === 'to_date' ? periodData.todayCompletionPercentage : periodData.periodCompletionPercentage
    }
  }
)

export const sellinRunRateData = createSelector(
  productTypeFromProps,
  vapeCategoryFromProps,
  dataSourceFromSectorLevel,
  (productType, vapeCategory, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`sellin-run-rate-${productType}-${vapeCategory}`] || {}
    if (isEmpty(amounts)) return {}

    return amounts
  }
)

export const sellinOrderCompletionData = createSelector(
  productTypeFromProps,
  dataSourceFromSectorLevel,
  (productType, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`sellin-order-completion-${productType}`] || {}
    if (isEmpty(amounts)) return {}

    return amounts
  }
)

export const sellinGeographicalData = createSelector(
  productTypeFromProps,
  geographyFromProps,
  periodFromProps,
  rangeFromProps,
  vapeCategoryFromProps,
  offsetFromProps,
  dataSourceFromSectorLevel,
  (productType, geography, period, range, vapeCategory, offset, sector) => {
    if (isEmpty(sector)) return {}
    const amounts =
      sector[`sellin-geography-${productType}-${geography}-${period}-${vapeCategory}-${range}-${offset}`] || {}
    if (isEmpty(amounts)) return {}

    return amounts.map((sellIn) => {
      if (geography === SECTOR_LEVELS.REGION) {
        return {
          linkTo: `/${SECTOR_LEVELS.REGION}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      if (geography === SECTOR_LEVELS.DISTRICT) {
        return {
          linkTo: `/${SECTOR_LEVELS.DISTRICT}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      if (geography === SECTOR_LEVELS.TERRITORY) {
        return {
          linkTo: `/${SECTOR_LEVELS.TERRITORY}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      if (geography === 'store') {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      return {
        linkTo: null,
        ...sellIn
      }
    })
  }
)
