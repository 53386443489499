import React from 'react'
import { stripUnit } from 'polished'
import { bool, node, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'
import Spinner from 'components/Spinner'

import { greyLight } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { H2 } from 'styles/typography'

const Container = styled.div`
  text-align: center;
  padding: ${spacing.xxLarge} ${spacing.large};
  pointer-events: none;

  * {
    color: ${greyLight};
    user-select: none;
  }
`

const Title = styled(H2)`
  margin-bottom: ${spacing.small};
`

const Inbox = styled(Icon)`
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  width: 48px;
  height: 48px;
  opacity: 0.65;
`

const FetchingSpinner = styled(Spinner)`
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  width: 48px;
  height: 48px;
  opacity: 0.65;
`

const EmptyState = ({ title = '', subtitle = '', isLoading, children }) => (
  <Container>
    {isLoading ? <FetchingSpinner icon="spinner" /> : <Inbox icon="inbox" />}
    <Title>{title}</Title>
    {subtitle && <p>{subtitle}</p>}
    {children}
  </Container>
)

EmptyState.propTypes = {
  title: string,
  subtitle: string,
  children: node,
  isLoading: bool
}

export default EmptyState
