import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { bool, func, object, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySelloutGeographyData } from 'store/Sellout/actions'
import { amplifySelloutTableData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import Pagination from 'components/Pagination'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE, SECTOR_LEVELS } from 'utils/constants'
import { formatCompactCurrency, formatCompactNumber, formatNumber, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import AmplifySelloutTableRowHeaderToggle, { displayOptTableLabels } from './AmplifySelloutTableRowHeaderToggle'

const TIME_COLS = {
  rolling: [
    {
      field: 'lShort',
      headerName: 'LW '
    },
    {
      field: 'vpShort',
      headerName: 'vs. PW'
    },
    {
      field: 'lMid',
      headerName: 'L4'
    },
    {
      field: 'vpMid',
      headerName: 'vs. P4'
    },
    {
      field: 'lLong',
      headerName: 'L13'
    },
    {
      field: 'vpLong',
      headerName: 'vs. P13'
    }
  ],
  pointInTime: [
    {
      field: 'lShort',
      headerName: 'CTD'
    },
    {
      field: 'vpShort',
      headerName: 'vs. PC'
    },
    {
      field: 'lMid',
      headerName: 'QTD'
    },
    {
      field: 'vpMid',
      headerName: 'vs. PQ'
    },
    {
      field: 'lLong',
      headerName: 'YTD'
    },
    {
      field: 'vpLong',
      headerName: 'vs. PY'
    }
  ]
}

// const getContribution = ({ currentProportion, currentMetric, rowL13, totalL13 }) => {
//   if (currentProportion === 'share') return formatPercent(rowL13 / totalL13)
//   if (currentMetric === 'cost') return `${formatCompactCurrency(rowL13 || 0)}/${formatCompactCurrency(totalL13)}`
//   return `${formatCompactNumber(rowL13 || 0)}/${formatCompactNumber(totalL13)}`
// }

const generateRow = (
  { currentProportion, currentMetric, sectorLevel, showDisposableUnitsCtd },
  {
    geo,
    rowL13,
    totalL13,
    lShort,
    lShortVariation,
    lMid,
    lMidVariation,
    lLong,
    lLongVariation,
    l13Trendline,
    ccTarget,
    ccTargetActuals,
    linkTo
  }
) => {
  const formatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, decimalPlaces: 1 })
      : currentMetric === 'cost'
      ? formatCompactCurrency
      : formatCompactNumber
  const rowData = {
    rowHeader: linkTo ? <Link to={linkTo}>{geo}</Link> : geo,
    lShort: formatter(lShort),
    vpShort: <DataVariation variation={lShortVariation} formatter={formatter} />,
    lMid: formatter(lMid),
    vpMid: <DataVariation variation={lMidVariation} formatter={formatter} />,
    lLong: formatter(lLong),
    vpLong: <DataVariation variation={lLongVariation} formatter={formatter} />,
    l13Trend: <DataGraph color="#53CCF8" data={l13Trendline} />
  }

  if (sectorLevel !== 'customer') {
    rowData.contribution = formatPercent(rowL13 / totalL13, { convertDecimal: true, decimalPlaces: 1 })
  }

  if (showDisposableUnitsCtd) {
    rowData.disposableUnitsCtd = formatNumber(ccTargetActuals)
    rowData.disposableUnitsVTarget = <DataVariation variation={ccTargetActuals - ccTarget} format={formatNumber} />
  }
  return rowData
}

const generateCols = ({ rowHeader, currentTimeDisplay, selectedLevel, showDisposableUnitsCtd }) => {
  const baseCols = [
    {
      field: 'rowHeader',
      headerName: displayOptTableLabels[rowHeader]
    }
  ]

  const baseColsForSectorLevel =
    selectedLevel === 'customer'
      ? baseCols
      : baseCols.concat({
          field: 'contribution',
          headerName: 'Contribution'
        })

  const colsForVapeChannelCategory = showDisposableUnitsCtd
    ? [
        {
          field: 'disposableUnitsCtd',
          headerName: 'Disposable units CTD'
        },
        {
          field: 'disposableUnitsVTarget',
          headerName: '(vs target)'
        }
      ]
    : []

  const trendCols = [{ field: 'l13Trend', headerName: 'L13 Trend' }]

  return baseColsForSectorLevel
    .concat(colsForVapeChannelCategory)
    .concat(TIME_COLS[currentTimeDisplay])
    .concat(trendCols)
}

const FMC_MANUFACTURERS_OPTIONS = [
  {
    label: 'ITCAN',
    value: 'ITCAN'
  },
  {
    label: 'RBH',
    value: 'RBH'
  },
  {
    label: 'JTI',
    value: 'JTI'
  }
]

const RenderManufacturerDropdown = ({ currentProductType, onChange, value, selectedLevel }) => {
  if (currentProductType !== 'fmc') return null
  const manufacturerOptions =
    selectedLevel === SECTOR_LEVELS.CUSTOMER
      ? FMC_MANUFACTURERS_OPTIONS.concat({
          label: 'ALL',
          value: 'ALL'
        })
      : FMC_MANUFACTURERS_OPTIONS
  return <Dropdown onChange={onChange} value={value} options={manufacturerOptions} />
}

RenderManufacturerDropdown.propTypes = {
  currentProductType: string,
  onChange: func,
  value: string,
  selectedLevel: string
}

const defaultGeographyBySectorLevel = {
  [SECTOR_LEVELS.CUSTOMER]: 'brand',
  [SECTOR_LEVELS.TERRITORY]: 'headoffice',
  [SECTOR_LEVELS.DISTRICT]: 'territory',
  [SECTOR_LEVELS.REGION]: 'province',
  [SECTOR_LEVELS.NATIONAL]: 'region'
}

const defaultSortAndPageData = {
  limit: DEFAULT_TABLE_PAGE_SIZE,
  sortColumn: 'l13',
  sortDirection: 'desc'
}
const geographiesInMain = ['brand', 'variantLabel']

const AmplifySelloutTableCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  fetchAmplifySelloutGeographyData,
  selloutDataLoading
}) => {
  const { formatMessage } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const [manufacturer, setManufacturer] = useState('ITCAN')
  const [geography, setGeography] = useState(defaultGeographyBySectorLevel[selectedLevel])
  const [error, setError] = useState()
  if (error) console.log(error)

  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
  }, [currentSector?.id, selectedLevel, currentProductType, geography, manufacturer, currentVapeCategory])

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])
  const dataKey = useMemo(() => {
    if (!geography) return
    const dataKeyToUse = geographiesInMain.includes(geography)
      ? DATAKEY_TYPES.AMPLIFY.SELL_OUT.MAIN
      : DATAKEY_TYPES.AMPLIFY.SELL_OUT.GEOGRAPHY
    return createDataKey(dataKeyToUse, {
      sectorType: selectedLevel,
      sectorId: currentSector[selectedLevel]?.id,
      productType: currentProductType,
      channel: currentChannel,
      geography,
      dataType,
      offset
    })
  }, [currentSector, selectedLevel, currentProductType, currentChannel, dataType, geography, offset])

  useEffect(() => {
    let mounted = true
    setError()
    const geographiesAreInMain = geographiesInMain.includes(geography)
    if (currentSector[selectedLevel]?.id && geography && dataKey && !geographiesAreInMain) {
      fetchAmplifySelloutGeographyData(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          channel: currentChannel,
          geography,
          dataType,
          offset,
          ...defaultSortAndPageData
        },
        dataKey
      ).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }

    return () => {
      mounted = false
    }
  }, [currentSector, selectedLevel, currentProductType, currentChannel, geography, dataKey])
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const showDisposableUnitsCtd = useMemo(
    () =>
      ['allConsumables', 'disposables'].includes(currentVapeCategory) &&
      currentChannel === 'pos' &&
      currentProductType === 'vape',
    [currentVapeCategory, currentChannel, currentProductType]
  )
  const cols = generateCols({ rowHeader: geography, currentTimeDisplay, selectedLevel, showDisposableUnitsCtd })
  const selloutData = useSelector((state) =>
    amplifySelloutTableData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentVapeCategory,
      geography,
      currentManufacturer: manufacturer,
      dataType,
      offset,
      ...defaultSortAndPageData
    })
  )

  const rows =
    selloutData?.map((v) =>
      generateRow({ currentProportion, currentMetric, sectorLevel: selectedLevel, showDisposableUnitsCtd }, v)
    ) || []

  const cardProps = {
    title: `${displayOptTableLabels[geography]} Performance`,
    span,
    displayAmplify: false
  }

  const emptyStateMessageId = selloutDataLoading || isLoading ? 'emptyState.dataLoading' : 'emptyState.noData'
  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(selloutData) || selloutData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  return (
    <Card
      {...cardProps}
      hideOnSmallScreens={true}
      headerActions={[
        <RenderManufacturerDropdown
          key="amplify-sell-out-table-manufacturer-opts"
          onChange={(e) => setManufacturer(e.target.value)}
          value={manufacturer}
          currentProductType={currentProductType}
          selectedLevel={selectedLevel}
        />,
        <AmplifySelloutTableRowHeaderToggle
          key="amplify-sell-out-geography"
          geography={geography}
          setGeography={setGeography}
          currentChannel={currentChannel}
        />
      ]}
      actions={[
        <Pagination key="sell-out-pagination" currentPage={page} onClickPrev={prevPage} onClickNext={nextPage} />
      ]}
    >
      {isEmpty(selloutData) || selloutDataLoading || isLoading ? (
        <EmptyState
          title={formatMessage({ messageId: emptyStateMessageId })}
          isLoading={selloutDataLoading || isLoading}
        />
      ) : (
        <>
          <DataTable columns={cols} rows={rows} fillContainer />
        </>
      )}
    </Card>
  )
}

AmplifySelloutTableCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  fetchAmplifySelloutGeographyData: func,
  selloutDataLoading: bool
}

export default connect(null, { fetchAmplifySelloutGeographyData })(AmplifySelloutTableCard)
