import React, { useContext, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { fetchAllSectors } from 'store/sector/actions'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import MobileOverlay from 'components/MobileOverlay'

import MobileBreadcrumbs from './MobileBreadcrumbs'
import SectorBrowser from './SectorBrowser'

const Scope = () => {
  const { formatMessage } = useContext(LangContext)
  const { selectedLevel, currentSector, modalOpen, setModalOpen } = useContext(SectorContext)

  const [sectorBrowserVisible, setSectorBrowserVisible] = useState(isEmpty(currentSector))

  const [sectorType, setSectorType] = useState('')
  const [queryString, setQueryString] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const clearFields = () => {
    setSectorType('')
    setQueryString('')
    setSearchResults([])
  }

  const resetView = () => {
    setSectorBrowserVisible(false)
  }

  const selectedSectorName = useMemo(() => {
    return currentSector && currentSector[selectedLevel]?.name
  }, [selectedLevel, currentSector])
  return (
    <div className="relative z-10 flex h-10 min-w-0 grow items-center rounded-full bg-brand-100 text-brand shadow-lg ring-1 ring-brand/20">
      <MobileOverlay
        header="Scope Selection"
        trigger={
          <button className="flex w-full min-w-0 items-center gap-2 p-2">
            <div className="shrink-0">
              <Icon icon="location" />
            </div>
            <p className="truncate">{selectedSectorName}</p>
          </button>
        }
        isOpen={modalOpen}
        onOpenChange={(params) => {
          clearFields()
          resetView()
          setModalOpen(!modalOpen)
        }}
        onHandleClose={() => setModalOpen(false)}
        actions={
          !sectorBrowserVisible ? (
            <Button icon="manage-search" primary full onClick={() => setSectorBrowserVisible(true)}>
              {formatMessage({ messageId: 'sector.change' })}
            </Button>
          ) : (
            <Button tertiary full onClick={() => setSectorBrowserVisible(false)}>
              Cancel
            </Button>
          )
        }
      >
        {!sectorBrowserVisible ? (
          <MobileBreadcrumbs />
        ) : (
          <SectorBrowser
            isMobile
            sectorType={sectorType}
            setSectorType={setSectorType}
            queryString={queryString}
            setQueryString={setQueryString}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            clearFields={clearFields}
          />
        )}
      </MobileOverlay>
    </div>
  )
}

export default connect(null, { fetchAllSectors })(Scope)
