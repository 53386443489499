/* eslint-disable camelcase */

import React, { useContext, useEffect, useRef, useState } from 'react'
import keyBy from 'lodash/keyBy'
import { bool, func, object } from 'prop-types'

import GoogleMapsContext from 'context/GoogleMapsContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

const defaultAddressError =
  'The current address is empty or invalid. Type in the address field and select one of the suggested address.'
const missingStreetNumberError = 'The chosen address is invalid. It does not include a street number.'
const AddressSheet = ({
  visible,
  userAddress,
  latLong,
  setUserAddress,
  toggleAddressVisible,
  autoSchedulingEnabled
}) => {
  const { initAutoComplete, gMapsLoaded } = useContext(GoogleMapsContext)

  const [line1, setLine1] = useState(userAddress?.line_1 || '')
  const [line2, setLine2] = useState(userAddress?.line_2 || '')
  const [city, setCity] = useState(userAddress?.city || '')
  const [postCode, setPostCode] = useState(userAddress?.post_code || '')
  const [state, setState] = useState(userAddress?.state || '')

  const [latlng, setLatLng] = useState(latLong)
  const [isValidAddress, setValidAddress] = useState(latLong.latitude && latLong.longitude)
  const [addressError, setAddressError] = useState()
  const addressRef = useRef()

  useEffect(() => {
    if (visible && userAddress) {
      setLine1(userAddress.line_1 || '')
      setLine2(userAddress.line_2 || '')
      setCity(userAddress.city || '')
      setPostCode(userAddress.post_code || '')
      setState(userAddress.state || '')
    }
  }, [visible, userAddress])

  const clearFields = () => {
    setCity('')
    setPostCode('')
    setState('')
    setAddressError()
  }

  useEffect(() => {
    if (!visible || !gMapsLoaded) return
    if (addressRef.current) {
      const autoComplete = initAutoComplete(addressRef.current)
      addressRef.current.focus()
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace()
        if (!place) return
        const addressComponents = keyBy(place.address_components, ({ types }) => types[0])
        const addressHasStreetNumber = addressComponents.street_number
        if (!addressHasStreetNumber) {
          if (place.formatted_address) setLine1(place.formatted_address)
          setAddressError(missingStreetNumberError)
        }
        if (addressHasStreetNumber) {
          setLine1(`${addressComponents.street_number.long_name} ${addressComponents.route.long_name}`)
          setCity(addressComponents.locality.long_name)
          setPostCode(addressComponents.postal_code.long_name)
          setState(addressComponents.administrative_area_level_1.short_name)
          setLatLng({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
          setValidAddress(true)
        }
      })
    }
  }, [addressRef?.current, visible, gMapsLoaded])

  return (
    <ActionSheet
      title="Set home address"
      visible={visible}
      action={<button onClick={toggleAddressVisible}>Cancel</button>}
    >
      <Fieldset>
        <FieldsetItem>
          <GlobalAlert success>Please contact narrosupport@bat.com if you can&apos;t find your address.</GlobalAlert>
        </FieldsetItem>
        {!isValidAddress && (
          <FieldsetItem>
            <GlobalAlert warning>{addressError || defaultAddressError}</GlobalAlert>
          </FieldsetItem>
        )}
        <FieldsetItem>
          <Input
            ref={addressRef}
            type="text"
            placeholder="Address Line 1"
            value={line1}
            onChange={({ target }) => {
              setLine1(target.value)
              setValidAddress(false)
              clearFields()
            }}
          />
        </FieldsetItem>
        <FieldsetItem>
          <Input
            half
            placeholder="Address Line 2"
            value={line2}
            onChange={({ target }) => {
              setLine2(target.value)
            }}
          />
        </FieldsetItem>
        <FieldsetItem>
          <Input
            half
            placeholder="Postal Code"
            disabled
            value={postCode}
            onChange={({ target }) => {
              setPostCode(target.value)
              setValidAddress(false)
            }}
          />
        </FieldsetItem>
        <FieldsetItem half>
          <Input
            half
            placeholder="City"
            disabled
            value={city}
            onChange={({ target }) => {
              setCity(target.value)
              setValidAddress(false)
            }}
          />
        </FieldsetItem>
        <FieldsetItem half>
          <Input
            half
            placeholder="Province"
            disabled
            value={state}
            onChange={({ target }) => {
              setState(target.value)
              setValidAddress(false)
            }}
          />
        </FieldsetItem>

        {autoSchedulingEnabled && (
          <FieldsetItem>
            <p>
              Updating your home address will have an impact on your schedule. Your schedule will be regenerated
              tonight.
            </p>
          </FieldsetItem>
        )}
        <FieldsetItem>
          <Button
            full
            primary
            onClick={() =>
              setUserAddress({
                address: {
                  line_1: line1,
                  line_2: line2,
                  city,
                  post_code: postCode,
                  state
                },
                ...latlng
              })
            }
            disabled={!isValidAddress}
          >
            Save
          </Button>
        </FieldsetItem>
      </Fieldset>
    </ActionSheet>
  )
}

AddressSheet.propTypes = {
  visible: bool,
  userAddress: object,
  latLong: object,
  setUserAddress: func.isRequired,
  toggleAddressVisible: func.isRequired,
  autoSchedulingEnabled: bool
}

export default AddressSheet
