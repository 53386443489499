import React, { useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { func } from 'prop-types'
import tw from 'twin.macro'

import { fetchCustomerSellInPrograms, getCustomer } from 'store/customers/actions'
import { customerSellInPrograms } from 'store/customers/selectors'

import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import { TouchableTableRow } from 'components/tableRow/TableRow'
import TableRowGroup from 'components/tableRow/TableRowGroup'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const Container = tw.div`w-full h-full p-8`

const programIsActive = (program) =>
  moment(program.startDate).isBefore(moment.now()) && moment(program.endDate).isAfter(moment.now())

const CustomerSellInAction = ({ connectedFetchCustomerSellInPrograms, getCustomer }) => {
  const { sectorId: customerId } = useParams()

  const sellInPrograms = useSelector((state) => customerSellInPrograms(state, { customerId }))

  const activeSellInPrograms = useMemo(() => {
    const programs = Object.values(sellInPrograms || {})
    return programs.length ? programs.filter((program) => programIsActive(program)) : []
  }, [sellInPrograms])

  const customerProgramDataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_SELL_IN_PROGRAMS, { customerId })
  useEffect(() => {
    connectedFetchCustomerSellInPrograms(customerId, customerProgramDataKey)
    getCustomer(customerId)
  }, [customerId])

  return (
    <Container>
      {activeSellInPrograms.length ? (
        <TableRowGroup heading="Sell-in Programs">
          {activeSellInPrograms.map((program, i) => (
            <TouchableTableRow key={i} link={`${program.id}`}>
              <span>{program.name}</span>
            </TouchableTableRow>
          ))}
        </TableRowGroup>
      ) : (
        <EmptyState title="There is no active Sell In program for this customer" />
      )}
      <LoadingCard dataKey={customerProgramDataKey} />
    </Container>
  )
}

CustomerSellInAction.propTypes = {
  connectedFetchCustomerSellInPrograms: func,
  getCustomer: func
}

export default connect(null, {
  connectedFetchCustomerSellInPrograms: fetchCustomerSellInPrograms,
  getCustomer
})(CustomerSellInAction)
