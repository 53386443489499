import React, { useContext } from 'react'
import { connect, useSelector } from 'react-redux'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { markAlertRead } from 'store/alerts/actions'
import * as alertSelector from 'store/alerts/selectors'
import * as authSelector from 'store/auth/selectors'

import AlertCard from 'components/AlertCard'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import MobileHeader from 'components/MobileHeader'
import SectionDivider from 'components/SectionDivider'

import { H2 } from 'styles/typography'

const Inbox = ({ markAlertRead }) => {
  const { formatMessage } = useContext(LangContext)
  const { read = [], unread = [] } = useSelector((state) => alertSelector.sortedAlerts(state))
  const language = useSelector((state) => authSelector.preferredLanguage(state))

  if (!read.length && !unread.length)
    return (
      <>
        <MobileHeader isNavOnly />
        <div className="flex w-full justify-center max-md:pt-12">
          <EmptyState title="No alerts" subtitle="There are no new alerts" />
        </div>
      </>
    )
  return (
    <Container>
      {unread.map((unreadAlert, idx) => (
        <AlertCard
          key={idx}
          date={new Date(unreadAlert.startAt).toDateString()}
          action={() => markAlertRead(unreadAlert.id)}
          cta={formatMessage({ messageId: 'alerts.gotIt' })}
        >
          <H2>{unreadAlert[`${language}Title`]}</H2>
          <div dangerouslySetInnerHTML={{ __html: unreadAlert[`${language}Content`] }} />
        </AlertCard>
      ))}
      {read.length > 0 && <SectionDivider title={formatMessage({ messageId: 'alerts.read' })} />}
      {read.map((readAlert, idx) => (
        <AlertCard
          key={idx}
          date={new Date(readAlert.startAt).toDateString()}
          action={() => markAlertRead(readAlert.id)}
          past
        >
          <H2>{readAlert[`${language}Title`]}</H2>
          <div dangerouslySetInnerHTML={{ __html: readAlert[`${language}Content`] }} />
        </AlertCard>
      ))}
    </Container>
  )
}

Inbox.propTypes = {
  markAlertRead: func
}

export default connect(null, { markAlertRead })(Inbox)
