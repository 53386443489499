import React, { useContext, useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { bool, func, number } from 'prop-types'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Input from 'components/Input'
import { ToggleField } from 'components/Toggle'

import { required } from 'utils/validators'

function PO98ActionSheet({ isMobileViewOnly, onClose, isEdit, totalQuantity }) {
  const { formatMessage } = useContext(LangContext)
  const formikContext = useFormikContext()
  const totalQuantityNotReached = totalQuantity < 5

  const coordinatorUpdated =
    formikContext.values.po98CoordinatorApproved && formikContext.values.po98CoordinatorName?.trim().length > 0

  useEffect(() => {
    if (formikContext.values.po98CoordinatorApproved) formikContext.setFieldTouched('po98CoordinatorName', true)
    if (!formikContext.values.po98CoordinatorApproved) formikContext.setFieldValue('po98CoordinatorName', '')
  }, [formikContext.values.po98CoordinatorApproved, coordinatorUpdated])

  const submitIsEnabled =
    !totalQuantityNotReached &&
    Object.keys(formikContext.errors).length === 0 &&
    !formikContext.isSubmitting &&
    !isMobileViewOnly

  // if changing any of the values, remember to change them in the BE too !
  const OPTIONS = [
    {
      label: formatMessage({ messageId: 'po98.outOfStock' }),
      value: 'outOfStock'
    },
    {
      label: formatMessage({ messageId: 'po98.lowInventory' }),
      value: 'lowInventory'
    },
    {
      label: formatMessage({ messageId: 'po98.missedOrder' }),
      value: 'missedOrder'
    },
    {
      label: formatMessage({ messageId: 'po98.distributionGap' }),
      value: 'distributionGap'
    },
    {
      label: formatMessage({ messageId: 'po98.firstOrder' }),
      value: 'firstOrder'
    }
  ]

  const YES_NO = [
    {
      label: formatMessage({ messageId: 'yes' }),
      value: true
    },
    {
      label: formatMessage({ messageId: 'no' }),
      value: false
    }
  ]

  const handleDropDown = (event) => {
    const status = event.target.value === 'true'

    formikContext.setFieldValue('po98CoordinatorApproved', status)
  }

  return (
    <ActionSheet
      title={isEdit ? 'Edit PO98 order' : 'Create PO98 order'}
      action={
        <button type="button" onClick={onClose}>
          <Icon icon="close" />
        </button>
      }
      visible
    >
      <Fieldset>
        <FieldsetItem>
          <Field
            component={Dropdown}
            options={OPTIONS}
            name="po98Reason"
            label={formatMessage({ messageId: 'po98.reason' })}
            menuPlacement="top"
            placeholder={formatMessage({ messageId: 'po98.selectOption' })}
            validate={required}
          />
        </FieldsetItem>
        <FieldsetItem>
          <Field
            component={Dropdown}
            options={YES_NO}
            name="po98CoordinatorApproved"
            label={formatMessage({ messageId: 'po98.isApprovedBy' })}
            menuPlacement="top"
            onChange={handleDropDown}
            placeholder={formatMessage({ messageId: 'po98.selectOption' })}
            validate={required}
          />
        </FieldsetItem>
        {formikContext.values.po98CoordinatorApproved && (
          <FieldsetItem>
            <Field
              component={Input}
              name="po98CoordinatorName"
              label={formatMessage({ messageId: 'po98.insertCoordonatorName' })}
              validate={required}
            />
          </FieldsetItem>
        )}
        <FieldsetItem>
          <Field component={Input} name="po98Comments" label={formatMessage({ messageId: 'po98.comments' })} textarea />
        </FieldsetItem>
        <FieldsetItem>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{formatMessage({ messageId: 'po98.isEmergencyOrder' })}</span>
            <Field component={ToggleField} name="emergencyFlag" />
          </div>
        </FieldsetItem>
        <FieldsetItem>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{formatMessage({ messageId: 'po98.sendEmailCopy' })}</span>
            <Field component={ToggleField} name="sendEmailCopy" isDisabled />
          </div>
        </FieldsetItem>
        {totalQuantityNotReached && (
          <FieldsetItem>
            <GlobalAlert warning>
              <p>{formatMessage({ messageId: 'po98.minimumQty' })}</p>
            </GlobalAlert>
          </FieldsetItem>
        )}
        <FieldsetItem>
          <Button
            onClick={formikContext.submitForm}
            type="submit"
            primary
            full
            disabled={formikContext.isSubmitting || !submitIsEnabled}
          >
            {formatMessage({ messageId: 'submit' })}
          </Button>
        </FieldsetItem>
      </Fieldset>
    </ActionSheet>
  )
}

PO98ActionSheet.propTypes = {
  isMobileViewOnly: bool,
  onClose: func.isRequired,
  isEdit: bool,
  totalQuantity: number
}

export default PO98ActionSheet
