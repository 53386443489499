import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

const getProps = (state, props) => props
const getCurrentPriceCaptureCompliance = createSelector(
  dataSourceFromSectorLevel,
  (sectorData) => sectorData?.priceCaptureCompliance || {}
)
export const getStoreFootprint = createSelector(getCurrentPriceCaptureCompliance, (price) => {
  const defaultFootprint = {
    IND: {
      storeCount: 0,
      storesUpdated: 0,
      storesRemaining: 0,
      storesPercent: 0
    },
    VS: {
      storeCount: 0,
      storesUpdated: 0,
      storesRemaining: 0,
      storesPercent: 0
    },
    POS: {
      storeCount: 0,
      storesUpdated: 0,
      storesRemaining: 0,
      storesPercent: 0
    }
  }

  return Object.assign(defaultFootprint, price.storeFootprint || {})
})

export const getBrandCompletion = createSelector(getCurrentPriceCaptureCompliance, getProps, (price, props) => {
  const { category } = props
  if (!category) return {}

  return price[`brandCompletion-${category}`] || {}
})

export const getFilters = createSelector(getCurrentPriceCaptureCompliance, (price) => price.filters || {})

export const getGeography = createSelector(getCurrentPriceCaptureCompliance, (price) => price.geographies)

export const getStrategyCompliance = createSelector(getCurrentPriceCaptureCompliance, getProps, (price, props) => {
  const { category } = props
  return price?.[`strategy-compliance-${category}`] || {}
})

export const getEdlpCompliance = createSelector(getCurrentPriceCaptureCompliance, (price) => price.edlpCompliance || {})

export const getPriceComplianceGeographyFilters = createSelector(
  getCurrentPriceCaptureCompliance,
  (price) => price.priceComplianceGeographyFilters
)

export const getPriceComplianceGeography = createSelector(
  getCurrentPriceCaptureCompliance,
  (price) => price.priceComplianceGeographies
)
