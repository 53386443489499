import { addEntities } from 'store/actions'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToRegion,
  addAmplifyExtraHubEngagementValuesToRegion,
  addAmplifyExtraHubFiltersValuesToRegion,
  addAmplifyExtraHubGeographyValuesToRegion,
  addAmplifyExtraHubTrendingValuesToRegion,
  addAmplifyInventoryOosBrandsValuesToRegion,
  addAmplifyInventoryOosFiltersValuesToRegion,
  addAmplifyInventoryOosGeographyValuesToRegion,
  addAmplifyInventoryOosMaterialValuesToRegion,
  addAmplifyInventoryOosTrendingValuesToRegion,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToRegion,
  addAmplifyPriceCaptureComplianceFiltersValuesToRegion,
  addAmplifyPriceCaptureComplianceFootprintValuesToRegion,
  addAmplifyPriceCaptureComplianceGeographyValuesToRegion,
  addAmplifyPriceComplianceEdlpComplianceValuesToRegion,
  addAmplifyPriceComplianceGeographyComplianceValuesToRegion,
  addAmplifyPriceComplianceGeographyFiltersValuesToRegion,
  addAmplifyPriceComplianceStrategyComplianceValuesToRegion,
  addAmplifySelloutGeographyValuesToRegion,
  addAmplifySelloutValuesToRegion,
  addDistroBrandDataToRegion,
  addDistroGeographyDataToRegion,
  addDistroMaterialDataToRegion,
  addDistroTrendedDataToRegion,
  addPlanningEngagementValuesToRegion,
  addPlanningHealthCheckValuesToRegion,
  addPlanningPriceCheckValuesToRegion,
  addPlanningSellinValuesToRegion,
  addPlanningSelloutValuesToRegion,
  addSellinGeographyToRegion,
  addSellinOrderCompletionToRegion,
  addSellinRunRateToRegion
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.regions) return state

    return Object.entries(payload.regions).reduce((acc, [id, region]) => {
      return Object.assign(acc, {
        [id]: region
      })
    }, Object.assign({}, state))
  },
  [addSellinRunRateToRegion]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addSellinGeographyToRegion]: (
    state,
    { payload: { sectorId: id, currentProductType, geography, period, range, vapeCategory, offset, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${range}-${offset}`]: result
      }
    })
  },
  [addSellinOrderCompletionToRegion]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion-${activeProductType}`]: result
      }
    })
  },
  [addDistroTrendedDataToRegion]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}`]: result
      }
    })
  },
  [addDistroBrandDataToRegion]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}`]: result
      }
    })
  },
  [addDistroGeographyDataToRegion]: (state, { payload: { sectorId: id, geography, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${geography}`]: result
      }
    })
  },
  [addDistroMaterialDataToRegion]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}`]: result
      }
    })
  },
  [addPlanningSellinValuesToRegion]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToRegion]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToRegion]: (state, { payload: { id, activeProductType, channel, dataType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`amplify-sellout-${activeProductType}-${channel}-${dataType}`]: result
      }
    })
  },
  [addAmplifySelloutGeographyValuesToRegion]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToRegion]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${activeProductType}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToRegion]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToRegion]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },
  [addAmplifyInventoryOosTrendingValuesToRegion]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToRegion]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToRegion]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToRegion]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToRegion]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToRegion]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToRegion]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToRegion]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToRegion]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToRegion]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToRegion]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToRegion]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToRegion]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToRegion]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToRegion]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToRegion]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToRegion]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToRegion]: priceCaptureCompliance.priceComplianceGeographyFilters
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
