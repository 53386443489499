import React, { useContext, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import flatten from 'lodash/flatten'
import { func } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { fetchCustomerGoFunds } from 'store/customers/actions'
import { customerPrograms, lastCustomerGoSalesBooked } from 'store/customers/selectors'
import { fetchTerritoryGoFunds } from 'store/territories/actions'

import ProgramAccordion, { Title } from 'components/accordion/ProgramAccordion'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import GlobalAlert from 'components/GlobalAlert'
import ProgramCard from 'components/ProgramCard'
import Section from 'components/Section'

const ProgramList = ({ fetchCustomerGoFunds, fetchTerritoryGoFunds }) => {
  const { sectorId: customerId } = useParams()
  const { currentSector } = useContext(SectorContext)
  const programs = useSelector((state) => customerPrograms(state, { customerId }))
  const territoryId = currentSector?.territory?.id
  const lastGoSalesBookedDate = useSelector((state) => lastCustomerGoSalesBooked(state, { customerId }))

  useEffect(() => {
    fetchCustomerGoFunds(customerId)
    fetchTerritoryGoFunds(territoryId)
  }, [])

  const noPrograms = !programs || !flatten(Object.values(programs)).length
  const { current, ended, completed } = programs
  const isOffline = !window.navigator.onLine
  return (
    <>
      <Container>
        {isOffline && (
          <GlobalAlert warning>
            You are offline, the list you are seeing might not be up to date and you will not be able to submit payments
            or exceptions
          </GlobalAlert>
        )}

        {noPrograms ? (
          <EmptyState title="Nothing yet" subtitle="No programs have been added yet." />
        ) : (
          <>
            {ended.length > 0 && (
              <Section>
                {ended.map((program, i) => (
                  <ProgramCard
                    key={i}
                    program={program}
                    programsPath="."
                    isEnded
                    lastGoSalesBookedDate={lastGoSalesBookedDate}
                  />
                ))}
              </Section>
            )}
            {current.length > 0 && (
              <Section>
                {ended.length > 0 && <Title>Current</Title>}
                {current.map((program, i) => (
                  <ProgramCard
                    key={i}
                    program={program}
                    programsPath="."
                    isCurrent
                    lastGoSalesBookedDate={lastGoSalesBookedDate}
                  />
                ))}
              </Section>
            )}
            {completed.length > 0 && (
              <Section>
                <ProgramAccordion title="Completed Programs">
                  {completed.map((program, i) => (
                    <ProgramCard
                      key={i}
                      program={program}
                      programsPath="."
                      isCompleted
                      lastGoSalesBookedDate={lastGoSalesBookedDate}
                    />
                  ))}
                </ProgramAccordion>
              </Section>
            )}
          </>
        )}
      </Container>
    </>
  )
}

ProgramList.propTypes = {
  fetchTerritoryGoFunds: func,
  fetchCustomerGoFunds: func
}

const mapActionCreators = {
  fetchCustomerGoFunds,
  fetchTerritoryGoFunds
}

export default connect(null, mapActionCreators)(ProgramList)
