import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { bool, func, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'

const StyledNavLink = styled(NavLink)((props) => [
  tw`text-white -mx-1 transition font-medium focus-visible:outline-none hover:bg-white/5 focus-visible:ring-2 focus-visible:ring-brand-500 px-4 flex rounded-md space-x-3 h-12 items-center relative`,
  props.isSubLink && tw`pl-11 font-normal text-white/80 hover:bg-white/5 hover:text-white/100`,
  props.active === 'true' &&
    tw`bg-brand-700 text-brand-accent after:absolute after:-left-5 after:top-0 after:h-full after:w-[3px] after:rounded-tr-md after:rounded-br-md after:bg-brand-accent after:content-[""] hover:bg-brand-700 hover:text-brand-accent`
])

StyledNavLink.propTypes = {
  active: string
}

const MobileLink = ({ name, icon, url, onClick, isSubLink }) => {
  const { pathname } = useLocation()
  const isActive = pathname.includes(url)
  return (
    <StyledNavLink to={url} active={isActive.toString()} onClick={onClick} isSubLink={isSubLink}>
      {icon && (
        <span className="-ml-[3px] flex">
          <Icon compact icon={icon} />
        </span>
      )}
      <span className="max-w-full select-none text-ellipsis">{name}</span>
    </StyledNavLink>
  )
}

MobileLink.propTypes = {
  name: string.isRequired,
  url: string,
  icon: string.isRequired,
  onClick: func,
  isSubLink: bool
}

export default MobileLink
