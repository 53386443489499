import { uniqBy } from 'lodash'
import groupBy from 'lodash/groupBy'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import without from 'lodash/without'

import { addEntities, mergeEntities, setEntities } from 'store/actions'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToCustomer,
  addAmplifyExtraHubEngagementValuesToCustomer,
  addAmplifyExtraHubFiltersValuesToCustomer,
  addAmplifyExtraHubGeographyValuesToCustomer,
  addAmplifyExtraHubTrendingValuesToCustomer,
  addAmplifyInventoryOosBrandsValuesToCustomer,
  addAmplifyInventoryOosFiltersValuesToCustomer,
  addAmplifyInventoryOosGeographyValuesToCustomer,
  addAmplifyInventoryOosMaterialValuesToCustomer,
  addAmplifyInventoryOosTrendingValuesToCustomer,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToCustomer,
  addAmplifyPriceCaptureComplianceFiltersValuesToCustomer,
  addAmplifyPriceCaptureComplianceFootprintValuesToCustomer,
  addAmplifyPriceCaptureComplianceGeographyValuesToCustomer,
  addAmplifyPriceComplianceEdlpComplianceValuesToCustomer,
  addAmplifyPriceComplianceGeographyComplianceValuesToCustomer,
  addAmplifyPriceComplianceGeographyFiltersValuesToCustomer,
  addAmplifyPriceComplianceStrategyComplianceValuesToCustomer,
  addAmplifySelloutGeographyValuesToCustomer,
  addAmplifySelloutValuesToCustomer,
  addCallsToCustomer,
  addCallToCustomer,
  addCustomerMessage,
  addDistroBrandDataToCustomer,
  addDistroGeographyDataToCustomer,
  addDistroMaterialDataToCustomer,
  addDistroTrendedDataToCustomer,
  addIntelToCustomer,
  addOrderToCustomer,
  addPlanningEngagementValuesToCustomer,
  addPlanningHealthCheckValuesToCustomer,
  addPlanningPriceCheckValuesToCustomer,
  addPlanningSellinValuesToCustomer,
  addPlanningSelloutValuesToCustomer,
  addPricingCaptureToCustomer,
  addProgramToCustomer,
  addSellinGeographyToCustomer,
  addSellinOrderCompletionToCustomer,
  addSellinRunRateToCustomer,
  removeCallFromCustomer,
  removeCustomerOrders,
  removeIntelFromCustomer,
  removeProgramFromCustomer,
  updateAutofilledCustomerSurveys,
  updateCustomerProject,
  updateCustomerSurvey
} from './actions'

function mergeNewCapture(latestPricings, newCapture) {
  const captureToUpdateIndex = latestPricings.findIndex(
    ({ upc, source }) => upc === newCapture.upc && source === newCapture.source
  )
  const captureToUpdate = latestPricings[captureToUpdateIndex]
  if (captureToUpdateIndex === -1) return [...latestPricings, newCapture]
  latestPricings.splice(captureToUpdateIndex, 1, merge({}, captureToUpdate, newCapture))
  return latestPricings
}

function mergeUpdatedSurvey(customerSurveys, updatedSurvey) {
  const formattedUpdate = { ...updatedSurvey, survey: updatedSurvey.surveyId }
  if (!customerSurveys) return [formattedUpdate]
  const surveyToUpdate = customerSurveys.findIndex(({ surveyId }) => surveyId === updatedSurvey.surveyId)
  if (surveyToUpdate === -1) return [...customerSurveys, formattedUpdate]
  return customerSurveys.map((cs) =>
    cs.surveyId === updatedSurvey.surveyId ? Object.assign({}, merge(cs, formattedUpdate)) : cs
  )
}

function mergeUpdatedProjectLink(customerProjectLinks, updatedProjectLink) {
  const projectLinkToUpdate = customerProjectLinks.findIndex(
    ({ projectId }) => projectId === updatedProjectLink.projectId
  )
  const formattedUpdate = { ...updatedProjectLink, project: updatedProjectLink.projectId }
  if (projectLinkToUpdate === -1) return [...customerProjectLinks, formattedUpdate]
  return customerProjectLinks.map((cs) =>
    cs.projectId === updatedProjectLink.projectId ? Object.assign({}, merge(cs, formattedUpdate)) : cs
  )
}

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => {
    if (!action.payload.customers) return state
    const newCustomerState = Object.assign({}, state)
    Object.entries(action.payload.customers).forEach(([id, customer]) => {
      const existingCustomer = state[id] || {}
      newCustomerState[id] = {
        ...existingCustomer,
        ...customer
      }
    })
    return newCustomerState
  },
  [mergeEntities]: (state, action) => {
    if (!action.payload.customers) return state
    const newCustomerState = Object.assign({}, state)
    Object.entries(action.payload.customers).forEach(([id, customer]) => {
      const existingCustomer = state[id] || {}
      const existingCustomerCalls = existingCustomer.calls || []
      const newCustomerCalls = customer.calls || []
      const existingCustomerMessages = existingCustomer.messages || []
      const newCustomerMessages = customer.messages || []

      newCustomerState[id] = {
        ...existingCustomer,
        ...customer,
        calls: uniq(existingCustomerCalls.concat(newCustomerCalls)),
        messages: uniqBy(existingCustomerMessages.concat(newCustomerMessages), 'id')
      }
    })
    return newCustomerState
  },
  [setEntities]: (state, action) => action.payload.customers || state,
  [addOrderToCustomer]: (state, { payload: { customerId, orderId, projectId } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        orders: uniq([...state[customerId].orders, orderId]),
        projectLinks: projectId
          ? state[customerId].projectLinks.map((pl) =>
              pl.projectId === projectId ? { ...pl, orders: pl.orders.concat({ id: orderId, status: 'pending' }) } : pl
            )
          : state[customerId].projectLinks
      }
    }),
  [addProgramToCustomer]: (state, { payload: { customerId, id } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        programs: [...state[customerId].programs, id]
      }
    }),
  [removeCustomerOrders]: (state, { payload: { customerId, cancelledOrders } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        orders: without(state[customerId].orders, ...cancelledOrders)
      }
    }),
  [removeProgramFromCustomer]: (state, { payload: { customerId, id, tempId = 0 } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        programs: state[customerId].programs.filter((programId) => ![id, tempId].includes(programId))
      }
    }),
  [addIntelToCustomer]: (state, { payload: { customerId, id, scopeId } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        intel: uniq([...state[customerId].intel, id]),
        scopeRequests: state[customerId].scopeRequests.filter((request) => request.scopeId !== scopeId)
      }
    }),
  [removeIntelFromCustomer]: (state, { payload: { customerId, id, tempId = 0 } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        intel: state[customerId].intel.filter((intelId) => ![id, tempId].includes(intelId))
      }
    }),
  [addPricingCaptureToCustomer]: (state, { payload: { customerId, ...pricingCapture } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        latestPricings: mergeNewCapture(state[customerId].latestPricings, { customerId, ...pricingCapture })
      }
    }),
  [updateCustomerSurvey]: (state, { payload: { customerId, ...updatedSurvey } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        surveys: mergeUpdatedSurvey(state[customerId].surveys || [], { customerId, ...updatedSurvey })
      }
    }),
  [updateAutofilledCustomerSurveys]: (state, { payload: targets }) => {
    const updatedCustomers = targets.reduce((acc, { customerId, ...rest }) => {
      return {
        ...acc,
        [customerId]: {
          ...state[customerId],
          surveys: mergeUpdatedSurvey(state[customerId].surveys, { customerId, ...rest })
        }
      }
    }, {})
    return Object.assign({}, state, updatedCustomers)
  },
  [updateCustomerProject]: (state, { payload: { customerId, ...updatedProjectLink } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        projectLinks: mergeUpdatedProjectLink(state[customerId].projectLinks, { customerId, ...updatedProjectLink })
      }
    }),
  [addCustomerMessage]: (state, { payload }) =>
    Object.assign({}, state, {
      [payload.customerId]: {
        ...state[payload.customerId],
        messages: sortBy([...state[payload.customerId].messages, payload], ({ createdAt }) => -new Date(createdAt))
      }
    }),
  [addCallToCustomer]: (state, { payload: { customerId, id, customer } }) =>
    Object.assign({}, state, {
      [customerId]: {
        ...state[customerId],
        ...customer,
        calls: uniq([...(state[customerId].calls || []), id])
      }
    }),
  [addCallsToCustomer]: (state, { payload }) => {
    if (!payload.calls) return state
    const groupedCalls = groupBy(Object.values(payload.calls), 'customerId')
    const updatedCustomers = Object.entries(groupedCalls).reduce((mergedCustomers, [customerId, customerCalls]) => {
      const existingCustomer = state[customerId]
      if (!existingCustomer) return mergedCustomers
      const customerCallIds = []
        .concat(customerCalls)
        .filter(Boolean)
        .map(({ id }) => id)
      return {
        ...mergedCustomers,
        [customerId]: {
          ...existingCustomer,
          calls: uniq([...(existingCustomer?.calls || []), ...customerCallIds])
        }
      }
    }, {})
    return Object.assign({}, state, updatedCustomers)
  },
  [removeCallFromCustomer]: (state, { payload: { customer, id, tempId = 0 } }) =>
    Object.assign({}, state, {
      [customer.id]: {
        ...state[customer.id],
        calls: state[customer.id].calls.filter((callId) => ![id, tempId].includes(callId))
      }
    }),
  [addPlanningSellinValuesToCustomer]: (state, { payload: { id, result, activeProductType, vapeCategory } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addSellinGeographyToCustomer]: (
    state,
    { payload: { sectorId: id, currentProductType, geography, period, range, vapeCategory, offset, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${range}-${offset}`]: result
      }
    })
  },
  [addSellinOrderCompletionToCustomer]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion-${activeProductType}`]: result
      }
    })
  },
  [addSellinRunRateToCustomer]: (state, { payload: { id, activeProductType, vapeCategory, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToCustomer]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addPlanningHealthCheckValuesToCustomer]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${activeProductType}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToCustomer]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addDistroTrendedDataToCustomer]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}`]: result
      }
    })
  },
  [addDistroBrandDataToCustomer]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}`]: result
      }
    })
  },
  [addDistroGeographyDataToCustomer]: (state, { payload: { sectorId: id, geography, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${geography}`]: result
      }
    })
  },
  [addDistroMaterialDataToCustomer]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}`]: result
      }
    })
  },
  [addPlanningEngagementValuesToCustomer]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },
  [addAmplifySelloutValuesToCustomer]: (state, { payload: { id, activeProductType, channel, result, dataType } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`amplify-sellout-${activeProductType}-${channel}-${dataType}`]: result
      }
    })
  },
  [addAmplifySelloutGeographyValuesToCustomer]: selloutReducer.mergeGeography,
  [addAmplifyInventoryOosTrendingValuesToCustomer]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToCustomer]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToCustomer]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToCustomer]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToCustomer]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToCustomer]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToCustomer]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToCustomer]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToCustomer]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToCustomer]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToCustomer]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToCustomer]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToCustomer]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToCustomer]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToCustomer]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToCustomer]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToCustomer]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToCustomer]: priceCaptureCompliance.priceComplianceGeographyFilters
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
