import uniqBy from 'lodash/uniqBy'

export const mergeGeography = (
  state,
  { payload: { id, activeProductType, channel, result, dataType, geography, uniqByCols } }
) => {
  const stateKey = `amplify-sellout-${activeProductType}-${channel}-${dataType}-${geography}`
  const existingEntity = state[id] || {}
  const existingResult = existingEntity[stateKey] || []
  return Object.assign({}, state, {
    [id]: {
      ...existingEntity,
      [stateKey]: uniqBy(existingResult.concat(result), (d) => uniqByCols.map((col) => d[col]).join('-'))
    }
  })
}
