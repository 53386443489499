import React, { useContext } from 'react'
import { stripUnit } from 'polished'
import { bool, node, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import Status from 'components/Status'

import { borderColor, greyDark, offWhite } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontSemibold, smallFontSize } from 'styles/typography'

const Table = styled.table`
  position: relative;
  text-align: left;
  width: 100%;
  margin-top: ${spacing.small};

  th {
    color: ${greyDark};
    font-weight: ${fontSemibold};
    font-size: ${smallFontSize};
    padding-top: 0;
    top: 104px;
    background-color: ${offWhite};
  }

  th,
  td {
    padding: ${stripUnit(spacing.small) * 1.5 + 'px'};
    border-bottom: 1px solid ${borderColor};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`

export const PriceCaptureTableRow = styled.tr`
  position: static;

  td {
    vertical-align: baseline;
  }
`

const PriceCaptureTable = ({ children, captureLink, isRetailer }) => {
  const { formatMessage } = useContext(LangContext)

  return (
    <Table>
      <thead>
        <tr>
          <th>{formatMessage({ messageId: 'product' })}</th>
          {isRetailer && (
            <>
              <th>
                <Status status="noStatus" label={formatMessage({ messageId: 'price' })} />
              </th>
              <th>{formatMessage({ messageId: 'date' })}</th>
            </>
          )}
          {!isRetailer && (
            <>
              <th>TM Price before tax</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  )
}
PriceCaptureTable.propTypes = {
  children: node.isRequired,
  captureLink: string.isRequired,
  isRetailer: bool
}

export default PriceCaptureTable
