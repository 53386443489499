import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import sum from 'lodash/sum'
import moment from 'moment'
import { rgba } from 'polished'
import { func } from 'prop-types'
import styled from 'styled-components'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchTerritory } from 'store/territories/actions'
import * as territorySelector from 'store/territories/selectors'

import Container from 'components/Container'
import Icon from 'components/Icon'
import Input from 'components/Input'
import LoadingCard from 'components/LoadingCard'
import SurveyCompletionFilters from 'components/SurveyCompletionFilters'
import SurveyList from 'components/SurveyList'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

import { black, white } from 'styles/colors'
import { square } from 'styles/global'

const ClearButton = styled.button`
  display: flex;
  border-radius: 50%;
  background-color: ${rgba(black, 0.4)};
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  ${square('22px')};
`

const TerritorySurveys = ({ connectedFetchTerritory }) => {
  const { sectorId: territoryId } = useParams()
  const [loading, setIsLoading] = useState(true)
  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const { surveys, total, complete, notStarted, inProgress, clarificationNeeded } = useSelector((state) =>
    territorySelector.territorySurveysList(state, { territoryId })
  )

  const [completionFilters, setCompletionFilters] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredSurveys, setFilteredSurveys] = useState(surveys)

  const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY, { territoryId })
  useEffect(() => {
    setIsLoading(true)
    if (territoryId)
      connectedFetchTerritory(territoryId, dataKey).then(() => {
        if (isMounted.current) {
          setIsLoading(false)
        }
      })
  }, [territoryId])
  const territoryLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const isLoading = loading || territoryLoading

  const debouncedFilteredUpdate = debounce(setFilteredSurveys, 300)
  useEffect(() => {
    if (completionFilters.length || searchQuery?.length) {
      const surveysFiltered = surveys.filter((s) => {
        const hasValidCompletionStatus = completionFilters.length
          ? sum(Object.values(pick(s, completionFilters)))
          : true
        if (!hasValidCompletionStatus) return false
        if (!searchQuery) return true
        const searchQueryMatchesName = s.survey.name.toLowerCase().includes(searchQuery.toLowerCase())
        const dateQuery = moment(searchQuery)
        if (dateQuery.isValid() && dateQuery.get('year') === 2001)
          dateQuery.set('year', moment(s.survey.endDate).get('year')) // valid dates without years default to 2001 for some reason
        const searchQueryMatchesDate = dateQuery.isValid() && dateQuery.isSame(s.survey.endDate, 'day')
        return searchQueryMatchesName || searchQueryMatchesDate
      })
      debouncedFilteredUpdate(surveysFiltered)
    } else {
      setFilteredSurveys(surveys)
    }
  }, [surveys, completionFilters, searchQuery, debouncedFilteredUpdate])

  if (isLoading) return <LoadingCard dataKey={dataKey} />

  return (
    <Container>
      <>
        <div style={{ marginBottom: 16 }}>
          <Input
            placeholder="Search by name or end date..."
            onChange={({ target }) => setSearchQuery(target.value)}
            value={searchQuery}
            addon={
              searchQuery ? (
                <ClearButton onClick={() => setSearchQuery('')}>
                  <Icon icon="close" small />
                </ClearButton>
              ) : null
            }
          />
        </div>
        <SurveyCompletionFilters
          total={total}
          complete={complete}
          notStarted={notStarted}
          inProgress={inProgress}
          clarificationNeeded={clarificationNeeded}
          updateCompletionFilters={setCompletionFilters}
        />
        <SurveyList surveys={filteredSurveys} />
      </>
    </Container>
  )
}

TerritorySurveys.propTypes = {
  connectedFetchTerritory: func
}

export default connect(null, {
  connectedFetchTerritory: fetchTerritory
})(TerritorySurveys)
