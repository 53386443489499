import deepKeys from 'deep-keys'
import isEqual from 'lodash/isEqual'

import { beta, common, dev, production, qa, staging } from './variables'

function getEnvConfig() {
  if (
    !isEqual(
      deepKeys({ ...common, ...production }),
      deepKeys({ ...common, ...staging }),
      deepKeys({ ...common, ...dev }),
      deepKeys({ ...common, ...beta })
    )
  ) {
    console.warn('Prod, staging and dev config structure mismatch')
  }

  if (__DEV__) return beta
  if (__ENV_OVERRIDE__ && __ENV_OVERRIDE__ === 'staging') return staging
  if (__ENV_OVERRIDE__ && __ENV_OVERRIDE__ === 'qa') return qa
  if (__ENV_OVERRIDE__ && __ENV_OVERRIDE__ === 'beta') return beta
  return production
}

export default { ...common, ...getEnvConfig() }
