import { createAction } from 'redux-actions'

import { PLANNING_SELECTOR_ACTIONS } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS } from 'utils/helpers'

import * as api from './endpoints'

export const fetchPlanningEngagement = createAction(
  'Get Planning Engagement Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline

      const { data } = await api.fetchPlanningEngagement(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].engagement
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)
