import React from 'react'
import { easeInOut, motion } from 'framer-motion'
import moment from 'moment'
import { bool, string } from 'prop-types'
import tailwindColors from 'tailwindcss/colors'

const Dot = ({ error }) => {
  if (error) return <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
  else
    return (
      <motion.div
        className="h-1.5 w-1.5 rounded-full"
        initial={{ backgroundColor: tailwindColors.green[900] }}
        animate={{
          backgroundColor: tailwindColors.green[600],
          boxShadow: `0 0 4px ${tailwindColors.green[500]}`
        }}
        transition={{ duration: 0.8, ease: easeInOut, repeat: Infinity, repeatType: 'mirror' }}
      />
    )
}

Dot.propTypes = {
  error: bool
}

const PageStatus = ({ error, timeUpdated }) => {
  const date = new Date(timeUpdated)

  return (
    <div className="flex items-center gap-2">
      <Dot error={error} />
      <span className="text-2xs text-slate-500">{'Updated ' + moment(date).format('MMM D YYYY, h:mma')}</span>
    </div>
  )
}

PageStatus.propTypes = {
  error: bool,
  timeUpdated: string
}

export default PageStatus
