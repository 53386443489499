import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import SAQList from 'views/Customer/SAQList'

import { SECTOR_LEVELS } from 'utils/constants'

const OrderActionSwitcher = () => {
  const { sectorType } = useParams()

  if (sectorType !== SECTOR_LEVELS.CUSTOMER) {
    return <Navigate to=".." />
  }

  return <SAQList />
}

export default OrderActionSwitcher
