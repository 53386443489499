import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import {
  fetchPriceComplianceGeographyCompliance,
  fetchPriceComplianceGeographyFilters
} from 'store/priceCaptureCompliance/actions'
import { getPriceComplianceGeography, getPriceComplianceGeographyFilters } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import WideDataTable, { createColumns } from 'components/DataTable/WideDataTable'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import GeographyToggle from '../GeographyToggle'

const SUB_COLUMNS = [
  {
    title: 'Compl.',
    field: 'compliantCustomers'
  },
  {
    title: 'Non-compl.',
    field: 'notCompliantCustomers'
  },
  {
    title: 'Compl. %',
    field: 'percentCompliant'
  }
]

const createOptions = (filters, special) => {
  let options = []
  if (special && special === 'counts') {
    options = filters.map((filter) => {
      switch (filter) {
        case 1:
          return { label: 'Single', value: filter }
        case 2:
          return { label: '2 Pack', value: filter }
        default:
          return { label: filter, value: filter }
      }
    })
  } else if (special && special === 'types') {
    options = filters.map((filter) => {
      switch (filter) {
        case 20:
          return { label: 'Pack of 20', value: filter }
        case 25:
          return { label: 'Pack of 25', value: filter }
        default:
          return { label: `Pack of ${filter}`, value: filter }
      }
    })
  } else {
    if (typeof filters[0] === 'string') {
      options = filters.map((filter) => ({ label: filter, value: filter }))
    } else {
      options = filters.map((filter) => ({ label: filter.name, value: filter.id }))
    }
  }

  return sortBy(options, 'label')
}

const GeographyCompliance = ({
  span,
  fetchPriceComplianceGeographyCompliance,
  fetchPriceComplianceGeographyFilters
}) => {
  const { sectorType, sectorId } = useParams()
  const { currentProductType: category } = useContext(SectorContext)

  const [error, setError] = useState()
  const [geography, setGeography] = useState('territory')
  // filters
  const [selectedChannel, setSelectedChannel] = useState('all')
  const [selectedOwnershipType, setSelectedOwnershipType] = useState('all')
  const [selectedBanner, setSelectedBanner] = useState('all')
  const [selectedHeadoffice, setSelectedHeadoffice] = useState('all')
  const [selectedPackType, setSelectedPackType] = useState('all')
  const [selectedPackCount, setSelectedPackCount] = useState('all')
  const [selectedStrategyType, setSelectedStrategyType] = useState('all')

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.PRICE_COMPLIANCE_GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    category,
    channel: selectedChannel,
    ownershipType: selectedOwnershipType,
    banner: selectedBanner,
    headoffice: selectedHeadoffice,
    packType: selectedPackType,
    packCount: selectedPackCount,
    strategyType: selectedStrategyType
  })
  const isGeographyDataLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))
  const filtersDataKey = createDataKey(
    DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.PRICE_COMPLIANCE_GEOGRAPHY_FILTERS,
    { sectorType, sectorId }
  )
  const areFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filtersDataKey }))
  const filtersData = useSelector((state) => getPriceComplianceGeographyFilters(state))

  const geographyData = useSelector((state) => {
    const geographies = getPriceComplianceGeography(state)
    return (
      geographies?.[
        `geography-${geography}-${category}-${selectedChannel}-${selectedHeadoffice}-${selectedBanner}-${selectedPackType}-${selectedPackCount}-${selectedStrategyType}-${selectedOwnershipType}`
      ] || {}
    )
  })

  const strategies = useMemo(() => {
    return Array.from(
      Object.values(geographyData).reduce((acc, strat) => {
        Object.keys(strat.strategies).forEach((key) => acc.add(key))
        return acc
      }, new Set())
    ).sort()
  }, [geographyData])

  const cols = useMemo(() => {
    return createColumns([
      {
        title: capitalize(geography),
        field: 'geo'
      },
      ...strategies.map((strat) => ({ title: strat, field: strat, subColumns: SUB_COLUMNS }))
    ])
  }, [strategies, geography])

  const rows = useMemo(() => {
    return Object.values(geographyData).reduce((acc, data) => {
      const value = {
        geo: data.name
      }
      strategies.forEach((strategyId) => {
        value[camelCase(`${strategyId}_compliantCustomers`)] = data.strategies?.[strategyId]?.compliantCustomers || ''
        value[camelCase(`${strategyId}_notCompliantCustomers`)] =
          data.strategies?.[strategyId]?.notCompliantCustomers || ''
        value[camelCase(`${strategyId}_percentCompliant`)] = data.strategies?.[strategyId]?.percentCompliant || ''
      })
      acc.push(value)
      return acc
    }, [])
  }, [geographyData, strategies])

  const channelOptions = useMemo(() => {
    const options = createOptions(filtersData?.channels || [])
    options.splice(0, 0, { label: 'All Channels', value: 'all' })
    return options
  }, [filtersData, sectorType, sectorId, areFiltersLoading])

  const ownershipOptions = useMemo(() => {
    const options = createOptions(filtersData?.ownershipTypes || [])
    options.splice(0, 0, { label: 'All Ownership Types', value: 'all' })
    return options
  }, [filtersData, sectorType, sectorId, areFiltersLoading])

  const headofficeOptions = useMemo(() => {
    const options = createOptions(filtersData?.headoffices || [])
    options.splice(0, 0, { label: 'All Head Offices', value: 'all' })
    return options
  }, [filtersData, sectorType, sectorId, areFiltersLoading])

  const bannerOptions = useMemo(() => {
    const options = createOptions(filtersData?.banners || [])
    options.splice(0, 0, { label: 'All Banners', value: 'all' })
    return options
  }, [filtersData, sectorType, sectorId, areFiltersLoading])

  const packTypesOptions = useMemo(() => {
    const options = createOptions(filtersData?.packTypes || [], 'types')
    options.splice(0, 0, { label: 'All Pack Types', value: 'all' })
    return options
  }, [filtersData, sectorType, sectorId, areFiltersLoading])

  const packCountOptions = useMemo(() => {
    const options = createOptions(filtersData?.packCounts || [], 'counts')
    options.splice(0, 0, { label: 'All Pack Counts', value: 'all' })
    return options
  }, [filtersData, sectorType, sectorId, areFiltersLoading])

  const strategyTypeOptions = useMemo(() => {
    const options = createOptions(filtersData?.strategyTypes || [])
    options.splice(0, 0, { label: 'All Strategy Types', value: 'all' })
    return options
  }, [filtersData, sectorType, sectorId, areFiltersLoading])

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchPriceComplianceGeographyCompliance({
      dataKey: geographyDataKey,
      sectorType,
      sectorId,
      geography,
      category,
      channel: selectedChannel,
      ownershipType: selectedOwnershipType,
      banner: selectedBanner,
      headoffice: selectedHeadoffice,
      packType: selectedPackType,
      packCount: selectedPackCount,
      strategyType: selectedStrategyType
    })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [
    sectorType,
    sectorId,
    geography,
    category,
    selectedChannel,
    selectedOwnershipType,
    selectedBanner,
    selectedHeadoffice,
    selectedPackType,
    selectedPackCount,
    selectedStrategyType
  ])

  useEffect(() => {
    fetchPriceComplianceGeographyFilters({ dataKey: filtersDataKey, sectorType, sectorId })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }, [sectorType, sectorId])

  const getContent = () => {
    if (isGeographyDataLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    return <WideDataTable columns={cols} rows={rows} fillContainer alignCenter />
  }

  if (areFiltersLoading) {
    return (
      <Card title="Region performance" span={span}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  return (
    <Card
      title="Region performance"
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown
          key={0}
          options={channelOptions}
          value={selectedChannel}
          onChange={(e) => setSelectedChannel(e.target.value)}
        />,
        <Dropdown
          key={0}
          options={headofficeOptions}
          value={selectedHeadoffice}
          onChange={(e) => setSelectedHeadoffice(e.target.value)}
        />,
        <Dropdown
          key={0}
          options={bannerOptions}
          value={selectedBanner}
          onChange={(e) => setSelectedBanner(e.target.value)}
        />,
        <Dropdown
          key={0}
          options={packTypesOptions}
          value={selectedPackType}
          onChange={(e) => setSelectedPackType(e.target.value)}
        />,
        <Dropdown
          key={0}
          options={packCountOptions}
          value={selectedPackCount}
          onChange={(e) => setSelectedPackCount(e.target.value)}
        />,
        <Dropdown
          key={0}
          options={strategyTypeOptions}
          value={selectedStrategyType}
          onChange={(e) => setSelectedStrategyType(e.target.value)}
        />,
        <Dropdown
          key={0}
          options={ownershipOptions}
          value={selectedOwnershipType}
          onChange={(e) => setSelectedOwnershipType(e.target.value)}
        />,
        <GeographyToggle key={5} includeBrand includeOwnership geography={geography} setGeography={setGeography} />
      ]}
      hideOnSmallScreens={true}
    >
      {getContent()}
    </Card>
  )
}

GeographyCompliance.propTypes = {
  span: object,
  fetchPriceComplianceGeographyCompliance: func,
  fetchPriceComplianceGeographyFilters: func
}

export default connect(null, {
  fetchPriceComplianceGeographyCompliance,
  fetchPriceComplianceGeographyFilters
})(GeographyCompliance)
