import React, { useContext } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import Label from 'components/Label'

import * as spacing from 'styles/spacing'
import { smallFontSize } from 'styles/typography'

const Container = styled.div`
  font-size: ${smallFontSize};
`

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${spacing.medium};
  margin-top: ${spacing.medium};
`

const Item = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledLabel = styled(Label)`
  margin-bottom: 0;
`

const Value = styled.span`
  display: block;
`

const PayoutOverview = ({ territory, lastUpdated, totalPayoutWithRsmChallenges, paymentDate, totalPayout }) => {
  const { formatMessage } = useContext(LangContext)
  return (
    <Container>
      <div>{territory}</div>

      <List>
        <Item>
          <StyledLabel>{formatMessage({ messageId: 'payouts.withoutRSM' })}</StyledLabel>
          <Value>{totalPayout}</Value>
        </Item>
        <Item>
          <StyledLabel>{formatMessage({ messageId: 'payouts.withRSM' })}</StyledLabel>
          <Value>{totalPayoutWithRsmChallenges}</Value>
        </Item>
        {paymentDate && (
          <Item>
            <StyledLabel>{formatMessage({ messageId: 'payouts.date' })}</StyledLabel>
            <Value>{paymentDate}</Value>
          </Item>
        )}
        <Item>
          <StyledLabel>{formatMessage({ messageId: 'payouts.update' })}</StyledLabel>
          <Value>{lastUpdated}</Value>
        </Item>
      </List>
    </Container>
  )
}

PayoutOverview.propTypes = {
  territory: string.isRequired,
  lastUpdated: string.isRequired,
  paymentDate: string,
  totalPayout: string.isRequired,
  totalPayoutWithRsmChallenges: string
}

export default PayoutOverview
