import React from 'react'
import { arrayOf, bool, number, shape, string } from 'prop-types'
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import tw from 'twin.macro'

const TooltipContainer = tw.div`w-auto -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white`

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length)
    return (
      <div>
        <TooltipContainer>{`${payload[0].value.toLocaleString()}`}</TooltipContainer>
      </div>
    )
}

CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ value: number }))
}

const DataGraph = ({ color, data }) => {
  return (
    <div className="-mr-24 h-8 w-64 overflow-visible">
      <ResponsiveContainer height="99%" width="99%">
        <LineChart data={data} margin={{ bottom: 5, left: 5, right: 5, top: 5 }}>
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{
              outline: 'none'
            }}
            animationDuration={300}
            allowEscapeViewBox={{ x: true }}
            cursor={false}
            offset={0}
          />
          <Line
            dataKey="d"
            strokeWidth={2}
            stroke={color}
            dot={false}
            activeDot={{ stroke: '#fff', strokeWidth: 4, r: 6 }}
            isAnimationActive={false}
          />
          <YAxis hide domain={('dataMin', 'dataMax')} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

DataGraph.propTypes = {
  color: string,
  data: arrayOf(shape({ d: number }))
}

export default DataGraph
