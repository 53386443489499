import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { transparentize } from 'polished'
import { func, object } from 'prop-types'
import { Pie, PieChart } from 'recharts'
import tw from 'twin.macro'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchEngagement } from 'store/extraHub/actions'
import { getCurrentEngagement } from 'store/extraHub/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const DataLabel = tw.span`block text-2xs font-medium text-slate-500`

const ChartWrapper = tw.div`relative flex min-h-fit h-48 grow flex-col items-center justify-center`

const DataXL = tw.span`block text-xl font-medium text-slate-900`

const Data2XL = tw.span`block text-3xl font-medium text-slate-900`

const Spacer = tw.div`shrink-0 h-9 w-full`

const convertToPieData = (engagementPercent) => [
  {
    name: 'engagement',
    value: engagementPercent,
    fill: '#1F4E9A'
  },
  {
    name: 'rest',
    value: 100 - engagementPercent,
    fill: transparentize(0.8, '#1F4E9A')
  }
]

const UserEngagement = ({ span, fetchEngagement }) => {
  const { sectorType, sectorId } = useParams()
  const [error, setError] = useState()

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.ENGAGEMENT, { sectorType, sectorId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  useEffect(() => {
    fetchEngagement({ sectorType, sectorId, dataKey })
      .then(() => isMounted.current && setError())
      .catch((error) => isMounted.current && setError(error))
  }, [sectorType, sectorId])

  const data = useSelector((state) => getCurrentEngagement(state))
  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    return (
      <div className="flex h-full flex-col justify-between">
        <Spacer />
        <div className="flex h-full pt-8">
          <div className="my-auto w-16 space-y-5">
            <div className="space-y-2">
              <DataLabel>Engaged</DataLabel>
              <DataXL>{data.customerCount}</DataXL>
            </div>
            <div className="space-y-2">
              <DataLabel>Active</DataLabel>
              <DataXL>{data.activeCustomers}</DataXL>
            </div>
          </div>
          <ChartWrapper>
            <div className="absolute min-w-fit">
              <PieChart width={200} height={200} margin={0}>
                <Pie
                  data={convertToPieData(data.engagement)}
                  startAngle={90}
                  endAngle={-270}
                  innerRadius="90%"
                  outerRadius="100%"
                  isAnimationActive={false}
                />
              </PieChart>
            </div>
            <DataLabel>Users</DataLabel>
            <Data2XL>{data.engagement}%</Data2XL>
          </ChartWrapper>
        </div>
        <Spacer />
      </div>
    )
  }

  return (
    <Card title="Users" span={span} displayAmplify>
      {getContent()}
    </Card>
  )
}

UserEngagement.propTypes = {
  span: object,
  fetchEngagement: func
}

export default connect(null, {
  fetchEngagement
})(UserEngagement)
